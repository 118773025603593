<template>
  <div class="auction-manager-blade-wrapper auction-manager-light-background">
    <hb-blade-header
      title="Auction Manager"
      back-button-off
      more-title-space
      @close="$emit('close')"
    >
      <template v-slot:right>
        <div style="position:absolute;top:0;left:43%;height:20px;width:200px;z-index:99999" @click="tempAuctionNotSold = !tempAuctionNotSold">&nbsp;</div>
        <help-juice blade-header />
      </template>
    </hb-blade-header>

      <div class="pb-4 px-4 pauction-manager-light-backgroundb-3 auction-manager-white-background" :class="{ 'pt-4' : !auctionUpdating }">
        <hb-notification
          v-if="!auctionUpdating"
          :left-cols="scheduled_auction.id && !isAuctionDay && !isMoveOut ? 10 : 12"
          :right-cols="scheduled_auction.id && !isAuctionDay && !isMoveOut ? 2 : 0"
          type="error"
          mdi-code="mdi-custom-auction"
          :title="auctionLease.auction_status == 'auction_day' ? 'Auction Closed:' : auctionLease.auction_status == 'auction_payment' ? 'Auction Information:' : 'Auction:'"
          notDismissable
          class="auction-dialog-notification"
        >{{ getAuctionMessage(auctionLease) }}
          <template v-slot:condensedActions v-if="scheduled_auction.id && !isAuctionDay && !isMoveOut">
            <hb-menu
              options
              options-hover-type="error"
            >
              <v-list>
                <v-list-item @click="showRemoveAuctionConfirmation = true" :ripple="false">
                  <v-list-item-title>Cancel Auction</v-list-item-title>
                </v-list-item>
              </v-list>
            </hb-menu>
        </template>
        <template v-slot:actions v-if="scheduled_auction.id && !isAuctionDay && !isMoveOut">
          <hb-btn small color="secondary" @click="showRemoveAuctionConfirmation = true">Cancel Auction</hb-btn>
        </template>
        </hb-notification>
        <mini-profile-view
          v-if="contact_id"
          :contact-id="contact_id"
          :use-contact="false"
          class="mt-4"
        />
      </div>
      <VDivider />

      <hb-stepper v-model="stepper">
        <hb-stepper-header>
            <hb-stepper-step
              @click="goToStep(0)"
              step="0"
              :complete="completed0"
              :rules="[() => hasNoErrors0]"
            >
              Schedule Auction
            </hb-stepper-step>

            <v-divider></v-divider>

            <hb-stepper-step
              @click="goToStep(1)"
              step="1"
              :complete="completed1"
              :rules="[() => hasNoErrors1]"
            >
              Images and Inventory
              <v-badge
                v-if="auctionImageSet && auctionImageSet.uploads && auctionImageSet.uploads.length"
                :content="auctionImageSet.uploads.length > 99 ? '99+' : auctionImageSet.uploads.length"
                :value="auctionImageSet.uploads.length > 0"
                :color="'#F26500'"
                offset-y="4"
                class="pl-1"
              ></v-badge>
            </hb-stepper-step>

            <v-divider></v-divider>

            <hb-stepper-step
              @click="goToStep(2)"
              step="2"
              :complete="auditDelinquency.audit_status === 'completed' || auditDelinquency.audit_status === 'skipped'"
              :rules="[() => hasNoErrors2]"
            >
              Audit
            </hb-stepper-step>

            <v-divider></v-divider>

            <hb-stepper-step
              @click="goToStep(3)"
              step="3"
              :complete="completed3"
              :rules="[() => hasNoErrors3]"
            >
              Auction Sale
            </hb-stepper-step>

        </hb-stepper-header>
      </hb-stepper>
      
        <div v-if="stepper === 1" class="pb-12 auction-manager-light-background">
          <v-row
            v-if="fetchingMedia"
            class="fill-height my-12 py-12"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="#00848E"
            ></v-progress-circular>
          </v-row>
          <hb-empty-state v-else-if="!auctionLease.auction_status" class="ma-4" background-color="#FFFFFF">
            Lock Cut and Inventory has not been completed for this Lease.
          </hb-empty-state>
          <div v-else class="pt-1 pb-12 px-4">
            <v-expansion-panels v-model="stepOneImagesAccordion" multiple>
              <hb-expansion-panel>
                <template v-slot:title>
                  Images and Inventory
                </template>
                <template v-slot:content>
                  <hb-media-viewer
                    grid
                    :uploader="!isPublished"
                    v-model="mediaViewer"
                    @upload="handleUploadedFiles"
                    :upload-error="imageError"
                    @delete="deleteMedia"
                    @disable-delete="deletingMedia"
                  >
                    <template v-slot:slides>
                      <slide
                        v-for="(upload, i) in uploads"
                        :key="'upload_' + i"
                        :id="'temp_upload_id_' + i"
                        :src="upload.src"
                        :upload="true"
                        :index="i"
                      />
                      <slide
                        v-for="(slide, i) in media"
                        :key="'slide_' + i"
                        :id="slide.id"
                        :src="slide.media_src"
                        :thumbnail="slide.media_thumbnail_src"
                      />
                    </template>
                  </hb-media-viewer>
                  <div class="mx-4 mb-4">
                    <div class="mb-2 font-weight-medium">General Description</div>
                    <HbTextarea
                      v-model="mediaDescription"
                      v-validate="'required|max:1000'"
                      name="description"
                      data-vv-scope="lockcut"
                      data-vv-as="General Description"
                      :error="errors.has('lockcut.description')"
                      :placeholder="isPublished ? '' : 'Enter General Description'"
                      :readonly="isPublished"
                      @keydown="allowMediaSave = true"
                    />
                    <VDivider />
                  </div>
                  <v-row no-gutters class="mx-4">
                    <div class="my-2 font-weight-medium">Categories / Keywords</div>
                    <div class="auction-dialog-categories-list" v-if="!isPublished">
                      <!-- have to use v-checkbox instead of hb-checkbox due to array passing up and down incompatibility - please do not replace with HbCheckbox -->
                      <v-checkbox
                        v-for="(category, i) in categories"
                        :key="'category_' + i"
                        v-model="categoriesSelected"
                        :label="category.name"
                        :value="category"
                        hide-details
                        class="auction-dialog-category pa-0 mx-0 my-2"
                        @change="allowMediaSave = true"
                      ></v-checkbox>
                    </div>
                    <div class="auction-dialog-categories-list" v-else-if="categoriesSelected.length">
                      <!-- have to use v-checkbox instead of hb-checkbox due to array passing up and down incompatibility - please do not replace with HbCheckbox -->
                      <v-checkbox
                        v-for="(category, i) in categoriesSelected"
                        :key="'category_' + i"
                        v-model="categoriesSelected"
                        :label="category.name"
                        :value="category"
                        hide-details
                        read-only
                        disabled
                        class="auction-dialog-category pa-0 mx-0 my-2"
                      ></v-checkbox>
                    </div>
                    <div class="auction-dialog-categories-list" v-else>
                      <hb-empty-state class="my-2" background-color="#FFFFFF">
                        No Categories Set
                      </hb-empty-state>
                    </div>
                  </v-row>
                  
                  <v-row no-gutters class="ma-4">
                    Tamper Tag:
                    <HbTextField
                      v-model="tamperTag"
                      v-validate="'max:45'"
                      name="tamper"
                      data-vv-scope="lockcut"
                      data-vv-as="Tamper Tag"
                      :error="errors.has('lockcut.tamper')"
                      :placeholder="isPublished ? '' : 'Enter Tamper Tag'"
                      :readonly="isPublished"
                      class="pl-3"
                      @keydown="allowMediaSave = true"
                      style="position:relative;top:2px"
                    />
                  </v-row>
                </template>
                <template v-slot:footer>
                  <hb-bottom-action-bar cancel-off>
                    <template v-slot:right-actions>
                      <hb-btn
                        color="primary"
                        @click="saveAuctionImagesAndDetails()"
                        :disabled="!allowMediaSave || savingAuctionMediaInProgess"
                        :loading="savingAuctionMediaInProgess"
                      >Save</hb-btn>
                    </template>
                  </hb-bottom-action-bar>
                </template>
              </hb-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
        <div v-else-if="stepper === 2" class="pb-12">
          <v-row
            v-if="fetchingDelinquency"
            class="fill-height my-12 py-12"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="#00848E"
            ></v-progress-circular>
          </v-row>
          <div v-else-if="delinquency.length" class="pb-12 auction-manager-light-background">
            <v-row no-gutters class="py-3 px-4 hb-text-light auction-manager-white-background">
              <HbIcon
                v-if="auditDelinquency.audit_status && (auditDelinquency.audit_status === 'completed' || auditDelinquency.audit_status === 'skipped')"
                color="#02AD0F"
                mdi-code="mdi-check"
                class="pr-2"
                style="position:relative;top:-3px;"
              />
              {{ auditDelinquency.audit_status && auditDelinquency.audit_status === 'completed' ? 'Audit Completed' : auditDelinquency.audit_status && auditDelinquency.audit_status === 'skipped' ? 'Audit Skipped' : 'Please verify each step in the Delinquency Timeline by clicking on the "Mark All as Verified" button.' }}
              <hb-tooltip v-if="auditDelinquency.audited_date && auditDelinquency.audited_by">
                <template v-slot:item>
                  <HbIcon
                    small
                    mdi-code="mdi-account-clock-outline"
                    class="pl-2"
                    style="position:relative;top:-2px;"
                  />
                </template>
                <template v-slot:header>
                  Last Updated:
                </template>
                <template v-slot:body>
                  {{ auditDelinquency.audited_by }}<br />
                  {{ auditDelinquency.audited_date | formatLocalDateTimeServices }}
                </template>
              </hb-tooltip>
            </v-row>
            <VDivider />
            <v-row v-if="auditDelinquency.audit_status !== 'completed' && auditDelinquency.audit_status !== 'skipped'" no-gutters class="py-3 px-4 auction-manager-light-background">
              <hb-btn color="secondary" small @click="updateAudit('completed')" :loading="updatingAuditStatus" :disabled="updatingAuditStatus">Mark All as Verified</hb-btn>
              <hb-btn class="pl-3" color="secondary" small @click="updateAudit('skipped')" :loading="updatingAuditStatus" :disabled="updatingAuditStatus">Skip Audit</hb-btn>
            </v-row>
            <delinquency
              :contact="{ id: contact_id }"
              :active_delinquencies="delinquency"
              :hide-header="true"
              :disable-elevation="true"
            ></delinquency>
          </div>
          <div v-else>
            <hb-empty-state class="ma-4">
              No Delinquency Found
            </hb-empty-state>
          </div>
        </div>
        <div class="pa-0 ma-0" v-else>
          <div class="pt-0 px-0 pb-12">
            <v-row
              v-if="auctionUpdating || (stepper === 3 && schedule_new_auction)"
              class="fill-height my-12 py-12"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="#00848E"
              ></v-progress-circular>
            </v-row>
            <div v-else-if="stepper === 3 && !scheduled_auction.id" class="pb-6">
              <hb-empty-state class="ma-4" background-color="#FFFFFF">
                There is no Auction scheduled yet.
              </hb-empty-state>
            </div>
            <div v-else-if="stepper === 3 && scheduled_auction.id && scheduled_auction.auction_type === 'storage-treasures' && !isPublished" class="pb-6">
              <hb-empty-state class="ma-4" background-color="#FFFFFF">
                Please Publish Online Auction before continuing.
              </hb-empty-state>
            </div>
            <div v-else-if="stepper === 3 && scheduled_auction.id && !isAuctionDay && !isMoveOut" class="pb-6">
              <hb-empty-state class="ma-4" background-color="#FFFFFF">
                Auction Sale details cannot be set until the date of the Auction.
              </hb-empty-state>
            </div>
            <div v-else-if="stepper === 3 && scheduled_auction.id && scheduled_auction.auction_type === 'storage-treasures' && !isPublished && cannotPublish" class="pb-6">
              <div class="auction-manager-not-sold-container ma-6 py-5 px-5 pb-4">
                <v-row no-gutters class="hb-font-header-3-medium">
                  <HbIcon mdi-code="mdi-custom-auction" color="#101318" class="pr-2" />
                  Auction Was Not Published before Scheduled Auction End Date
                </v-row>
                <v-row no-gutters class="hb-text-light auction-manager-not-sold-description">
                  Scheduled Auction for Space #{{ auctionLease.Unit.number }} ending at {{ scheduled_auction.date | formatLocalShortDate }} @ {{ scheduled_auction.scheduled_date | formatTime12Hour }} was not published to StorageTreasures before the scheduled end date and time. You can reschedule this space back to auction and publish it before the scheduled auction end date and time has passed. You can also create a task to resend your lien notices or to redo any other delinquency related tasks.
                </v-row>
                <v-row no-gutters class="mt-3">
                  <hb-radio-group v-model="notSoldRadioGroup" row>
                    <HbRadio
                      label="Create Task"
                      value="dispose"
                    />

                    <HbRadio
                      label="Reschedule Auction"
                      value="reschedule"
                    />
                  </hb-radio-group>
                </v-row>
              </div>
              <todo v-model="todo" v-if="todo" @close="closeCreateTask"></todo>
            </div>
            <div v-else-if="stepper === 3 && scheduled_auction.id && isPublished && isAuctionNotSold" class="pb-6">
              <div class="auction-manager-not-sold-container ma-6 py-5 px-5 pb-4">
                <v-row no-gutters class="hb-font-header-3-medium">
                  <HbIcon mdi-code="mdi-custom-auction" color="#101318" class="pr-2" />
                  Auction Not Sold
                </v-row>
                <v-row no-gutters class="hb-text-light auction-manager-not-sold-description">
                  Auction for Space #{{ auctionLease.Unit.number }} has ended {{ scheduled_auction.date | formatLocalShortDate }} @ {{ scheduled_auction.scheduled_date | formatTime12Hour }} and was not sold. You can reschedule this space back to auction or create a task to dispose of the content of the space.
                </v-row>
                <v-row no-gutters class="mt-3">
                  <hb-radio-group v-model="notSoldRadioGroup" row>
                    <HbRadio
                      label="Dispose Content"
                      value="dispose"
                    />

                    <HbRadio
                      label="Reschedule Auction"
                      value="reschedule"
                    />
                  </hb-radio-group>
                </v-row>
              </div>
              <todo v-model="todo" v-if="todo" @close="closeCreateTask"></todo>
            </div>
            <div v-else>
              <v-expansion-panels v-model="auctionFormAccordion" multiple class="pt-1 px-4 pb-12">
                <hb-expansion-panel>
                  <template v-slot:title>
                    Auction Information
                  </template>
                  <template v-slot:content>
                    <v-row class="hb-table-row ma-0 pa-0">
                      <v-col class="hb-table-label py-4 pl-4" cols="3">
                        Auction Type &amp; Platform
                      </v-col>
                      <v-col class="ma-0 pa-4" cols="6">
                        <HbSelect
                          v-if="!scheduled_auction.id || change_auction_info"
                          v-model="scheduled_auction.auction_type"
                          :items="auctionTypes"
                          item-value="value"
                          item-text="text"
                          name="auction_type"
                          data-vv-as="Auction Type &amp; Platform"
                          placeholder="Select Auction Type &amp; Platform"
                          :clearable="false"
                          :error="errors.has('auction_type')"
                          v-validate="'required'"
                        />
          
                        <v-row
                          class="pt-0 pl-4"
                          v-if="scheduled_auction.id && !change_auction_info"
                        >
                          <v-col v-if="scheduled_auction.id" cols="11" class="text-capitalize pa-0 ma-0">
                            {{ getScheduledAuctionType(scheduled_auction.auction_type) }}
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row class="hb-table-row ma-0 pa-0">
                      <v-col class="hb-table-label py-4 pl-4" cols="3">
                        Auction Date &amp; Time
                      </v-col>
                      <v-col class="ma-0 pt-3 pb-0" :class="{'hb-form-editable' : scheduled_auction.id && fieldEditing != 'date_time' && !isAuctionDay && !isMoveOut && !change_auction_info}" cols="9">
                        <v-row v-if="(!scheduled_auction.id || fieldEditing == 'date_time' || change_auction_info) && scheduled_auction.auction_type === 'storage-treasures'" no-gutters class="my-1 hb-text-light">
                            <div style="position:relative;top:-2px;" class="mr-2">
                                <HbIcon small mdi-code="mdi-information" color="#3D8FFF" />
                            </div>
                            <div>The start date for online auctions will be 10 days before the selected end date.</div>
                        </v-row>
                        <v-row class="pa-0">
                          <v-col
                            cols="6"
                            class="pt-0 mt-0"
                            v-if="!scheduled_auction.id || fieldEditing == 'date_time' || change_auction_info"
                          >
                            <hb-date-picker
                              v-model="scheduled_auction.date"
                              v-validate="'required'"
                              id="date"
                              name="due_date"
                              :label="scheduled_auction.auction_type === 'storage-treasures' || scheduled_auction.auction_type === 'online' ? 'Select Auction End Date' : 'Select Auction Date'"
                              :solo="false"
                              :min="minAuctionDate"
                              :data-vv-as="scheduled_auction.auction_type === 'storage-treasures' || scheduled_auction.auction_type === 'online' ? 'Auction End Date' : 'Auction Date'"
                              :error="errors.has('due_date')"
                            ></hb-date-picker>
                          </v-col>
                          <v-col
                            cols="6"
                            class="pt-0 mt-0"
                            v-if="!scheduled_auction.id || fieldEditing == 'date_time' || change_auction_info"
                          >
                            <label class="v-label v-label--active theme--light auction-dialog-font-11px">{{ scheduled_auction.auction_type === 'storage-treasures' ? 'Select Ending Time for Auction' : 'Select Starting Time for Auction' }}</label>
                            <v-row no-gutters class="mt-1">
                              <v-col md="3" class="ma-0 pa-0">
                                <hb-text-field
                                  id="auction_hour"
                                  name="auction_hour"
                                  placeholder="HH"
                                  v-model="timeValue.hours"
                                  data-vv-as="Starting Hour"
                                  type="number"
                                  min="1"
                                  max="12"
                                  width="80%"
                                  v-validate="'required|numeric|max_value:12|min_value:1'"
                                  :error="errors.has('auction_hour')"
                                  class="mt-0 pt-0 time-w-80"
                                ></hb-text-field>
                              </v-col>
                              <v-col md="3" class="ma-0 pa-0">
                                <hb-text-field
                                  id="auction_minutes"
                                  name="auction_minutes"
                                  placeholder="MM"
                                  v-model="timeValue.minutes"
                                  data-vv-as="Starting Minute"
                                  type="number"
                                  min="0"
                                  max="59"
                                  width="80%"
                                  v-validate="'required|numeric|max_value:59|min_value:0'"
                                  :error="errors.has('auction_minutes')"
                                  class="mt-0 pt-0 time-w-80"
                                ></hb-text-field>
                              </v-col>
                              <v-col md="6" class="ma-0 pa-0">
                                <hb-select
                                  :items="['AM','PM']"
                                  id="auction_ampm"
                                  name="auction_ampm"
                                  v-model="timeValue.ampm"
                                  :clearable="false"
                                  placeholder="AM/PM"
                                  v-validate="'required'"
                                  data-vv-name="auction_ampm"
                                  data-vv-as="Starting AM/PM"
                                  width="80%"
                                  :error="errors.has('auction_ampm')"
                                  class="mt-0 pt-0 time-w-80"
                                ></hb-select>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col
                            cols="12"
                            v-if="scheduled_auction.id && fieldEditing == 'date_time' && !change_auction_info"
                            class="ma-0 pt-1 pb-3 d-flex align-center"
                          >
                            <hb-btn
                              color="primary"
                              small
                              @click="showEditAuctionConfirmation = true"
                              >Save</hb-btn
                            >
                            <hb-link class="ml-2" small @click="clearEditField()"
                              >Cancel</hb-link
                            >
                          </v-col>
                        </v-row>
          
                        <v-row
                          class="py-1 pl-4"
                          v-if="fieldEditing != 'date_time' && !isAuctionDay && !isMoveOut && !change_auction_info && ((scheduled_auction.auction_type === 'storage-treasures' && !isPublished) || scheduled_auction.auction_type !== 'storage-treasures')"
                          @click="editField('date_time')"
                        >
                          <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                            <div class="hb-text-light mb-3">Auction has been scheduled for</div>
                            <div v-if="scheduled_auction.auction_type === 'storage-treasures'" class="mb-1 font-weight-medium">Auction Start Date:</div>
                            <span v-if="scheduled_auction.auction_type === 'storage-treasures'">
                                <div class="mb-1">{{ tenDaysBeforeScheduledAuction | formatLocalShortDate }}</div>
                            </span>
                            <span v-else>
                                <div class="mb-1">{{ scheduled_auction.date | formatLocalShortDate }}</div>
                                <div class="mb-3">{{ scheduled_auction.scheduled_date | formatTime12Hour }}</div>
                            </span>
                            
                            <div v-if="scheduled_auction.auction_type === 'storage-treasures'" class="hb-text-error">
                                <div class="mb-1 font-weight-medium">Auction End Date &amp; Time:</div>
                                <div class="mb-1">{{ scheduled_auction.date | formatLocalShortDate }}</div>
                                <div class="mb-3">{{ scheduled_auction.scheduled_date | formatTime12Hour }}</div>
                            </div>
                            <div
                              v-if="scheduled_auction.auction_type === 'storage-treasures' && isPublished && scheduled_auction.vendor_auction_url"
                              class="mt-n1 mb-4"
                            >
                              <hb-link
                                :href="scheduled_auction.vendor_auction_url"
                                target="_blank"
                              >View Auction on StorageTreasures</hb-link>
                            </div>
                          </v-col>
                          <v-col v-if="scheduled_auction.id" cols="1" class="pa-0 ma-0">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-hover v-slot:default="{ hover }">
                                  <span v-on="on">
                                    <hb-icon small :color="hover ? '#101318' : ''"
                                      >mdi-pencil</hb-icon
                                    >
                                  </span>
                                </v-hover>
                              </template>
                              <span>Edit</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                        <v-row
                          class="py-1 pl-5" 
                          v-else-if="!change_auction_info && fieldEditing != 'date_time'"
                        >
                          <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                            <div class="hb-text-light mb-3">Auction has been scheduled for</div>
                            <div v-if="scheduled_auction.auction_type === 'storage-treasures'" class="mb-1 font-weight-medium">Auction Start Date:</div>
                            <span v-if="scheduled_auction.auction_type === 'storage-treasures'">
                                <div class="mb-1">{{ tenDaysBeforeScheduledAuction | formatLocalShortDate }}</div>
                            </span>
                            <span v-else>
                                <div class="mb-1">{{ scheduled_auction.date | formatLocalShortDate }}</div>
                                <div class="mb-3">{{ scheduled_auction.scheduled_date | formatTime12Hour }}</div>
                            </span>
                            
                            <div v-if="scheduled_auction.auction_type === 'storage-treasures'" class="hb-text-error">
                                <div class="mb-1 font-weight-medium">Auction End Date &amp; Time:</div>
                                <div class="mb-1">{{ scheduled_auction.date | formatLocalShortDate }}</div>
                                <div class="mb-3">{{ scheduled_auction.scheduled_date | formatTime12Hour }}</div>
                            </div>
                            
                            <div
                              v-if="scheduled_auction.auction_type === 'storage-treasures' && isPublished && scheduled_auction.vendor_auction_url"
                              class="mt-n1 mb-4"
                            >
                              <hb-link
                                :href="scheduled_auction.vendor_auction_url"
                                target="_blank"
                              >View Auction on StorageTreasures</hb-link>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <span v-if="stepper === 3">
                      <v-row class="hb-table-row ma-0 pa-0" v-if="!change_auction_info && (isAuctionDay || isMoveOut)">
                        <v-col class="hb-table-label py-4 pl-4" cols="3"> Winning Bid
                          <span style="position:relative;top:-2px;">
                            <hb-tooltip>
                                  <template v-slot:item>
                                    <hb-icon small mdi-code="mdi-information"></hb-icon>
                                  </template>
                                  <template v-slot:body>
                                    Amount of the bid placed by the Winner. Auction Tax will be calculated on this amount.                      
                                  </template>
                            </hb-tooltip>
                          </span>
                        </v-col>
                        <v-col class="ma-0 pl-4" cols="9"  v-if="!isMoveOut">
                          <v-row>
                            <v-col cols="4">
                              <v-text-field
                                v-if="scheduled_auction.id"
                                label="Enter Amount"
                                class="pa-0 mt-0"
                                flat
                                id="amount"
                                name="winning_bid_amount"
                                data-vv-as="winning bid amount"
                                v-model="scheduled_auction.amount"
                                v-validate="schedule_new_auction ? '': 'required|decimal:2|min_value:0'"
                                :error="errors.has('winning_bid_amount')"
                                @blur="validateField('winning_bid_amount')"
                                hide-details
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="4">
                              <v-text-field
                                v-if="scheduled_auction.id"
                                class="pa-0 mt-0"
                                flat                 
                                v-model="scheduled_auction.auction_cleaning_deposit"
                                v-validate="'required|decimal:2|min_value:0'"
                                data-vv-as="cleaning deposit"
                                label="Cleaning Deposit"
                                id="cleaning_deposit"
                                name="cleaning_deposit"
                                :error="errors.has('cleaning_deposit')"
                                @blur="validateField('cleaning_deposit')"
                                hide-details
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="4">
                              <v-text-field
                                class="pa-0 mt-0"
                                flat                  
                                v-if="scheduled_auction.id"
                                v-validate="'required|min_value:0'"
                                data-vv-as="clean out period"
                                label="Clean Out Period"
                                id="cleaning_period"
                                name="cleaning_period"
                                :error="errors.has('cleaning_period')"
                                v-model="scheduled_auction.auction_cleaning_period"
                                hide-details
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col class="mx-0 mt-1 pl-4" cols="9" v-if="isMoveOut">
                          <v-row
                            class="pt-0 pl-4"
                            v-if="scheduled_auction.id"
                          >
                            <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                              {{ scheduled_auction.amount | formatMoney }}
                              <hb-status class="pl-2" style="position:relative;top:-.5px;" color="#02AD0F">Paid</hb-status>
                            </v-col>
                          </v-row>
                          <v-row
                            class="pt-3 pl-4"
                            v-if="scheduled_auction.id"
                          >
                            <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                              Cleaning Deposit: {{ auctionCleaningDeposit | formatMoney }}
                              <hb-btn class="pl-2" style="position:relative;top:-1.5px;" small color="secondary" @click="refundAuction" v-if="!scheduled_auction.refund_id && !auction_refund_id" :disabled="disableRefundButton"
                                >Refund</hb-btn>
                              <hb-status color="#919EAB" v-else>Refunded</hb-status>
                            </v-col>
                          </v-row>
                          <v-row
                            class="pt-3 pl-4"
                            v-if="scheduled_auction.id"
                          >
                            <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                              Cleaning Up Period: {{ scheduled_auction.cleaning_period }} Days
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="hb-table-row ma-0 pa-0" v-if="!change_auction_info && isAuctionDay && !isMoveOut">
                        <v-col class="hb-table-label py-4 pl-4" cols="3">
                          <div class="buyer-premium-label">
                            <span class="block">Buyer Premium</span>
                            <span class="ml-1">
                              <hb-tooltip>
                                <template v-slot:item>
                                  <hb-icon small mdi-code="mdi-information"></hb-icon>
                                </template>
                                <template v-slot:body>
                                  Premium paid by the bidder to the auctioneer<br>or online auction company.<br>This is for record purpose only.                      </template>
                              </hb-tooltip>
                            </span>
                          </div>
                        </v-col>
                        <v-col class="ma-0 pl-4" cols="9">
                          <v-row no-gutters style="margin-top:6px;">
                            <v-col cols="6" class="ma-0 pa-0 pr-3">
                              <hb-select
                                v-model="scheduled_auction.BuyerPremium.type"
                                v-validate="'required'"
                                :items="feeTypes"
                                item-value="value"
                                item-text="text"
                                name="buyer_premium_type" 
                                data-vv-as="Buyer Premium Type"
                                placeholder="Select Buyer Premium Type"
                                :error="errors.has('buyer_premium_type')"
                                @blur="validateField('buyer_premium_type')"
                              >
                              </hb-select>
                            </v-col>
                            <v-col cols="6" class="ma-0 pa-0">
                              <hb-text-field 
                                v-model="scheduled_auction.BuyerPremium.value"
                                v-validate="scheduled_auction.BuyerPremium.type === 'percent' ? 'required|decimal:2|min_value:0.1|max_value:100': 'required|decimal:2|min_value:0'"
                                placeholder="Enter Buyer Premium Value"
                                name="buyer_premium_value"
                                data-vv-as="Buyer Premium Value"
                                :error="errors.has('buyer_premium_value')"
                                @blur="validateField('buyer_premium_value')"
                              >
                              </hb-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="hb-table-row ma-0 pa-0" v-if="!change_auction_info && !isAuctionDay && isMoveOut && isBuyerPremiumPresent">
                        <v-col class="hb-table-label py-4 pl-4" cols="3">
                          <div class="buyer-premium-label">
                            <span class="block">Buyer Premium</span>
                            <span class="ml-1">
                              <hb-tooltip>
                                <template v-slot:item>
                                  <hb-icon small mdi-code="mdi-information"></hb-icon>
                                </template>
                                <template v-slot:body>
                                  Premium paid by the bidder to the auctioneer<br>or online auction company.<br> This is for record purpose only.
                                </template>
                              </hb-tooltip>
                            </span>
                          </div>
                        </v-col>
                        <v-col class="ma-0 pl-4" cols="9" v-if="isMoveOut">
                          <v-row class="pt-0 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0">
                              Type: {{ scheduled_auction.BuyerPremium.type }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0">
                              Value: {{ scheduled_auction.BuyerPremium.value }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0">
                              Product: {{ getAuctionProductNameById(scheduled_auction.BuyerPremium.product_id) }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="hb-table-row ma-0 pa-0" v-if="!change_auction_info && isAuctionDay && !isMoveOut">
                        <v-col class="hb-table-label py-4 pl-4" cols="3">
                          <div class="buyer-premium-label">
                            <span class="block">Seller Premium</span>
                            <span class="ml-1">
                              <hb-tooltip>
                                <template v-slot:item>
                                  <hb-icon small mdi-code="mdi-information"></hb-icon>
                                </template>
                                <template v-slot:body>
                                  The premium paid by the property to the auctioneer or online auction company.<br>Some companies refer to it as Purchase Deposit.<br>Final Bid Amount will be the difference between Winning Bid and Seller’s Premium
                                </template>
                              </hb-tooltip>
                            </span>
                          </div>
                        </v-col>
                        <v-col class="ma-0 pl-4" cols="9">
                          <v-row no-gutters style="margin-top:6px;">
                            <v-col cols="6" class="ma-0 pa-0 pr-3">
                              <hb-select
                                v-model="SellerPremium.type"
                                v-validate="'required'"
                                :items="feeTypes"
                                item-value="value"
                                item-text="text"
                                name="seller_premium_type" 
                                data-vv-as="Seller Premium Type"
                                placeholder="Select Seller Premium Type"
                                :error="errors.has('seller_premium_type')"
                                @blur="validateField('seller_premium_type')"
                              >
                              </hb-select>
                            </v-col>
                            <v-col cols="6">
                              <hb-text-field 
                                v-model="SellerPremium.value"
                                v-validate="SellerPremium?.type === 'percent' ? 'required|decimal:2|min_value:0.1|max_value:100': 'required|decimal:2|min_value:0'"
                                placeholder="Enter Seller Premium Value"
                                name="seller_premium_value"
                                data-vv-as="Seller Premium Value"
                                :error="errors.has('seller_premium_value').length"
                                @blur="validateField('seller_premium_value')"
                              >
                              </hb-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>

                      <v-row class="hb-table-row ma-0 pa-0" v-if="!change_auction_info && !isAuctionDay && isMoveOut && isSellerPremiumPresent">
                        <v-col class="hb-table-label py-4 pl-4" cols="3">
                          <div class="buyer-premium-label">
                            <span class="block">Seller Premium</span>
                            <span class="ml-1">
                              <hb-tooltip>
                                <template v-slot:item>
                                  <hb-icon small mdi-code="mdi-information"></hb-icon>
                                </template>
                                <template v-slot:body>
                                  The premium paid by the property to the auctioneer or online auction company.<br>Some companies refer to it as Purchase Deposit.<br>Final Bid Amount will be the difference between Winning Bid and Seller’s Premium
                                </template>
                              </hb-tooltip>
                            </span>
                          </div>
                        </v-col>
                        <v-col class="ma-0 pl-4" cols="9" v-if="isMoveOut">
                          <v-row class="pt-0 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0">
                              Type: {{ SellerPremium.type }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0">
                              Value: {{ SellerPremium.value }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0">
                              Product: {{ getAuctionProductNameById(SellerPremium.product_id) }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>

                      <v-row class="hb-table-row ma-0 pa-0" v-if="!change_auction_info && (isAuctionDay || isMoveOut)">
                        <v-col class="hb-table-label py-4 pl-4" cols="3">Auction Winner</v-col>
                        <v-col class="mx-0 mt-4 pa-0 px-4" cols="9" v-if="!isMoveOut">
                          <v-row no-gutters>
                            <v-col cols="6" class="ma-0 pa-0 pr-3">
                              <HbTextField
                                v-if="scheduled_auction.id"
                                :error="errors.has('first_name')"
                                placeholder="Enter First Name"
                                id="first_name"
                                name="first_name"
                                data-vv-as="First Name"
                                v-model="scheduled_auction.Contact.first"
                                :v-validate="schedule_new_auction ? '': 'required'"
                              />
                            </v-col>
                            <v-col cols="6" class="ma-0 pa-0">
                              <HbTextField
                                v-if="scheduled_auction.id"                  
                                v-model="scheduled_auction.Contact.last"
                                :hide-details="!errors.collect('last_name').length"
                                :v-validate="schedule_new_auction ? '': 'required'"
                                data-vv-as="Last Name"
                                placeholder="Enter Last Name"
                                id="last_name"
                                name="last_name"
                                :error="errors.has('last_name')"
                              />
                            </v-col>
                          </v-row>
            
                          <v-row no-gutters>
                            <v-col cols="12" class="mx-0 mt-3 pa-0">
                              <HbTextField
                                v-if="scheduled_auction.id"
                                :error-="errors.has('email')"
                                :hide-details="!errors.collect('email').length"
                                placeholder="Enter Email"
                                id="email"
                                name="email"
                                data-vv-as="Email"
                                v-model="scheduled_auction.Contact.email"
                                :v-validate="schedule_new_auction ? '': 'required'"
                              />
                            </v-col>
                          </v-row>
            
                          <v-row>
                            <v-col cols="7" class="pt-4 pb-0 auction-dialog-phone-code-fix">
                              <VuePhoneNumberInput class="pa-0 custom-styling"
                                v-model="scheduled_auction.Contact.Phones[0].phone"
                                :default-country-code="scheduled_auction.Contact.country_code ? scheduled_auction.Contact.country_code : 'US'"
                                label="Mobile Number"
                                show-code-on-list
                                no-example
                                id="mobile_no"
                                name="mobile_no"
                                @update="updatePhoneNumber"
                                color="#949494"
                                valid-color="#949494"
                                dense
                                flat
                                :translations="mobileNoWording"
                              />                  
                            </v-col>
                          </v-row>
            
                          <v-row>
                            <v-col cols="6">
                              <v-checkbox
                                class="my-0"
                                flat
                                hide-details
                                id="tax_exempt"
                                name="tax_exempt"
                                single-line
                                solo
                                v-model="scheduled_auction.tax_exempt"
                                @change="generateAuctionInvoices"
                              >
                                <span slot="label">Tax Exempt (Reseller License)</span>
                              </v-checkbox>
                            </v-col>
                          </v-row>
                          <v-row v-if="scheduled_auction.tax_exempt">
                            <v-col cols="6">
                              <v-text-field
                                v-if="scheduled_auction.id"
                                :error="errors.has('license_number')"
                                :hide-details="!errors.collect('license_number').length"
                                label="Reseller License Number"
                                class="pa-0 mt-0"
                                flat
                                id="license_number"
                                name="license_number"
                                data-vv-as="license number"
                                v-model="scheduled_auction.license_number"
                                :v-validate="schedule_new_auction ? '': 'required'"
                                v-mask="'##-######'"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col class="mx-0 pl-4" cols="9" v-if="isMoveOut" style="margin-top:2px;">
                          <v-row
                            class="pt-0 pl-4"
                            v-if="scheduled_auction.id"
                          >
                            <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                              {{ scheduled_auction.Contact.first }} {{ scheduled_auction.Contact.last }}
                            </v-col>
                          </v-row>
                          <v-row
                            class="pt-1 pl-4"
                            v-if="scheduled_auction.id"
                          >
                            <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                              {{ scheduled_auction.Contact.email }}
                            </v-col>
                          </v-row>
                          <v-row
                            class="pt-1 pl-4"
                            v-if="scheduled_auction.id"
                          >
                            <v-col v-if="scheduled_auction.Contact.Phones.length && scheduled_auction.Contact.Phones[0].phone" cols="11" class="pa-0 ma-0">
                              {{ 1 + scheduled_auction.Contact.Phones[0].phone | formatPhone }}
                            </v-col>
                          </v-row>
                          <v-row
                            class="pt-1 pl-4"
                            v-if="scheduled_auction.id && scheduled_auction.tax_exempt"
                          >
                            <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                              Tax Exempt (Reseller License)
                            </v-col>
                          </v-row>
                          <v-row
                            class="pt-1 pl-4"
                            v-if="scheduled_auction.id && scheduled_auction.tax_exempt"
                          >
                            <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                              {{ scheduled_auction.license_number }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>

                      <VDivider class="auction-dialog-temp-divider" />

                    </span>
          
                    <v-row class="hb-table-row ma-0 pa-0">
                      <v-col class="hb-table-label py-4 pl-4" cols="3"> <span v-if="change_auction_info">Reason for Rescheduling</span><span v-else>Notes</span></v-col>
                      <v-col class="ma-0 pa-4" :class="{'hb-form-editable' : scheduled_auction.id && fieldEditing != 'notes' && !isAuctionDay && !isMoveOut}" cols="9">
                        <v-row class="pa-0" no-gutters>
                          <v-col
                            cols="12"
                            class="ma-0 pa-0"
                            v-if="!scheduled_auction.id || fieldEditing == 'notes' || (isAuctionDay && stepper === 3) || change_auction_info"
                          >
                            <div class="hb-text-light mb-3">Add in Reminder Notes about this Auction</div>
                            <HbTextarea
                              v-validate="'max:1000'"
                              data-vv-as="Notes"
                              placeholder="Enter Notes"
                              id="notes"
                              name="notes"
                              :error="errors.has('notes')"
                              v-model="scheduled_auction.notes"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            v-if="scheduled_auction.id && fieldEditing == 'notes'"
                            class="ma-0 pt-4 pb-0 mb-n1 d-flex align-center"
                          >
                            <hb-btn
                              color="primary"
                              small
                              @click="scheduleAuction"
                              :disabled="isLoading($options.name)"
                              >Save</hb-btn
                            >
                            <hb-link class="ml-2" small @click="clearEditField()"
                              >Cancel</hb-link
                            >
                          </v-col>
                        </v-row>
                        <v-row
                          class="pl-3"
                          v-if="fieldEditing != 'notes' && !isAuctionDay && !isMoveOut && !change_auction_info && ((scheduled_auction.auction_type === 'storage-treasures' && !isPublished) || scheduled_auction.auction_type !== 'storage-treasures')"
                          @click="editField('notes')"
                        >
                          <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0" :class="{ 'hb-link' : !scheduled_auction.notes }">{{ scheduled_auction.notes ? scheduled_auction.notes : 'Click to Add' }}</v-col>
                          <v-col v-if="scheduled_auction.id && scheduled_auction.notes" cols="1" class="pa-0 ma-0">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-hover v-slot:default="{ hover }">
                                  <span v-on="on">
                                    <hb-icon small :color="hover ? '#101318' : ''"
                                      >mdi-pencil</hb-icon
                                    >
                                  </span>
                                </v-hover>
                              </template>
                              <span>Edit</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                        <v-row
                          class="pl-4"
                          v-else-if="!change_auction_info && fieldEditing != 'notes' && (stepper === 0 || isMoveOut)"
                        >
                          <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">{{ scheduled_auction.notes }}</v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <VDivider v-if="stepper === 0" class="auction-dialog-temp-divider" />

                    <span v-if="stepper === 3">
                      <v-row class="hb-table-row ma-0 pa-0" v-if="!change_auction_info && isAuctionDay && !isMoveOut">
                        <v-col class="hb-table-label py-4 pl-4" cols="3">
                          <span>Summary</span>
                        </v-col>
                        <v-col class="mx-0 mb-0 pl-4" cols="9" style="margin-top:2px;">
                          <v-row class="pt-0 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0 hb-font-body">
                              Winning Bid: ${{ summary.winning_bid }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0 hb-font-body">
                              Seller's Premium: ${{ summary.seller_premium_cost }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0 hb-font-body-medium">
                              Remaining Bid: ${{ summary.remaining_bid }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0 hb-font-body-medium">
                              Cleaning Deposit: ${{ summary.cleaning_deposit }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0 hb-font-body-medium">
                              Auction Tax: ${{ summary.total_tax }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0 hb-font-body-medium">
                              Invoice Total: ${{ summary.invoice_total }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="hb-table-row ma-0 pa-0" v-if="!change_auction_info && !isAuctionDay && isMoveOut && isSummary">
                        <v-col class="hb-table-label py-4 pl-4" cols="3">
                          <span>Summary</span>
                        </v-col>
                        <v-col class="mx-0 mb-0 pl-4" cols="9" style="margin-top:2px;">
                          <v-row class="pt-0 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0 hb-font-body">
                              Winning Bid: ${{ summary.winning_bid }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0 hb-font-body">
                              Seller's Premium: ${{ summary.seller_premium_cost }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0 hb-font-body-medium">
                              Remaining Bid: ${{ summary.remaining_bid }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0 hb-font-body-medium">
                              Cleaning Deposit: ${{ summary.cleaning_deposit }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0 hb-font-body-medium">
                              Auction Tax: ${{ summary.total_tax }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0 hb-font-body-medium">
                              Invoice Total: ${{ summary.invoice_total }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
          
                      <div class="pt-6 px-6" v-if="!change_auction_info && isAuctionDay" style="background: #f9fafb;">

                        <hb-notification
                          v-model="showWarning"
                          v-if="showWarning"
                          type="caution"
                          notDismissable
                        >
                          Please add all the required fees and charges before processing the payment. Fees and Charges cannot be added after the ‘Auction Payment' is processed.
                        </hb-notification>
            
                        <payment-process 
                            :propertyID="property_id"  
                            :invoice="auction_invoices"
                            paymentSource="AUCTION">
                        </payment-process>
            
                      </div>

                    </span>

                  </template>

                  <template v-slot:footer>
                    <hb-bottom-action-bar :top-border="stepper === 3 && !change_auction_info && (isAuctionDay || isMoveOut) ? true : false" cancel-off>
                        <template v-slot:right-actions>
                          <v-progress-circular
                            v-if="isLoading($options.name) || disable_payment"
                            indeterminate
                            color="primary"
                            class="mr-0"
                          ></v-progress-circular>
                          <span>
                            <span v-if="change_auction_info && !schedule_new_auction" style="position:relative;top:2px;">
                              <hb-link @click="backToAddInfo">Back</hb-link>
                            </span>
                            <hb-btn
                              v-if="stepper === 0 && ((scheduled_auction.id && !isAuctionDay && !isMoveOut) || change_auction_info)"
                              color="secondary"
                              @click="showRemoveAuctionConfirmation = true"
                            >
                              Cancel Auction
                            </hb-btn>
                            <hb-btn
                              v-if="!scheduled_auction.id || change_auction_info"
                              color="primary"
                              @click="scheduleAuction(false, true)"
                              :disabled="!enableNewSchedule || isLoading($options.name)"
                              >Schedule Auction</hb-btn
                            >
                            <!--
                            <hb-btn v-if="scheduled_auction.id && !isAuctionDay && !isMoveOut && ((scheduled_auction.auction_type === 'storage-treasures' && isPublished) || scheduled_auction.auction_type !== 'storage-treasures')" color="primary" @click="close"
                              >Close</hb-btn
                            >
                            -->
                            <hb-btn v-if="stepper === 0 && scheduled_auction.id && isAuctionDay && !change_auction_info" color="secondary" @click="showChangeAuctionConfirmation = true" :disabled="disable_payment"
                              >Change Auction</hb-btn
                            >
                            <span v-if="stepper === 3">
                              <hb-btn v-if="scheduled_auction.id && isAuctionDay  && !change_auction_info" color="secondary" @click="scheduleAuction" :disabled="enableSaveBtn || disable_payment || isLoading($options.name)"
                                >Save</hb-btn
                              >
                              <hb-btn v-if="scheduled_auction.id && isAuctionDay && !change_auction_info" color="primary" @click="checkPaymentsErrors" :disabled="enableSaveBtn || disable_payment" :loading="disable_payment"
                                >Process Payment</hb-btn
                              >
                              <hb-btn v-if="scheduled_auction.id && isMoveOut" color="primary" @click="auctionMoveOut" :disabled="auctionLease.auction_status != 'move_out'"
                                >Move-Out</hb-btn
                              >
                            </span>
                          </span>
                        </template>
                    </hb-bottom-action-bar>
                  </template>

                </hb-expansion-panel>

              </v-expansion-panels>

            </div>
  
            <hb-modal v-model="showEditAuctionConfirmation" show-help-link v-if="showEditAuctionConfirmation" size="medium" confirmation title="Confirm Auction Update" @close="closeEditConfirmation">
              <template v-slot:content>
                  <div class="pa-6">
                    <span class="fw-500">You are about to reschedule the auction date for Space #{{ auctionLease.Unit.number }}.</span>
                    <br /><br />
  
                    Changing an auction date may not comply with your states legal requirements. Please verify that this aligns with your lien process.
  
                    <br /><br />
                    <span class="fw-500">Are you sure you want to reschedule?</span>
                  </div>
              </template>
              <template v-slot:actions>
                  <hb-btn @click="scheduleAuction(false, true)" color="primary" :disabled="isLoading($options.name)" >Confirm</hb-btn>
              </template>
            </hb-modal>

            <!-- Change Auction Confirmation Modal -->
            <hb-modal v-model="showChangeAuctionConfirmation" show-help-link v-if="showChangeAuctionConfirmation" size="medium" confirmation title="Confirm Auction Change" @close="showChangeAuctionConfirmation = false">
              <template v-slot:content>
                  <div class="pa-6">
                    <span class="fw-500">You are about to change the auction date for Space #{{ auctionLease.Unit.number }}.</span>
                    <br /><br />
                    Changing an auction date may not comply with your states legal requirements, please verify that this aligns with your lien process.
                    <br /><br />
                    <span>If you schedule a new date, any information you've previously saved will also be deleted.</span>
  
                    <span v-if="scheduled_auction.auction_type === 'storage-treasures' && isPublished">
                      <br /><br />
                      <span>*The auction details for StorageTreasures auctions cannot be changed after they have been published to StorageTreasures. You can only delete the published auction and schedule a new auction.</span>
                    </span>
                  </div>
              </template>
              <template v-slot:right-actions>
                  <hb-btn @click="scheduleNewAuction" color="secondary">Schedule New Auction</hb-btn>
                  <hb-btn v-if="(scheduled_auction.auction_type === 'storage-treasures' && !isPublished) || scheduled_auction.auction_type !== 'storage-treasures'" @click="changeAuctionInfo" color="primary">Change Info</hb-btn>
              </template>
            </hb-modal>
            <!-- Change Auction Confirmation Modal -->
            <!-- Schedule New Auction Modal -->
            <hb-modal v-model="schedule_new_auction" show-help-link v-if="schedule_new_auction" :title="'Schedule New Auction: Space #' + auctionLease.Unit.number" @close="cancelScheduleNewAuction()">
              <template v-slot:subheader>
                <span>Choose whether this is going to be an online or live auction, and then schedule the date and time for the auction.</span>
              </template>
              <template v-slot:content>
                  <div class="pa-0 ma-0">
                    <div class="pa-0 ma-0">
                      <v-row class="hb-table-row ma-0 pa-0">
                        <v-col class="hb-table-label py-4 pl-4" cols="3">
                          Auction Type &amp; Platform
                        </v-col>
                        <v-col class="ma-0 pa-4" cols="6">
                          <HbSelect
                            v-model="scheduled_auction.auction_type"
                            :items="auctionTypes"
                            item-value="value"
                            item-text="text"
                            name="auction_type"
                            data-vv-as="Auction Type &amp; Platform"
                            label="Select Auction Type &amp; Platform"
                            :clearable="false"
                            :error="errors.has('auction_type')"
                            v-validate="'required'"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="hb-table-row ma-0 pa-0">
                        <v-col class="hb-table-label py-4 pl-4" cols="3">
                          Auction Date &amp; Time
                        </v-col>
                        <v-col class="ma-0 py-4 pt-0" cols="9">
                          <v-row class="pa-0">
                            <v-col
                              cols="6"
                            >
                              <hb-date-picker
                                v-model="scheduled_auction.date"
                                id="date"
                                name="due_date"
                                label="Select Starting Date for Auction"
                                :solo="false"
                                :min="minAuctionDate"
                              ></hb-date-picker>
                            </v-col>
                            <v-col
                              cols="6"
                            >
                              <label class="v-label v-label--active theme--light auction-dialog-font-11px">Select Starting Time for Auction</label>
                              <v-row no-gutters class="mt-1">
                                <v-col md="3" class="ma-0 pa-0">
                                  <hb-text-field
                                    id="auction_hour"
                                    name="auction_hour"
                                    placeholder="HH"
                                    v-model="timeValue.hours"
                                    data-vv-as="Starting Hour"
                                    type="number"
                                    min="1"
                                    max="12"
                                    width="80%"
                                    v-validate="'required|numeric|max_value:12|min_value:1'"
                                    :error="errors.has('auction_hour')"
                                    class="mt-0 pt-0 time-w-80"
                                  ></hb-text-field>
                                </v-col>
                                <v-col md="3" class="ma-0 pa-0">
                                  <hb-text-field
                                    id="auction_minutes"
                                    name="auction_minutes"
                                    placeholder="MM"
                                    v-model="timeValue.minutes"
                                    data-vv-as="Starting Minute"
                                    type="number"
                                    min="0"
                                    max="59"
                                    width="80%"
                                    v-validate="'required|numeric|max_value:59|min_value:0'"
                                    :error="errors.has('auction_minutes')"
                                    class="mt-0 pt-0 time-w-80"
                                  ></hb-text-field>
                                </v-col>
                                <v-col md="6" class="ma-0 pa-0">
                                  <hb-select
                                    :items="['AM','PM']"
                                    id="auction_ampm"
                                    name="auction_ampm"
                                    v-model="timeValue.ampm"
                                    :clearable="false"
                                    placeholder="AM/PM"
                                    v-validate="'required'"
                                    data-vv-name="auction_ampm"
                                    data-vv-as="Starting AM/PM"
                                    width="80%"
                                    :error="errors.has('auction_ampm')"
                                    class="mt-0 pt-0 time-w-80"
                                  ></hb-select>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="hb-table-row ma-0 pa-0">
                        <v-col class="hb-table-label py-4 pl-4" cols="3">Notes</v-col>
                        <v-col class="ma-0 pa-4" cols="9">
                          <v-row class="pa-0" no-gutters>
                            <v-col
                              cols="12"
                              class="ma-0 pa-0"
                            >
                              <div class="hb-text-light mb-3">Add in Reminder Notes about this Auction</div>
                              <HbTextarea
                                class="mt-0 pt-0"
                                v-validate="'max:1000'"
                                data-vv-as="Notes"
                                placeholder="Enter Notes"
                                id="notes"
                                name="notes"
                                :error="errors.has('notes')"
                                v-model="scheduled_auction.notes"
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
              </template>
              <template v-slot:right-actions>
                <hb-btn @click="scheduleAuction(false, true)" color="primary" :disabled="isLoading($options.name)">Schedule New Auction</hb-btn>
              </template>
            </hb-modal>
            <!-- Schedule New Auction Modal -->
          </div>
        </div>
        
        <v-row no-gutters class="auction-manager-bab-wrapper" style="z-index:999;">
          <hb-bottom-action-bar cancel-off class="auction-manager-bab">
            <template v-slot:actions>
              <!--
              <hb-menu>
                <template v-slot:menu-activator>
                  <hb-btn color="secondary" append-icon="mdi-menu-down">Go to</hb-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-if="stepper !== 0"
                    @click="stepper = 0"
                    :ripple="false"
                  >
                    <v-list-item-title>Schedule Auction</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="stepper !== 1"
                    @click="stepper = 1"
                    :ripple="false"
                  >
                    <v-list-item-title>Images and Inventory</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="stepper !== 2"
                    @click="stepper = 2"
                    :ripple="false"
                  >
                    <v-list-item-title>Audit</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="stepper !== 3"
                    @click="stepper = 3"
                    :ripple="false"
                  >
                    <v-list-item-title>Auction Sale</v-list-item-title>
                  </v-list-item>
                </v-list>
              </hb-menu>
              -->
              <hb-btn v-if="stepper !== 0" color="secondary" @click="stepper--" class="ml-3">Back</hb-btn>
              <hb-btn v-if="stepper !== 3" :color="scheduled_auction.id && scheduled_auction.auction_type === 'storage-treasures' && !isPublished && !cannotPublish ? 'secondary' :  'primary'" @click="stepper++" class="ml-3">Next</hb-btn>
              <hb-tooltip
                v-if="scheduled_auction.id && scheduled_auction.auction_type === 'storage-treasures' && !isPublished && !cannotPublish"
                :disabled="imagesAndInventoryCompleted"
                max-width="400"
              >
                <template v-slot:item>
                  <hb-btn
                    color="primary"
                    @click="publishStorageTreasuresAuction"
                    :disabled="isLoading($options.name) || !imagesAndInventoryCompleted || isPublishing || (auditDelinquency.audit_status !== 'completed' && auditDelinquency.audit_status !== 'skipped')"
                    :loading="isPublishing"
                    class="ml-3"
                    >Publish Online Auction</hb-btn
                  >
                </template>
                <template v-slot:body>
                  Images and Inventory details and Audit steps must be completed in order to publish to StorageTreasures. Please upload images and set space inventory details in the "Images and Inventory" step (or through our Mobile Manager App) and also complete the "Audit" step.
                </template>
              </hb-tooltip>
            </template>
          </hb-bottom-action-bar>
        </v-row>
    <hb-modal
      size="medium"
      title="Confirm Payment"
      v-model="confirm_process_payment"
      confirmation
      show-help-link
    >
      <template v-slot:content>
        <div class="py-4 px-6">
          Please add all the required fees and charges before processing the payment. Fees and Charges cannot be added after the 'Auction Payment' is processed.
        </div>
      </template>
      <template v-slot:actions>
        <hb-btn color="primary" @click="confirmProcessPayment" :disabled="disable_payment">Would you like to proceed?</hb-btn>
      </template>
    </hb-modal>

    <hb-modal v-model="showRemoveAuctionConfirmation" show-help-link v-if="showRemoveAuctionConfirmation" size="medium" confirmation title="Confirm Auction Removal" @close="closeRemoveConfirmation">
      <template v-slot:content>
          <div class="pa-6">
            <span class="fw-500">You are about to remove the auction date for Space #{{ auctionLease.Unit.number }}.</span>
            <br /><br />

            Changing an auction date may not comply with your states legal requirements. Please verify that this aligns with your lien process.

            <br /><br />
            <span class="fw-500">Are you sure you want to remove?</span>
          </div>
      </template>
      <template v-slot:actions>
          <hb-btn @click="removeAuctionSchedule" color="destructive">Confirm</hb-btn>
      </template>
    </hb-modal>

    <hb-modal
      size="medium"
      title="Refund Cleaning Deposit"
      show-help-link
      v-model="refundAuctionFlag"
      v-if="refundAuctionFlag"
    >
      <template v-slot:content>
        <v-row class="pa-0 ma-0">
            <v-col cols="4" class="py-4 px-6 ma-0 hb-cloud-light font-weight-medium">
                Refund From
            </v-col>
            <v-col cols="8" class="ma-0 pt-4 pl-6">
              <v-select
                v-model="refund.paymentFrom"
                :items="paymentTypes"
                item-value="value"
                item-text="text"
                name="payment_type"
                label="Select a Payment Type"
                v-validate="'required'"
                data-vv-scope="refund"
                data-vv-as="Payment Type"
                :error-messages="errors.collect('refund.payment_type')"
                :hide-details="!errors.collect('refund.payment_type').length"
              ></v-select>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="pa-0 ma-0">
            <v-col cols="4" class="py-4 px-6 ma-0 hb-cloud-light font-weight-medium">
                Amount
            </v-col>
            <v-col cols="8" class="ma-0 pt-4 pl-6">
              <v-text-field
                :disabled="true"
                :error-messages="errors.collect('refund.refund_amount')"
                :hide-details="!errors.collect('refund.refund_amount').length"
                label="Enter Refund Amount"
                flat
                id="refund_amount"
                name="refund_amount"
                data-vv-scope="refund"
                data-vv-as="Refund Amount"
                v-model="refund.amount"
                v-validate="'required|integer'"
              ></v-text-field>
              <span class="caption" style="color: #637381;">(Total Available: {{scheduled_auction.cleaning_deposit | formatMoney}})</span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="pa-0 ma-0">
            <v-col cols="4" class="py-4 px-6 ma-0 hb-cloud-light font-weight-medium">
                Refund To
            </v-col>
            <v-col cols="8" class="ma-0 pt-4 pl-6 text-capitalize">
              {{ refund_option.text }}
            </v-col>
        </v-row>
      </template>
      <template v-slot:actions>
          <hb-btn color="primary" :disabled="isRefundAmount || disableRefund" :loading="disableRefund" @click="processRefund">Refund</hb-btn>
      </template>
    </hb-modal>
  </div>
</template>
<script type="text/babel">
import MiniProfileView from "../includes/LeadIntake/MiniProfileView.vue";
import Delinquency from "../contacts/Delinquency.vue";
import Status from "../includes/Messages.vue";
import { EventBus } from "../../EventBus.js";
import api from "../../assets/api.js";
import Loader from "../assets/CircleSpinner.vue";
import HbDatePicker from "../assets/HummingbirdDatepicker";
import HbTimePicker from "../assets/HummingbirdTimepicker";
import VuePhoneNumberInput from 'vue-phone-number-input';
import moment from "moment";
import PaymentProcess from '../includes/PaymentProcess/Index.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { notificationMixin } from  '../../mixins/notificationMixin.js';
import axios from 'axios';
import https from 'https';
import Todo from '../includes/Todo.vue';

export default {
  name: "AuctionManager",
  mixins: [ notificationMixin ],
  data() {
    return {
      feeTypes: [
        { text: "%", value: "percent" },
        { text: "Fixed Rate", value: "fixed" },
      ],
      auctionProducts: [],
      scheduled_auction: {
        Contact: {
          Phones: [{phone: "", type: ""}],
          country_code: "",
          email: "",
          first: "",
          last: ""
        },
        id: "",
        auction_type: "",
        scheduled_date: "",
        date: "",
        time: "",
        notes: "",
        cleaning_deposit: null,
        cleaning_period: null,
        amount: null,
        tax_exempt: false,
        license_number: '',
        country_code: '',
        mobile_no: '',
        refund_id: '',
        BuyerPremium: {
          type: "",
          value: "",
          is_inclusive: false,
          amount: 0
        },
      },
      SellerPremium: null,
      summary: {
        remaining_bid: 0,
        winning_bid: 0,
        buyer_premium_cost: 0,
        seller_premium_cost: 0,
        total_tax: 0,
        cleaning_deposit: 0,
        invoice_total: 0,
      },
      mobileNoWording: {
        countrySelectorLabel: '',
        countrySelectorError: 'Select country code',
        phoneNumberLabel: 'Mobile Number'
      },
      fieldEditing: "",
      showRemoveAuctionConfirmation: false,
      showEditAuctionConfirmation: false,
      showChangeAuctionConfirmation: false,
      change_auction_info: false,
      schedule_new_auction: false,
      auction_invoices: [],
      disable_payment: false,
      process_payment: false,
      confirm_process_payment: false,
      showWarning: true,
      timeValue: {
        hours: '',
        minutes: '',
        ampm: ''
      },
      stepper: [],
      categories: [],
      media: [],
      mediaDescription: '',
      tamperTag: '',
      mediaViewer: false,
      categoriesSelected: [],
      fetchedSelectedCategories: [],
      fetchingMedia: true,
      allowMediaSave: false,
      uploads: [],
      savingAuctionMediaInProgess: false,
      auctionImageSet: {},
      imageError: false,
      delinquency: [],
      fetchingDelinquency: false,
      stepOneImagesAccordion: [ 0 ],
      auctionFormAccordion: [ 0 ],
      auctionUpdating: false,
      isPublishing: false,
      deletingMedia: false,
      tempAuctionNotSold: false,
      notSoldRadioGroup: null,
      todo: false,
      delinquencyId: '',
      updatingAuditStatus: false,
      auditDelinquency: {},
      currentDateTime: '',
      selectedContact: {},
      paymentTypes: [
        { text: "Cleaning Deposit", value: "cleaning_deposit" }
      ],
      refund: {
        paymentFrom: 'cleaning_deposit',
        amount: 0,
        paymentTo: ''
      },
      refundAuctionFlag: false,
      refund_option: {
        text: '',
        value: 0
      },
      auctionPayment: {},
      auction_refund_id: null,
      disableRefund: false,
      storageTreasuresEnabled: false,
    };
  },
  components: {
    MiniProfileView,
    Delinquency,
    Status,
    Loader,
    HbDatePicker,
    HbTimePicker,
    VuePhoneNumberInput,
    PaymentProcess,
    Todo
  },
  computed: {
    ...mapGetters({
      getErrors: 'paymentsStore/getErrors',
      hasPermission: "authenticationStore/rolePermission"
    }),
    minAuctionDate() {
        if(this.scheduled_auction.auction_type === 'storage-treasures'){
            return (this.change_auction_info) ? null : moment().add(11, 'days').format('YYYY-MM-DD');
        } else {
            return (this.change_auction_info) ? null : moment().add(1, 'day').format('YYYY-MM-DD');
        }
    },
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isAuctionDay() {
      return (this.auctionLease.auction_status == 'auction_day' || this.auctionLease.auction_status == 'auction_payment') ? true : false;
    },
    isAuctionNotSold() {
      return (this.scheduled_auction.vendor_auction_status == 'unsold' || this.tempAuctionNotSold) ? true : false;
    },
    isMoveOut() {
      return (this.auctionLease.auction_status == 'move_out' || this.auctionLease.auction_status == 'complete') ? true : false;
    },
    enableSaveBtn() {
      return (
        !this.scheduled_auction.amount ||
        !this.scheduled_auction.Contact.first ||
        !this.scheduled_auction.Contact.last ||
        !this.scheduled_auction.Contact.email ||
        !this.scheduled_auction.BuyerPremium.type ||
        (isNaN(parseFloat(this.scheduled_auction.BuyerPremium.value)) || this.scheduled_auction.BuyerPremium.value < 0) ||
        !this.SellerPremium.type ||
        (isNaN(parseFloat(this.SellerPremium.value)) || this.SellerPremium.value < 0)
      );
    },
    auction_bidding_amount() {
      return this.auction_invoices && this.auction_invoices.balance;
    },
    isBuyerPremiumPresent() {
      return this.scheduled_auction.BuyerPremium.type && this.scheduled_auction.BuyerPremium.value && this.scheduled_auction.BuyerPremium.product_id
    },
    isSellerPremiumPresent() {
      return this.SellerPremium.type && this.SellerPremium.value && this.SellerPremium.product_id
    },
    isSummary() {
      return this.summary.remaining_bid !== 0 || this.summary.buyer_premium_cost !== 0 || this.summary.seller_premium_cost !== 0 || this.summary.cleaning_deposit !== 0 || this.summary.total_tax !== 0 || this.summary.invoice_total !== 0 || this.summary.winning_bid !== 0
    },
    enableNewSchedule() {
      return !!this.scheduled_auction.auction_type;
    },
    auctionCleaningDeposit() {
      return (this.auctionLease.LeaseAuction && this.auctionLease.LeaseAuction.cleaning_deposit && !this.auctionLease.LeaseAuction.refund_id) ? this.auctionLease.LeaseAuction.cleaning_deposit : 0;
    },
    disableRefundButton(){
      if (this.auctionLease.LeaseAuction.refund_id || this.auctionCleaningDeposit <= 0){
        return true;
      }
      else if (this.auctionLease.LeaseAuction.has_clean_out_period_passed){
        return !this.hasPermission('refund_after_clean_out_period');
      }
      return false;
    },
    isPublished(){
      return (this.scheduled_auction.vendor_auction_id) ? true : false;
    },
    tenDaysBeforeScheduledAuction(){
      if(this.scheduled_auction.scheduled_date){
        return moment(this.scheduled_auction.scheduled_date).subtract(10, 'days');
      } else {
        return '';
      }
    },
    imagesAndInventoryCompleted(){
      if(this.auctionImageSet && this.auctionImageSet.uploads && this.auctionImageSet.uploads.length && this.auctionImageSet.description && this.auctionImageSet.categories && this.auctionImageSet.categories.length){
        return true;
      } else {
        return false;
      }
    },
    completed0() {
      if(this.scheduled_auction.id){
        return true;
      } else {
        return false;
      }
    },
    completed1() {
      if(this.imagesAndInventoryCompleted){
        return true;
      } else {
        return false;
      }
    },
    completed3() {
      if(this.isMoveOut){
        return true;
      } else {
        return false;
      }
    },
    hasNoErrors0() {
      return true;
    },
    hasNoErrors1() {
      return true;
    },
    hasNoErrors2() {
      return true;
    },
    hasNoErrors3() {
      return true;
    },
    cannotPublish(){
      if(this.currentDateTime && this.scheduled_auction.scheduled_date && (this.currentDateTime > moment(this.scheduled_auction.scheduled_date))){
        return true;
      } else {
        return false;
      }
    },
    isRefundAmount() {
      return this.refund.amount ? false : true;
    },
    auctionTypes() {
      if(this.storageTreasuresEnabled){
        return [
          { text: "Online Auction (Any Platform)", value: "online" },
          { text: "Online Auction (StorageTreasures)", value: "storage-treasures" },
          { text: "Live Onsite Auction", value: "holler" },
        ]
      } else {
        return [
          { text: "Online Auction (Any Platform)", value: "online" },
          { text: "Live Onsite Auction", value: "holler" },
        ]
      }
    }
  },
  async created() {
    this.getStorageTreasuresEnabledStatus();
    this.getAuctionCategories();
    this.getAuctionMedia();
    this.getAuctionProducts();
    this.fetchDelinquencies();
    const { LeaseAuction } = this.auctionLease;
    
    this.currentDateTime = moment();

    let momentData = moment('3:00 pm', 'hh:mm a').add(1, 'day');
    // this.scheduled_auction.date = momentData.format("YYYY-MM-DD");
    let hoursOriginal = momentData.hours();
    let hours = hoursOriginal < 12 ? hoursOriginal : hoursOriginal == 12 ? 12 : (hoursOriginal - 12);
    hours = hours < 10 ? "0" + hours : hours;
    let minutes = momentData.minutes();
    minutes = minutes < 10 ? "0" + minutes : minutes;
    this.timeValue = {
      hours,
      minutes,
      ampm: hoursOriginal < 12 ? 'AM' : 'PM'
    }

    if (LeaseAuction && Object.keys(LeaseAuction).length!== 0) {
      this.scheduled_auction = JSON.parse(JSON.stringify(LeaseAuction));
      this.SellerPremium = LeaseAuction.SellerPremium? JSON.parse(JSON.stringify(LeaseAuction.SellerPremium)): null;
      if(!this.scheduled_auction.contact_id){
        this.scheduled_auction.Contact = {
          Phones: [{phone: "", type: ""}],
          country_code: "",
          email: "",
          first: "",
          last: ""
        };
      } else {
        if(!this.scheduled_auction.Contact.Phones.length){
          this.scheduled_auction.Contact.Phones = [{phone: "", type: ""}];
        } else {
          this.scheduled_auction.Contact.Phones[0].phone = this.scheduled_auction.Contact.Phones[0].phone.slice(1);
        }
      }
      let localScheduleDateTime = moment(
        this.scheduled_auction.scheduled_date
      ).format("YYYY-MM-DD HH:mm:ss");

      this.scheduled_auction.scheduled_date = localScheduleDateTime;
      this.scheduled_auction.date = moment(localScheduleDateTime).format(
        "YYYY-MM-DD"
      );
      let mommentTime = moment(localScheduleDateTime);
      let hoursOriginal = mommentTime.hours();
      let hours = hoursOriginal < 12 ? hoursOriginal : hoursOriginal == 12 ? 12 : (hoursOriginal - 12);
      hours = hours === 0 ? 12 : hours < 10 ? "0" + hours : hours;
      let minutes = mommentTime.minutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      this.timeValue = {
        hours,
        minutes,
        ampm: hoursOriginal < 12 ? 'AM' : 'PM'
      }
      if (!this.scheduled_auction.BuyerPremium) {
        this.scheduled_auction.BuyerPremium = {
          type: "fixed",
          value: 0,
          is_inclusive: false,
          amount: 0
        }
      }
      if (!this.SellerPremium) {
        this.SellerPremium = {
          type: "fixed",
          value: 0,
          is_inclusive: false,
          amount: 0
        }
      }
      
      this.scheduled_auction.auction_cleaning_deposit = this.scheduled_auction.cleaning_deposit === null ? this.scheduled_auction.default_cleaning_deposit : this.scheduled_auction.cleaning_deposit;
      this.scheduled_auction.auction_cleaning_period = this.scheduled_auction.cleaning_period === null ? this.scheduled_auction.default_cleaning_period : this.scheduled_auction.cleaning_period;
    }

    if(this.isAuctionDay) {
      this.generateAuctionInvoices();
    } else if(LeaseAuction.BidInvoice && Object.keys(LeaseAuction.BidInvoice).length !== 0){
      this.generateAuctionInvoices();
    }

    EventBus.$on('paymentReader', this.enablePayment);

    if(this.startOnStep){
      this.stepper = this.startOnStep;
    } else if(this.isAuctionDay || this.isMoveOut){
      this.stepper = 3;
    } else {
      this.stepper = 0;
    }

    if(this.contact_id){
      this.fetchAuctionContact();
    }
  },
  mounted() {
  },
  destroyed(){
    EventBus.$off('paymentReader', this.enablePayment);
    this.closeMessageNotification();
  },
  filters: {
    formatDays(value) {
      return `${value} Days`;
    }
  },
  props: [
    "auctionLease",
    "contact_id",
    "property_id",
    "value",
    "payments",
    "unallocated",
    // "auction_refund_id",
    // "message",
    "startOnStep",
  ],
  methods: {
    ...mapActions({
      getTransformedPaymentObject: 'paymentsStore/getTransformedPaymentObject',
      getTasks: 'taskCenterStore/getTasks',
    }),
    ...mapMutations({
      setCheckErrors: 'paymentsStore/setCheckErrors',
      checkOnlyCredit:'paymentsStore/checkOnlyCredit'
    }),
    getAuctionProducts() {
      api.get(this, api.PRODUCTS + 'list?type=auction&category_type=fee').then(response => {
        const products = response.products.map(p => {
          return {
            value: p.id,
            text: p.name
          }
        });
        this.auctionProducts = products;
      }).catch((err) => {
        this.products = []
      });
    },
    getAuctionProductNameById(id) {
      const product = this.auctionProducts.find(item => item.value === id);
      return product ? product.text : "";
    },
    async enablePayment(response) {
      this.disable_payment = false;
      if(response && response.error) {
        this.showMessageNotification({ list: [response.error] })
      } else if(response && response.data && response.data.msg) {
        this.showMessageNotification({ type: 'success', description: response.data.msg.text });
        EventBus.$emit('contact_updated',  this.contact_id);
        this.fetchAuctionContact();
        EventBus.$emit('reservation_made');
        EventBus.$emit('payment_saved');
        if(this.process_payment && response.data.payment && response.data.payment.id) {
          this.$emit("processPayment", response.data.payment.id);
        }
        this.getTasks({ reset: true, role_ids: this.rolesIDs });
        // this.$emit("close");
        this.schedule_new_auction = false;
        this.change_auction_info = false;
      }
    },
    backToAddInfo() {
      this.change_auction_info = false;
      this.schedule_new_auction = false;
      this.fetchAuctionContact();
    },
    scheduleNewAuction() {
      this.showChangeAuctionConfirmation = false;

      let momentData = moment('3:00 pm', 'hh:mm a').add(1, 'day');
      this.scheduled_auction.auction_type = '';
      this.scheduled_auction.date = '';
      this.scheduled_auction.notes = '';
      let hoursOriginal = momentData.hours();
      let hours = hoursOriginal < 12 ? hoursOriginal : hoursOriginal == 12 ? 12 : (hoursOriginal - 12);
      hours = hours < 10 ? "0" + hours : hours;
      let minutes = momentData.minutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      this.timeValue = {
        hours,
        minutes,
        ampm: hoursOriginal < 12 ? 'AM' : 'PM'
      }
      this.schedule_new_auction = true;
    },
    changeAuctionInfo() {
      this.showChangeAuctionConfirmation = false;
      this.dialog = true;
      this.change_auction_info = true;
      this.stepper = 0;
    },
    auctionMoveOut() {
      this.$emit("close");
      EventBus.$emit('move_out', {
        property_id: this.auctionLease.Unit.property_id,
        contact: this.selectedContact,
        lease_id: this.auctionLease.id,
        unit_id: this.auctionLease.unit_id
      });
    },
    /**
     * Winning bid amount and cleaning deposit will come from settings so their value will
     * be present. If not then we have either remove it or entered an invalid value.
     * That is why they can be put dependent on each other and other fields while validating.
     * param {The field to be validated} field 
     */
    async validateField(field) {
      let status = true;
      switch (field) {
        case 'winning_bid_amount':
          status = await this.$validator.validate('winning_bid_amount');
          status = status && await this.$validator.validate('cleaning_deposit');
          break;
        case 'cleaning_deposit':
          status = await this.$validator.validate('cleaning_deposit');
          status = status && await this.$validator.validate('winning_bid_amount');
          break;
        case 'buyer_premium_type':
          status = await this.$validator.validate('buyer_premium_type');
          status = status && await this.$validator.validate('winning_bid_amount');
          status = status && await this.$validator.validate('cleaning_deposit');
          if (status && this.scheduled_auction.BuyerPremium.value) {
            status = await this.$validator.validate('buyer_premium_value');
          } else {
            status = false;
          }
          break;
        case 'buyer_premium_value':
          status = await this.$validator.validate('buyer_premium_value');
          status = status && await this.$validator.validate('winning_bid_amount');
          status = status && await this.$validator.validate('cleaning_deposit');
          if (status && this.scheduled_auction.BuyerPremium.type) {
            status = await this.$validator.validate('buyer_premium_type');
          } else {
            status = false;
          }
          break;
          case 'seller_premium_type':
          status = await this.$validator.validate('seller_premium_type');
          status = status && await this.$validator.validate('winning_bid_amount');
          status = status && await this.$validator.validate('cleaning_deposit');
          if (status && this.SellerPremium.value) {
            status = await this.$validator.validate('seller_premium_value');
          } else {
            status = false;
          }
          break;
        case 'seller_premium_value':
          status = await this.$validator.validate('seller_premium_value');
          status = status && await this.$validator.validate('winning_bid_amount');
          status = status && await this.$validator.validate('cleaning_deposit');
          if (status && this.SellerPremium.type) {
            status = await this.$validator.validate('seller_premium_type');
          } else {
            status = false;
          }
          break;
        default:
          break;
      }
      if (status) {
        this.generateAuctionInvoices();
      }
    },
    async generateAuctionInvoices() {
      try {        
        let auctionInvoiceData = {
          winning_bid_amount: this.scheduled_auction.amount,
          lease_auction_id: this.scheduled_auction.id,
          cleaning_deposit: this.scheduled_auction.auction_cleaning_deposit,
          tax_exempt: this.scheduled_auction.tax_exempt,
        }
        if (this.scheduled_auction.BuyerPremium.type && this.scheduled_auction.BuyerPremium.value) {
          auctionInvoiceData.BuyerPremium = {
            type: this.scheduled_auction.BuyerPremium.type,
            value: this.scheduled_auction.BuyerPremium.value,
            is_inclusive: this.scheduled_auction.BuyerPremium.is_inclusive,
            amount: this.SellerPremium.amount
          }
          if (this.scheduled_auction.BuyerPremium.id) {
            auctionInvoiceData.BuyerPremium.id = this.scheduled_auction.BuyerPremium.id
          }
        }
        if (this.SellerPremium.type && this.SellerPremium.value) {
          auctionInvoiceData.SellerPremium = {
            type: this.SellerPremium.type,
            value: this.SellerPremium.value,
            is_inclusive: this.SellerPremium.is_inclusive,
            amount: this.SellerPremium.amount
          }
          if (this.SellerPremium.id) {
            auctionInvoiceData.SellerPremium.id = this.SellerPremium.id
          }
        }
        let response = await api.post(this, `${api.AUCTIONS}compute-auction-data`, auctionInvoiceData);
        this.auction_invoices = response.lease_auction.invoices;
        this.SellerPremium = response.lease_auction.SellerPremium || this.SellerPremium;
        this.auction_invoices.balance = response.lease_auction.invoice_total;
        this.calculateSummary()
      } catch (error) {
        this.showMessageNotification({ description: error.toString() });
      }
    },
    resetSummary() {
      this.summary.winning_bid = 0;
      this.summary.remaining_bid = 0;
      this.summary.buyer_premium_cost = 0;
      this.summary.seller_premium_cost = 0;
      this.summary.cleaning_deposit = 0;
      this.summary.total_tax = 0;
      this.summary.invoice_total = 0;
    },
    calculateSummary() {
      this.resetSummary();
      this.auction_invoices.forEach(invoice =>{
        invoice.InvoiceLines.forEach(element => {
          if (element.Product?.slug === 'remaining_bid'){
            this.summary.remaining_bid += element.cost
          }else if (element.Product?.slug === 'cleaning_deposit'){
            this.summary.cleaning_deposit += element.cost
          } 
        });
        this.summary.total_tax += invoice.total_tax;
      });
      this.summary.invoice_total = this.auction_invoices.balance;
      this.summary.seller_premium_cost = this.SellerPremium.amount;
      this.summary.winning_bid = this.scheduled_auction.amount || 0;
    },
    updatePhoneNumber(data){
      this.scheduled_auction.Contact.phone = data.formattedNumber;
      this.scheduled_auction.Contact.country_code = data.countryCode ;
    },
    closeRemoveConfirmation() {
      this.showRemoveAuctionConfirmation = false;
    },
    closeEditConfirmation() {
      this.showEditAuctionConfirmation = false;
    },
    editField(fieldName) {
      this.fieldEditing = fieldName;
    },
    clearEditField() {
      this.fetchAuctionContact();
      this.fieldEditing = "";
    },
    async removeAuctionSchedule() {
      if(this.scheduled_auction.auction_type === 'storage-treasures'){
      
        let data = {
          vendor: "storage-treasures",
          cancel_reason: this.auctionLease.Unit.number ? "canceling auction for space " + this.auctionLease.Unit.number : "unknown"
        }

        try {
          let r = await api.put(this, api.AUCTIONS + 'lease-auction/' + this.scheduled_auction.id + '/cancel', data);

          if(r){
            this.showMessageNotification({ type: 'success', description: 'Successfully Removed from Auction List' });
            EventBus.$emit('contact_updated',  this.contact_id);
            EventBus.$emit('reservation_made');
            EventBus.$emit('reload_tenant_profile_leases');
            this.$emit("close");
          }
        } catch (err) {
          this.showMessageNotification({
            type: "error",
            description: err,
          });
        }

      } else {
        try {
          const response = await api.delete(
            this,
            api.LEASE_AUCTION + this.scheduled_auction.id
          ).then(r => {
            this.showMessageNotification({ type: 'success', description: 'Successfully Removed from Auction List' });
            EventBus.$emit('contact_updated',  this.contact_id);
            EventBus.$emit('reservation_made');
            EventBus.$emit('reload_tenant_profile_leases');

          });

          this.$emit("close");
        } catch (err) {
          this.showMessageNotification({
            type: "error",
            description: err,
          });
        }
      }
    },
    checkPaymentsErrors() {
      //this.setCheckErrors({ value: true });
      //this function will check if the payment only with credit it will skip the checkErrors. otherwise, if not credit payment it wich check the errors normal.
      this.checkOnlyCredit();
    },
    async fetchPropertyConnections(device_id) {
       let r = await api.get(this.$app, api.PROPERTIES + this.property_id + "/connections/address?device_id=" + device_id);
       this.IPaddress = r.IPaddress;
       this.lan = r.lan;
    },
    async scheduleAuction(processPayment = false, validateAuctionDatetime = false) {
      this.process_payment = processPayment;
      this.closeEditConfirmation();
      let auctionTime = this.getAuctionTime();
      let scheduleDateTime = moment(
        `${this.scheduled_auction.date} ${auctionTime}:00`,
        "YYYY-MM-DD HH:mm:ss"
      ).format("YYYY-MM-DD HH:mm");
      if(validateAuctionDatetime) {
        let status = await this.$validator.validateAll();
        if(!status) return;
        // let currentTime = moment().format("YYYY-MM-DD HH:mm");
        if(!moment(scheduleDateTime).isValid()) {
        // if(!moment(scheduleDateTime).isValid() || !moment(scheduleDateTime).isSameOrAfter(currentTime)) {
          this.showMessageNotification({ description: 'Invalid Date Time provided' });
          return;
        }
      }
      let auction_data = {
        id: this.schedule_new_auction ? null : this.scheduled_auction.id,
        lease_id: this.auctionLease.id,
        unit_id: this.auctionLease.Unit.id,
        contact_id: this.contact_id,
        auction_type: this.scheduled_auction.auction_type,
        amount: this.schedule_new_auction ? null : this.scheduled_auction.amount,
        cleaning_deposit: this.schedule_new_auction ? null : this.scheduled_auction.auction_cleaning_deposit,
        cleaning_period: this.schedule_new_auction ? null : this.scheduled_auction.auction_cleaning_period,
        license_number: this.schedule_new_auction ? null : this.scheduled_auction.license_number,
        tax_exempt: this.schedule_new_auction ? false : this.scheduled_auction.tax_exempt,
        notes: this.scheduled_auction.notes,
        scheduled_date: scheduleDateTime,
        lease_auction_id: this.schedule_new_auction ? this.scheduled_auction.id : null,
        deleted: this.schedule_new_auction ? true : false,
      };
      if (!this.schedule_new_auction && this.scheduled_auction.BuyerPremium && this.scheduled_auction.BuyerPremium.value) {
        auction_data.BuyerPremium = {
          type: this.scheduled_auction.BuyerPremium.type,
          value: this.scheduled_auction.BuyerPremium.value,
          is_inclusive: this.scheduled_auction.BuyerPremium.is_inclusive,
        }
        if (this.scheduled_auction.BuyerPremium.id) {
          auction_data.BuyerPremium.id = this.scheduled_auction.BuyerPremium.id
        }
      }
      if (!this.schedule_new_auction && this.SellerPremium && this.SellerPremium.value) {
        auction_data.SellerPremium = {
          type: this.SellerPremium.type,
          value: this.SellerPremium.value,
          is_inclusive: this.SellerPremium.is_inclusive,
        }
        if (this.SellerPremium.id) {
          auction_data.SellerPremium.id = this.SellerPremium.id
        }
      }

      if(this.scheduled_auction.Contact && this.isAuctionDay && !this.schedule_new_auction){
        auction_data.Contact = {
          id: this.scheduled_auction.Contact.id,
          first: this.scheduled_auction.Contact.first,
          last: this.scheduled_auction.Contact.last,
          email: this.scheduled_auction.Contact.email,
          country_code: this.scheduled_auction.Contact.country_code,
          Phones: [{
            type: '',
            phone: this.scheduled_auction.Contact.phone ? this.scheduled_auction.Contact.phone : ''
          }]
        };
      }
      if(processPayment) {
        this.disable_payment = true;
        this.confirm_process_payment = false;

        let paymentInfo = await this.getTransformedPaymentObject({
            id: this.$options.name,
        });

        if(paymentInfo.errors && paymentInfo.errors.length) {
          this.showMessageNotification({ list: paymentInfo.errors });
          this.disable_payment = false;
          return;
        }
        
        let payment_info = {
          payment: (this.auction_bidding_amount > 0) ? paymentInfo.payment : null,
          paymentMethod: paymentInfo.paymentMethod,
          property_id: this.property_id,
          lease: this.auctionLease
        }
        auction_data = Object.assign(payment_info, auction_data)
        //Fetch Device IP and Port for Lan Connection
        if(paymentInfo?.paymentMethod?.device_id)
            await this.fetchPropertyConnections(paymentInfo.paymentMethod.device_id);
      }
      let response = {};
      let payloadResponse;
      let lanResponseData;
      let payment_id;

      try {
            // Create an instance of the HTTPS agent with certificate verification enabled.
            const certData = ``;
            const httpsAgent = new https.Agent({
            ca: [certData],
            });

            // Configure Axios to use the custom HTTPS agent.
            const instance = axios.create({
            httpsAgent
            });
            const endpoint = 'https://' + this.IPaddress + '?';
        
        if (!auction_data.id || this.schedule_new_auction) {
          if(auction_data.paymentMethod && auction_data.paymentMethod.device_id && this.lan == 1){
            //Get PAX Request Payload from HB Backend server
            payloadResponse = await api.post(this, api.PAYMENTS + "getLanSwipePayload",auction_data);
            payment_id = payloadResponse.payment.id;
            auction_data.payment.id = payment_id;
            // Make the GET request using the custom Axios instance.
            lanResponseData = await instance.get(endpoint + payloadResponse.payment.status_desc , {headers: {'Accept': 'text/plain',},});
            auction_data.payment.PAXResponse = lanResponseData;
            await api.post(this, api.LEASE_AUCTION, auction_data).then(r => {
                  response = {
                    data: {
                      msg: {
                        id: this.$options.name,
                        text: this.process_payment ? 'Payment Saved Successfully' : 'Auction Saved Successfully',
                      },
                      payment: {id: r.payment_id}
                    }
                  };
              });
          } else {
          await api.post(this, api.LEASE_AUCTION, auction_data).then(r => {
              response = {
                data: {
                  msg: {
                    id: this.$options.name,
                    text: this.process_payment ? 'Payment Saved Successfully' : 'Auction Saved Successfully',
                  },
                  payment: {id: r.payment_id}
                }
              };
          });
          }
        } else {
          if(auction_data.paymentMethod && auction_data.paymentMethod.device_id && this.lan == 1){
            //Get PAX Request Payload from HB Backend server
            payloadResponse = await api.post(this, api.PAYMENTS + "getLanSwipePayload",auction_data);
            payment_id = payloadResponse.payment.id;
            auction_data.payment.id = payment_id;
            // Make the GET request using the custom Axios instance.
            lanResponseData = await instance.get(endpoint + payloadResponse.payment.status_desc , {headers: {'Accept': 'text/plain',},});
            auction_data.payment.PAXResponse = lanResponseData;
            await api.put(
              this,
              api.LEASE_AUCTION + this.scheduled_auction.id,
              auction_data
            ).then(r => {
                response = {
                  data: {
                    msg: {
                      id: this.$options.name,
                      text: this.process_payment ? 'Payment Saved Successfully' : 'Auction Saved Successfully',
                    },
                    payment: {id: r.payment_id}
                  }
                };
            });
          } else {
            await api.put(
              this,
              api.LEASE_AUCTION + this.scheduled_auction.id,
              auction_data
            ).then(r => {
                response = {
                  data: {
                    msg: {
                      id: this.$options.name,
                      text: this.process_payment ? 'Payment Saved Successfully' : 'Auction Saved Successfully',
                    },
                    payment: {id: r.payment_id}
                  }
                };
            });
          }
        }
        if(auction_data.id) {
          this.clearEditField();
        }

      } catch (err) {
         response = {
           error: err,
           contact_id: auction_data.contact_id
         };
         if(auction_data.paymentMethod && auction_data.paymentMethod.device_id && this.lan == 1)
         {
            response = await api.post(this, api.PAYMENTS + payment_id + "/lanSwipeResponse", response);
         }
       } finally {
       this.enablePayment(response);
     }
    },
    confirmProcessPayment() {
      this.scheduleAuction(true);
    },
    resetForm() {
      this.selection = "";
      this.amount = "";
    },
    close() {
      this.change_auction_info = false;
      this.schedule_new_auction = false;
      this.errorClear(this.$options.name);
      this.successClear(this.$options.name);
      this.$emit("close");
    },
    getAuctionTime() {
      var hours =  this.timeValue.ampm == 'AM' ? this.timeValue.hours == 12 ? 0 : parseInt(this.timeValue.hours) : this.timeValue.hours == 12 ? 12 : parseInt(this.timeValue.hours) + 12;
      var minutes = this.timeValue.minutes;
      return `${hours}:${minutes}`;
    },
    async getAuctionCategories(){
      try {
        let r = await api.get(this, api.AUCTIONS + '/images/categories');

        if(r && r.categories && r.categories.length){

          r.categories.map(c => {
            this.categories.push({
              id: c.id,
              name: c.name
            });
          })
        }
      } catch (err){
        this.showMessageNotification({
          type: "error",
          description: err,
        });
      }
    },
    async getAuctionMedia(){
      try {
        let r = await api.get(this, api.AUCTIONS + '/leases/' + this.auctionLease.id + '/uploads');

        if(r){
          if(r.imageSet && r.imageSet[0]){
            this.auctionImageSet = r.imageSet[0];
          }
          if(r.imageSet && r.imageSet[0] && r.imageSet[0].uploads ){
            let mediaSort = r.imageSet[0].uploads;
            mediaSort.sort(function(a, b) {
              return moment(b.upload_date) > moment(a.upload_date);
            });
            this.media = mediaSort;
          }
          if(r.imageSet && r.imageSet[0] && r.imageSet[0].description ){
            this.mediaDescription = r.imageSet[0].description
          }
          if(r.imageSet && r.imageSet[0] && r.imageSet[0].categories ){

            let fetchedSelectedCategories = r.imageSet && r.imageSet[0] && r.imageSet[0].categories;
            
            fetchedSelectedCategories.map(c => {
              this.categoriesSelected.push({
                id: c.id,
                name: c.name
              });
            })

          }
          if(r.imageSet && r.imageSet[0] && r.imageSet[0].tamper_tag ){
            this.tamperTag = r.imageSet[0].tamper_tag;
          }
        }
      } catch (err){
        this.showMessageNotification({
          type: "error",
          description: err,
        });
      } finally {
        this.fetchingMedia = false;
      }
    },
    async publishStorageTreasuresAuction() {
      this.isPublishing = true;
      try {
        let auctionId = this.scheduled_auction.id ? this.scheduled_auction.id : null;
        let r = await api.post(this, api.AUCTIONS + 'lease-auction/' + auctionId + '/publish');

        if(r && r.auction_id && r.auction_url){
          EventBus.$emit('contact_updated');
          this.getTasks({ reset: true, role_ids: this.rolesIDs });
          this.scheduled_auction.vendor_auction_url = r.auction_url;
          this.scheduled_auction.vendor_auction_id = r.auction_id;
          this.stepper = 1;
          this.stepper = 0;
          this.fetchAuctionContact();
          setTimeout(() => {
            this.showMessageNotification({ type: 'success', description: 'Auction has been successfully published to StorageTreasures.' });
          }, 2000);
        }
      } catch (err){
        this.showMessageNotification({
          type: "error",
          description: err,
        });
      } finally {
        setTimeout(() => {
          this.isPublishing = false;
        }, 2000);
      }
    },
    getScheduledAuctionType(type){
      let auctionTypeMatch = this.auctionTypes.find( i => type.toLowerCase() === i.value.toLowerCase() );
      if(auctionTypeMatch) {
        return auctionTypeMatch.text;
      } else {
        return type;
      }
    },
    handleUploadedFiles(files){
      Array.from(files).forEach(file => {
        if(file.size > 10000000){
          this.showMessageNotification({ type: 'error', description: 'Failed: "' + file.name + '" is larger than the allowed maximum file size of 10 MB.' });
        } else {
            const reader = new FileReader();
            reader.onload = (e) => {
            this.uploads.unshift({
              src: e.target.result,
              file: file,
              document_type: 'image',
              caption: '',
              is_thumbnail: 0
            });
            };
            reader.readAsDataURL(file);
            this.allowMediaSave = true;
        }
      });
    },
    async saveAuctionImagesAndDetails(){
      this.savingAuctionMediaInProgess = true;
      this.allowMediaSave = false;
      let status = await this.$validator.validateAll('lockcut');
      if (!status) {
        this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
        this.savingAuctionMediaInProgess = false;
        this.allowMediaSave = true;
        return;
      }
      if(!this.categoriesSelected.length){
        this.showMessageNotification({ type: 'error', description: 'At least one Category / Keyword selection is required.' });
        this.savingAuctionMediaInProgess = false;
        this.allowMediaSave = true;
        return;
      }
      if(!this.uploads.length && !this.media.length){
        this.showMessageNotification({ type: 'error', description: 'At least one image is required.' });
        this.imageError = true;
        this.savingAuctionMediaInProgess = false;
        this.allowMediaSave = true;
        return;
      } else {
        this.imageError = false;
      }

      let reduceCategoriesToIds = [... new Set(this.categoriesSelected.map(a=>a.id))];

      if(this.uploads.length){

        let imageCount = this.uploads.length + this.media.length;

        const formData = new FormData();

        this.uploads.forEach((image, index) => {
          formData.append(`file`, image.file);
          formData.append(`document_type[${index}]`, 'image');
          formData.set(`caption[${index}]`, image.file.name);
          formData.append(`is_thumbnail[${index}]`, image.is_thumbnail);
        });

        formData.append('description', this.mediaDescription);
        formData.append('tamper_tag', this.tamperTag);
        reduceCategoriesToIds.forEach((id, index) => {
          formData.append(`category_ids[${index}]`, id);
        });

        try {
          let r = await api.post(this, api.AUCTIONS + 'leases/' + this.auctionLease.id + '/uploads', formData);

          if(r){
            EventBus.$emit('auction-image-upload', this.auctionLease.id, imageCount);
            this.showMessageNotification({ type: 'success', description: 'Auction Images & Space Inventory Details successfully saved.' });
            this.uploads = [];
            this.allowMediaSave = false;
            this.getAuctionMedia();
          }
        } catch (err){
          this.showMessageNotification({
            type: "error",
            description: err,
          });
          this.allowMediaSave = true;
        } finally {
          this.savingAuctionMediaInProgess = false;
        }
      } else {

        let data = {
          description: this.mediaDescription,
          tamper_tag: this.tamperTag,
          category_ids: reduceCategoriesToIds
        }

        try {
          let r = await api.put(this, api.AUCTIONS + 'leases/' + this.auctionLease.id + '/uploads', data);

          if(r){
            this.showMessageNotification({ type: 'success', description: 'Auction Images & Space Inventory Details successfully saved.' });
            this.allowMediaSave = false;
            this.getAuctionMedia();
          }
        } catch (err){
          this.showMessageNotification({
            type: "error",
            description: err,
          });
          this.allowMediaSave = true;
        } finally {
          this.savingAuctionMediaInProgess = false;
        }
      }
    },
    goToStep(number){
      this.stepper = number;
    },
    getAuctionMessage(lease) {
      if(lease && lease.LeaseAuction && lease.LeaseAuction.auction_type === 'storage-treasures' && !lease.LeaseAuction.vendor_auction_id) {
          return 'Space #'+ lease.Unit.number + ` is scheduled for online auction from ${this.$options.filters.formatLocalShortDate(moment(lease.LeaseAuction.scheduled_date).subtract(10, 'days'))} to ${this.$options.filters.formatLocalShortDate(lease.LeaseAuction.scheduled_date)}.`;
      } else if(lease && lease.LeaseAuction && lease.LeaseAuction.auction_type === 'storage-treasures' && lease.LeaseAuction.vendor_auction_id && lease.auction_status && lease.auction_status === 'scheduled') {
          return 'Space #'+ lease.Unit.number + ` is scheduled and published for online auction from ${this.$options.filters.formatLocalShortDate(moment(lease.LeaseAuction.scheduled_date).subtract(10, 'days'))} to ${this.$options.filters.formatLocalShortDate(lease.LeaseAuction.scheduled_date)}.`;
      } else {
          switch (lease.auction_status) {
              case 'schedule':
                  return 'Schedule Space #'+ lease.Unit.number +' for Auction.';
                  break;
              case 'scheduled':
                  return 'Space #'+ lease.Unit.number +
                      ` is scheduled for auction ending on ${this.$options.filters.formatLocalShortDate(lease.LeaseAuction.scheduled_date)}.`;
                  break;
              case 'auction_day':
                  return `Verify if auction for Space #${lease.Unit.number} has been closed and enter the bid amount.`;
              case 'auction_payment':
                  return `Auction Winner info has been saved. Please take the auction payment.`;
              case 'move_out':
                  return `Space #${lease.Unit.number} has been sold on ${this.$options.filters.formatLocalShortDate(lease.LeaseAuction.scheduled_date)}.`;
                  break;
              case 'auction_not_sold':
                  return `Auction for Space #${lease.Unit.number} was not sold - Auction ended ${this.$options.filters.formatLocalShortDate(lease.LeaseAuction.scheduled_date)}.`;
                  break;
              default:
                  break;
          }
      }
    },
    async fetchDelinquencies(){
      this.fetchingDelinquency = true;

      try {
        let r = await api.get(this, api.CONTACTS + this.contact_id + '/delinquencies' + '?property_id=' + this.property_id);

        if(r){
          let allDelinquencies = r.delinquencies;

          let match = allDelinquencies.find( i => this.auctionLease.id === i.lease_id );

          if(match && match.id){
            try {
              let d = await api.get(this, api.AUCTIONS + 'delinquencies/' + match.id + '/audit');
              if(d && d.delinquency && d.delinquency[0]){
                this.auditDelinquency = d.delinquency[0];
              }
            } catch (err){
              this.showMessageNotification({
                type: "error",
                description: err,
              });
            }

            this.delinquencyId = match.id;
            this.delinquency = [ match ];
            await this.reverseDelinquencyActions();
          }
        }
      } catch (err){
        this.showMessageNotification({
          type: "error",
          description: err,
        });
      } finally {
        this.fetchingDelinquency = false;
      }

    },
    reverseDelinquencyActions() {
      this.delinquency.map(d => {
        d.Actions.reverse();
      });
    },
    async fetchAuctionContact() {
      this.auctionUpdating = true;

      try {
        let r = await api.get(this, api.CONTACTS + this.contact_id);

        if(r && r.contact){
          this.selectedContact = r.contact;
        }

        if(r && r.contact && r.contact.Leases){
          let match = r.contact.Leases.find( i => this.auctionLease.id === i.id );

          if(match){
            this.$emit('update:auctionLease', match);

            const { LeaseAuction } = match;

            if (LeaseAuction && Object.keys(LeaseAuction).length!== 0) {
              this.scheduled_auction = JSON.parse(JSON.stringify(LeaseAuction));
              this.SellerPremium = LeaseAuction.SellerPremium? JSON.parse(JSON.stringify(LeaseAuction.SellerPremium)): null;
              if(!this.scheduled_auction.contact_id){
                this.scheduled_auction.Contact = {
                  Phones: [{phone: "", type: ""}],
                  country_code: "",
                  email: "",
                  first: "",
                  last: ""
                };
              } else {
                if(!this.scheduled_auction.Contact.Phones.length){
                  this.scheduled_auction.Contact.Phones = [{phone: "", type: ""}];
                } else {
                  this.scheduled_auction.Contact.Phones[0].phone = this.scheduled_auction.Contact.Phones[0].phone.slice(1);
                }
              }
              let localScheduleDateTime = moment(
                this.scheduled_auction.scheduled_date
              ).format("YYYY-MM-DD HH:mm:ss");

              this.scheduled_auction.scheduled_date = localScheduleDateTime;
              this.scheduled_auction.date = moment(localScheduleDateTime).format(
                "YYYY-MM-DD"
              );
              let mommentTime = moment(localScheduleDateTime);
              let hoursOriginal = mommentTime.hours();
              let hours = hoursOriginal < 12 ? hoursOriginal : hoursOriginal == 12 ? 12 : (hoursOriginal - 12);
              hours = hours === 0 ? 12 : hours < 10 ? "0" + hours : hours;
              let minutes = mommentTime.minutes();
              minutes = minutes < 10 ? "0" + minutes : minutes;
              this.timeValue = {
                hours,
                minutes,
                ampm: hoursOriginal < 12 ? 'AM' : 'PM'
              }
              if (!this.scheduled_auction.BuyerPremium) {
                this.scheduled_auction.BuyerPremium = {
                  type: "fixed",
                  value: 0,
                  is_inclusive: false,
                  amount: 0
                }
              }
              if (!this.SellerPremium) {
                this.SellerPremium = {
                  type: "fixed",
                  value: 0,
                  is_inclusive: false,
                  amount: 0
                }
              }
              
              this.scheduled_auction.auction_cleaning_deposit = this.scheduled_auction.cleaning_deposit === null ? this.scheduled_auction.default_cleaning_deposit : this.scheduled_auction.cleaning_deposit;
              this.scheduled_auction.auction_cleaning_period = this.scheduled_auction.cleaning_period === null ? this.scheduled_auction.default_cleaning_period : this.scheduled_auction.cleaning_period;
            }
          }
        }
      } catch (err){
        this.showMessageNotification({
          type: "error",
          description: err,
        });
      } finally {
        this.auctionUpdating = false;
      }
    },
    async deleteMedia(item){
      this.deletingMedia = true;

      if(item.attrs.upload === true){
        this.uploads.splice(item.attrs.index, 1);
        this.showMessageNotification({ type: 'success', description: 'Media Deleted.' });
        this.deletingMedia = false;
      } else {

        let data = {
          uploads: [
            {
              id : item.attrs.id
            }
          ]
        }

        try {
          let r = await api.put(this, api.AUCTIONS + 'leases/' + this.auctionLease.id + '/uploads/delete', data);

          if(r){
            const indexOfSelectedMedia = this.media.findIndex(m => m.id === item.attrs.id);
            this.media.splice(indexOfSelectedMedia, 1);
            this.showMessageNotification({ type: 'success', description: 'Media Deleted.' });
          }
        } catch (err){
          this.showMessageNotification({
            type: "error",
            description: err,
          });
        } finally {
          this.deletingMedia = false;
        }
      }
    },
    /*
    updateAudit(action, event){
      if(action === 'completed' || action === 'skipped'){
        // compile all ids
        console.log(action);
      } else if(action === 'verify') {
        console.log(action, event);
      } else if(action === 'undo') {
        console.log(action, event);
      }
    },
    */
    async updateAudit(action){
      this.updatingAuditStatus = true;

      let data = {
        status: action
      }

      try {
        let r = await api.post(this, api.AUCTIONS + 'delinquencies/' + this.delinquencyId + '/audit', data);

        if(r && r.delinquency && r.delinquency[0]){
          if(r.delinquency[0].audit_status){
            this.auditDelinquency.audit_status = r.delinquency[0].audit_status;
          }
          if(r.delinquency[0].audited_date){
            this.auditDelinquency.audited_date = r.delinquency[0].audited_date;
          }
          if(r.delinquency[0].audited_by){
            this.auditDelinquency.audited_by = r.delinquency[0].audited_by;
          }

          this.showMessageNotification({ type: 'success', description: 'Audit status successfully updated.' });
        }
      } catch (err){
        this.showMessageNotification({
          type: "error",
          description: err,
        });
      } finally {
        this.updatingAuditStatus = false;
      }
    },
    closeCreateTask(){
      this.todo = false;
      this.notSoldRadioGroup = null;
    },
    cancelScheduleNewAuction(){
      this.fetchAuctionContact();
      this.schedule_new_auction = false;
      this.notSoldRadioGroup = null;
    },
    async processRefund(){
      const status = await this.$validator.validateAll('refund');
      if(!status) {
        this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
        return;
      }
      // refund

      this.disableRefund = true;

      var data = {
          amount: parseFloat(this.refund.amount)
      }

      try {
        let r = await api.post(this, api.LEASE_AUCTION + this.scheduled_auction.id + '/refund', data);

        if(r){
          this.auction_refund_id = r.lease_auction_id;
          this.fetchAuctionContact();
          EventBus.$emit('contact_updated', this.contact_id, true);
          EventBus.$emit('reservation_made');
          this.refundAuctionFlag = false;

          this.showMessageNotification({ type: 'success', description: 'Refund successfully processed.' });
        }
      } catch (err){
        this.showMessageNotification({
          type: "error",
          description: err,
        });
      } finally {
        this.disableRefund = false;
      }
    },
    async fetchPayment(){
      try {
        let r = await api.get(this, api.GET_PAYMENT + this.scheduled_auction.payment_id);

        if(r){
          this.auctionPayment = r.payment;
          this.getRefundOptions();
        }
      } catch (err){
        this.showMessageNotification({
          type: "error",
          description: err,
        });
        this.refundAuctionFlag = false;
      }
    },
    getRefundOptions() {
        // Handling Credit payment refund option
        if(this.auctionPayment.method && this.auctionPayment.method === 'credit') {
            this.refund_option = {
            text: 'Credit Balance',
            value: 0
            }
            return;
        }
        let text = ''
        if (['card', 'ach'].indexOf(this.auctionPayment.method) >= 0) {
            text = this.auctionPayment.PaymentMethod.card_type + '****' +  this.auctionPayment.PaymentMethod.card_end
        } else {
            text = this.auctionPayment.method
        }
        this.refund_option = {
            text,
            value: this.auctionPayment.PaymentMethod.id  || 1
        }
    },
    refundAuction() {
      this.refundAuctionFlag = true;
      this.refund.amount = this.scheduled_auction.cleaning_deposit;
      this.fetchPayment();
    },
    async getStorageTreasuresEnabledStatus(){
      if(this.property_id){
        try {
          let r = await api.get(this, api.AUCTIONS + 'vendor-properties/' + this.property_id);

          if(r && r.st_enabled){
            this.storageTreasuresEnabled = true;
          }
        } catch (err){
          this.showMessageNotification({
            type: "error",
            description: err,
          });
        }
      }
    },
  },
  watch: {
    getErrors(value) {
      if(value === false) {
        this.confirm_process_payment = true;
        this.setCheckErrors({ value: null });
      }
    },
    stepper(){
      this.imageError = false;
      this.notSoldRadioGroup = null;
    },
    notSoldRadioGroup(){
      if(this.notSoldRadioGroup === 'dispose'){
        this.todo = true;
      } else if(this.notSoldRadioGroup === 'reschedule'){
        this.scheduleNewAuction();
      }
    }
  }
};
</script>
<style scoped>
  .fw-500 {
    font-weight: 500;
  }
  .payment-data{
    background: white;
    margin-bottom: 10px;
    border-radius: 1px;
  }
  .buyer-premium-label {
    height: 100%;
    display: flex;
  }
  .buyer-premium-label span:first-child{
    height: max-content;
    margin-top: 2px;
  }
  .auction-dialog-categories-list {
    width: 100%;
  }
  .auction-dialog-categories-list .auction-dialog-category {
    width: 42%;
    display: inline-block;
  }
  .auction-dialog-notification {
    word-break: normal;
  }
  .auction-dialog-temp-divider {
    border-top: none;
  }
  .auction-dialog-font-11px {
    font-size: 11px !important;
  }

.auction-manager-bab-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.auction-manager-bab {
  width: 100%;
}

.auction-manager-light-background {
  background: #F9FAFB;
}

.auction-manager-blade-wrapper {
  height: 100%;
}

.auction-manager-white-background {
  background: #FFFFFF;
}

.auction-manager-not-sold-container {
  background: #ffffff;
  border: 1px solid #DFE3E8;
}

.auction-manager-not-sold-description {
  margin-top: 3px;
  line-height: 24px;
}
</style>

<style>
  .auction-dialog-phone-code-fix .country-selector__country-flag {
    top: 14px !important;
  }
  .auction-dialog-phone-code-fix .country-selector__input {
    padding-top: 0 !important;
  }
</style>
