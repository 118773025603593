<template>
  <div class="section-content pt-4" :class="{'mt-10' : $vuetify.breakpoint.xs}">
    <div ref="rmTopSection">
      <hb-header full :divider="false">
        <hb-page-header
          title="Merchandise Management"
          description="Manage your Merchandise and Inventory Tracking for your portfolio"
        >
        </hb-page-header>
      </hb-header>
    </div>
      <div>
        <hb-header one-action :padding="false" align-top>
            <template v-slot:left>
              <hb-tabs v-model="activeTab" class="mt-n2">
                <v-tab
                  v-for="item in tabOptions"
                  :key="item.key"
                >
                  {{ item.label }}
                </v-tab>
              </hb-tabs>
            </template>
            <template v-slot:right>
                <hb-btn class="mr-1" :class="{'mr-3' : $vuetify.breakpoint.xs}" icon :active-state-off="true" @click="showSidebar=!showSidebar">mdi-table-actions-custom-1</hb-btn>
            </template>
        </hb-header>
      
      <v-row class="mx-0" v-if="isPropertyLevel">
        <v-col md="12" class="px-0">         
          <HbPropertySelector
            class="mt-1 mb-1" 
            id="property" 
            :items="facilityList" 
            item-text="name" 
            item-value="id"
            v-validate="'required'" 
            v-model.lazy="property_id" 
            data-vv-scope="gate" 
            data-vv-name="property"
            data-vv-as="Property" 
            :error="errors.has('gate.property')" 
            />
        </v-col>
      </v-row>

      <v-row class="pb-3 mx-0" v-if="(!isPropertyLevel || (isPropertyLevel && property_id)) && !selectedTab" :class="{'pt-4': !isPropertyLevel}">
        <v-col v-if="(isPropertyLevel && property_id) || !isPropertyLevel" xl="5" class="py-0 pl-0">
        <div class="merch-subtitle">Merchandise Library</div>
        <p class="mb-0 hb-text-light lh-24px">Add, download, and edit inventory</p>
        </v-col>
        <v-col xl="7" class="py-0 pr-0" v-if="!hideTableFilters">
        <div class="flex-content">
          <hb-text-field
          class="py-0"
          style="width: 100%;"
          :disabled="hideTableFilters || (isPropertyLevel &&  !property_id)" 
          search
          full
          v-model="searchField"
          placeholder="Search"
          ></hb-text-field>
          <div class="merch-global-buttons ml-2">
          <hb-btn icon class="ml-1" tooltip="Download" :active-state-off="true" @click="editEvent('download')" :disabled="hideTableFilters || (isPropertyLevel &&  !property_id)" hover-color="#637381">mdi-download</hb-btn>
          <hb-btn class="ml-2 mr-4" icon @click="editEvent('edit')" :active-state-off="true" :disabled="hideTableFilters || (isPropertyLevel &&  !property_id)"  tooltip="Bulk Edit">mdi-square-edit-outline</hb-btn>
          <hb-btn small color="secondary" class="w-126px" @click="showDialogEvent"> {{ isPropertyLevel ? 'Assign' : 'Add New' }} Product</hb-btn>
          </div>
        </div>
        </v-col>
      </v-row>
      <v-tabs-items v-model="activeTab" style="background-color: transparent;">

        <v-tab-item>
          <v-row class="mx-0" v-if="!isPropertyLevel">
            <v-col class="pa-0" :md="showSidebar ? 10 : 12">
              <corporate-merchandise ref="corporate" :show-assign-slide="showAssignSlide" @set-sidebar-height="setSidebarHeightEvent" @hide-slide="showAssignSlide=''" :search="searchField" @hide-filters="hideTableFiltersEvent" :selected-tab="selectedTab" />
            </v-col>
            <v-col class="py-0 mt-n2" v-if="showSidebar" :md="showSidebar ? 2 : 0">
              <div class="side-bar-merch" :style="{'height': sideBarHeight}">
                <v-list dense class="merchandise-list">
                  <v-list-item-group
                    v-model="selectedTab"
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, i) in ['Inventory','Product Categories']"
                      :key="i"
                    >
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 14px;;line-height: 20px;" v-text="item"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <v-row class="mx-0" v-if="isPropertyLevel">
            <v-col class="pa-0" :md="showSidebar ? 10 : 12">
              <property-merchandise ref="property" :show-assign-slide="showAssignSlide" @set-sidebar-height="setSidebarHeightEvent" @hide-slide="showAssignSlide=''" :selected-tab="selectedTab" v-if="isPropertyLevel" :property-id="property_id" :search="searchField" @hide-filters="hideTableFiltersEvent" />
            </v-col>
            <v-col class="py-0 mt-n2" v-if="showSidebar" :md="showSidebar ? 2 : 0">
              <div class="side-bar-merch" :style="{'height': sideBarHeight}">
                <v-list dense class="merchandise-list">
                  <v-list-item-group
                    v-model="selectedTab"
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, i) in ['Inventory','Notifications']"
                      :key="i"
                    >
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 14px;line-height: 20px;" v-text="item"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script type="text/babel">
import Status from "../includes/Messages.vue";
import Loader from "../assets/CircleSpinner.vue";
import { mapGetters, mapActions } from 'vuex';
import { EventBus } from "../../EventBus";

export default {
name: "MerchandiseManagement",
components: {
  'corporate-merchandise': () => import('../settings/MerchandiseManagement/CorporateMerchandise.vue'),
  'property-merchandise': () => import('../settings/MerchandiseManagement/PropertyMerchandise.vue'),
  'add-product': () => import('../settings/MerchandiseManagement/AddProduct.vue'),
  Loader, Status
},
data() {
  return {
    productWeightType: 'lbs',
    property: null,
    property_id: "",
    searchItems: [],
    searchField: "",
    isPropertySelected: null,
    showTertiary: true,
    selection: true,
    menuItems: [],
    showAside: true,
    merchandise: true,
    showAdd: false,
    label: "Add Product",
    leftColsValue: '6',
    rightColsValue: '6',
    isExpanded: false,
    uploading: false,
    preview: [],
    displayCorporateInventory: false,
    addProductDialog: false,
    showAddProductBox: false,
    showAssignSlide: '',
    hideTableFilters: false,
    selectedTab: 0,
    showSidebar: false,
    sideBarHeight: '100%',
    tabOptions:[
      { label: 'Corporate', key: 1 },
      { label: 'Property', key: 2 }
    ],
    activeTab: null
  }
},
computed: {
  ...mapGetters({
    facilityList: 'propertiesStore/filtered',
    defaultProperty: "propertiesStore/defaultProperty"
  }),
  isPropertyLevel() {
    return this.activeTab === 1
  }
},
watch: {
  activeTab(params) {
    if (!params) {
      // this.property_id = this.defaultProperty ? this.defaultProperty.id : "";
      this.property_id = "";
      this.hideTableFilters = false;
    } else {
      this.hideTableFilters = true;
    }
    this.searchField = '';
    this.showAssignSlide = '';
    this.selectedTab = 0;
    this.showSidebar = false;
  },
  property_id() {
    this.setDefaultProperty(this.facilityList.find(e => e.id == this.property_id))
  }
},
methods: {
  ...mapActions({
    setDefaultProperty: 'propertiesStore/setDefaultProperty'
  }),
  toggleSidebarEvent() {
    this.showSidebar = !this.showSidebar;
  },
  showDialogEvent() {
    if (!this.isPropertyLevel) {
      this.$refs.corporate.addProductDialog = true;
    } else {
      this.$refs.property.showAssignProductModalEvent();
    }
  },
  editEvent(params) {
    if (params === 'download') {
      if (this.isPropertyLevel) this.$refs.property.downloadReport()
      else this.$refs.corporate.downloadReport()
    } else {
      this.showAssignSlide = params;
    }
  },
  hideTableFiltersEvent(params) {
    this.hideTableFilters = params;
  },
  downloadReportEvent() {
    if (!this.isPropertyLevel) {
      this.$refs.corporate.downloadReport();
    } else {
      this.$refs.property.downloadReport();
    }
  },
  setSidebarHeightEvent(params) {
    this.sideBarHeight = params - 2 + 'px';
  }
}
}
</script>

<style lang="scss" scoped>
.inline-flex {
display: inline-flex !important;
}

.left-border {
border-left: 1px solid #ccc;
/* Adjust color and thickness as needed */
}

.display-none {
display: none;
}

.modal-form-bg-color {
background: #F4F6F8;
}

.font-weight-500 {
font-weight: 500;
}

/* Add styles for preview section */
.preview {
margin-top: 20px;
display: flex;
flex-wrap: wrap;
}

.preview-img {
margin-right: 10px;
position: relative;
}

.preview img {
max-width: 100%;
max-height: 100%;
}

.remove-button {
position: absolute;
top: 5px;
right: 5px;
cursor: pointer;
font-size: 25px;
color: #bfbfbf;
}

/* Rest of the styles remain the same */
.image-upload {
border: 2px dashed #ccc;
padding: 20px;
text-align: center;
cursor: pointer;
position: relative;
}

.image-upload input[type="file"] {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
opacity: 0;
cursor: pointer;
}

.uploading {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(255, 255, 255, 0.5);
display: flex;
align-items: center;
justify-content: center;
}

.upload-content {
display: flex;
flex-direction: column;
align-items: center;
}

.icon-upload {
font-size: 48px;
color: #ccc;
margin-bottom: 10px;
}

.click-to-upload {
color: blue;
text-decoration: underline;
cursor: pointer;
}

.preview-img-border {
border: 1px solid #bfbfbf;
margin: 0;
}

.flex-content {
display: flex;
justify-content: space-around;
align-items: center;
}

.no-data-box {
padding: 30px 0;
text-align: center;
border: 1.5px dashed #ccc;
border-radius: 3px;
}

.merch-subtitle {
font-size: 14px;
font-weight: 500;
line-height: 16px;
}

.merch-global-buttons {
min-width: 235px;
}

.width-100 {
width: 100%;
}



.side-bar-merch {
border-left: 1px solid #ccc;
width: 100%;
height: 100%;
}

.lh-24px {
line-height: 24px;
}

</style>


<style>
.corporate-merchand-table-2 .theme--light.v-data-table tbody tr.v-data-table__selected,
.property-merchand-table .theme--light.v-data-table tbody tr.v-data-table__selected,
.corporate-merchand-table .theme--light.v-data-table tbody tr.v-data-table__selected {
background-color: #E0F5F5 !important;
}

.corporate-merchand-table .v-data-table .v-data-table__wrapper .v-data-table-header .v-data-table__checkbox.v-simple-checkbox .material-icons.theme--light,
.property-merchand-table .v-data-table .v-data-table__wrapper .v-data-table-header .v-data-table__checkbox.v-simple-checkbox .material-icons.theme--light,
.corporate-merchand-table-2 .v-data-table .v-data-table__wrapper .v-data-table-header .v-data-table__checkbox.v-simple-checkbox .material-icons.theme--light {
color: #00848e !important;
caret-color: #00848e !important;
}

.w-126px .hb-secondary-button {
width: 126px
}

.merchandise-drawer-property .v-navigation-drawer__content {
background-color: #F9FAFB;
}

.theme--light.v-list.merchandise-list {
background-color: transparent;
}

.merchandise-list .v-item-group .v-list-item .v-list-item__title {
color: #637381;
font-weight: 400;
}

.merchandise-list .v-item-group .v-list-item--active .v-list-item__title {
color: #000;
font-weight: 500;
}

.merchandise-list .v-item-group .v-list-item--active {
border-left: 4px solid #00848E;
background-color: #E0F5F5;
}

.merchandise-list .v-item-group .v-list-item:hover {
background-color: #E0F5F5;
}

.merchandise-list .v-item-group .v-list-item--active:before {
opacity: 0;
}

/* Chrome, Safari, Edge, Opera */
.no-num-arrows input::-webkit-outer-spin-button,
.no-num-arrows input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* Firefox */
.no-num-arrows input[type=number] {
-moz-appearance: textfield;
}
</style>