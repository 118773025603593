<template>
    <div class="pa-0">
        <div class="interaction-section-body1">
            <div v-for="setting in settings" :key="setting.id">
                <hb-form :label="getName(setting.payment_method)" :active.sync="active" 
                    @cancel="handleEmittedCancel(setting.payment_method)" @save="handleEmittedSave(setting.payment_method)" 
                    :editable="hasPermission('close_of_day_config') && toggleSwitched"
                    :empty="false">
                    <template v-slot:display>
                       <v-col class="ma-0 pa-0" cols="12" sm="12">
                            <template v-if="setting.payment_method === 'cleaning_deposit'">
                                <hb-checkbox :disabled="!toggleSwitched || !hasPermission('close_of_day_config')" class="ma-0 pa-0 pb-3" hide-details v-model="setting.auto_close"
                                    label="Do not show on close of day tools page" />
                            </template>
                            <template v-else>
                                <hb-radio-group :disabled="!toggleSwitched || !hasPermission('close_of_day_config')" v-model="setting.auto_close" row>
                                    <hb-radio v-if="setting.payment_method === 'cash&check'" label="Manual" :value="0" />
                                    <hb-radio v-if="setting.payment_method !== 'cash&check'" label="Automatic" :value="1" />
                                </hb-radio-group>
                                <input v-if="setting.auto_close == 1" type="time" :disabled="!toggleSwitched || !hasPermission('close_of_day_config')"
                                    v-model="setting.settlement_time" />
                            </template>
                        </v-col>
                    </template>

                    <template v-slot:edit>
                        <div>
                            <template v-if="setting.payment_method === 'cleaning_deposit'">
                                <hb-checkbox class="ma-0 pa-0 pb-3" hide-details v-model="setting.auto_close"
                                label="Do not show on close of day tools page" />
                            </template>
                            <template v-else>
                                <hb-radio-group v-model="setting.auto_close" row>
                                    <hb-radio v-if="setting.payment_method === 'cash&check'" label="Manual" :value="0" />
                                    <hb-radio v-if="setting.payment_method !== 'cash&check'" label="Automatic" :value="1" />
                                </hb-radio-group>
                                <template v-if="setting.auto_close == 1">
                                    <span>Set the time to close the day</span>
                                    <br />
                                    <v-row no-gutters v-if="setting.auto_close == 1" class="mt-3">
                                        <v-col md="3" class="ma-0 pa-0">
                                            <v-text-field
                                                id="settlement_hours"
                                                name="settlement_hours"
                                                label="HH"
                                                v-model="parseSettlementTime(setting.settlement_time).hours"
                                                @change="(e) =>updateSettlementTime(setting, e, parseSettlementTime(setting.settlement_time).period)"
                                                data-vv-as="Hours"
                                                type="number"
                                                min="1"
                                                max="12"
                                                width="80%"
                                                v-validate="'required|numeric|max_value:12|min_value:0'"
                                                hide-details
                                                :error="errors.collect('settlement_hours').length > 0"
                                                class="mt-0 pt-0 time-w-80"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col md="3" class="ma-0 pa-0">
                                            <v-text-field
                                                id="settlement_time_mm"
                                                name="settlement_time_mm"
                                                label="MM"
                                                value="00"
                                                disabled
                                                data-vv-as="Minutes"
                                                type="number"
                                                min="0"
                                                max="59"
                                                width="80%"
                                                hide-details
                                                class="mt-0 pt-0 time-w-80"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col md="5" class="ma-0 pa-0" :style="{position: 'relative'}">
                                            <v-select
                                                :items="['AM','PM']"
                                                id="settlement_time_ampm"
                                                name="settlement_time_ampm"
                                                v-validate="'required'"
                                                v-model="parseSettlementTime(setting.settlement_time).period"
                                                @change="(e) =>updateSettlementTime(setting, parseSettlementTime(setting.settlement_time).hours, e)"
                                                data-vv-name="settlement_time_ampm"
                                                data-vv-as="AM/PM"
                                                hide-details
                                                width="80%"
                                                :error="errors.collect('settlement_time_ampm').length > 0"
                                                class="mt-0 pt-0 time-w-80"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                </template>
                            </template>
                        </div>
                    </template>
                </hb-form>

            </div>
        </div>
    </div>
</template>

<script type="text/babel">

import api from "../../../assets/api.js";
import { mapGetters } from "vuex";
import { notificationMixin } from '@/mixins/notificationMixin.js';

export default {
    name: 'CloseOfDaySettings',
    data() {
        return {
            active: '',
            settings: [
            ],
            codSettingsCache: [],
        };
    },
    async created() {
        await this.getSettings();
    },
    props: ['facilityID', 'toggleSwitched'],
    mixins: [notificationMixin],
    components: {
    },
    computed: {
        ...mapGetters({
            hasPermission: 'authenticationStore/rolePermission',
        }),
    },
    methods: {
        handleEmittedCancel(label) {            
            const cancelSetting = this.settings.find(x => x.payment_method === label);
            const cacheSetting = this.codSettingsCache.find(x => x.payment_method === label);
            if(cancelSetting){
                cancelSetting.auto_close = cacheSetting ? cacheSetting.auto_close : null;
                cancelSetting.settlement_time = cacheSetting ? cacheSetting.settlement_time : null;
            }
        },
        mapData(response) {
            const dummy = [
                { payment_method: "cash&check" },
                { payment_method: "card" },
                { payment_method: "directdeposit" },
                { payment_method: "directdebit" },
                { payment_method: "ach" },
                { payment_method: "eftpos" },
                { payment_method: "cleaning_deposit" },
            ];
            const mergedData = dummy.map(d => {
                const serverMatch = response && response.find(s => s.payment_method === d.payment_method);
                return serverMatch ? { ...d, ...serverMatch } : d;
            });
            return mergedData;
        },
        async handleEmittedSave(label) {
            try {
                await this.saveSetting(label);
                this.active = 'saved';
                this.showMessageNotification({ type: 'success', description: `close of day setting have been saved.` });
            } catch (err) {
                this.showMessageNotification({ type: 'error', description: `Error: ${err}` });
            }
        },
        async getSettings() {
            try {
                const res = await api.get(this.$app, api.SETTINGS + `cod-config${this.facilityID ? `?property_id=${this.facilityID}` : ''}`);
                this.settings = res && res.settings && this.mapData(res.settings);
                this.codSettingsCache = JSON.parse(JSON.stringify(this.settings));
            } catch (err) {
                this.showMessageNotification({ type: 'error', description: `Error: ${err}` });
            }
        },
        async saveSetting(label) {
            let formData = {};
            const settingsObj = this.settings.find(x => x.payment_method === label);
            settingsObj.auto_close = settingsObj.auto_close || 0; 
            if(settingsObj.auto_close == 1 && !settingsObj.settlement_time) settingsObj.settlement_time = "00:00";
            if (!settingsObj) {
                this.showMessageNotification({ description: `settings not found` });
                return;
            }
            const { id, ...foundSettings } = settingsObj;
            formData = {
                settings: { ...foundSettings },
                is_enabled: 1,
                previous_details_id: settingsObj.id
            };
            const response = await api.post(this.$app, api.SETTINGS + `cod-config${this.facilityID ? `?property_id=${this.facilityID}` : ''}`, formData);
            if(response && response.settings && this.settings){
                this.settings.forEach((item, index) => {
                    if (item.payment_method === response.settings.payment_method) {
                        this.settings[index] = { ...item, ...response.settings };
                    }
                });    
            this.codSettingsCache = JSON.parse(JSON.stringify(this.settings));
            }
        },
        updateSettlementTime(setting, hours, period) {
            let hoursNumeric = parseInt(hours, 10);
            const minutesNumeric = '00';
            if (period === "PM" && hoursNumeric < 12) hoursNumeric += 12;
            if (period === "AM" && hoursNumeric === 12) hoursNumeric = 0;
            setting.settlement_time = `${hoursNumeric
                .toString()
                .padStart(2, "0")}:${minutesNumeric.toString().padStart(2, "0")}`;
        },
        parseSettlementTime(time) {
            const defaultTime = time ? time : "00:00";
            const [hourStr, minuteStr] = defaultTime.split(":");
            let hours = parseInt(hourStr, 10);
            const minutes = parseInt(minuteStr, 10);
            const period = hours >= 12 ? "PM" : "AM";
            if (hours > 12) hours -= 12;
            return {
                hours: hours.toString().padStart(2, "0"),
                minutes: minutes.toString().padStart(2, "0"),
                period,
            };
        },
        getName(paymentMethod) {
            switch (paymentMethod) {
                case 'cash&check':
                    return 'Cash and Check';
                case 'eftpos':
                    return 'EFTPOS';
                case 'directdeposit':
                    return 'Direct Deposit';
                case 'card':
                    return 'Credit Card';
                case 'directdebit':
                    return 'Direct Debit';
                case 'ach':
                    return 'ACH';
                case 'cleaning_deposit':
                    return 'Cleaning Deposit';
                default:
                    return '';
            }
        }
    },
    watch: {
        facilityID() {
            this.getSettings();
        },
        toggleSwitched() {
            this.getSettings();
        }
    }
};
</script>
<style scoped>
.disabled-color {
    color: #637381;
}

.time-w-80{
    width: 80%;
  }
</style>
