<template>
  <div>
    <div class="px-4 pt-3 pb-2">
      <div v-for="product in productServices" class="pb-1">
        <div class="align-start d-flex justify-space-between my-2">
          <div class="pr-2 pt-1">
            <div class="hb-button-text-regular font-weight-medium pb-2">
              {{ product.name }}
            </div>
            <div class="hb-text-light hb-button-text-small">
              {{ product.description }}
            </div>
          </div>
          <div class="d-flex align-center">
            <div class="mr-2" style="" v-if="(product.amount_type !== 'variable')">
              <div class="d-flex justify-space-between">
                <div class="d-flex align-center justify-space-between px-2 py-1"
                  style="height:32px; border: 1px solid #DFE3E8; border-radius: 5px;">
                  <hb-icon color="#000" :custom-size="12" class="hand"
                    @click="() => { removeAddQty('remove', product?.product_id) }">mdi-minus</hb-icon>
                  <span class="d-inline-block px-3">{{ product.qty }}</span>
                  <hb-icon color="#000" class="hand" :custom-size="12"
                    @click="() => { removeAddQty('add', product?.product_id) }">mdi-plus</hb-icon>
                </div>
              </div>
            </div>
            <div class="mr-2" style="width: 60px;text-align: right;">${{ product?.price || '0'
              }} </div>
            <hb-icon color="#637381" class="hand" @click="removeAddQty('remove_all', product?.product_id)" small>mdi-close-circle</hb-icon>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script type="text/babel">

import api from '../../../assets/api.js';
import { EventBus } from '../../../EventBus.js';

import moment from 'moment';
import { mapGetters } from 'vuex';
import { notificationMixin } from '../../../mixins/notificationMixin.js';

export default {
  name: "SendQuoteMerchandise",
  mixins: [notificationMixin],
  data() {

  },
  async created() {
  },
  props: ["productServices", "unit_Id", "property_Id", "allPropertyUnits"],
  computed: {
  },
  methods: {
    async removeAddQty(type, prodId) {
      let propertyUnitsParse = JSON.parse(JSON.stringify(this.allPropertyUnits));

      propertyUnitsParse = await Promise.all(
        propertyUnitsParse.map(async (propData) => {
          if (propData?.property?.id == this.property_Id) {
            const unitValues = await Promise.all(
              propData?.units.map(async (unitValue) => {
                if (unitValue.unit_id == this.unit_Id) {
                  unitValue.selectedMerchandise = unitValue.selectedMerchandise
                    .map((merchandiseData) => {
                      if (merchandiseData.product_id == prodId) {
                        if (type == "add") {
                          return {
                            ...merchandiseData,
                            qty: merchandiseData.qty + 1,
                          };
                        } else if (type == "remove" && merchandiseData.qty > 1) {
                          return {
                            ...merchandiseData,
                            qty: merchandiseData.qty - 1,
                          };
                        }
                        // Skip items with qty == 1 for "remove"
                        return null;
                      }
                      return merchandiseData;
                    })
                    .filter((item) => item !== null); // Remove null items
                }
                return unitValue;
              })
            );
            propData.units = unitValues;
          }
          return propData;
        })
      );

      await this.$store.dispatch(
        "sendQuoteStore/getPropertyUnitsInfo",
        propertyUnitsParse
      );
    }

  }
}
</script>
