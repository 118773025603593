<template>
    <div style="overflow: hidden" class="navigation-panel-container">
        <div class="panel-content">
            <hb-blade-header
                title="Transfer"
                title-icon="mdi-system-navigation-transfer"
                @back="back"
                :back-button-off="step == 1 || step == 3 || step == 4"
                @close="closeProcess"
            >
                <template v-slot:right>
                    <hb-btn
                        class="mr-1"
                        icon
                        tooltip="Transfer Summary"
                        @click="
                            summary_panel =
                                summary_panel === 'transferSummary'
                                    ? null
                                    : 'transferSummary'
                        "
                        :active="summary_panel === 'transferSummary'"
                        hover-background-color="#CAEAEA"
                        >
                        mdi-users-custom-1
                    </hb-btn>
                </template>
            </hb-blade-header>
            <div class="main-panel-body">
                <div class="main-panel-header">
                    <hb-stepper
                        :value="step"
                        v-if="showStepper"
                    >
                        <hb-stepper-header :force-resize="resizeStepper">
                            <hb-stepper-step
                                step="1"
                                :complete="step > 1"
                            >
                                Setup Transfer
                            </hb-stepper-step>

                            <v-divider></v-divider>

                            <hb-stepper-step
                                step="2"
                                :complete="step > 2"
                            >
                                Payment
                            </hb-stepper-step>

                            <v-divider></v-divider>

                            <hb-stepper-step
                                step="3"
                                :complete="step > 3"
                            >
                                Sign Lease
                            </hb-stepper-step>
                        </hb-stepper-header>
                    </hb-stepper>
                    <div class="d-flex justify-center py-10" v-if="isLoadingData">
                        <v-progress-circular
                            indeterminate
                            color="primary"
                            class="loading"
                        ></v-progress-circular>
                    </div>
                    <div class="main-panel-content">
                        <setup-transfer
                            v-if="step === 1 && unit_out.id"
                            @close="closeProcess"
                            @next="next"
                            @setUnit="setUnitSelected"
                            @setReason="setReasonSelected"
                            :lease="lease"
                            :unitOut.sync="unit_out"
                            :transferData.sync="form"
                            @showTransferSummary="
                                summary_panel = 'transferSummary'
                            "
                        >
                        </setup-transfer>
                        
                        <transfer-payments
                            v-if="step === 2"
                            :unit_invoice="configuration_unit_invoice"
                            :paymentMethodsData="getPaymentMethods"
                            :property_id="property_id"
                            :contact_id="contact.id"
                            :refundCredit="currentRefund"
                            :lease_id="lease_id"
                            :transferDataPayments.sync="form"
							:move-out-space-access="moveOutSpaceAccess"
                            :is-space-access-codes-vendor="isSpaceAccessCodesVendor"
                            :configuration_unit="configuration_unit"
                            :selectedHours="selectedHours"
                            @isAutoChargeEnrolled="disableAutoChargeDropDown"
                            @selectedPaymentMethod="setPaymentMethod"
                            @close="closeProcess"
                            @next="next"
                        ></transfer-payments>

                        <sign-lease
                            v-if="step === 3"
                            :transfer="transfer"
                            :selectedHours="selectedHours"
                            @close="closeProcess"
                            @next="next"
                            :access-areas-enabled="accessAreasEnabled"
                        ></sign-lease>

                        <transfer-receipt
                            v-if="step === 4"
                            @close="closeProcess"
                        ></transfer-receipt>
                    </div>
                </div>
                <div class="main-side-info-panel" v-if="summary_panel">
                    <transfer-summary
                        dense
                        v-if="summary_panel === 'transferSummary' && lease.id"
                        @hide="summary_panel = ''"
                        @selectedPaymentMethod="setPaymentMethod"
                        @selectedUserHasOwnInsurance="setUserHasOwnInsurance"
                        @selectedProductFees="setProductFees"
                        :currentRefund="currentRefund"
                        :insurances="insurances"
                        :fees="fees"
                        :paymentMethodsData="getPaymentMethodsArray"
                        :transferData.sync="form"
                        :selectedHoursObject.sync="selectedHours"
                        :leaseData="lease"
                        :unitIn="form.unit_in"
                        :contact="contact"
                        :property_id="property_id"
                        :step.sync="step"
                        :access="access"
                        :space-access="spaceAccess"
                        :is-space-access-codes-vendor="isSpaceAccessCodesVendor"
                        :access-areas-enabled="accessAreasEnabled"
                    ></transfer-summary>
                </div>
            </div>
        </div>
        <hb-modal confirmation v-model="dialog" size="medium" :title="beforePayment ? 'Abort Transfer' : 'Complete Document Signing'" show-help-link>
            <template v-slot:content
                >
                <div class="px-6 pt-4" v-if="beforePayment">
                     This transfer process will require you to restart the transfer
                in the future.
                </div>
                <div class="px-6 py-4" v-if="beforePayment">
                    Are you sure you want to continue?
                </div>
                <div class="px-6 py-4" v-if="afterPayment">
                    In order to complete document signing, please look in the Pending Transfer's from the Task Center.
                </div>
            </template>
            <template v-slot:actions>
                <hb-btn color="primary" @click="confirmClose">Confirm</hb-btn>
            </template>
        </hb-modal>
    </div>
</template>

<script type="text/babel">
import SetupTransfer from "./SetupTransfer.vue";
import TransferPayments from "./TransferPayments.vue";
import SignLease from "./SignLease.vue";
import TransferSummary from "./TransferSummary.vue";
import api from "../../../assets/api.js";
import moment from "moment";
import TransferReceipt from "./TransferReceipt.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import Loader from '../../assets/CircleSpinner.vue';
import { EventBus } from '../../../EventBus.js';

export default {
    name: "TransferIndex",
    data() {
        return {
            accessAreasEnabled: false,
            step: 1,
            showStepper: true,
            summary_panel: "transferSummary",
            lease: [],
            unit_out: {},
            insurances: [],
            fees: [],
            leaseMatrices: [],
            show_reminder: false,
            refundInvoices: [],
            form: {
                unit_in: "",
                reason: "",
                notes: "",
                autopay_id: "",
                promotion: {id: "", name: "No Promo"},
                discount_id: "",
                productFees: [{product_id: ''}],
                insurance_id: "",
                isFreeInsuranceEnabled: false,
                table_rate_coverage_amount: null,
                decline_insurance: false,
                insurance_exp_month: "",
                payment_cycle: "",
                insurance_exp_year: "",
                disable_autopay: false,
                bill_day: 1,
                PaymentCycleOptions: []
            },
            selectedHours: {},
            selectedPaymentMethod: {},
            paymentMethods: [],
            autoPaymentMethods: [],
            dialog: false,
            beforePayment: false,
            afterPayment: false,
            resizeStepper: 0,
        };
    },
    props: ["close", "contact", "property_id", "lease_id", "unit_id", "transfer", "access", "spaceAccess", "isSpaceAccessCodesVendor"],
    components: {
        SetupTransfer,
        TransferPayments,
        SignLease,
        TransferSummary,
        TransferReceipt,
        Loader
    },
    async mounted() {
        setTimeout(() => {
            this.$store.dispatch('charmCallStore/setLoadingAction', {"status": false, "eventStatus": "transfer-button-clicked"});
        }, 2000);
        await this.getTransferOutCredit();
        if(this.transfer && this.transfer.id && this.activeDrawerComponent?.step !== 4) return;
        else if(this.activeDrawerComponent?.step === 4) this.step = 4;
        this.$store.commit("onBoardingStore/resetConfigurationUnit");
        this.$store.commit("onBoardingStore/resetUnitsInArea");
        await this.updateProperty();
        await this.setPhase();
    },
    async created() {
        await api.get(this, api.PROPERTIES + this.property_id + "/property-area-management").then(results => {
            this.accessAreasEnabled = results?.property_area_configuration.access_hours_upgrade === 1;
        }).catch(error => {
            this.accessAreasEnabled = false;
        });
        this.setOnBoardingSource("TRANSFERS");
        await this.setPaymentSource({
            paymentSource: "TRANSFERS"
        });
        if(this.transfer && this.transfer.id) {
            this.step = 3;
            await this.$store.dispatch(
                "onBoardingStore/getConfigurationUnitInfo",
                {
                    unit_id: this.unit_id,
                }
            );
        }
        await this.getLeaseData();
        await this.$store.dispatch("onBoardingStore/getTaxRate", {
                property_id: this.property_id,
            });
        await this.$store.dispatch("onBoardingStore/getContactInfo", {contact_id : this.contact.id});
        await this.fetchLeasePaymentMethods();
        await this.fetchLeaseAutoPaymentMethods();
        await this.fetchLeaseInsurance();
        await this.fetchFees();
        await this.setUpPaymentCycle();
        await this.setUpBillingCycle();
        EventBus.$on('reset_transfer_hb_model', this.confirmClose);
    },
    computed: {
        ...mapGetters({
            properties: "propertiesStore/filtered",
            configuration_invoices: "onBoardingStore/configuration_invoices",
            configuration_unit: "onBoardingStore/configuration_unit",
            services: "onBoardingStore/services",
            isAutoChargeSet: "paymentsStore/isAutoChargeSet",
            activeDrawerComponent: 'navigationStore/activeDrawerComponent',
            billingProfiles: 'billingProfileStore/billingProfiles',
        }),
        getFeesFromServices () {
            let fees_arr = [];
            fees_arr = this.services.filter(p =>{
                if(p.Product.default_type === "late") return {product_id: p.Product.id};
            });
            return fees_arr;
        },
        currentRefund() {
            return this.refundInvoices.reduce((a, p) => a + p.credit, 0);
        },
        getPaymentMethods() {
            let paymentData = JSON.parse(JSON.stringify(this.paymentMethods));

            if (this.autoPaymentMethods && this.autoPaymentMethods.length) {
                this.form.autopay_id = this.autoPaymentMethods[0].id;
            }
            return paymentData;
        },
        getPaymentMethodsArray() {
            let paymentData = JSON.parse(JSON.stringify(this.paymentMethods));
            paymentData.unshift({ id: "", name: (paymentData && paymentData.length ? "No Autopay" : "No available payment method")});

            if (this.autoPaymentMethods && this.autoPaymentMethods.length) {
                this.form.autopay_id = this.autoPaymentMethods[0].id;
            } else {
                this.form.autopay_id = paymentData[0].id;
            }

            return paymentData;
        },
        configuration_unit_invoice() {
            let invoices = [];
            invoices = this.configuration_invoices;

            let summary_invoice;
            if (invoices && invoices.length) {
                for (const invoice of invoices) {
                    if (!summary_invoice) {
                        summary_invoice = JSON.parse(JSON.stringify(invoice));
                        continue;
                    }

                    for (const line of invoice.InvoiceLines) {
                        let index = summary_invoice.InvoiceLines.findIndex(
                            (il) => il.Product.id == line.Product.id
                        );
                        if (index >= 0) {
                            if (
                                moment(line.end_date, "YYYY-MM-DD").isAfter(
                                    moment(
                                        summary_invoice.InvoiceLines[index]
                                            .end_date,
                                        "YYYY-MM-DD"
                                    )
                                )
                            ) {
                                summary_invoice.InvoiceLines[index].end_date =
                                    line.end_date;
                            }
                            summary_invoice.InvoiceLines[index].cost +=
                                line.cost;
                        } else {
                            summary_invoice.InvoiceLines.push(
                                JSON.parse(JSON.stringify(line))
                            );
                        }
                    }

                    summary_invoice.sub_total += invoice.sub_total;
                    summary_invoice.total_tax += invoice.total_tax;
                    summary_invoice.discounts += invoice.discounts;
                    summary_invoice.total_due += invoice.total_due;
                    summary_invoice.balance += invoice.balance;
                }
            }

            return summary_invoice;
        },
        isLoadingData() {
            return !!(this.isLoading(this.$options.name) && this.step === 1)
        },
        moveOutSpaceAccess(){
            return this.spaceAccess.find(i => i.unit_id === this.unit_id);
        }
    },
    methods: {
        ...mapMutations({
           setPaymentSource: 'paymentsStore/setPaymentSource',
           setOnBoardingSource: 'onBoardingStore/setOnBoardingSource',
           resetContact: 'onBoardingStore/resetContact',
           setPCycleOptions: 'onBoardingStore/setPCycleOptions'
        }),
        ...mapActions({
            setActiveDrawerComponent:'navigationStore/setActiveDrawerComponent',
        }),
        async setUpPaymentCycle(){
            this.form.payment_cycle = this.lease.payment_cycle;
            let results = await api.get(this, api.LEASES + this.lease_id + "/payment-cycles");
             this.form.PaymentCycleOptions = results.lease.PaymentCycleOptions;
             this.lease.PaymentCycleOptions = results.lease.PaymentCycleOptions;
        },
        async setUpBillingCycle(){
            if(this.lease?.BillingCycle?.billing_cycle_id){
                this.form.billing_cycle_id = this.lease?.BillingCycleOptions?.find(bco => bco.billing_cycle_id === this.lease?.BillingCycle?.billing_cycle_id)?.billing_cycle_id;
            } else {
                if(this.billingProfiles?.length){
                    let profile = this.billingProfiles?.find( bf => bf.property_id === this.property_id);
                    if(profile){
                        this.form.billing_cycle_id = this.lease?.BillingCycleOptions?.find(bco => bco.billing_cycle_id === profile.billing_cycle_id)?.billing_cycle_id;
                    }
                }
            }
        },
        fetchLeasePaymentMethods() {
            api.get(this, api.LEASES + this.lease_id + "/payment-methods").then(
                (r) => {
                    this.paymentMethods = r.paymentMethods;
                }
            );
        },
        fetchLeaseAutoPaymentMethods() {
            api.get(
                this,
                api.LEASES + this.lease_id + "/payment-methods/autopay"
            ).then((r) => {
                this.autoPaymentMethods = r.paymentMethods;
            });
        },
        async fetchLeaseInsurance() {
            api.get(this, api.LEASES + this.lease.id + "/insurance").then((r) => {
                if(r.data.insurance_service){
                    if(r.data.insurance_service.status) {
                        this.form.insurance_id = r.data.insurance_service.insurance_id;
                    } else {
                        if(r.data.options.length > 0) {
                            this.form.insurance_id = r.data.options[0].id;
                        }
                    }
                }
                this.insurances = r.data.options;
                this.form.isFreeInsuranceEnabled = r.data.is_free_insurance_enabled;
                this.insurances.push({ id: "", name: "Tenant has their own insurance" });
            });
        },
        async fetchFees() {
            let r = await api.get(
                this,
                api.PROPERTIES +
                    this.lease.Unit.property_id +
                    "/products?type=late&category_type=transfer"
            );

            r.products.unshift({ id: "", name: "No Fee" });

            if(this.services.length && this.getFeesFromServices.length) {
                this.form.productFees = this.getFeesFromServices;
            }
            this.fees = r.products;
        },
        closeProcess() {
            if (this.step == 1 || this.step == 2) {
                this.dialog = true;
                this.beforePayment = true;
                this.afterPayment = false;
            } else if (this.step == 3){
                this.dialog = true;
                this.beforePayment = false;
                this.afterPayment = true;
                
                EventBus.$emit('refresh-contact-profile');
            }
             else {
                this.$store.commit("onBoardingStore/resetConfigurationLease");
                this.$store.commit("onBoardingStore/resetConfigurationInvoice");
                this.$store.commit("onBoardingStore/resetConfigurationUnit");
                this.$store.commit("onBoardingStore/resetUnitsInArea");
                this.$store.commit("onBoardingStore/resetLease");
                this.$store.commit("onBoardingStore/resetInvoice");
                this.$store.commit("onBoardingStore/resetUnit");
                
                EventBus.$emit('refresh-contact-profile');
                this.$emit("close");
            }
        },
        confirmClose() {
            this.dialog = false;
            this.beforePayment = false;
            this.$store.commit("onBoardingStore/resetConfigurationLease");
            this.$store.commit("onBoardingStore/resetConfigurationInvoice");
            this.$store.commit("onBoardingStore/resetLease");
            this.$store.commit("onBoardingStore/resetInvoice");
            this.$store.commit("onBoardingStore/resetConfigurationUnit");
            this.$store.commit("onBoardingStore/resetUnitsInArea");
            this.$store.commit('onBoardingStore/resetContact');
            this.$store.commit("onBoardingStore/resetUnit");
            this.$emit("close");
        },
        next() {
            if (this.step === 4) {
                this.closeProcess();
            } else {
                this.step += 1;
                if (this.step == 4) {
                    this.summary_panel = null;
                    this.showStepper = false;
                }
            }
            this.activeDrawerComponent.step = this.step;
            this.setActiveDrawerComponent({ ...this.activeDrawerComponent })
        },
        async getLeaseData() {
            let r = await api.get(this, api.LEASES + this.$props.lease_id);
            this.lease = r.lease;
            this.unit_out = this.lease.Unit;
        },
        async getUnitData(unitID) {
            let r = await api.get(this, api.UNITS + unitID);
            return r.unit;
        },
        async updateProperty() {
            await this.$store.dispatch(
                "onBoardingStore/getPropertyInfo",
                this.properties.find((p) => p.id === this.$props.property_id)
            );
        },
        async getTransferOutCredit() {
            let date = moment(new Date()).startOf("day").format("YYYY-MM-DD");
            let r = await api.get(this, api.LEASES + this.lease_id + "/transfer-credits", { transfer_date: date });
            this.refundInvoices = r.invoices;
        },
        setPaymentMethod(paymentMethod) {
            this.form.autopay_id = paymentMethod;
        },
        setProductFees(selectedFees) {
            this.form.productFees = selectedFees;
        },
        setUserHasOwnInsurance(userInsuranceData) {
            this.form = Object.assign(this.form, userInsuranceData);
        },
        back() {
            if (this.step === 1) {
                return;
            } else {
                this.step -= 1;
            }
        },
        async setConfigurationInfo(unitID) {
            this.$store.commit("onBoardingStore/resetConfigurationLease");
            this.$store.commit("onBoardingStore/resetConfigurationInvoice");
            this.$store.commit("onBoardingStore/resetLease");
            await this.configureUnit(unitID);
        },
        async configureUnit(unitID) {
            await this.$store.dispatch(
                "onBoardingStore/getConfigurationUnitInfo",
                {
                    unit_id: unitID,
                }
            );
        },
        async getEstimate() {
            await this.$store.dispatch("onBoardingStore/getEstimate", {
                config: true,
                unit: this.configuration_unit,
                is_transfer: 1, 
                transfer_lease_id: this.lease_id
            });
        },
        async setUnitSelected(unit_id) {
            if(this.form.unit_in != unit_id) {
                await this.setConfigurationInfo(unit_id);
            }
            this.form.unit_in = unit_id;
            this.setUpBillingCycle();
        },
        setReasonSelected(reason) {
            this.form.reason = reason;
        },
        disableAutoChargeDropDown(value) {
            this.form.disable_autopay = value;
            this.form.autopay_id = "";
        },
        async setPhase() {
            let unit_id = this.activeDrawerComponent?.unit_in;
            let step = this.activeDrawerComponent?.step;
            
            if(unit_id && step < 4) {
                await this.configureUnit(unit_id);
                await this.getEstimate();
                this.form = {...this.activeDrawerComponent?.transferData}
                this.step = step;
            }else if(step) {
                this.step = step;
            }
        }
    },
    destroyed(){
        this.resetContact();
        this.setPCycleOptions([]);
        EventBus.$off('reset_transfer_hb_model', this.confirmClose);
    },
    watch: {
        isAutoChargeSet(value) {
            this.disableAutoChargeDropDown(value);
        },
        summary_panel(){
            this.resizeStepper++;
        }
    },
};
</script>

<style scoped>
.main-panel-body {
    height: 100%;
    display: flex;
}

.main-panel-header {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    flex: 1;
}

.main-panel-content {
    padding: 16px 24px;
    flex: 1;
    overflow-y: scroll !important;
    padding-bottom: 81px;
}

.main-side-info-panel {
    flex: 0 0 420px;
    height: 100%;
    background-color: #f9fafb;
    border-left: 1px solid #e1e6ea;
    overflow-y: auto;
    padding-bottom: 81px;
}


.navigation-panel-container {
    background: #f9fafb;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.panel-content {
    height: 100%;
    overflow: hidden;
    padding-bottom: 63px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.nav-drawer-header {
    background: white;
    flex: 0 1;
}

.section-header {
    font-weight: 500;
}
</style>>