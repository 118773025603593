<template>
  <div>
    <div v-if="displayNoServiceMessage && !services.length">
      No services found
    </div>
    <div v-for="(service, i) in services" :key="i">
      <v-row class="ma-0 pa-0">
        <v-col :cols="shouldDisplayRemoveOption(service) ? 10 : 12" class="ma-0 pa-0">
          <div>{{ service.name }} ({{ getMoneyType(service) }})</div>
          <div :class="durationPeriodColor" class="text-body-2 pb-2">
            {{ service.start_date | formatLocalShortDate }}
            <span v-if="service.end_date">- {{ service.end_date | formatLocalShortDate }}</span>
          </div>
        </v-col>
        <v-col cols="2" class="ma-0 pa-0" v-if="shouldDisplayRemoveOption(service)">
          <div class="mt-n2">
            <hb-menu options>
              <v-list>
                <v-list-item @click="removeService(service)">
                  <v-list-item-title>Remove</v-list-item-title>
                </v-list-item>
              </v-list>
            </hb-menu>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script type="text/babel">
import { EventBus } from '../../EventBus';

export default {
  name: "AllServices",
  data() {
    return {};
  },

  props: {
    services: {
      type: Array,
      required: true,
    },
    displayNoServiceMessage: {
      type: Boolean,
      required: false,
      default: false,
    },
    durationPeriodColor: {
      type: String,
      required: false,
      default: "hb-text-light",
    },
    displayRemoveOption: {
      type: Boolean,
      default: false
    }
  },

  methods: {
		removeService(serviceId) {
      EventBus.$emit("removeService", serviceId);
		},
    shouldDisplayRemoveOption(service) {
      let showRemove = (service.recurring === 1 && service.end_date === null) ? true : false;
      return this.displayRemoveOption &&  showRemove;
    },
    getMoneyType(service) {
      let { Product } = service;
      let price = (Math.round(service.price * 1e2) / 1e2).toFixed(2);
      return (Product.amount_type === "percentage" ? price+"%" : "$"+price);
    }
	},
};
</script>

<style>
</style>