<template>
    <div class="settings-template-main">
        <div class="settings-template-section">
            <menu-tabs @setMenuOption="setMenuOption" @selectedProperty="selectedProperty"></menu-tabs>
            <div class="mr-6" :class="{ 'pt-1': !isPropertySelected }">
                <template v-if="level === 'property' && !property.id">
                    <hb-empty-state message="Please select a Property to continue." />
                </template>
                <template v-else>
                    <div class="d-flex justify-space-between align-center mb-4 mt-5">
                        <HbTextField search width="400px" v-model="searchTemplate" v-validate="'required|max:45'"
                            data-vv-scope="default" data-vv-name="search-templates" data-vv-as="search-templates"
                            :error="errors.has('default.search-templates')" @click:clear="clearSearch"
                            @input="onSearchInput" placeholder="Search Templates" />
                        <div>
                            <HbBtn type="icon" active-state-off tooltip="Filter" @click="ShowFilter = true"
                                class="mr-3">
                                <template>
                                    <v-badge color="#F26500"
                                    :content="isFilterApplied" :value="isFilterApplied > 0">
                                        <hb-icon>mdi-filter-variant</hb-icon>
                                    </v-badge>
                                </template>
                            </HbBtn>
                            <hb-btn color="secondary" small @click="createNewTemplate()"
                                v-if="level !== 'property'">Create
                                New Template</hb-btn>
                            <!-- <hb-btn color="secondary" small @click="assignTemplate()" v-if="level == 'property'">
                                Assign Template</hb-btn> -->
                        </div>
                    </div>
                    <v-expansion-panels v-model="templatesPanel" multiple>
                        <hb-expansion-panel>
                            <template v-slot:title>
                                System Generated Templates
                            </template>
                            <template v-slot:content>
                                <div v-if="defaultTemplateList && defaultTemplateList.length != 0">
                                    <div style="margin: 7px;" v-for="item in defaultTemplateList " :key="item.id">
                                        <hb-btn mega @click="editTemplate(item)">
                                            {{ item.name }}
                                            <template v-slot:actions>
                                                <div class="container-main">
                                                    <div class="hb-font-body-medium ml-3 child-category">{{
                                                        getCategory(item.category) |
                                                        capitalize }}

                                                    </div>
                                                    <div class="hb-font-body-medium ml-3 child-type">{{
                                                        item.type
                                                        |
                                                        capitalize }}

                                                    </div>
                                                    <div class="hb-font-body-medium mx-6 child-property">
                                                        <div class="d-flex flex-column">
                                                            <hb-tooltip>
                                                                <template v-slot:item>
                                                                    <span>
                                                                        <span
                                                                            style="border-bottom: 1px dashed #101318; padding-bottom: 2px;">
                                                                            {{ item?.assigned_properties_count }} {{
                                                                                item?.assigned_properties_count
                                                                                    <= 1 ? 'Property' : 'Properties' }} </span>
                                                                        </span>
                                                                </template>
                                                                <template v-slot:header>
                                                                    <div v-if="item?.assigned_properties_count >= 1">
                                                                        Applies to {{ item?.assigned_properties_count
                                                                        }} {{
                                                                            item?.assigned_properties_count
                                                                                <= 1 ? 'Property' : 'Properties' }} </div>
                                                                            <div v-else>
                                                                                No properties assigned to this template
                                                                            </div>
                                                                </template>
                                                                <template v-slot:body>
                                                                    <div v-for="p in item?.applied_properties"
                                                                        :key="p.id">
                                                                        <span v-if="p">
                                                                            {{ p?.property_number }}-{{ p?.property_name
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                    <span class="hb-font-body-medium"
                                                                        v-if="item?.modified_properties?.length">Property
                                                                        modified({{
                                                                            item?.modified_properties?.length }})</span>
                                                                    <div v-for="p in item?.modified_properties"
                                                                        :key="p.id">
                                                                        <span v-if="p">
                                                                            {{ p?.property_number }}-{{ p?.property_name
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </template>
                                                            </hb-tooltip>
                                                        </div>
                                                    </div>
                                                    <div class="mx-2 d-flex align-center pr-1 child-status ">
                                                        <div class="mr-1">
                                                            <HbIcon :mdi-code="TEMPLATE.ICONS[item.templateStatus].icon"
                                                                small
                                                                :color="TEMPLATE.ICONS[item.templateStatus].icon_color" />
                                                        </div>
                                                        <div v-html="TEMPLATE.ICONS[item.templateStatus].name"
                                                            class="font-weight-medium"></div>
                                                    </div>
                                                    <div class="child">
                                                        <hb-tooltip>
                                                            <template v-slot:item>
                                                                <HbIcon mdi-code="mdi-account-clock-outline"
                                                                    class="mx-2" small />
                                                            </template>
                                                            <template v-slot:header>
                                                                {{ formatUpdatedAt(item.created_at) }}
                                                            </template>
                                                            <template v-slot:body>
                                                                <span
                                                                    v-if="item.created_modified_by.firstName && item.created_modified_by.lastName">
                                                                    {{
                                                                        item.created_modified_by.firstName + " " +
                                                                        item.created_modified_by.lastName
                                                                    }}</span>

                                                            </template>
                                                        </hb-tooltip>
                                                    </div>
                                                    <div class="child"><hb-menu options>
                                                            <v-list>
                                                                <v-list-item @click="editTemplate(item)"
                                                                    v-if="!item.is_base_template">
                                                                    <v-list-item-title>Edit</v-list-item-title>
                                                                </v-list-item>
                                                                <!-- <v-list-item @click="unassignTemplate(item)"
                                                                    v-if="level == 'property' && !item.is_base_template">
                                                                    <v-list-item-title>Unassign</v-list-item-title>
                                                                </v-list-item> -->
                                                                <v-list-item @click="duplicateTemplate(item)"
                                                                    :disabled="item.is_base_template">
                                                                    <v-list-item-title>Duplicate</v-list-item-title>
                                                                </v-list-item>
                                                            </v-list>
                                                        </hb-menu>
                                                    </div>
                                                </div>
                                            </template>
                                        </hb-btn>
                                    </div>
                                </div>
                                <div v-else class="pa-4">
                                    <hb-empty-state message="You have no system generated templates yet." />
                                </div>
                            </template>
                        </hb-expansion-panel>
                        <hb-expansion-panel :top-margin="true">
                            <template v-slot:title>
                                User Generated Templates
                            </template>
                            <template v-slot:content>
                                <div v-if="userGeneratedTemplates && userGeneratedTemplates.length != 0">
                                    <div style="margin: 7px;" v-for="item in userGeneratedTemplates" :key="item.id">
                                        <hb-btn mega @click="editTemplate(item)">
                                            {{ item.name }}
                                            <template v-slot:actions>
                                                <div class="container-main">
                                                    <div class="hb-font-body-medium ml-3 child-category">{{
                                                        getCategory(item.category) |
                                                        capitalize }}

                                                    </div>
                                                    <div class="hb-font-body-medium ml-3 child-type">{{
                                                        item.type
                                                        |
                                                        capitalize }}

                                                    </div>
                                                    <div class="hb-font-body-medium mx-6 child-property">
                                                        <div class="d-flex flex-column">
                                                            <hb-tooltip>
                                                                <template v-slot:item>
                                                                    <span>
                                                                        <span
                                                                            style="border-bottom: 1px dashed #101318; padding-bottom: 2px;">
                                                                            {{ item.assigned_properties_count }} {{
                                                                                item.assigned_properties_count
                                                                                    <= 1 ? 'Property' : 'Properties' }} </span>
                                                                        </span>
                                                                </template>
                                                                <template v-slot:header>
                                                                    <div v-if="item.assigned_properties_count >= 1">
                                                                        Applies to {{ item.assigned_properties_count
                                                                        }} {{
                                                                            item.assigned_properties_count
                                                                                <= 1 ? 'Property' : 'Properties' }} </div>
                                                                            <div v-else>
                                                                                No properties assigned to this template
                                                                            </div>
                                                                </template>
                                                                <template v-slot:body>
                                                                    <div v-for="p in item.applied_properties"
                                                                        :key="p.id">
                                                                        <span v-if="p">
                                                                            {{ p.property_number }}-{{ p.property_name
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                    <span class="hb-font-body-medium"
                                                                        v-if="item?.modified_properties.length">Property
                                                                        modified({{
                                                                            item?.modified_properties.length }})</span>
                                                                    <div v-for="p in item?.modified_properties"
                                                                        :key="p.id">
                                                                        <span v-if="p">
                                                                            {{ p.property_number }}-{{ p.property_name
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </template>
                                                            </hb-tooltip>

                                                        </div>
                                                    </div>
                                                    <div class="mx-2 d-flex align-center pr-1 child-status ">
                                                        <div class="mr-1">
                                                            <HbIcon :mdi-code="TEMPLATE.ICONS[item.templateStatus].icon"
                                                                small
                                                                :color="TEMPLATE.ICONS[item.templateStatus].icon_color" />
                                                        </div>
                                                        <div v-html="TEMPLATE.ICONS[item.templateStatus].name"
                                                            class="font-weight-medium"></div>
                                                    </div>
                                                    <div class="child">
                                                        <hb-tooltip>
                                                            <template v-slot:item>
                                                                <HbIcon mdi-code="mdi-account-clock-outline"
                                                                    class="mx-2" small />
                                                            </template>
                                                            <template v-slot:header>
                                                                {{ formatUpdatedAt(item.created_at) }}
                                                            </template>
                                                            <template v-slot:body>
                                                                <span> {{
                                                                    item.created_modified_by.firstName + " " +
                                                                    item.created_modified_by.lastName
                                                                }}</span>

                                                            </template>
                                                        </hb-tooltip>
                                                    </div>
                                                    <div class="child"><hb-menu options options-hover-color="#E0F5F5">
                                                            <v-list>
                                                                <v-list-item @click="editTemplate(item)"
                                                                    v-if="!item.is_base_template">
                                                                    <v-list-item-title>Edit</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item @click="unassignTemplate(item)"
                                                                    v-if="level == 'property' && !item.is_base_template">
                                                                    <v-list-item-title>Unassign</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item @click="duplicateTemplate(item)"
                                                                    :disabled="item.is_base_template">
                                                                    <v-list-item-title>Duplicate</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item @click="resetDefault(item)" disabled
                                                                    v-if="level == 'property' && !item.is_base_template">
                                                                    <v-list-item-title>Reset to
                                                                        Default</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item @click="deleteTemplate(item)"
                                                                    v-if="level !== 'property' && !item.is_base_template">
                                                                    <v-list-item-title>Delete</v-list-item-title>
                                                                </v-list-item>
                                                            </v-list>
                                                        </hb-menu>
                                                    </div>
                                                </div>
                                            </template>
                                        </hb-btn>
                                    </div>
                                </div>
                                <div v-else class="pa-4">
                                    <hb-empty-state message="You have no user generated templates yet." btn-txt="Create
                            New Template" @click="createNewTemplate()" :show-btn="level !== 'property'" />
                                </div>
                            </template>
                        </hb-expansion-panel>
                    </v-expansion-panels>
                </template>
            </div>
        </div>

        <hb-modal size="medium" title="Delete Template" v-model="deletePopup" confirmation>
            <template v-slot:content>
                <div class="py-4 px-6">
                    Are you sure you want to delete the selected template?
                </div>
            </template>
            <template v-slot:left-actions>
            </template>
            <template v-slot:right-actions>
                <hb-btn color="destructive" :disabled="true" @click="finalDelete()">Delete</hb-btn>
            </template>
        </hb-modal>

        <hb-modal size="medium" title="Unassign Template" v-model="unassignPopup" confirmation>
            <template v-slot:content>
                <div v-if="isAssigned" class="py-4 px-6">
                    This template is currently assigned to actions in active processes
                    Do you want to select a replacement template?
                    <div class="mt-5 mb-5">
                        <HbSelect v-model="replacement_template" :items="listOfTemplates" full
                            v-validate="'required|max:45'" data-vv-scope="default" data-vv-name="replacement_template"
                            data-vv-as="replacement_template" :error="errors.has('default.replacement_template')"
                            placeholder="Select Replacement Template">
                        </HbSelect>
                    </div>
                    if no replacement template is selected , any action that had this template applied will be changes
                    to a
                    blank message
                </div>
                <div v-else class="py-4 px-6">
                    Are you sure you want to unassign the selected template?
                </div>
            </template>
            <template v-slot:left-actions>
            </template>
            <template v-slot:right-actions>
                <hb-btn color="destructive" @click="finalUnassign()" disabled>Unassign </hb-btn>
            </template>
        </hb-modal>

        <hb-modal size="large" title="Assign Templates" v-model="assignPopUp">
            <template v-slot:content>
                <v-container>
                    <!-- header section -->
                    <div class="d-flex justify-space-between align-center pa-2">
                        <!-- <HbTextField search width="90%" v-model="searchTemplateAssign" v-validate="'required|max:45'"
                            data-vv-scope="default" data-vv-name="search-templates" data-vv-as="search-templates"
                            :error="errors.has('default.search-templates')" @click:clear="clearSearch"
                            @input="onSearchInput" placeholder="Search Templates" /> -->
                        <HbBtn type="icon" active-state-off tooltip="Filter" @click="ShowFilter = true" class="mr-3">
                            <template>
                                <v-badge color="#F26500" dot :value=isFilterApplied>
                                    <hb-icon>mdi-filter-variant</hb-icon>
                                </v-badge>
                            </template>
                        </HbBtn>
                    </div>

                    <!-- content section  -->
                    <div style="margin: 7px;" v-for="item in UnassignedTemplates " :key="item.id">
                        <hb-btn mega>
                            <div class="mt-4">
                                <v-checkbox :value="item.id" v-model="checkedItems" />
                            </div>
                            <template v-slot:actions>
                                <div>
                                    {{ item.name }}
                                </div>
                            </template>
                        </hb-btn>
                    </div>
                </v-container>

            </template>
            <template v-slot:right-actions>
                <hb-btn @click="assignTemplates">Assign</hb-btn>
            </template>
        </hb-modal>


        <hb-modal size="large" title="Filter By" v-model="ShowFilter">
            <template v-slot:content>
                <hb-form label="Category" full>
                    <v-row>
                        <v-col cols="12">
                            <v-checkbox v-for="(item, index) in items" class="ma-0 pa-0" :key="index" :label="item.text"
                                v-model="selectedCategory" :value="item.value" />
                        </v-col>
                    </v-row>
                </hb-form>
                <hb-form label="Template Type" full>
                    <v-row>
                        <v-col cols="12">
                            <v-checkbox v-for="(item, index) in templateTypes" class="ma-0 pa-0" :key="index"
                                :label="item.label" v-model="selectedType" :value="item.value" />
                        </v-col>
                    </v-row>
                </hb-form>
                <hb-form label="Status" full :divider="false">
                    <v-row>
                        <v-col cols="12">
                            <v-checkbox v-for="status in statusOptions" :key="status.value" class="ma-0 pa-0"
                                :label="status.label" :value="status.value" v-model="selectedStatuses" />
                        </v-col>
                    </v-row>
                </hb-form>
            </template>
            <template v-slot:right-actions>
                <hb-btn @click="applyFilter()" :loading="loadingFilter">Apply</hb-btn>
            </template>
        </hb-modal>
    </div>
</template>

<script type="text/babel">
import api from '../../../assets/api.js';
import { mapGetters } from "vuex";
import settingsTabMixin from '../../../mixins/settingsTabMixin.js';
import { notificationMixin } from '../../../mixins/notificationMixin.js';
import { EventBus } from '../../../EventBus';
import MenuTabs from '../Common/MenuTabs';
import TEMPLATE from '@/constants/template.js';
import moment from 'moment';

export default {
    name: "CommunicationTemplates",
    mixins: [settingsTabMixin, notificationMixin],
    data() {
        return {
            TEMPLATE:'',
            level: 'corporate',
            searchTemplate: '',
            replacement_template: '',
            property: {},
            panel: 0,
            templatesPanel: [],
            defaultTemplateList: [],
            userGeneratedTemplates: [],
            mainTemplates: [],

            deletePopup: false,// for delete popup

            unassignPopup: false, // for unassign popup
            assignPopUp: false,

            checkedItems: [],// Store the checked items here

            ShowFilter: false,
            selectedCategory: [],
            selectedType: [],
            loadingFilter: false,
            isFilterApplied: '',

            isAssigned: false, // if template is currently used or not
            currentItem: null,
            selectedTemplate: [], // assign template checkbox v-model
            loading: false,
            UnassignedTemplates: [],
            deleteId: '',
            singleSelect: false,
            // TODO : its should be in backend
            templateTypes: [
                { label: 'Email', value: 'email' },
                { label: 'SMS', value: 'sms' },

            ],
            selectedStatuses: [],
            statusOptions: [
                { label: "Tenant Process", value: "tenant_process" },
                { label: "User Generated", value: "user_generated" },
                { label: "Corporate Modified", value: "corporate_modified" },
                { label: "Property Modified", value: "property_modified" },
            ],
            items: [
                { text: 'Rent Change', value: 'rent_change' },
                { text: 'Delinquency', value: 'deliquency' },
                { text: 'Maintenance', value: 'maintenance' },
                { text: 'Alerts', value: 'alerts' },
                { text: 'General', value: 'general' },
                { text: 'Reservation', value: 'reservation' },
                { text: 'Rental', value: 'rental' }
            ],
        };
    },
    components: {
        MenuTabs
    },
    filters: {
        capitalize: function (value) {
            if (!value) return ''
            value = value.toString()
            if (value.toUpperCase() === 'SMS') {
                return 'SMS'; // If the word is 'SMS', return it in all caps
            } else {
                return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(); // Capitalize only the first letter
            }
        }
    },
    created() {
        this.TEMPLATE = TEMPLATE;
        EventBus.$on('CommunicationTemplate:refreshList', this.getBaseTemplateData);
    },
    destroyed() {
        EventBus.$off('CommunicationTemplate:refreshList', this.getBaseTemplateData);
    },
    computed: {
        ...mapGetters({
            facilityList: "propertiesStore/filtered",
            properties: "propertiesStore/properties",
        }),
        isPropertySelected() {
            return !!this.property?.id;
        },
        isPropertyLevel() {
            return this.level === 'property' ? true : false;
        },
        headers() {
            let headers = [
                { text: "Template Name", value: "name", sortable: true },
                // { text: "Content", value: "content",sortable: false},
                { text: "Category", value: "category", sortable: true },
                { text: "Delivery Type", value: "type", sortable: true },
                { text: "Created By", value: "created_user", sortable: true },
                { text: "Status", value: "status", sortable: true },
                { text: "# Of Properties", value: "assigned_properties_count", sortable: true },
                { text: "", value: "actions", align: "right", sortable: false }
            ];

            if (this.level === 'property') {
                headers = headers.filter(header => header.value !== "assigned_properties_count");
            }

            return headers;
        },
        listOfTemplates() {
            return this.defaultTemplateList.map(obj => obj.name);
        },
        // filteredTemplates() {
        //     if (!this.searchTemplate) {
        //         // If search input is empty, return full list
        //         return this.defaultTemplateList.sort((a, b) => {
        //             if (a.is_base_template === 1 && b.is_base_template !== 1) {
        //                 return -1; // a comes before b
        //             } else if (a.is_base_template !== 1 && b.is_base_template === 1) {
        //                 return 1; // b comes before a
        //             } else if (a.status === "inactive" && b.status !== "inactive") {
        //                 return 1; // b comes before a
        //             } else if (a.status !== "inactive" && b.status === "inactive") {
        //                 return -1; // a comes before b
        //             } else {
        //                 return 0; // no change in order
        //             }
        //         });
        //     }
        //     // Otherwise, filter the list based on the search input
        //     return this.defaultTemplateList.filter(item =>
        //         Object.values(item).some(value =>
        //             value !== null && value.toString().toLowerCase().includes(this.searchTemplate.toLowerCase())
        //         )
        //     );
        // }
    },
    watch: {
        property: {
            async handler(value) {
                // Emit the value to the parent component
                await this.getBaseTemplateData()
            },
            deep: true
        },
        templatesPanel(newValue) {
            // Ensure only one panel is open
            if (newValue.length > 1) {
                this.templatesPanel = [newValue[newValue.length - 1]]; // Keep only the last opened panel
            }
        },
    },
    async mounted() {
        await this.getBaseTemplateData()
    },
    methods: {
        onSearchInput: _.debounce(function () {
            this.getBaseTemplateData();
        }, 500),
        getCategory(cat) {
            const item = this.items.find(item => item.value === cat);
            return item ? item.text : null;
        },
        getPropertyCount(ids) {
            let count = 0;
            ids.forEach(id => {
                if (this.facilityList.find(property => property.id === id)) {
                    count++;
                }
            });
            return count;
        },
        getPropertyById(id) {
            return this.facilityList.find(property => property.id === id);
        },
        async clearSearch() {
            return this.defaultTemplateList;
        },
        async initializeData() {
            // created because of mixin
        },
        formatUpdatedAt(updatedAt) {
            if (updatedAt) {
                // Adjust the format as needed
                return moment.utc(updatedAt).utcOffset("+05:30").format("MMM D, YYYY @ hh:mma");
            }
            return ""; // Or any default value for empty updated_at
        },
        async selectedProperty(property) {
            if (this.property != property) {
                this.property = property;
            }
        },
        setMenuOption(option) {
            this.level = option
            this.property = ''
            this.searchTemplate = ''
        },
        async getBaseTemplateData() {
            try {
                this.loadingFilter = true
                // Construct the payload for search and filter
                const data = {
                    searchQuery: this.searchTemplate || '', // Use searchTemplate for search
                    filterKeys: {
                        category: this.selectedCategory.length ? this.selectedCategory : null,
                        type: this.selectedType.length ? this.selectedType : null,
                        TemplateStatus: this.selectedStatuses.length ? this.selectedStatuses : null
                    },
                };

                let url = api.TEMPLATE_MANAGER + `templates`;

                // url += `?get_only_active_templates=true`;
                // Append property_id if applicable
                let isFirstParam = true; // Tracks if this is the first query parameter
                if (this.isPropertySelected) {
                    url += `?property_id=${this.property.id}`;
                    isFirstParam = false; // First parameter added, set to false
                }

                if (data.filterKeys.category) {
                    url += `${isFirstParam ? '?' : '&'}filterKeys[category]=${data.filterKeys.category.join(',')}`;
                    isFirstParam = false; // Now subsequent parameters will use &
                }

                if (data.filterKeys.type) {
                    url += `${isFirstParam ? '?' : '&'}filterKeys[type]=${data.filterKeys.type.join(',')}`;
                    isFirstParam = false;
                }

                if (data.filterKeys.TemplateStatus) {
                    url += `${isFirstParam ? '?' : '&'}filterKeys[TemplateStatus]=${data.filterKeys.TemplateStatus.join(',')}`;
                    isFirstParam = false;
                }

                if (data.searchQuery) {
                    url += `${isFirstParam ? '?' : '&'}searchQuery=${encodeURIComponent(data.searchQuery)}`;
                }
                this.loading = true;
                // Make API call
                const resp = await api.get(this, url);
                // Assign the results
                this.mainTemplates = resp.templates;
                // Separate default templates
                this.defaultTemplateList = this.mainTemplates.filter(
                    template => template.status === 'active' && template.is_base_template === 1
                );
                // Separate user-generated templates
                this.userGeneratedTemplates = this.mainTemplates.filter(
                    template => template.status === 'active' && template.is_base_template !== 1
                );
                this.loadingFilter = false
                this.ShowFilter = false
            } catch (error) {
                this.loadingFilter = false
                console.error('Error fetching templates:', error);
                this.ShowFilter = false
                // Handle error case
            } finally {
                // End loading
                this.loadingFilter = false
                this.ShowFilter = false
                this.loading = false;
            }
        },
        deleteTemplate(item) {
            this.deletePopup = true
        },
        resetDefault() {

        },
        async finalDelete() {
            try {
                let resp;
                if (this.isPropertySelected) {
                    resp = await api.delete(this, api.TEMPLATE_MANAGER + `template/${this.deleteId}` + `?property_id=${this.property.id}`);
                }
                else {
                    resp = await api.delete(this, api.TEMPLATE_MANAGER + `template/${this.deleteId}`);
                }
                EventBus.$emit('CommunicationTemplate:refreshList')
                this.showMessageNotification({ type: 'success', description: "Template Deleted successfully" });
            } catch (error) {
                console.error(error);
                this.showMessageNotification({ type: 'error', description: "Something went wrong" });
            }
        },
        unassignTemplate(item) {
            this.unassignPopup = true
            this.deleteId = item.template_id
        },
        async finalUnassign() {
            try {
                let resp;
                if (this.isPropertySelected) {
                    resp = await api.delete(this, api.TEMPLATE_MANAGER + `template/${this.deleteId}` + `?property_id=${this.property.id}`);
                }
                else {
                    resp = await api.delete(this, api.TEMPLATE_MANAGER + `template/${this.deleteId}`);
                }
                this.unassignPopup = false
                EventBus.$emit('CommunicationTemplate:refreshList')
                this.showMessageNotification({ type: 'success', description: "Template unassigned successfully" });
            } catch (error) {
                console.error(error);
                this.showMessageNotification({ type: 'error', description: "Something went wrong" });
            }
        },
        createNewTemplate() {
            this.currentItem = {
                isNew: true
            };
            EventBus.$emit('HB-Navigation:CommunicationTemplate', this.currentItem);
        },
        async duplicateTemplate(item) {
            let resp;
            if (this.isPropertySelected) {
                resp = await api.get(this, api.TEMPLATE_MANAGER + `template/${item.template_id}` + `?property_id=${this.property.id}`);
            }
            else {
                resp = await api.get(this, api.TEMPLATE_MANAGER + `template/${item.template_id}`);
            }
            this.currentItem = {
                item: item,
                propertyId: this.property?.id, // if it is from property tab
                data: resp,
                duplicate: true
            };
            EventBus.$emit('HB-Navigation:CommunicationTemplate', this.currentItem);
        },
        async editTemplate(item) {
            //check if it is a base - template from HB
            let resp;
            if (this.isPropertySelected) {
                resp = await api.get(this, api.TEMPLATE_MANAGER + `template/${item.template_id}` + `?property_id=${this.property.id}`);
            }
            else {
                resp = await api.get(this, api.TEMPLATE_MANAGER + `template/${item.template_id}`);
            }
            this.currentItem = {
                item: item,
                propertyId: this.property.id,// if it is from property tab
                data: resp,
                edit: true
            };
            EventBus.$emit('HB-Navigation:CommunicationTemplate', this.currentItem);
            // this.showMessageNotification({ type: 'error', description: "Unable to Edit HB Templates" });
        },
        async applyFilter() {
            this.getBaseTemplateData()
            this.isFilterApplied =
                this.selectedCategory.length +
                this.selectedType.length +
                this.selectedStatuses.length;
        },
        async fetchUnassignedTemplates() {
            const resp = await api.get(this, api.TEMPLATE_MANAGER + `templates`);
            this.UnassignedTemplates = resp.templates;
        },
        assignTemplate() {
            this.checkedItems = [];
            this.assignPopUp = true // open modal
            this.fetchUnassignedTemplates() // fetch data
        },
        async assignTemplates() {
            const data = this.UnassignedTemplates.filter(item =>
                this.checkedItems.includes(item.id)
            );
            console.log(data, "data");
            // assign api
        },
    }
}
</script>
<style scoped>
.container-main {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
}

.child {
    flex: 0 0 0;
    justify-content: center;
    width: 40px;
}

.child-category {
    flex: 2 0 0;
    justify-content: center;
    width: 150px;
}

.child-type {
    flex: 2 0 0;
    justify-content: center;
    width: 150px;
}

.child-property {
    flex: 2 0 0;
    justify-content: center;
    width: 150px;
}

.child-status {
    flex: 2 0 0;
    justify-content: center;
    width: 150px;
}
</style>