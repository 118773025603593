<template>
    <div>
        <v-card v-if="is_loading" class="ti-widget" style="height:300px">
        <v-row justify="center" align="center">
            <v-col class="text-center ma-12">
            <v-progress-circular width="2" size="32" indeterminate color="primary" ></v-progress-circular>
            </v-col>
        </v-row>
        </v-card>

        <v-expansion-panels v-model="panel" multiple v-else>
            <hb-expansion-panel
                v-bind="balanceCardColor"
                :left-cols="6"
                :right-cols="5"
                disable-title-icon
                :top-margin="false"
            >
                <template v-slot:title>
                    Total Balance:
                </template>
                <template v-slot:actions>
                    <v-row no-gutters class="pl-2" v-if="!nationalAccount">
                        <v-col cols="8" class="pa-0 ma-0 d-flex justify-end hb-font-header-3-medium">
                            {{balanceDue | formatMoney}}
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="pl-2" v-else>
                        <v-col cols="8" class="pa-0 ma-0 d-flex justify-end hb-font-header-3-medium">
                           {{ NationalAccount.keystat.totalBalance | formatMoney }}
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:content >
                    <!-- <v-row class="pa-0 mt-5 mx-4 d-flex justify-center align-center">
                        <v-col cols="8" class="pa-0 ma-0 font-weight-medium">
                            Credit Balance
                        </v-col>
                        <v-col cols="2" class="pa-0 ma-0 d-flex justify-end" v-if="totalCredits">
                            {{totalCredits | formatMoney}}
                        </v-col>
                        <v-col cols="2" class="pa-0 ma-0 d-flex justify-end" v-else>
                            $0.00
                        </v-col>
                        <v-col cols="2" class="pa-0 ma-0 pl-2"> -->
                            <!--
                            <hb-btn small color="secondary" @click="goCredit()" v-if="!credits || totalCredits <= 0">Apply</hb-btn>
                            <hb-btn small color="secondary" @click="goCredit()" v-else>Adjust</hb-btn>
                            -->
                            <!-- <hb-btn small color="secondary" @click="goCredit()">Apply</hb-btn>
                        </v-col>
                    </v-row> -->
                    <!-- <v-row class="pa-0 mt-1 mx-4 d-flex justify-center align-center">
                        <v-col cols="8" class="pa-0 ma-0 font-weight-medium">
                            Reserve Balance
                            <hb-tooltip>
                                <template v-slot:item>
                                    <hb-icon small color="#101318" class="ml-1">mdi-information-outline</hb-icon>
                                </template>
                                <template v-slot:header>
                                    Reserve Balance
                                </template>
                                <template v-slot:body>
                                    The remaining balance of a payment that is<br />
                                    applied to the tenant's profile, and is not<br />
                                    associated with a space.
                                </template>
                            </hb-tooltip>
                        </v-col>
                        <v-col cols="2" class="pa-0 ma-0 d-flex justify-end" v-if="payments">
                            {{totalPayments | formatMoney}}
                        </v-col>
                        <v-col cols="2" class="pa-0 ma-0 d-flex justify-end" v-else>
                            $0.00
                        </v-col>
                        <v-col cols="2" class="pa-0 ma-0 pl-2">
                            <hb-btn small color="secondary" @click="goRefund()" :disabled="!payments || (totalPayments <= 0) ? true : false">Refund</hb-btn>
                        </v-col>
                    </v-row> -->
                    <v-row class="pa-0 mt-3 mb-3 mx-4 d-flex justify-center align-center" v-if="!nationalAccount">
                        <v-col cols="8" class="pa-0 ma-0 font-weight-medium">
                            Total Prepaid Balance
                        </v-col>
                        <v-col cols="4" class="pa-0 ma-0 d-flex justify-end" v-if="totalPrepaidBalance">
                            {{totalPrepaidBalance | formatMoney}}
                        </v-col>
                        <v-col cols="4" class="pa-0 ma-0 d-flex justify-end" v-else>
                            $0.00
                        </v-col>
                        <!-- <v-col cols="2" class="pa-0 ma-0 pl-2">
                        </v-col> -->
                    </v-row>

                    <!-- only for national account -->
                    <div v-if="nationalAccount">
                        <v-row class="pa-0 mt-3 mb-3 mx-4 d-flex justify-center align-center">
                        <v-col cols="8" class="pa-0 ma-0 font-weight-medium">
                            Total SQM
                        </v-col>
                        <v-col cols="4" class="pa-0 ma-0 d-flex justify-end" v-if="NationalAccount.keystat.sqm">
                            {{ NationalAccount.keystat.sqm }}
                        </v-col>
                        <v-col cols="4" class="pa-0 ma-0 d-flex justify-end" v-else>
                            0.00
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 mt-3 mb-3 mx-4 d-flex justify-center align-center">
                        <v-col cols="8" class="pa-0 ma-0 font-weight-medium">
                            Properties / Spaces
                        </v-col>
                        <v-col cols="4" class="pa-0 ma-0 d-flex justify-end" v-if="NationalAccount.keystat.property_count && NationalAccount.keystat.unitsCount ">
                            {{ NationalAccount.keystat.property_count }} / {{NationalAccount.keystat.unitsCount}}
                        </v-col>
                        <v-col cols="4" class="pa-0 ma-0 d-flex justify-end" v-else>
                           0/0
                        </v-col>
                        <!-- <v-col cols="2" class="pa-0 ma-0 pl-2">
                        </v-col> -->
                    </v-row>
                    <v-row class="pa-0 mt-3 mb-3 mx-4 d-flex justify-center align-center">
                        <v-col cols="8" class="pa-0 ma-0 font-weight-medium">
                            Monthly Rent
                        </v-col>
                        <v-col cols="4" class="pa-0 ma-0 d-flex justify-end" v-if="NationalAccount.keystat.monthly_rent">
                            {{ NationalAccount.keystat.monthly_rent  | formatMoney}}
                        </v-col>
                        <v-col cols="4" class="pa-0 ma-0 d-flex justify-end" v-else>
                            $0.00
                        </v-col>
                        <!-- <v-col cols="2" class="pa-0 ma-0 pl-2">
                        </v-col> -->
                    </v-row>
                    <v-row class="pa-0 mt-3 mb-3 mx-4 d-flex justify-center align-center">
                        <v-col cols="8" class="pa-0 ma-0 font-weight-medium">
                            Next Bill 
                        </v-col>
                        <v-col cols="4" class="pa-0 ma-0 d-flex justify-end" v-if="NationalAccount.keystat.next_bill_date">
                            {{ NationalAccount.keystat.next_bill_date }}
                        </v-col>
                        <v-col cols="4" class="pa-0 ma-0 d-flex justify-end" v-else>
                           No bill date found 
                        </v-col>
                        <!-- <v-col cols="2" class="pa-0 ma-0 pl-2">
                        </v-col> -->
                    </v-row>
                    <v-row class="pa-0 mt-3 mb-3 mx-4 d-flex justify-center align-center">
                        <v-col cols="8" class="pa-0 ma-0 font-weight-medium">
                            Discount Amount
                        </v-col>
                        <v-col cols="4" class="pa-0 ma-0 d-flex justify-end" v-if="computedDiscount">
                            {{ computedDiscount}}
                        </v-col>
                        <v-col cols="4" class="pa-0 ma-0 pl-2" v-else>
                        </v-col>
                    </v-row>
                    </div>
                </template>
                <template v-slot:footer>
                    <hb-bottom-action-bar class="hb-action-bar-balance" cancel-off>
                        <template v-slot:right-actions>
                            <div :class="{'py-2': $vuetify.breakpoint.width < 396}">
                            <hb-btn color="secondary" @click="goAdd">Add Space</hb-btn>
                            <hb-btn color="primary" @click="goPayment" v-if="!nationalAccount">Take a Payment</hb-btn>
                            <span v-if="showMenus && !nationalAccount" class="contact-menu-bar">
                                <hb-menu options class="overview-link-option">
                                    <v-list>
                                        <v-list-item v-if="hasPermission('convert_tenant_profile') && !isAuctionLeasePresent" class="pointer-cursor" @click="openConvertTenantProfileModel">
                                            <span @click="dialog = true">
                                                {{ isBusinessProfile ? TENANT.TENANT_PROFILE.TITLE : TENANT.BUSINESS_PROFILE.TITLE }}
                                            </span>
                                        </v-list-item>
                                        <v-list-item v-if="hasPermission('linking_contact') && !isBusinessProfile" class="pointer-cursor" @click="openLinkContactDialog">
                                            <span @click="dialog = true">
                                                Link Contact
                                            </span>
                                        </v-list-item>
                                        <hb-tooltip v-else-if="hasPermission('linking_contact') && isBusinessProfile">
                                            <template v-slot:item>
                                                <v-list-item disabled>
                                                    <span>
                                                        Link Contact
                                                    </span>
                                                </v-list-item>
                                            </template>
                                            <template v-slot:body>
                                                Link Contact is not available for business profiles.
                                            </template>
                                        </hb-tooltip>
                                        <v-list-item v-if="showUnlinkSpace && !isBusinessProfile" class="pointer-cursor" @click="openUnlinkSpaceDialog">
                                            <span @click="dialog = true">
                                                Unlink Space
                                            </span>
                                        </v-list-item>
                                    </v-list>
                                </hb-menu>
                            </span>
                            </div>
                        </template>
                    </hb-bottom-action-bar>
                </template>
            </hb-expansion-panel>
        </v-expansion-panels>
        <LinkContact v-if="showLinkContactDialog" @closeDialog="showLinkContactDialog = false" />
        <unlink-space 
            v-model="showUnlinkSpaceDialog" 
            v-if="showUnlinkSpaceDialog" 
            @close="showUnlinkSpaceDialog = false" 
            :contact="contact"
        ></unlink-space>
        
        <convert-tenant-profile-model
            v-model="showConvertTenantProfileModel" 
            v-if="showConvertTenantProfileModel"
            @close="showConvertTenantProfileModel = false"
            :contact="contact"
        />
    </div>
</template>

<script type="text/babel">
    import api from '../../assets/api.js';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import Modal from '../assets/Modal.vue';
    import moment from 'moment';
    import { EventBus } from '../../EventBus.js';
    import { mapGetters, mapMutations } from 'vuex';
    import LinkContact from '../contacts/Linking/Index.vue'
    import UnlinkSpace from '../../components/leases/unlink/Index.vue';
    import ConvertTenantProfileModel from '../../components/contacts/ConvertTenantProfileModel.vue';
    import TENANT from "@/constants/tenant.js";

    export default {
        name: "OverviewChargesWidget",
        data() {
            return {
                dataObject: {
                    totalSqm: "",
                    prop_or_Space: "",
                    discount: "",
                    rent: "",
                    next_bill_date: "",
                    totalBalance: ""
                },
                services:[],
                taxRate: 0,
                showAddService:false,
                showDeleteService:false,
                showAddProduct:false,
                showEditService: false,
                selectedService: {},
                offset: 0,
                transactions: ['Make a Payment', 'Add Fee / Sell Merchandise', 'Add a Credit'],
                transactionsSelected: 'Make a Payment',
                is_loading: false,
                panel: [0],
                showLinkContactDialog: false,
                showUnlinkSpaceDialog: false,
                showConvertTenantProfileModel: false,
            }
        },
        components:{
            Modal,
            Loader,
            Status,
            LinkContact,
            UnlinkSpace,
            ConvertTenantProfileModel
        },
        created (){
           this.TENANT = TENANT;
        },
        /*
        created (){
            // await this.fetchOpenInvoices();
            // await this.fetchOpenPayments();
            this.fetchPayments();
            EventBus.$on('update_charges_widget', this.fetchPayments);
            EventBus.$on('lease_payment_saved', this.fetchPayments);
            EventBus.$on('payment_saved', this.fetchPayments);
            EventBus.$on('invoice_saved', this.fetchPayments);
            EventBus.$on('lease_edited', this.fetchPayments);
        },

        destroyed(){
            EventBus.$off('update_charges_widget', this.fetchPayments);
            EventBus.$off('lease_payment_saved', this.fetchPayments);
            EventBus.$off('payment_saved', this.fetchPayments);
            EventBus.$off('invoice_saved', this.fetchPayments);
            EventBus.$off('lease_edited', this.fetchPayments);
        },
        */
        computed:{
            ...mapGetters({
                isAdmin: 'authenticationStore/isAdmin',
                properties: 'propertiesStore/filtered',
                hasPermission: 'authenticationStore/rolePermission',
                NationalAccount: 'onBoardingStore/getNationalAccount'
            }),
            computedDiscount(){
                let discountObj = this.NationalAccount?.keystat?.discount
                if (!discountObj) {
                    return '--';
                }
                let discount = ""
                switch (discountObj.type) {
                    case 'fixed':
                        discount = `$${discountObj.value} Fixed` 
                        break;
                    case 'percent':
                        discount = `${discountObj.value}% Off`
                        break;
                    case 'dollar':
                        discount = `$${discountObj.value} Off`
                }
                return discount;
            },
            balanceDue(){
                if(this.leases){
                    return Math.round(this.leases.reduce(function(a, b){ return a + b.open_balance }, 0)*1e2)/1e2;
                }
            },
            widgetBorderClass(){
                if(this.contact.status.toLowerCase() === "active lien" || this.contact.status.toLowerCase() === "auction"){
                    return 'hb-border-warning';
                } else if(this.balanceDue > 0){
                    return 'hb-border-caution';
                } else {
                    return 'hb-border-success';
                }
            },
            widgetBackgroundClass(){
                if(this.contact.status.toLowerCase() === "active lien" || this.contact.status.toLowerCase() === "auction"){
                    return 'hb-background-color-warning';
                } else if(this.balanceDue > 0){
                    return 'hb-background-color-caution';
                } else {
                    return 'hb-background-color-success';
                }
            },
            totalCredits(){
                return this.credits.reduce((a, p) => a + Math.round(p.amt_remaining*1e2)/1e2, 0);
            },
            totalPayments(){
                return this.payments.reduce((a, p) => a + Math.round(p.amt_remaining*1e2)/1e2, 0);
            },
            totalPrepaidBalance(){
                return this.leases.filter(l => l.end_date === null || moment(l.end_date).startOf('day') > moment().startOf('day'))
                        .reduce((a, l) => a + (l.Metrics && l.Metrics.prepaid_balance ? Math.round(l.Metrics.prepaid_balance * 1e2)/1e2 : 0), 0);
            },
            showMenus(){
                return this.hasPermission('linking_contact') || this.hasPermission('unlinking_space') || this.hasPermission('convert_tenant_profile');
            },
            isBusinessProfile(){
                return this.contact.rent_as_business ? true : false;
            },
            showUnlinkSpace(){
                let leases = this.contact?.Leases?.filter((l) => l.Unit?.property_id === this.propertyView)
                return leases?.length > 1 && this.hasPermission('unlinking_space')
            },
            isAuctionLeasePresent() {
                return this.contact?.Leases?.find(lease => lease.auction_status && lease.auction_status.length !== 0 && lease.auction_status != null && lease.auction_status != 'complete');
            },
            balanceCardColor() {
                if(this.contact?.status?.toLowerCase() === "active lien" || this.contact?.status?.toLowerCase() === "auction"){
                    return {
                        error: true
                    };
                } else if(this.balanceDue > 0){
                    return {
                        caution: true
                    };
                } else {
                    return {
                        success: true
                    };
                }
            }
        },
        methods: {
            ...mapMutations({
                setSecondContact: 'contactStore/setSecondContact',
            }),              
            goCredit(){
                this.$emit('takeAction', 'credit', this.credits);
            },
            goRefund(){
                this.$emit('takeAction', 'refund', this.payments, 'unallocated');
            },
            goPayment(){
                this.$emit('takeAction', 'payment');
            },
            goMerchandise(){
                this.$emit('takeAction', 'merchandise');
            },
            goAdd(){
                if(!this.nationalAccount) this.$emit('addLease');
                else EventBus.$emit('HB-Navigation:NationalAccount:Movin', {contact :this.contact, property: this.propertyView });
            },
            openLinkContactDialog(){ 
                this.showLinkContactDialog = true
                this.setSecondContact(null)
            },
            openUnlinkSpaceDialog(){ 
                this.showUnlinkSpaceDialog = true
            },
            openConvertTenantProfileModel(){
                this.showConvertTenantProfileModel = true;
            }
            /*
            fetchPayments(){
                this.payments = [];
                api.get(this, api.CONTACTS + this.$route.params.contact_id + '/credits', { property_id: this.propertyView }).then(r => {
                    this.payments = r.payments.filter(payment => 
                        payment.credit_type === 'payment'
                    );
                    this.credits = r.payments.filter(payment => 
                        payment.credit_type === 'credit'
                    );
                });
            }
            */
            /*
            async fetchOpenInvoices(){
                let r = await api.get(this, api.LEASES + this.lease.id + '/invoices/past-due', {}, 'overview-invoices');

                this.invoices = r.invoices.slice().reverse() || [];

            },
            async fetchOpenPayments(){
                let r = await api.get(this, api.LEASES + this.lease.id + '/payments/open', {}, 'overview-payments');
                this.payments = r.payments  || [];
            },
            async deleteOpenPayments(){
                let r = await api.delete(this, api.PAYMENTS, 'E9BnMXWjyZ');
                this.fetchOpenPayments();
            },
            
            */
        },
        props: ['leases', 'lease_change', 'contact', 'propertyView','payments','credits','nationalAccount'],
        watch: {
            /*
            '$route': async function(){
                this.is_loading = true;
                this.fetchPayments();
                this.is_loading = false;
            },
            */
           /*
            leases(){
                this.is_loading = true;
                this.fetchPayments();
                this.is_loading = false;
            }
            */
        }
    }
</script>
<style>
    @media (min-width: 391px) and (max-width: 414px) {
        .hb-action-bar-balance button span.hb-button-text-regular {
            font-size: 13px;
        }
    }
    @media (max-width: 390px) {
        .hb-action-bar-balance button span.hb-button-text-regular {
            font-size: 12px;
        }
    }
</style>
<style scoped>
.error-color{
    color:#CD2400;
}

.row-title {
    font-weight: 500;
}

.success-color{
    color:#02AD0F;
}

.col-cursor-pointer{
    cursor:pointer;
}

.pointer-cursor {
    cursor:pointer;
}

.overview-link-option {
    display: inline-block;
}

</style>
