<template>
  <hb-modal
    v-model="dialog"
    size="large"
    title="Change Insurance / Protection Plan Coverage"
    @close="$emit('close')"
    show-help-link
  >
    <template v-slot:subheader>
      <span>
        Send your insurance/protection plan enrollment form to the tenant. Your
        tenant can also sign on this device or a printed copy.
      </span>
    </template>
    <template v-slot:content>
      <hb-form label="Current Coverage">
        <div v-if="isInsuranceDeclined">
          Declined
        </div>
        <div v-else>
          <v-row class="pa-0 ma-0 hb-text-night hb-font-body">
            <v-col cols="12" class="ma-0 pa-0">{{ currentInsurance.name }}</v-col>
            <v-col cols="12" class="ma-o pa-0" v-if="currentInsurance.insurance.coverage">
              {{ currentInsurance.insurance.deductible | formatMoney }} Deductible, 
              <span>{{ currentInsurance.insurance.premium_type === '$' ? currentInsurance.insurance.premium_type : '' }}</span>
              <span>{{ currentInsurance.insurance.premium_value | RoundNumber }}</span> 
              Premium
              <span>{{ currentInsurance.insurance.premium_type === '%' ? currentInsurance.insurance.premium_type : '' }}</span>
            </v-col>
          </v-row>
        </div>
      </hb-form>
      <hb-form label="Free Insurance" class="start-insurance" v-if="shouldOfferFreeInsurance">
        <hb-checkbox
          v-model="isFreeInsuranceSelected"
          label="Waive off insurance amount"
        >
        </hb-checkbox>
      </hb-form>
      <hb-form label="Coverage" description="Select the tenant’s new coverage level." class="start-insurance">
        <hb-radio-group v-model="selectedInsurance">
          <hb-radio v-if="!table_rate_insurance" :disabled="isSameInsurance(insurance)" :value="insurance" v-for="insurance in insurances" :key="insurance.id" @change = "checkInsuranceStatus(insurance)">
            <template v-slot:label>
              {{ insurance.name }}
            </template>
            <template v-slot:description v-if="insurance.coverage">
              {{ insurance.deductible | formatMoney }} Deductible, 
              <span>{{ insurance.premium_type === '$' ? insurance.premium_type : '' }}</span>
              <span>{{ insurance.premium | RoundNumber }}</span> 
              Premium
              <span>{{ insurance.premium_type === '%' ? insurance.premium_type : '' }}</span>
            </template>
          </hb-radio>
          <table-rate-insurance
            v-if="table_rate_insurance"
            :insurances="insurances"
            :isSelected="!!selectedInsurance"
            :fullWidth="true"
            @updateCoverageValue="updateCoverageValue"
          />
        </hb-radio-group>
        <span v-if="!insurances || !insurances.length">
          No insurances found 
        </span>
      </hb-form>

      <hb-form label="Decline Coverage" class="start-insurance">
        <hb-checkbox
          v-model="declineInsurance"
          label="Tenant has own coverage"
        >
        </hb-checkbox>

        <div v-if="declineInsurance">
          <div class="mt-1">
            <span>Please select the expiration date of the coverage policy</span>
          </div>
          <v-row class="pa-0 ma-0 mt-5">
            <v-col cols="5" class="pa-0 ma-0">
              <v-select
                :items="CALENDAR.MONTHS_LIST"
                item-text="name"
                hide-details
                v-model="insurance_exp_month"
                clearable
                data-vv-name="insurance_exp_month"
                data-vv-as="Month"
                label="Select Month"
              ></v-select>
            </v-col>

            <v-col cols="5" class="pa-0 ma-0 ml-4">
              <v-select
                :items="CALENDAR.YEARS_LIST"
                item-text="name"
                hide-details
                v-model="insurance_exp_year"
                clearable
                data-vv-name="insurance_exp_year"
                data-vv-as="Year"
                label="Select Year"
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </hb-form>

      <hb-form
        v-if="selectedInsurance"
        class="start-insurance"
        multiple
        label="Start"
        required
      >
        <hb-radio-group v-model="insuranceStart">
          <hb-radio
            :disabled="shouldDisableCurrentPeriod"
            :label="`Current Billing Period: ${currentBillingPeriodLabel}`"
            value="current"
          ></hb-radio>
          <hb-radio
            :disabled="isFreeInsuranceSelected"
            :label="`Next Billing Period: ${
              billingPeriods.future &&
              $options.filters.formatDateServices(
                billingPeriods.future[0].start
              )
            } - ${
              billingPeriods.future &&
              $options.filters.formatDateServices(billingPeriods.future[0].end)
            }`"
            value="next"
          ></hb-radio>
        </hb-radio-group>
        <div class="mt-3 mb-1">
          <hb-notification
            title-off
            v-model="currentInsuranceCaution"
            v-if="!shouldTakePaymentForCurrentPeriod && isCurrentPeriodSelected"
            type="success"
            not-dismissable
          >
            The selected insurance is fully paid by the Free Coverage Credit.          
          </hb-notification>
          <hb-notification
            title-off
            v-model="currentInsuranceCaution"
            v-else-if="isCurrentPeriodSelected && isDowngrade === false"
            type="caution"
            not-dismissable
          >
            Payment is required to apply coverage for the current billing period.
          </hb-notification>
          <hb-notification
            title-off
            v-model="currentInsuranceCaution"
            v-if="displayAdjustInvoiceWarning"
            type="caution"
            not-dismissable
          >
            The invoice for next billing period already exists with an insurance. 
            The change will be in effect for any new invoices that would be generated.
            To update insurance in any existing invoices please adjust the invoice.
          </hb-notification>
        </div>
      </hb-form>
      <v-row v-if="displayPaymentModule && isDowngrade === false" class="ma-0 pa-5">
        <v-col cols="12" class="ma-0 pa-0">
          <payment-process
            :contactID="contact.id"
            :propertyID="lease.Unit.property_id"
            paymentSource="INSURANCE"
          >
          </payment-process>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <hb-btn class="mr-2" v-if="displayPaymentModule && isDowngrade === false && hasPermission('tenant_profile_add_service_skip_payment')" color="secondary" :disabled="disablePayment" @click="skipPayment">Skip Payment</hb-btn>
      <hb-tooltip v-else-if="!hasPermission('tenant_profile_add_service_skip_payment') && displayPaymentModule">
        <template v-slot:item>
          <hb-btn color="secondary" disabled>Skip Payment</hb-btn>
        </template>
        <template v-slot:body>
          {{ !hasPermission('tenant_profile_add_service_skip_payment') ? 'You do not have the permission to skip payment' : 'You do not have the permission for the payment' }}
        </template>
      </hb-tooltip>

      <hb-btn
        v-if="displayPaymentModule && isDowngrade === false"
        color="primary"
        :disabled="disablePayment"
        :loading="disablePayment"
        @click="checkPaymentErrors"
        >Process Payment</hb-btn
      >
      <hb-btn v-else color="primary" @click="saveInsurance" :disabled="disableInsurance" :loading="disableInsurance"
        >Save Insurance</hb-btn
      >
    </template>
  </hb-modal>
</template>

<script type="text/babel">
import moment from 'moment';
import CALENDAR from '@/constants/calendar.js';
import api from "../../../assets/api.js";
import PaymentProcess from "../../includes/PaymentProcess/Index.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { EventBus } from "../../../EventBus.js";
import { notificationMixin } from  '../../../mixins/notificationMixin.js';
import TableRateInsurance from '../../table_rate_insurance/Index.vue';

// Updated component for adding insurance
export default {
  name: "AddNewInsurance",
  mixins: [ notificationMixin ],
  data() {
    return {
      insurances: [],
      selectedInsurance: null,
      insuranceStart: "current",
      currentInsuranceCaution: true,
      disablePayment: false,
      declineInsurance: false,
      isFreeInsuranceSelected: false,
      newInsuranceApplicationDate: null,
      insurance_exp_year: "",
      insurance_exp_month: "",
      disableInsurance: false,
      invoicesToPay: [],
      shouldDisableCurrentPeriod: false,
      shouldOfferFreeInsurance: false,
      shouldTakePaymentForCurrentPeriod: false,
      table_rate_insurance: false,
      coverage_value: "",
	    isUpgrade: false,
      isDowngrade: false,
    };
  },

  props: {
    lease: {
      type: Object,
      required: true,
    },
    contact: {
      type: Object,
      required: true,
    },
    showAddInsuranceModal: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    currentInsurance: {
      type: Object,
      default: null
    },
    declinedInsurance: {
      type: Object,
      default: null
    }
  },

  components: {
    PaymentProcess,
    TableRateInsurance,
  },

  async created() {
    this.CALENDAR = CALENDAR;
    await this.fetchInsurances();
    await this.fetchNextBillingPeriods();
    this.setDefaultInsuranceStart();
    this.table_rate_insurance = this.insurances.length && this.insurances[0]?.insurance_type === "table_rate" ? true : false;

    EventBus.$on("paymentReader", this.enablePayment);
  },

  destroyed() {
    EventBus.$off("paymentReader", this.enablePayment);
  },

  computed: {
    ...mapGetters({
      getLeases: "paymentsStore/getLeases",
      getErrors: "paymentsStore/getErrors",
    }),
    isInsuranceDeclined() {
      return !this.currentInsurance || !this.currentInsurance.id || this.declinedInsurance.active == 1;
    },
    getInvoice() {
      return {
        selectedInsurance: this.selectedInsurance,
        insuranceStart: this.insuranceStart,
        isFreeInsuranceSelected: this.isFreeInsuranceSelected,
        coverage_value: this.coverage_value,
      };
    },
    displayPaymentModule() {
      return (
        this.shouldTakePaymentForCurrentPeriod &&
        this.selectedInsurance &&
        this.selectedInsurance.id &&
        this.isCurrentPeriodSelected
      );
    },
    shouldFetchInvoice(){
      return (
        ((this.table_rate_insurance && this.coverage_value) || !this.table_rate_insurance) &&
        this.selectedInsurance &&
        this.selectedInsurance.id &&
        this.isCurrentPeriodSelected 
      );
    },
    isCurrentPeriodSelected() {
      return this.insuranceStart === "current";
    },
    displayAdjustInvoiceWarning() {
      if(this.newInsuranceApplicationDate && !this.isCurrentPeriodSelected) {
        return moment(this.newInsuranceApplicationDate) > moment(this.billingPeriods.future[0].start);
      }
    },
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    currentBillingPeriodLabel() {
      const period = this.isFreeInsuranceSelected ? this.billingPeriods.free_insurance_period : this.billingPeriods.current;

      const startDate = period && this.$options.filters.formatDateServices(period.start);
      const endDate = period && this.$options.filters.formatDateServices(period.end);

      return `${startDate} - ${endDate}`;
    }
  },

  methods: {
    ...mapMutations({
      createLeaseStructure: "paymentsStore/createLeaseStructure",
      setLeaseBilledMonths: "paymentsStore/setLeaseBilledMonths",
      setCheckErrors: "paymentsStore/setCheckErrors",
    }),
    ...mapActions({
      getTransformedPaymentObject: "paymentsStore/getTransformedPaymentObject",
    }),
    isSameInsurance(insurance) {
      console.log("this.currentInsurance", this.currentInsurance);
      console.log(insurance);
    return (
      this.currentInsurance &&
      insurance.name === this.currentInsurance.name
    );
    },
    checkInsuranceStatus(insurance) {
      this.isUpgrade = false;
      this.isDowngrade = false;

      if (insurance && this.currentInsurance) {
        if (insurance.premium > this.currentInsurance.price) {
          this.isUpgrade = true; 
        } else if (insurance.premium < this.currentInsurance.price) {
          this.isDowngrade = true; 
        }
      }
      console.log(" this.isUpgrade", this.isUpgrade);
      console.log("this.isDowngrade", this.isDowngrade);      
    },
    setDefaultInsuranceStart() {
      if(this.shouldDisableCurrentPeriod) {
        this.insuranceStart = "next";
      } 
    },
    checkPaymentErrors() {
      this.setCheckErrors({ value: true });
    },
    async fetchInsurances() {
      try {
        const { id: leaseId } = this.lease;
        const res = await api.get(this, `${api.LEASES}/${leaseId}/insurance`);
        this.insurances = res.data.options;
      } catch (err) {
        console.log("err ", err);
      }
    },
    emitServiceAddedEvents() {
      this.showMessageNotification({ type:'success', description: 'Coverage has been changed. View the changes on the tenant profile.' });
      EventBus.$emit("closeAddInsuranceModal");
      EventBus.$emit("fetchInsurances");
    },
    async fetchNextBillingPeriods() {
      try {
        const res = await api.get(
          this,
          `${api.LEASES}/${this.lease.id}/insurance-billing-periods?future_months=1`
        );
        this.billingPeriods = res.billing_months;
        this.shouldDisableCurrentPeriod = false;
        // this.shouldDisableCurrentPeriod = !res.should_enable_current_period || (this.currentInsurance && this.currentInsurance.id);
        this.shouldOfferFreeInsurance = res.enable_free_insurance;
      } catch (err) {
        console.log("err ", err);
      }
    },
    async saveInsurance(dryRun = false) {
      this.disableInsurance = true;
      const insuranceData = {
        decline: false,
        dryrun: dryRun,
        contact_id: this.contact.id,
        property_id: this.lease.Unit.property_id,
        is_free_insurance: this.isFreeInsuranceSelected
      };
      if(this.table_rate_insurance && this.coverage_value) {
        insuranceData.coverage_amount = this.coverage_value;
      }

      if(this.declineInsurance) {
        insuranceData.decline = true;
        insuranceData.insurance_exp_month = this.insurance_exp_month; 
        insuranceData.insurance_exp_year = this.insurance_exp_year;
      } else {
        insuranceData.insurance_id = this.selectedInsurance.id;
        insuranceData.starting_date = this.insuranceStart === "current" ? moment().format('YYYY-MM-DD')  : this.billingPeriods.future[0].start;
        // insuranceData.starting_date = !this.isCurrentPeriodSelected ? this.billingPeriods.future[0].start : '';
      }
  
      const response = await this.getOrSaveInsuranceDetails(insuranceData);

      if(!dryRun) {
        this.emitServiceAddedEvents();
      }
      this.disableInsurance = false;
      return response; 
    },
    async skipPayment() {
      if (this.hasPermission('tenant_profile_add_service_skip_payment')) {
        try {
          const insuranceData = {
            decline: false,
            dryrun: false,
            contact_id: this.contact.id,
            property_id: this.lease.Unit.property_id,
            is_free_insurance: this.isFreeInsuranceSelected,
            skip_payment: true,
            insurance_id: this.selectedInsurance.id,
          };
      
          const response = await this.getOrSaveInsuranceDetails(insuranceData);
          this.emitServiceAddedEvents();        
          return response;

        } catch (err) {
          this.showMessageNotification({ description: err })
        }
      } else {
        this.showMessageNotification({ type: 'error', description: 'You do not have the permission to skip payment' });
      }
    },   
    async getOrSaveInsuranceDetails(insuranceData) {    
      insuranceData.serviceEnd =this.insuranceStart;
      const response = await api.put(
        this,
        `${api.LEASES}/${this.lease.id}/insurance`,
        insuranceData
      );

      const invoice = response.Invoice;
      this.shouldTakePaymentForCurrentPeriod = !(this.isFreeInsuranceSelected && invoice?.total_payments === (invoice?.sub_total + invoice?.total_tax - invoice?.total_discounts));

      return response;
    },
    async payInvoice(data) {
      const response = await this.getOrSaveInsuranceDetails(data);
      return response;
    },
    async fetchInvoice() {
      const response = await this.saveInsurance(true);
      this.invoicesToPay = [response.Invoice];
      this.createLeaseStructure({
        invoices: this.invoicesToPay,
        lease: this.lease,
        unit: this.lease.Unit,
        updateInvoice: this.getLeases && this.getLeases.length,
      });
      this.setLeaseBilledMonths({
        leaseIndex: 0,
        billedMonths: 0,
      });
    },
    async fetchNewInsuranceApplicationDate() {
      try {
        const res = await api.get(this, `${api.LEASES}/${this.lease.id}/insurance-start-date`);
        this.newInsuranceApplicationDate = res;
      } catch (err) {
        console.log("err ", err);
      }
    },
    enablePayment(response) {
      this.disablePayment = false;
      if(response && response.error) {
        this.showMessageNotification({ description: response.error });
      } else if(response && response.data && response.data.msg) {
        this.showMessageNotification({ type: 'success', description: response.data.msg.text });
      }
    },
    checkPaymentErrors() {
      this.setCheckErrors({ value: true });
    },
    async processPayment() {
      this.disablePayment = true;
      const paymentInfo = await this.getTransformedPaymentObject({
        id: this.$options.name,
        formErrors: this.formErrors,
      });

      if (paymentInfo.errors && paymentInfo.errors.length) {
        this.showMessageNotification({ list: paymentInfo.errors });
        this.disablePayment = false;
        return;
      }

      const insuranceData = {
        ...paymentInfo,
        decline: false,
        dryrun: false,
        insurance_id: this.selectedInsurance.id,
        is_free_insurance: this.isFreeInsuranceSelected
      };
      if(this.table_rate_insurance && this.coverage_value) {
        insuranceData.coverage_amount = this.coverage_value;
      }

      if (paymentInfo && paymentInfo.payment) {
        try {
          const responseData = await this.getOrSaveInsuranceDetails(insuranceData);      
          EventBus.$emit('lease_edited');
          this.emitServiceAddedEvents();
          if(responseData && responseData.payment_id) {
            EventBus.$emit('showPaymentDone', responseData.payment_id);
          }
        } catch(err){
          this.showMessageNotification({ description: err });
        } finally {
          this.disablePayment = false;
        }
      }
    },
    updateCoverageValue(coverageValue){
      this.coverage_value = coverageValue;
    }
  },

  watch: {
    async getInvoice() {
      if (this.shouldFetchInvoice) {
        await this.fetchInvoice();
        EventBus.$emit('updatePaymentApplication');
      }
    },
    selectedInsurance(insurance) {
      if(insurance && insurance.id) {
        this.declineInsurance = false;
      }
    },
    declineInsurance(check) {
      if(check) {
        this.selectedInsurance = null;
      }
    },
    async insuranceStart(period) {
      if(!this.newInsuranceApplicationDate && period === 'next') {
        await this.fetchNewInsuranceApplicationDate();
      }
    },
    getErrors(value) {
      if(value === false) {
        this.processPayment();
        this.setCheckErrors({ value: null });
      }
    }
  },
};
</script>

<style>
.start-insurance .v-messages {
  min-height: 0px;
}
</style>