<template>
  <div>
    <div class="px-6 py-4" v-if="invoice.id" style="background: #F9FAFB;">
      <v-row class="mx-0" v-if="invoice.void_date">
        <hb-notification type="guidance" :notDismissable="true" class="mb-4">
          <template > 
            This invoice was voided on {{invoice.void_date | formatDateServices}} by {{ invoice.VoidedByContact.first | capitalize }} {{ invoice.VoidedByContact.last | capitalize }} {{ invoice.void_reason ? `for ${invoice.void_reason}`:'' }}.
          </template>
        </hb-notification>
      </v-row>
      <v-row class="mx-0" v-else-if="invoice.ReissuedFrom.number">
        <hb-notification type="guidance" :notDismissable="true" class="mb-4">
          <template > 
            This invoice was reissued from Invoice #{{invoice.ReissuedFrom.number}} on {{invoice.date | formatDateServices}}.
          </template>
        </hb-notification>
      </v-row>
      <v-row class="mx-0">
        <v-col cols="5" class="pa-0 pr-4">
          <v-card class="hb-elevation-medium hb-border-default">
            <v-card-title class="pa-4 pb-0">
              <span class="subtitle-1 font-weight-medium text-capitalize">{{invoice.Contact.first}} {{invoice.Contact.last}} </span>
            </v-card-title>
            <v-card-text class="px-4">
              <span class="subtitle-1" v-if="invoice.Contact.Phones && invoice.Contact.Phones.length > 0">{{invoice.Contact.Phones[0].phone | formatPhone}} </span><br>
              <span class="subtitle-1"> {{contactAddress.address}}</span><br>
              <span class="subtitle-1" v-if="contactAddress"> {{ contactAddress | formatAddress }}</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="7" class="pa-0">
          <v-card class="hb-elevation-medium hb-border-default">
            <v-card-title class="d-flex align-center ma-0 pa-0 inv-header-cell" style="flex-wrap: unset;">
              <v-col cols="4" class="d-flex align-center">
                <span class="ml-3 subtitle-1 font-weight-medium">Invoice ID:</span>
              </v-col>
              <v-divider  vertical></v-divider>
              <v-col cols="4" class="d-flex align-center">
                <span class="ml-3 subtitle-1 font-weight-medium">Invoice Date:</span>
              </v-col>
              <v-divider  vertical></v-divider>
              <v-col cols="4" class="d-flex align-center">
                <span class="ml-3 subtitle-1 font-weight-medium">Due Date</span>
              </v-col>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="d-flex pa-0 ma-0 hb-default-font-size">
              <v-col cols="4" class="d-flex align-center justify-center">
                <span class="subtitle-1 font-weight-regular hb-text-night">{{invoice.number}}</span>
              </v-col>
              <v-divider  vertical></v-divider>
              <v-col cols="4" class="d-flex align-center pr-0">
                <span class="ml-3 subtitle-1 font-weight-regular hb-text-night">{{invoice.date | formatDateServices}}</span>
              </v-col>
              <v-divider  vertical></v-divider>
              <v-col cols="4" class="d-flex align-center pr-0">
                <span class="ml-3 subtitle-1 font-weight-regular hb-text-night">{{invoice.due | formatDateServices}}</span>
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mx-0 mt-4">
        <v-col class="pa-0">
          <v-card class="hb-border-default" elevation="0">
            <v-card-title class="d-flex align-center ma-0 pa-0 inv-header-cell" style="flex-wrap: unset;">
              <v-col :cols="isAdjusted ? 7 : 5" class="d-flex align-center">
                <span class="ml-3 subtitle-2 font-weight-medium">Description</span>
              </v-col>
              <v-col cols="1" class="d-flex align-center justify-end px-0">
                <span class="subtitle-2 font-weight-medium">Qty</span>
              </v-col>
              <v-col cols="2" class="d-flex align-center justify-end px-0">
                  <span class="subtitle-2 font-weight-medium">Rate</span>
              </v-col>
              <v-col cols="2" class="d-flex align-center justify-end pl-0">
                <span class="subtitle-2 font-weight-medium">Total</span>
              </v-col>
              <v-col cols="2" class="d-flex align-center justify-end pl-0" v-if="!isAdjusted">
                <span class="subtitle-2 font-weight-medium">Credit</span>
              </v-col>

            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-0 ma-0 hb-default-font-size">
              <v-row class="d-flex pa-0 mx-0 space-header mb-1">
                <v-col cols="5" class="d-flex align-center py-1">
                  <span class="ml-3 subtitle-2 font-weight-medium " v-if="invoice.Lease && invoice.Lease.Unit">Space #{{invoice.Lease.Unit.number || ''}}</span>
                </v-col>
                <v-col cols="7" class="d-flex align-center py-1 pl-0 justify-end">
                  <span class="subtitle-2 font-weight-regular">Invoice #{{invoice.number || ''}}</span>
                  <span class="ml-1 subtitle-2 font-weight-medium" v-if="invoice.paid === 0"> - Due Date: {{invoice.due | formatDateServices}}</span>
                  <span class="ml-1 subtitle-2 font-weight-medium" v-else> - Paid</span>
                </v-col>
              </v-row>
              <v-row class="d-flex pa-0 mx-0" v-for="(line, index) in invoice.InvoiceLines" :key="index">
                <v-col :cols="isAdjusted ? 7 : 5" class="d-flex align-center py-1">
                  <div v-if="line.Service && line.Service.service_type === 'insurance'">
                    <span class="ml-3 subtitle-2 font-weight-medium hb-text-night">Protection Plan</span>
                    <span style="font-size:10px;"> ({{line.Product.name}})</span>
                  </div>
                  <div v-else-if="line.Service && line.Service.service_type !=='insurance' && line.Service.recurring === 1">
                    <span class="ml-3 subtitle-2 font-weight-medium hb-text-night">
                      {{line.Product.name}} 
                    </span>
                    <span style="font-size:10px;">({{  invoice.period_start | formatDateServices}} - {{ invoice.period_end | formatDateServices}})</span>
                  </div>
                  <span v-else class="ml-3 subtitle-2 font-weight-medium hb-text-night">
                    {{line.Product.name}}
                  </span>


                </v-col>
                <v-col cols="1" class="d-flex align-center justify-end px-0 py-1" :class="{'pr-1' : isAdjusted}">
                  <v-text-field
                      v-if="isAdjusted"
                      hide-details
                      single-line
                      v-validate="'required|max:45'"
                      required
                      :id="'qty-'+index"
                      :name="'qty-'+index"
                      v-model="line.qty"
                      class="px-0 mt-0 view-invoice-txt-field pb-2 pt-1"
                  ></v-text-field>
                  <span v-else class="subtitle-2 font-weight-regular hb-text-night">{{line.qty}}</span>
                </v-col>
                <v-col cols="2" class="d-flex align-center justify-end px-0 py-1">
                  <v-text-field
                      v-if="isAdjusted"
                      hide-details
                      single-line
                      v-validate="'required|max:45'"
                      required
                      :id="'cost-'+index"
                      :name="'cost-'+index"
                      v-model="line.cost"
                      prefix="$"
                      class="pa-0 mt-0 view-invoice-txt-field pb-2 pt-1"
                  ></v-text-field>
                  <span v-else class="subtitle-2 font-weight-regular hb-text-night">{{line.cost | formatMoney}}</span>
                </v-col>
                <v-col cols="2" class="text-right pl-0 py-1" :class="{'top-8px' : isAdjusted}">
                  <span class="subtitle-2 font-weight-regular hb-text-night">{{line | lineTotal | formatMoney}}</span><br/>
                  <div v-if="isAdjusted">
                    <hb-link @click="removeProduct(index)">Remove</hb-link>
                  </div>
                </v-col>
              <v-col cols="2" class="d-flex align-center justify-end pl-2 py-1" v-if="!isAdjusted">
              <v-text-field
                single-line
                hide-details
                v-validate="'required|max:45'"
                required
                :id="'credit-'+line.id"
                :name="'credit-'+line.id"
                v-model="creditAmounts[line.id]"
                prefix="$"
                class="pa-0 mt-0 view-invoice-txt-field pb-2 pt-1"
              ></v-text-field>

              <div class="pl-1">
                <hb-link @click="updateCreditAmount(line, creditAmounts[line.id])">Credit</hb-link>
              </div>
            </v-col>


              </v-row>
              <v-row class="mx-0" v-if="isAdjusted">
                <v-col cols="7" class="d-flex align-center">
                  <hb-link class="ml-3" v-if="!showAddProduct" @click="showAddProduct = true">+ Add a product or service</hb-link>
                  <v-autocomplete
                    v-else
                    :items="products"
                    dense
                    item-text="name"
                    item-value="id"
                    return-object
                    label="Search for product or services"
                    v-model="product"
                    @input="addProductToInvoice"
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                    clearable
                    single-line
                    outlined
                    class="ml-3 mb-2"
                  >
                    <template v-slot:item="{ item }">
                      <v-row class="mx-0" style="max-width: 320px">
                        <v-col class="pa-1 font-weight-medium pb-0" md="9">{{ item.name }}</v-col>
                        <v-col class="pa-1 text-right font-weight-medium pb-0" md="3">{{ item.price | formatMoney }}</v-col>
                        <v-col v-if="item.description" md="9" class="pa-1 pt-0">{{ item.description }}</v-col>
                      </v-row>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="3" class="d-flex align-center justify-end px-0 py-1"></v-col>
                <v-col cols="2" v-if="showAddProduct" class="d-flex align-center justify-end pl-0 py-1">
                    <hb-link @click="showAddProduct = false">Remove</hb-link>
                </v-col>
              </v-row>
              <v-divider class="mt-1"></v-divider>
              <v-row class="d-flex pa-0 mx-0">
                <v-col cols="10" class="d-flex align-center justify-end px-0 py-1">
                  <span class="subtitle-1">Subtotal:</span>
                </v-col>
                <v-col cols="2" class="d-flex align-center justify-end pl-0 py-1">
                  <span class="subtitle-1">{{ subTotal | formatMoney }}</span>
                </v-col>
              </v-row>
              <v-row class="d-flex pa-0 mx-0">
                <v-col cols="10" class="d-flex align-center justify-end px-0 py-0">
                  <span class="subtitle-1">Discount:</span>
                </v-col>
                <v-col cols="2" class="d-flex align-center justify-end pl-0 py-0">
                  <span class="subtitle-1">{{promotionTotal | formatMoney }}</span>
                </v-col>
              </v-row>
              <v-row class="d-flex pa-0 mx-0">
                <v-col cols="10" class="d-flex align-center justify-end px-0 py-1">
                  <span class="subtitle-1">Tax:</span>
                </v-col>
                <v-col cols="2" class="d-flex align-center justify-end pl-0 py-1">
                  <span class="subtitle-1">{{taxSum | formatMoney }}</span>
                </v-col>
              </v-row>
              <v-row class="d-flex pa-0 mx-0">
                <v-col cols="10" class="d-flex align-center justify-end px-0 py-0">
                  <span class="subtitle-1 font-weight-medium">Invoice Total:</span>
                </v-col>
                <v-col cols="2" class="d-flex align-center justify-end pl-0 py-0">
                  <span class="subtitle-1 font-weight-medium">{{invoiceTotal | formatMoney }}</span>
                </v-col>
              </v-row>
              <v-divider class="mt-2"></v-divider>
              <v-row class="d-flex pa-0 mx-0 inv-header-cell">
                <v-col cols="7" class="d-flex pb-0 pt-2" style="flex-direction:column;">
                  <span class="subtitle-1 font-weight-medium" v-if="!isAdjusted && invoice.Payments.length > 0">Payment(s) made:</span>
                </v-col>
                <v-col cols="3" class="d-flex  justify-end pa-0 pt-2">
                  <span class="subtitle-1">Total Payment received:</span>
                </v-col>
                <v-col cols="2" class="d-flex  justify-end pa-0 pt-2 pr-3">
                  <span class="subtitle-1">{{ totalPayments | formatMoney }}</span>
                </v-col>
              </v-row>
              <v-row class="d-flex pa-0 mx-0 inv-header-cell">
                <v-col cols="7" class="d-flex pb-0 pt-2" style="flex-direction:column;" >
                  <div v-if="!isAdjusted" class="pb-2">
                    <div
                      :class="{ 'cursor-pointer' : !fromMasterVueFile }"
                      v-for="p in invoice.Payments"
                      :key="p.payment_id"
                      @click="!fromMasterVueFile ? showPamentDetails(p) : ''"
                    >
                      <span v-if="p.PaymentMethod" :class="{ 'hb-payment' : !fromMasterVueFile }">
                        Payment applied on {{p.date | formatDateServices}} - {{p.amount | formatMoney}}
                      </span>
                      <span v-else-if="p.Payment.method == 'credit'" :class="{ 'hb-payment' : !fromMasterVueFile }">
                        Credit applied on {{p.date | formatDateServices}} - {{p.amount | formatMoney}}
                      </span>
                      <span v-else-if="p.Payment.method == 'adjustment'" :class="{ 'hb-payment' : !fromMasterVueFile }">
                        {{SUB_METHOD[p.Payment.sub_method]}} adjustment applied on {{p.date | formatDateServices}} - {{p.amount | formatMoney}}
                      </span>
                      <span v-else :class="{ 'hb-payment' : !fromMasterVueFile }">
                        <span class="text-capitalize" :class="{ 'hb-payment' : !fromMasterVueFile }">{{p.Payment.method}}</span>
                        payment applied on {{p.date | formatDateServices}} - {{p.amount | formatMoney}}
                      </span>
                    </div>
                  </div>
                </v-col>
                <v-col cols="3" class="d-flex justify-end px-0 pt-0">
                  <span class="title font-weight-medium">Balance Due:</span>
                </v-col>
                <v-col cols="2" class="d-flex justify-end pl-0 pt-0">
                  <span class="title font-weight-medium">{{ balanceDue | formatMoney }}</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>


      </v-row>
    </div>

    <!-- <modal v-if="showMakePayment" size="lg" allow-click-away="false" @close="showMakePayment = false">
        <h3 slot="header">Make Payment</h3>
        <div slot="body">
            <record-payment
              :lease_id="invoice.lease_id"
              @close="showMakePayment = false"
            >
            </record-payment>
        </div>
    </modal> -->

  </div>
</template>

<script type="text/babel">

import api from '../../assets/api.js'; 
import moment from 'moment';
import { EventBus } from '../../EventBus.js';
import { mapGetters } from 'vuex';
import ADJUSTMENT from "@/constants/adjustment.js";
import { notificationMixin } from  '../../mixins/notificationMixin.js';


export default {
  name: "ViewInvoice",
  mixins: [notificationMixin],
  data() {
      return {
          invoice:{},
          payments:[],
          showAddProduct:false,
          showMakePayment:false,
          products: [],
          charge:{
              product_id: null,
              service_id: null,
              cost: null,
              qty: null
          },
          product:{},
          creditAmounts: {},      
      }
  },

  created (){
    this.SUB_METHOD = ADJUSTMENT.SUB_METHOD;
    this.invoice = this.selected;
    this.searchProducts();
  },
  mounted() {
    this.invoice.InvoiceLines .forEach(line => {
      this.$set(this.creditAmounts, line.id, 0); 
    });
  },
  filters:{
    lineTotal(c){
      return c.qty * c.cost;
    }
  },
  computed:{
    ...mapGetters({
        isAdmin: 'authenticationStore/isAdmin'
    }),
    isPastDue(){
        return this.balanceDue && moment() > moment(this.invoice.due, 'MM/DD/YYYY');
    },
    balanceDue(){
      return this.invoiceTotal - this.totalPayments;
    },
    totalPayments(){
      return this.invoice.Payments.reduce(function(a, b){
        return a + b.amount;
      }, 0)
    },
    promotionTotal(){
      // if (this.isAdjusted) {
      //   var discountTotal = 0;
      //   if(!this.invoice.InvoiceLines) return 0;
      //   this.invoice.InvoiceLines.forEach(function(line){
      //       discountTotal += line.DiscountLines.reduce(function(a,b){
      //           return a += b.amount;
      //       }, 0);
      //   });
      //   return discountTotal;
      // } else {
        return this.invoice && this.invoice.discounts;
      // }
    },
    taxSum(){
      // if (this.isAdjusted) {
      //   if(!this.invoice.InvoiceLines) return 0;
      //     return this.invoice.InvoiceLines.reduce(function(a, b){
      //         var lineTotal = (b.cost * b.qty);
      //         if(!b.Product.taxable) return a;
      //         var notTaxed = b.DiscountLines.filter(function(d){
      //             return d.pretax;
      //         }).reduce(function(c,d){
      //             return c + d.amount;
      //         }, 0);
      //         var taxableAmount = lineTotal - notTaxed;
      //         var lineTax =  b.TaxLines && b.TaxLines.length && b.TaxLines.reduce(function(c,d){
      //             return c +=  (taxableAmount *  (d.taxrate/100))
      //         }, 0);
      //         return a += lineTax;

      //     }, 0)
      // } else {
        return this.invoice && this.invoice.total_tax;
      // }
    },
    subTotal(){
      if (this.isAdjusted) {
        if(!this.invoice.InvoiceLines) return 0;
        return  this.invoice.InvoiceLines.reduce(function(a, b){
            return a +=  (b.cost * b.qty)
        }, 0);

      } else {
        return this.invoice && this.invoice.sub_total;
      }
    },
    invoiceTotal(){
      return this.taxSum + this.subTotal - this.promotionTotal;
    },
    contactAddress() {
      if(this.invoice) {
        return this.invoice.Contact.Addresses.length > 0 ? this.invoice.Contact.Addresses[0].Address: ''
      }
    }
  },
  methods: {
    showPamentDetails(p){
      EventBus.$emit('show_lease_payment', p.payment_id)
    },
    payInvoice(){
        this.$router.push('/invoices/pay/'+ this.invoice_id)
    },
    leaseEvents(){
        this.fetchData();
        this.fetchPayments();
    },
    closeDropdown(){
        //TODO JQUERY FIX
        // $(event.target).closest('.dropdown-menu').dropdown('toggle');
    },
    prevent(event){
        event.stopPropagation();
    },
    fetchData(){
        if(!this.invoice_id) return;
        api.get(this, api.INVOICES + this.invoice_id).then(r => {
            this.invoice = r.invoice;
            this.invoice.date = moment(this.invoice.date).format('MM/DD/YYYY');
            this.invoice.due = moment(this.invoice.due).format('MM/DD/YYYY');
        });
    },
    fetchPayments(){
        if(!this.invoice_id) return;
        api.get(this, api.INVOICES + this.invoice_id + '/payments').then(r => {
            this.payments = r.payments;
            this.payments.date = moment(this.payments.date).format('MM/DD/YYYY');
        });
    },
    unapplyPayment(p){
        api.delete(this, api.PAYMENTS + 'apply/', p.id).then(r => {
            this.$refs.unapplyDropdown[0].away();
            EventBus.$emit('lease_payment_saved');
        });
    },
    removeProduct(index){
      if(this.invoice.InvoiceLines){
        this.invoice.InvoiceLines.splice(index, 1);
      }
    },
    away(){
        return this.openSuggestion = false;
    },
    email(){

    },
    download(){

    },
    cancelUnapply(){
        this.$refs.unapplyDropdown[0].away()
    },
    searchProducts(search){
        var params = `type[]=product&type[]=late&search=&lease_id=${this.invoice.lease_id}`;
        api.get(this, api.PRODUCTS + `list?${params}`).then(r => {
            this.products = r.products;
        });

    },
  
    updateCreditAmount(line, creditAmount) {
      let amount = parseFloat(creditAmount);

      const unpaidAllocation = line.line_pending_amount || 0; 
      const paidAllocation = line.line_item_amount_paid + line.line_tax_amount_paid|| 0;
      const availableCredit = unpaidAllocation + paidAllocation;
      const maxCredit = line.subtotal + line.total_tax || 0;

      if (amount > maxCredit) {
        console.error("Entered credit amount exceeds the maximum allowable credit for this line.");
        this.showMessageNotification({
          type: "error",
          description: `Entered credit amount ($${amount}) exceeds the total line charge ($${maxCredit}).`
        });
        return;
      }

      console.log("Available Credit:", availableCredit);
      console.log("Unpaid Allocation:", unpaidAllocation);
      console.log("Paid Allocation:", paidAllocation);

      const taxPercentage = line.total_tax > 0 && line.cost > 0 ? (line.total_tax / line.cost) * 100 : 0;
      console.log("Tax Percentage:", taxPercentage);

      const taxOnCredit = (amount * taxPercentage) / 100;
      console.log("Tax on Credit:", taxOnCredit);
      amount = Number(creditAmount) + taxOnCredit;
      console.log("totalCreditAmount with tax",amount);

      let line_total = line.cost + line.total_tax - line.total_discounts;
      let line_adjustments = line.LineAllocations
        .filter(ila => ila.Payment.credit_type == 'adjustment')
        .reduce((total, ila) => total + ila.total_allocation, 0);

      if ((Math.round((amount + line_adjustments) * 1e2) / 1e2) > line_total){
        this.showMessageNotification({
          type: "error",
          description: `Adjustment cannot be credited`
        });
        return;
      }

      if (amount <= unpaidAllocation) {
        console.log(`Crediting ${amount} to the unpaid balance.`);
        const payload = { amount };
        api.post(this, api.INVOICES + `${this.invoice.id}/invoice_line/${line.id}/credit`, payload)
          .then((response) => {
            console.log("Credit updated successfully", response);
            this.invoice = response.invoice;
          })
          .catch((error) => {
            console.error("Failed to update credit", error);
          });
        return;
      }

      if (amount > unpaidAllocation) {
        const creditAmount = unpaidAllocation;
        const refundAmount = amount - creditAmount;
        console.log("creditAmount",creditAmount);
        console.log("refundAmount", refundAmount);
        console.log(`Crediting $${creditAmount} to the unpaid balance and refunding $${refundAmount} via void modal.`);

        if (creditAmount > 0) {
          const payload = { amount: creditAmount };
          api.post(this, api.INVOICES + `${this.invoice.id}/invoice_line/${line.id}/credit`, payload)
            .then((response) => {
              console.log("Credit updated successfully", response);
              this.invoice = response.invoice;
            })
            .catch((error) => {
              console.error("Failed to update credit", error);
            });
        }
        if (refundAmount > 0) {
          console.log("Before opening void model");
          this.$emit("open-void-modal", { is_credited: true, amount: refundAmount, line: line });
        }
      }

    },

    addProductToInvoice(product){
      if((!product || !product.id) || this.invoice.InvoiceLines.find( line => line.product_id === product.id)) {
          this.product = {};
          return;
      }

      var newLine = {
        
          Product: product,
          product_id: product.id,
          cost: product.price || 0,
          qty: this.charge.qty || 1,
          DiscountLines:[],
          TaxLines:[],
      };

      this.invoice.InvoiceLines.push(newLine);

      this.charge = {
          product_id: null,
          cost: null,
          qty: null
      };
      this.product = {};
    }
  },
  props:['selected', 'readonly', 'isAdjusted', 'refreshInvoice', 'fromMasterVueFile'],
  watch:{
    selected:function(newInvoice){
      this.invoice = newInvoice;
    },
    "invoice.InvoiceLines": {
        handler: _.debounce(function(){
          console.log("*******Hello****");
            if(this.isAdjusted){
              this.$emit('refreshInvoice');
            }
        }, 150),
        deep: true
    },
  }
}

</script>

<style scoped>

  span{
    color: #101318;
  }

  .inv-header-cell{
    background: #F4F6F8;
  }

  .space-header {
    background: #F9FAFB;
    box-shadow: inset 0px -1px 0px #DFE3E8;
  }
  .hb-payment {
    color: #306FB6;
  }
  .top-8px{
    position:relative;
    top:8px
  }
</style>

<style lang="scss">
  .view-invoice-txt-field{
    max-width: 50px;
    .v-input__slot{
      &:before{
        border-color: rgba(0, 0, 0, 0.15) !important;
        border-bottom-left-radius: 5px;
      };
      input{
        text-align: right !important;
        padding-bottom: 0;
        padding-top: 0;
      }
    } 
  }
</style>
