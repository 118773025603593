export default Object.freeze({
	REFUND: {
        text : 'Refund',
        value: 'refund'
    },
    REVERSALS: {
        card: {
            CHARGEBACK: {
                text : 'C.C. Chargeback',
                value: 'chargeback',
                reversal_threshold:365,
                permission: 'card_chargeback_reversal'
            },
            OFFLINE: {
                text : 'Offline Reversal',
                value: 'offline',
                reversal_threshold:365,
                permission: 'card_offline_reversal'
            },
            REFUND: {
                text : 'Refund',
                value: 'refund',
                reversal_threshold:365,
                permission: 'card_refund_permission'
            },
        },
        ach: {
            ACH: {
                text : 'ACH Reversal',
                value: 'ach',
                reversal_threshold:365,
                permission: 'ach_reversal'
            },
            REFUND: {
                text : 'Refund',
                value: 'refund',
                reversal_threshold:365,
                permission: 'ach_refund'
            }
        },
        check: {
            NSF: {
                text : 'NSF (Non-sufficient funds)',
                value: 'nsf',
                reversal_threshold:365,
                permission: 'check_reversal'
            },
            VOID:{
                text : 'Void',
                value: 'void',
                reversal_threshold:1,
                permission: 'check_void_reversal'
            },
            REFUND: {
                text : 'Refund',
                value: 'refund',
                reversal_threshold:365,
                permission: 'check_refund_reversal'
            },
            DIRECTREFUND: {
                text : 'Direct Refund',
                value: 'directrefund',
                reversal_threshold:365,
                permission: 'check_direct_refund'
            },
            OFFLINE: {
                text : 'Offline Reversal',
                value: 'offline',
                reversal_threshold:365,
                permission: 'card_offline_reversal'
            },
        },
        cash: {
            VOID:{
                text : 'Void',
                value: 'void',
                reversal_threshold:1,
                permission: 'cash_void_reversal'
            },
            REFUND: {
                text : 'Refund',
                value: 'refund',
                reversal_threshold:365,
                permission: 'cash_refund_reversal'
            },
            OFFLINE: {
                text : 'Offline Reversal',
                value: 'offline',
                reversal_threshold:365,
                permission: 'cash_offline_reversal'
            },
            DIRECTREFUND: {
                text : 'Direct Refund',
                value: 'directrefund',
                reversal_threshold:365,
                permission: 'cash_direct_refund'
            }
        },
        eftpos: {
            REFUND: {
                text : ' EFTPOS Refund',
                value: 'refund',
                reversal_threshold:365,
                permission: 'eftpos_refund_reversal'
            },
            DIRECTREFUND: {
                text : 'Direct Refund',
                value: 'directrefund',
                reversal_threshold:365,
                permission: 'eftpos_direct_refund'
            },
            CHARGEBACK: {
                text : 'C.C. Chargeback',
                value: 'chargeback',
                reversal_threshold:365,
                permission: 'eftpos_chargeback_reversal'
            },
            OFFLINE: {
                text : 'Offline Reversal',
                value: 'offline',
                reversal_threshold:365,
                permission: 'eftpos_offline_reversal'
            },
        },
        directdebit: {
            DIRECTREFUND: {
                text : 'Direct Refund',
                value: 'directrefund',
                reversal_threshold:365,
                permission: 'directdebit_direct_refund'
            },
            OFFLINE: {
                text : 'Offline Reversal',
                value: 'offline',
                reversal_threshold:365,
                permission: 'directdebit_offline_reversal'
            },
            BANKREJECT: {
                text : 'Reject',
                value: 'bankreject',
                reversal_threshold:365,
                permission: 'directdebit_Reject'
            },
        },
        giftcard: {
            VOID:{
                text : 'Void',
                value: 'void',
                reversal_threshold:1,
                permission: 'giftcard_void_reversal'
            },
            REFUND: {
                text : 'Refund',
                value: 'refund',
                reversal_threshold:365,
                permission: 'giftcard_refund_reversal'
            }
        },
        credit: {
            REVERSE: {
                text : 'Reverse Credit',
                value: 'credit',
                reversal_threshold:365,
                permission: 'credit_reversal'
            }
        }
    },
    REVERSAL_TYPES: {
        NSF: 'NSF Reversal',
        ACH: 'ACH Reversal',
        OFFLINE: 'Offline Reversal',
        BANKREJECT: 'Bank Reject',
        CHARGEBACK: 'C.C. Chargeback',
        REFUND: 'Refund',
        VOID:'Void',
        CREDIT:'Credit',
        DIRECTREFUND:'Direct Refund'

    }
});