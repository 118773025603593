<template>
    <div class="bg-colo-light" ref="firstContent">
        <hb-blade-header class="hb-blade-header-wrapper-custom"
            :title="toTitleCase(currentItem?.item?.name) || 'New Template'" backButtonOff @close="$emit('close')">
        </hb-blade-header>
        <div v-if="!currentItem.isNew">
            <div>
                <template-edit ref="templateEditRef" :currentItem="currentItem"
                    class="custom-compo-element-002 bg-colo-light" @scrollIntoView="scrollIntoView()"
                    @content-changed="contentChanged" />
            </div>
            <div class="footer-wrapper">
                <div class="d-flex justify-end">
                    <div>
                        <hb-link class="mr-5" @click="$emit('close')">Cancel</hb-link>
                        <hb-btn color="primary" v-if="!checkIfCreate" :disabled="disableButton" :loading="loading"
                            @click="check()">Save Template</hb-btn>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="mb-13 bg-colo-light">
            <template-edit :currentItem="currentItem" ref="templateEditRef" @scrollIntoView="scrollIntoView()"
                @content-changed="contentChanged" />

            <div class="footer-wrapper">
                <div class="d-flex justify-end">
                    <div>
                        <hb-link class="mr-5" @click="$emit('close')">Cancel</hb-link>
                        <hb-btn color="primary" @click="createTemplate(false)" :loading="loading">Create
                            Template</hb-btn>
                    </div>
                </div>
            </div>
        </div>
        <hb-modal size="medium" title="Warning" v-model="warningModal" confirmation>
            <template v-slot:content>
                <div class="py-4 px-6">
                    This may impact the Corporate level & Assigned properties.
                    <br /><br />
                    Would you like to continue?
                </div>
            </template>
            <template v-slot:left-actions>
            </template>
            <template v-slot:right-actions>
                <hb-btn color="primary" @click="createTemplate(true)" :loading="loading">Confirm</hb-btn>
            </template>
        </hb-modal>
    </div>
</template>
<script type="text/babel">
import { mapGetters } from "vuex";
import api from '../../../../assets/api.js';
import TabSection from '../../../includes/Tabs/Index.vue'
import { notificationMixin } from '../../../../mixins/notificationMixin.js';
import { EventBus } from '../../../../EventBus';
import TemplateConfig from './templateConfig.vue';
import TemplateEdit from './templateEdit.vue';
export default {
    name: "TemplateManager",
    props: ['currentItem'],
    mixins: [notificationMixin],
    data() {
        return {
            tabOptions: [
                { label: 'Configuration', key: 'template-config' },
                { label: 'Edit/Preview', key: 'template-edit' }

            ],
            component: 'template-config',
            templateConfig: {},
            templateEdit: {},
            loading: false,
            disableButton: true,
            warningModal: false
        };
    },
    components: {
        TabSection,
        'template-config': TemplateConfig,
        'template-edit': TemplateEdit,
    },
    computed: {
        checkIfCreate() {
            if (this.currentItem) {
                return false
            }
            else return true
        },
    },
    methods: {
        contentChanged(val) {
            this.disableButton = !val
        },
        toTitleCase(str) {
            if (!str) return 'New Template'; // Default value if str is falsy
            return str.replace(/\w\S*/g, (txt) => {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        },
        scrollIntoView() {
            this.$refs.firstContent.scrollIntoView({ behavior: 'smooth', block: 'start' });
        },
        async check() {
            let general_status_edit = await this.$refs.templateEditRef.$validator.validateAll("general");
            if (!general_status_edit) {
                this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                return;
            }
            else {
                if (!this.currentItem.duplicate) this.warningModal = true
                else this.createTemplate(true)
            }
        },
        // checkDisable(val) {
        //     if (val) this.disableButton = true
        //     else this.disableButton = false
        // },
        tabChange(tabName) {
            this.component = tabName
        },
        handleMessageTypeChange(newMessageType) {
            this.templateConfig = newMessageType // getting from component via event
        },
        async createTemplate(update) {
            this.loading = true;
            // this is not scalable approach but due to high level requirement and suggestions 
            const isReservationTemplate = /^Reservation (Failure|Success) - (Online|Instore)$/i.test(this.currentItem?.item?.name) && this.currentItem?.item?.is_base_template;
            const isRentalTemplate = /^Rental Confirmation - (Online|Instore)$/i.test(this.currentItem?.item?.name) && this.currentItem?.item?.is_base_template;

            // general validation 
            const validateGeneral = async () => this.$refs.templateEditRef.$validator.validateAll("general");
            const showErrorNotification = () => {
                this.showMessageNotification({
                    type: 'error',
                    description: 'There are errors in your form, correct them before continuing.',
                    list: this.errors.items,
                });
                this.loading = false;
            };
            //general api response handle
            const handleApiResponse = async (data, endpoint) => {
                try {
                    const { properties: propertiesArray } = this.$refs.templateEditRef.getConfigData();
                    const properties = propertiesArray.map(property => property); // Extract properties as an array of strings
                    const payload = isReservationTemplate || isRentalTemplate
                        ? {
                            notes: data,
                            property_ids: properties, // Set property_ids as an array of strings
                        }
                        : data;// Wrap data in "notes" object and add "property_ids"
                    await api.post(this, endpoint, payload);
                } catch (error) {
                    console.error(error);
                    this.showMessageNotification({ type: 'error', description: "Something went wrong" });
                    this.loading = false;
                    throw error;
                }
            };
            // if we are updating the data 
            if (update) {
                let isGeneralValid = await validateGeneral();
                if (!isGeneralValid) {
                    showErrorNotification();
                    return;
                }

                if (isReservationTemplate || isRentalTemplate) {
                    let emailNotes = this.$refs.templateEditRef.getEmailNotes();
                    let data = isReservationTemplate
                        ? { success: emailNotes?.success, failure: emailNotes?.failure }
                        : {
                            successIDV: emailNotes?.successIDV,
                            disabledIDV: emailNotes?.disabledIDV,
                            skippedIDV: emailNotes?.skippedIDV,
                        };

                    for (let key in data) {
                        if (data[key]) {
                            const endpoint = this.currentItem?.propertyId
                                ? `${api.TEMPLATE_MANAGER}template/email-notes?property_id=${this.currentItem?.propertyId}`
                                : `${api.TEMPLATE_MANAGER}template/email-notes`;
                            await handleApiResponse(data[key], endpoint);
                        }
                    }

                    this.loading = false;
                    this.$emit('close');
                    EventBus.$emit('CommunicationTemplate:refreshList');
                    this.showMessageNotification({ type: 'success', description: "Template edited successfully" });
                }
                // normal templates other than rental and reservation
                else {
                    try {
                        const { name, type, category, properties: propertiesArray } = this.$refs.templateEditRef.getConfigData();
                        const properties = propertiesArray.map(property => ({
                            id: property,
                            is_property_modified: this.currentItem?.propertyId ? "1" : "0",
                        }));

                        let data = {
                            name,
                            type,
                            category,
                            properties,
                            template: this.$refs.templateEditRef.getData(),
                        };

                        // 1. system generated corporate & property
                        if (this.currentItem.item.is_base_template === 1) {
                            data.base_template_id = this.currentItem?.item?.base_template_id || this.currentItem?.item?.template_id;
                            if (this.currentItem?.item?.template_id) {
                                data.template_id = this.currentItem?.item?.template_id;
                            }
                        }

                        // if (this.currentItem?.item?.is_base_template === 1) {
                        //     const { base_template_id, template_id } = this.currentItem.item;
                        //     data.base_template_id = base_template_id || template_id;
                        //     if (template_id) {
                        //         data.template_id = template_id;
                        //     }
                        // }
                        
                        // editing user-generated corporate & property
                        else {
                            data.template_id = this.currentItem.item.template_id;
                        }

                        const endpoint = this.currentItem?.propertyId
                            ? `${api.TEMPLATE_MANAGER}template?property_id=${this.currentItem?.propertyId}`
                            : `${api.TEMPLATE_MANAGER}template`;
                        await handleApiResponse(data, endpoint);

                        this.$emit('close');
                        EventBus.$emit('CommunicationTemplate:refreshList');
                        this.showMessageNotification({
                            type: 'success',
                            description: this.currentItem.duplicate ? "Template duplicated successfully" : "Template edited successfully",
                        });
                    } catch { }
                }
            }
            // creating from scratch means its custom template
            else {
                let isGeneralValid = await validateGeneral();
                if (!isGeneralValid) {
                    showErrorNotification();
                    return;
                }

                try {
                    const { name, type, category, properties: propertiesArray } = this.$refs.templateEditRef.getConfigData();
                    const properties = propertiesArray.map(property => ({
                        id: property,
                        is_property_modified: this.currentItem?.propertyId ? "1" : "0",
                    }));

                    let data = {
                        name,
                        type,
                        category,
                        properties,
                        template: this.$refs.templateEditRef.getData(),
                    };

                    if (data.template.body || data.template.smsContent) {
                        await handleApiResponse(data, `${api.TEMPLATE_MANAGER}template`);

                        this.$emit('close');
                        EventBus.$emit('CommunicationTemplate:refreshList');
                        this.showMessageNotification({ type: 'success', description: "Template created successfully" });
                    } else {
                        this.showMessageNotification({ type: 'error', description: "Content cannot be empty" });
                        this.loading = false;
                    }
                } catch { }
            }
        }

    }
}

</script>
<style scoped>
.bg-colo-light {
    background-color: #F9FAFB;
    min-height: 100vh;
}

.footer-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    padding: 12px 16px;
    box-shadow: inset 0px 1px 0px rgba(142, 142, 143, 0.15);
    z-index: 1;
}

.custom-compo-element {
    height: calc(100vh - 30px);
}

.hb-blade-header-wrapper-custom {
    position: sticky;
    top: 0;
    z-index: 1000;
}
</style>