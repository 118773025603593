<template>
  <div style="height: 100%;">
    <div class="send-sms-panel">
      <v-card flat class="edit-panel">
        <div class="px-4" :class="{ 'mt-3': errorHas($options.name) || successHas($options.name) }">
          <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)"
            :message="errorGet($options.name)" status="error"></status>
          <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)"
            :message="successGet($options.name)" status="success"></status>
          <!-- <div class="font-regular hb-text-light">Enter your text message.</div> -->
        </div>
        <div v-show="!showConfirm">
          <div class="breadcrumb-communication">
            <hb-breadcrumb @click="goBack()">
              <span class="back-text-communication">
                Send Communication
              </span>
            </hb-breadcrumb>
          </div>
          <v-divider></v-divider>
          <div class="pa-4 hb-text-light">
            <span>Draft your text message or choose a communication template to start with.</span>
          </div>
          <div class="mt-0">
            <v-divider></v-divider>
            <hb-form label="Template" :divider=true>
              <template v-slot:tooltipBody>
                Search for an existing template from the template manager library
              </template>
              <HbAutocomplete v-model="temmplateName" :items="templateNames" v-validate="'required|max:45'"
                item-text='name' item-value='template_id' data-vv-scope="default" data-vv-name="autocomplete-box"
                data-vv-as="Autocomplete Box" :error="errors.has('default.autocomplete-box')"
                placeholder="Select Template" single-line @change="appendtemplate($event)"
                @click:clear="clearTemplate" />
            </hb-form>
            <v-row class="hb-table-row">
              <v-col class="hb-table-value">
                <hb-textarea rows="11" placeholder="Enter Message" dense solo flat hide-details class="pa-1"
                  v-model="form.message"  @input="triggerAddMergeFieldByInput"></hb-textarea>
                <!-- <div v-else style="max-height: 468px; min-height: 400px; overflow: auto; margin-top: 5px;">
              <div v-html="form.message" style="font-size: medium;""></div>
            </div> -->
              </v-col>
            </v-row>
          </div>

        </div>
        <div v-show="showConfirm">
          <div class="bread-confirm pa-4">
            <hb-breadcrumb @click="showConfirm = false, disable = false">
              <span class="back-text-communication">
                Confirm & Send to Recipients
              </span>
            </hb-breadcrumb>
          </div>
          <v-divider></v-divider>
          <div class="pa-4 hb-text-light">
            <span>This message will be sent to ALL the recipients you selected below:</span>
          </div>
          <v-divider></v-divider>
          <div class="pa-5">
            <hb-data-table :loading="!contacts.length > 0" fixed-header :height="computedTableHeight" disable-sort :headers="headers" :items="contacts">
              <template v-slot:[`item.Phone`]="{ item }">
                <span v-if="item.Phone">{{ item.Phone.phone }}</span>
                <v-alert v-else style="font-size: 14px;" dense flat text class="ma-0" type="error">
                  Phone Number missing!
                </v-alert>
              </template>
              <template v-slot:[`item.first`]="{ item }">
                {{ item.first }} {{ item.last }}
              </template>
              <template v-slot:footer>
                <div class="pa-2 custom-footer">
                  Total Recipients: {{ contacts.length}}
                </div>
              </template>
            </hb-data-table>
          </div>
        </div>
      </v-card>
      <hb-bottom-action-bar v-if="!showConfirm" cancel-off>
        <template v-slot:left-actions>
          <hb-menu>
              <template v-slot:menu-activator>
                <hb-btn color="secondary" @click="handleAddMergeClick">
                   Add Merge Fields
                </hb-btn>
                <hb-btn icon @click="toggleList = true" mdi-code="mdi-paperclip" tooltip="Attach File"></hb-btn>
              </template>
              <v-list v-if="toggleList">
                <v-list-item class="pointer-cursor" @click="showFile = true,clearfiles = false">
                  Upload
                </v-list-item>
                <v-list-item class="pointer-cursor" @click="openUploadDocs(0)" disabled>
                  Generate Documents (Non-Signature)
                </v-list-item>
                <v-list-item class="pointer-cursor" @click="openUploadDocs(1)" disabled>
                  Generate Documents (Signature)
                </v-list-item>
              </v-list>
            </hb-menu>
        </template>
        <template v-slot:right-actions>
          <hb-btn color="secondary" @click="goBack">Back</hb-btn>
          <hb-btn color="primary" @click="setEditForm">Next</hb-btn>
        </template>
      </hb-bottom-action-bar>

      <hb-bottom-action-bar v-if="showConfirm" cancel-off>
        <template v-slot:left-actions>

        </template>
        <template v-slot:right-actions>
          <hb-btn color="secondary" @click="showConfirm = false, disable = false">Back</hb-btn>
          <hb-btn color="primary" :loading="loading" @click="save">Confirm & Send</hb-btn>
        </template>
      </hb-bottom-action-bar>
    </div>
    <add-file :show="showFile" :onClose='onFileClose' :loading="fileLoading" :getAttachments="getAttachments" :clearData="clearfiles">
    </add-file>
  </div>
</template>

<script>
import api from '../../../assets/api.js';
import Status from '../Messages.vue';
import Loader from '../../assets/CircleSpinner.vue';
import HbDatePicker from '../../assets/HummingbirdDatepicker';
import { EventBus } from '../../../EventBus.js';
import { mapGetters, mapActions } from 'vuex';
import MergeFields from "../../includes/MergeFields";
import { notificationMixin } from '@/mixins/notificationMixin.js';
import addFile from '../../communication_center/AddFile.vue'

export default {
  name: "BulkSendSMS",
  data() {
    return {
      headers: [
        { text: 'Person', align: 'start', value: 'first' },
        { text: 'Phone', align: 'start', value: 'Phone' },
      ],
      temmplateName: '',
      showConfirm: false,
      form: {
        subject: '',
        email: '',
        message: ''
      },
      loading: false,
      toggleList: false,
      showFile: false,
      fileLoading: false,
      attachments:[],
      contacts: [],
      showMergeFields: false,
      templateNames: [],
      itemsNew: [],
      clearfiles: false,
      debounceTimeout: null,
      isTriggeredByButton: false
    }
  },
  mixins: [notificationMixin],
  props: ['items'],
  created() {
    this.getContactDetails();
  },
  components: {
    Status,
    Loader,
    HbDatePicker,
    MergeFields,
    'add-file': addFile,
  },
  computed: {
    ...mapGetters({
      facilityList: "propertiesStore/filtered",
      contactsList: "reportStore/getBulkContactsList",
      triggerMergeFields: 'addMergeFieldsStore/triggerMergeFields',
      selectedMergeField: 'addMergeFieldsStore/selectedMergeField',
      actionSource: 'addMergeFieldsStore/actionSource',
      addMergeButtonClickFlag: 'addMergeFieldsStore/addMergeButtonClickFlag',
    }),
    computedTableHeight() {
      const maxHeight = 400; // Define a maximum height for the table
      const rowHeight = 48;  // Approximate height of each row in pixels
      const headerHeight = 48; // Approximate height of the table header

      // Calculate height based on the number of rows plus the header height
      const calculatedHeight = (this.contacts.length * rowHeight) + headerHeight;

      // Ensure height does not exceed the maximum
      return `${Math.min(calculatedHeight, maxHeight)}px`;
    }
  },
  mounted() {
    this.getBaseTemplateData()
  },
  methods: {
    ...mapActions({
      getBulkContacts: 'reportStore/addBulkContacts',
      setTriggerMergeFields: 'addMergeFieldsStore/setTriggerMergeFields',
      setActionSource: 'addMergeFieldsStore/setActionSource',
      setFilterInput: "addMergeFieldsStore/setFilterInput",
      resetMergeFieldParameters: "addMergeFieldsStore/reset",
    }),
    triggerAddMergeFieldByInput(txt) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        const bracketMatch = /\[[^\]]+[^\]]*$/mg;
        const isMergeFlag = txt.replace(/<\/?[^>]+(>|$)/g, "").match(bracketMatch);
        const openBracketMatch = txt.match(/\[[^\]]+$/);
        if (isMergeFlag) {
          if (openBracketMatch[0]) {
            this.setFilterInput({
              filterInput: openBracketMatch[0].replace(/<\/?[^>]+(>|$)/g, "").replace(/[^a-zA-Z0-9\s]/g, "")
            })
            this.setTriggerMergeFields({
              actionSource: 'bulk-edit-mail',
              addMergeButtonClickFlag: false
            })
          }  
        }
      }, 500)
    },

    insertMergeField(txt) {
      if (this.actionSource === 'bulk-edit-sms') {
        if (this.addMergeButtonClickFlag) {
          this.form.message = this.form.message === undefined ? txt : this.form.message + txt;
          if (this.$vuetify.breakpoint.xs) {
            this.resetMergeFieldParameters()
          }
          this.setFilterInput({
            filterInput: ""
          })
          this.$store.commit('addMergeFieldsStore/SET_SELECTED_MERGE_FIELD', null);
        }
        else {
          const characterReplacedText = this.form.message.replace(/\[[^\]]+$/, txt);
          this.form.message =  characterReplacedText;
          if (this.$vuetify.breakpoint.xs) {
            this.resetMergeFieldParameters()
          }
          this.setFilterInput({
            filterInput: ""
          })
        }
      }
    },
    
      handleAddMergeClick(){
        this.setTriggerMergeFields({
        actionSource: 'bulk-edit-sms',
        addMergeButtonClickFlag: true
      })   
    },
    async goBack() {
      await this.$emit('back');
      EventBus.$emit('send_email:back', true);
      EventBus.$emit('disablecheckbox:report', false);
    },
    clearTemplate() {
      this.form.message = ''
      this.form.subject = ''
    },
    async getBaseTemplateData() {
      const templates = await this.getAllTemplatesWithId([this.facilityList[0].id]); // only one property will select at a time
      const templateFilterd = templates.filter(template => template.base_template_id === null)
      this.templateNames = templateFilterd.filter(template => template.type === 'sms').map(template => ({ name: template.name, template_id: template.template_id }));
    },
    async getAllTemplatesWithId(properties) {
      return await this.getTemplatesById(properties);
    },
    async getTemplatesById(propertyIds) {
      try {
        const propertyIdQueryString = propertyIds.map(id => `property_id=${id}`).join('&');
        const response = await api.get(this, api.TEMPLATE_MANAGER + `templates?${propertyIdQueryString}&get_only_active_templates=true`);
        return response.templates;
      } catch (error) {
        console.error(`Error fetching templates for property IDs ${propertyIds.join(',')}:`, error);
        throw error; // Rethrow the error to propagate it up
      }
    },
    async appendtemplate(id) {
      try {
        await api.get(this, api.TEMPLATE_MANAGER + `template/${id}`).then((res) => {
          if (res.template.smsContent) {
            this.form.message = this.form.message ? this.form.message + ' ' + res.template.smsContent : res.template.smsContent
          }
          else {
            this.showMessageNotification({ type: 'error', description: "Can't Attach Email Template to SMS Field" });
          }
        })
      } catch (error) {
        throw error;
      }
    },
    smsPhones(phones = []) {
      if (!phones.length) return [];
      return phones.filter(p => p.sms);
    },
    closeWindow() {
      this.resetAction();
    },
    onFileClose() {
      this.showFile = false;
      this.toggleList = false;
    },
    async getAttachments(attachments) {
      try {
        this.fileLoading = true;
        this.attachments = attachments;
        if (this.attachments.length) {
          let fileUploadResponse = await this.upload(this.items);
          console.log(fileUploadResponse, "fileUploadResponse");

          let res = await api.get(this, api.SEND_SHORTEN_LINK + `?upload_id=${fileUploadResponse.uploads.id}&contact_id=${this.contacts[0].id}`);
          this.form.message = this.form.message ? this.form.message + ' ' + res.short_url : res.short_url;
          this.fileLoading = false;
          this.attachments = [],
            this.onFileClose();
          this.clearfiles = true
        }
      } catch (error) {
        console.error("Error in getAttachments:", error);
        this.onFileClose();
        this.attachments = [],
          this.fileLoading = false;
        this.clearfiles = true
        // You can add any additional error handling logic here, such as showing an error message
      }
    },
    async upload(contacts) {
      let body = {
        document_type: this.type,
        document_type_id: this.document_type_id,
        contacts: contacts
      }
      let response = await api.postFile(this, api.CONTACTS + 'uploads', body, this.attachments);

      return response

    },
    async getContactDetails() {

      let items = this.items.map(item => {
        return {
          id: item.tenant_id || item.contact_id || item.lead_id,
          lease_id: item.lease_id,
          national_account_id: item?.na_id
        }
      });
      this.itemsNew = [...(this.contactsList.length ? this.contactsList : items)];
      let contacts_data = this.contactsList.filter(data => data.id);

      let response = await api.post(this, api.CONTACTS + 'bulk-config', { contact_ids: contacts_data.length ? contacts_data : items.filter(i => i.id) });
      this.contacts = response.contacts.map(contact => {

        let c = {
          id: contact.id,
          first: contact.first,
          last: contact.last,
        }
        c.Phone = contact.Phones.find(p => p.sms)
        // let data = this.contactsList.length? this.contactsList : items;
        // let item = data.find(item => {
        //   if (c.id === item.id) {
        //     return item.lease_id
        //   }
        // })
        // console.log(item,"item")
        // if(item?.national_account_id){
        //   c.id = item.id
        //   c.lease_id = item.lease_id
        // }else{
        //   c.lease_id = item.lease_id;
        // }

        return c
      });

      
    },
    setEditForm() {

      if (!this.form.message) {
        this.errorSet(this.$options.name, "Please enter your message");
        return;
      };

      this.showConfirm = true;
    },
    async save() {
      try {
        this.loading = true
        let form = {
          message: this.form.message,
          contacts: this.itemsNew
        };
        let response = await api.post(this, api.CONTACTS + 'bulk-send-sms', form);
        if (!response) {
          this.loading = false;
          return;
        }
        this.showMessageNotification({ type: 'success', description: 'Message Sent!' });
        this.loading = false;
        EventBus.$emit('unit_edited');
        this.getBulkContacts([]);
        this.$emit('close-bulk-edit');
      } catch (error) {
        this.loading = false
        this.errorSet(this.$options.name, "Failed to send messages. Please try again.");
        console.error("Error sending SMS:", error);
      }
    },
  },
  watch: {
    selectedMergeField(newValue){
      if(newValue){
        this.insertMergeField(newValue)
      }
    }
  }
}

</script>
<style scoped>
.strike {
  text-decoration: line-through;
  color: #a0a0a0;
}

.enhanced {
  font-weight: 500;
}

.primary-color {
  color: #00727A;

}

.panel-footer {
  border-top: none !important;
  z-index: 5;
  width: 100%;
  bottom: 0;
  position: static !important;
}
</style>
<style lang="scss" scoped>
.send-sms-panel {
  display: flex;
  flex-direction: column;
  height: 100%;

  .edit-panel {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.v-card-class-main {
  margin-top: 30px;
  border-radius: 4px;
  border: 1px solid #e3e8ed;
  box-shadow: none !important;
}

.no-border-table {
  border-collapse: collapse;
  /* Ensures no separation between table cells */
}

.no-border-table th,
.no-border-table td {
  border: none !important;
  /* Removes borders from table headers and cells */
  padding: 5px !important;
  /* Adjusts padding for a cleaner look */
}

.back-text-communication {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: #101318;
  display: block;
}

.breadcrumb-communication {
  padding: 16px;
}

.breadcrumb-communication ::v-deep a.hb-link i.mdi-chevron-left {
  color: #101318 !important;
}
.custom-footer{
  border: 1px solid rgb(223,227,232);
  font-weight: 500;
}
</style>
