<template>
    <div>
      <div class="tab-container">
        <hb-mini-profile class="pt-8 pb-3"
            type="space"
            disable-avatar-click
            disable-click
            :title="unit.number"
            :status="unit.state"
            :space-type="unit.type"
            :space-category="unit.label"
            :space-featured-amenity="unit.Category.name"
            :space-property-info="spacePropertyInfo(unit.Address)"
        >
        </hb-mini-profile>
        <v-divider class="pb-2"></v-divider>
        <v-expansion-panels v-model="accordionGroup" multiple>
            <hb-expansion-panel>
                <template v-slot:title>
                    Select Coverage *
                </template>
                <template v-slot:content>
                    <insurance></insurance>
                </template>
                
            </hb-expansion-panel>

            <hb-expansion-panel :expand-icon-off="!hasMerchandise || isfetching">
                <template v-slot:title>
                    Merchandise <span class="products-counter" v-if="!isfetching && hasMerchandise">{{  hasMerchandise }}</span>
                </template>
                <template v-slot:actions>
                    <a v-if="!hasMerchandise && !isfetching" @click.prevent="showDialogEvent('merchandise')">Click to Add</a>
                </template>
                <template v-slot:content>
                    <merchandise @fetchServices="fetchServices" v-if="hasMerchandise" :merchandise-settings="merchandiseSettings"  :merchandise-products="merchandiseProducts" ref="merchandiseElemenet"></merchandise>
                </template>
                <template v-slot:footer v-if="hasMerchandise && !isfetching">
                    <hb-bottom-action-bar :footer-cancel-option="false" key="merchandise">
                        <template v-slot:right-actions>
                            <hb-btn color="secondary" @click="showDialogEvent('merchandise')">+ Add Merchandise</hb-btn>
                        </template>
                    </hb-bottom-action-bar>
                </template>
            </hb-expansion-panel>

            <hb-expansion-panel :expand-icon-off="!hasFees || isfetching">
                <template v-slot:title>
                    Fees <span class="products-counter" v-if="!isfetching && hasFees">{{  hasFees }}</span>
                </template>
                <template v-slot:actions>
                    <a v-if="!hasFees && !isfetching" @click.prevent="showDialogEvent('fees')">Click to Add</a>
                </template>
                <template v-slot:content>
                    <fees @fetchServices="fetchServices" v-if="hasFees" :fees-products="feesProducts" ref="feesElemenet"></fees>
                </template>

                <template v-slot:footer v-if="hasFees && !isfetching">
                    <hb-bottom-action-bar :footer-cancel-option="false" key="fees">
                        <template v-slot:right-actions>
                            <hb-btn color="secondary" @click="showDialogEvent('fees')">+ Add Fees</hb-btn>
                        </template>
                    </hb-bottom-action-bar>
                </template>
            </hb-expansion-panel>
        </v-expansion-panels>

        <add-service :products-list="productsList" :merchandise-settings="merchandiseSettings" @fetchServices="fetchServices" :dialog-type="dialogType" v-model="productsDialog" />
                        
        <!-- <v-tabs
            v-show="!this.unit_id"
            background-color="#F9FAFB"
            class="hb-text-light"
            color="#101318"
            show-arrows
        >
            <v-tabs-slider color="#00848E"></v-tabs-slider>
            <v-tab class="text-capitalize" :ripple="false" @click="active_tab = 'insurance'">Coverage</v-tab>
            <v-tab class="text-capitalize" :ripple="false" @click="active_tab = 'merchandise'">Merchandise</v-tab>
            <v-tab class="text-capitalize" :ripple="false" @click="active_tab = 'fees'">Fees</v-tab>
        </v-tabs> -->
      </div>


        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

        <!-- <div class="services-section scrollable-content">
            <insurance v-if="active_tab === 'insurance'"></insurance>
            <merchandise v-if="active_tab === 'merchandise'"></merchandise>
            <fees v-if="active_tab === 'fees'"></fees>
        </div> -->

        <!-- <div class="new-tenant-walkthrough-footer">
            <hb-bottom-action-bar
                @close="$emit('close')"
                :top-border="false"
            >
                <template v-slot:left-actions>
                    <help-juice />
                </template>
                <template v-slot:right-actions>
                    
                    <hb-btn
                        color="secondary"
                        :disabled="isLoading($options.name) || !canProgress"
                        @click="$emit('retire_lead')"
                      >
                        Retire Lead
                      </hb-btn>
                    <hb-btn
                        :disabled="isLoading($options.name) || !canProgress"
                        :loading="savingInProgress"
                        @click="productsSaved"
                    >
                        Payments
                    </hb-btn>
                </template>
            </hb-bottom-action-bar>
        </div> -->
    </div>

</template>

<script type="text/babel">
    import api from '../../../assets/api.js';
    import Status from './../Messages.vue';
    import Loader from '../../assets/CircleSpinner.vue';
    import moment from 'moment';

    import Insurance from './Insurance';
    import Merchandise from './Merchandise';
    import Fees from './Fees';
    import { EventBus } from '../../../EventBus.js';
    import { mapGetters, mapActions } from 'vuex';
    export default {
        name: 'ServicesSelection',
        components: {
            Status,
            Insurance,
            Merchandise,
            Fees,
            'add-service' : () => import('./AddServiceModal.vue')
        },
        data: () => ({
            active_tab: 'insurance',
            accordionGroup:[],
            panel: 0,
            confirm: false,
            show_security_deposit: false,
            promotions:[],
            unit_id: '',
            savingInProgress: false,
            merchandiseProducts: [],
            feesProducts: [],
            isfetching: true,
            productsDialog: false,
            dialogType: ''
        }),
        props: [],

        async created(){
            await this.getMerchandiseSettingsAction();
            this.fetchServices();
        },
        computed:{
            ...mapGetters({
                lease: 'onBoardingStore/lease',
                insurance: 'onBoardingStore/insurance',
                unit: 'onBoardingStore/unit',
                property: 'onBoardingStore/property',
                services: 'onBoardingStore/services',
                merchandiseSettings: 'productStore/merchandiseSettings'
            }),
            canProgress(){
                if(this.lease.decline_insurance || this.insurance.id){
                    return true;
                }
                return false;
            },
            contact: {
                get() { return this.$store.getters['onBoardingStore/contact'] } ,
                set(val){  return this.$store.commit('onBoardingStore/setContact', val ) }
            },
            lead: {
                get() { return this.$store.getters['onBoardingStore/lead'] } ,
                set(val){  return this.$store.commit('onBoardingStore/setLead', val ) }
            },
            hasMerchandise() {
                let merchandProducts = this.merchandiseProducts.filter(p => p.default_type === 'product');
                let servicesTemp = this.services && this.services.length ? this.services.filter(p => p.Product.default_type === 'product').map(item => item.product_id) : [];
                if (servicesTemp.length) {
                    merchandProducts = merchandProducts.filter(p => servicesTemp.includes(p.id));
                    return merchandProducts.length > 0 ? merchandProducts.length : false;
                }
                return false;
            },
            hasFees() {
                let feesProducts = this.feesProducts;
                let servicesTemp = this.services && this.services.length ? this.services.map(item => item.product_id) : [];
                if (servicesTemp.length) {
                    feesProducts = feesProducts.filter(p => servicesTemp.includes(p.id));
                    return feesProducts.length > 0 ? feesProducts.length : false;
                }
                return false;
            },
            productsList() {
                let modalType = this.dialogType;
                if (modalType) {
                    let default_type = modalType === 'merchandise' ? 'product' : 'late';
                    let servicesTemp = this.services && this.services.length ? this.services.filter(p => p.Product.default_type === default_type).map(item => item.product_id) : [];
                    if (modalType === 'merchandise') {
                        return this.merchandiseProducts.filter(p => !servicesTemp.includes(p.id));
                    } else {
                        return this.feesProducts.filter(p => !servicesTemp.includes(p.id));
                    }
                };
                return [];
            }

        },
        methods:{
            ...mapActions({
                getMerchandiseSettingsAction: 'productStore/getMerchandiseSettingsAction'
            }),
            async productsSaved(){
                this.savingInProgress = true;
                this.$emit('saved');
                this.savingInProgress = false;
            },
            spacePropertyInfo(address) {
              if(this.property && this.property.number){
                return this.property.number + ' - ' + this.$options.filters.formatAddress(address, '^', true);
              } else {
                return undefined;
              }
            },
            async fetchServices() {
                let merchandisePromise = api.get(this, api.PROPERTIES + this.property.id + '/products', {
                    type: 'product',
                    source: 'property_products'
                });
                let feesPromise = api.get(this, api.PROPERTIES + this.property.id + '/products', {
                    type: 'late',
                    category_type: 'movein'
                });
                this.isfetching  = true;
                await Promise.all([merchandisePromise, feesPromise])
                .then(responses => {
                    if (responses[0]?.products && responses[0]?.products?.length) {
                        this.merchandiseProducts = responses[0]?.products.filter(p => p.default_type === 'product')
                        .map(p => {
                            let { merchandise_details } = p;
                            let foundImage = null;
                            if (merchandise_details && merchandise_details.Images?.length) {
                                foundImage = merchandise_details.Images.find(item => item.is_thumbnail);
                                if(!foundImage) foundImage = merchandise_details.Images[0]
                            }
                            let service = this.services.find(s => s.product_id === p.id);
                            this.$set(p, 'qty', service ? service.qty: p.qty ? p.qty : 1);  
                            this.$set(p, 'image', foundImage);  
                            this.$set(p, 'service_price', service ? service.price : p.price);
                            this.$set(p, 'Service', service || null);
                            if (this.merchandiseSettings) {
                                this.$set(p, 'negative_inventory', p.negative_inventory ? true : false)
                                this.$set(p, 'stock', p.summary_inventory && p.summary_inventory.remaining_inventory > 0 ? p.summary_inventory.remaining_inventory : 0)
                            }
                            return p;
                        });
                    }
                    this.feesProducts = responses[1].products && responses[1].products.length ? responses[1].products
                    .filter(p => p.default_type === 'late')
                    .map(p => {
                        let service = this.services.find(s => s.product_id === p.id);
                        this.$set(p, 'activated', !!service);
                        this.$set(p, 'qty', service ? service.qty: p.qty ? p.qty : 1);
                        this.$set(p, 'service_price', service ? service.price : p.price);
                        this.$set(p, 'recurring', service ? service.recurring: p.recurring ? p.recurring : null);
                        this.$set(p, 'prorate', service ? service.prorate: null );
                        this.$set(p, 'prorate_out', service ? service.prorate_out: null );
                        this.$set(p, 'start_date', service ? service.start_date: moment().format('YYYY-MM-DD') );
                        this.$set(p, 'Service', service || {});
                        return p;
                    }) : [];
                })
                .catch(error => {
                    console.error(error)
                })
                .finally(() => {
                    this.isfetching  = false;
                })
            },
            showDialogEvent(params) {
                this.dialogType = params;
                this.productsDialog = true;
            }
        },
        mounted(){
            this.contact.Lead = this.lead
        }
    }
</script>

<style scoped>
    .tab-container{
      padding: 0 24px;
      background: none;
      border-bottom: 0px solid #E1E6EA
    }
    .new-tenant-walkthrough .v-tabs,
    .new-tenant-walkthrough .v-tabs .v-tabs-bar{
      background: #F9FAFB;
    }
    .new-tenant-walkthrough-footer{
        /* padding: 3px 0 3px 0; */
        background: #FFFFFF;
        border-top: 1px solid #e2e2e2;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 100;
        left: 0;
        right: 0;
    }
    .services-section{
        padding: 20px 24px 100px 24px;
    }

    .unit-confirm-icon .v-list-item__icon {
        text-align: center;
        background: white;
        padding: 25px;

    }

    .v-expansion-panel-content .form-label{
        background: #F4F6F8;
        padding: 20px;
        font-weight: bold;
    }

    .products-counter {
        background-color: #F26500;
        color: #FFFFFF;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        text-align: center;
        padding: 2px 6px;
        border-radius: 50px;
        display: inline-block;
        margin-left: 8px;
    }

</style>
