<template>
  <div class="hb-text-night">
    <div v-for="ins in insurances" :key="ins.id">
      <hb-radio
        v-if="showRadioButton"
        :value="ins"
        :name="ins.id"
        :id="ins.id"
      >
        <template v-slot:content>
          <TableRateLabel
            :insurances="insurances"
            :isSelected="isSelected"
            @updateCoverageValue="updateCoverageValue"
          />
        </template>
      </hb-radio>
      <div v-if="!showRadioButton">
        <TableRateLabel
          :insurances="insurances"
          :isSelected="isSelected"
          @updateCoverageValue="updateCoverageValue"
        />
      </div>
    </div>
    <div :class="['table-rate-tiers','mb-4', { 'full-width': fullWidth }, { 'tiers-small-font': tiersSmallFont}]">
      <v-row no-gutters v-for="(item, index) in insurances[0]?.TableRateTiers" :key="index">
        <v-col class="hb-text-light" cols="6">${{ item.coverage_limit_start }} - ${{ item.coverage_limit }}</v-col>
        <v-col class="hb-text-light coverage-rate" cols="6">${{ item.premium_rate }} per ${{ item.coverage_unit }} Coverage</v-col>
      </v-row>
    </div>
  </div>
</template>

<script type="text/babel">
  import TableRateLabel from './TableRateLabel.vue';

  export default {
    name: "TableRateInsurance",
    components: {
      TableRateLabel
    },
    methods: {
      updateCoverageValue(coverageValue){
        coverageValue *= (this.insurances.length ? this.insurances[0]?.TableRateTiers[0]?.coverage_unit : 1000);
        this.$emit('updateCoverageValue', coverageValue);
      }
    },
    props: {
      insurances: {
        type: Array,
        required: true
      },
      isSelected: {
        type: Boolean,
        required: true
      },
      fullWidth: {
        type: Boolean,
        default: false
      },
      showRadioButton: {
        type: Boolean,
        default: true
      },
      tiersSmallFont: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style scoped>
  .table-rate-tiers {
    border: 1px solid #DFE3E8;
    padding: 1.2rem;
    border-radius: 4px;
    width: 80%;
    font-size: 13px;
  }
  .coverage-rate {
    display: flex;
    justify-content: flex-end;
  }
  .full-width {
    width: 100% !important
  }
  .tiers-small-font {
    font-size: 11px;
    font-weight: 500;
  }
</style>