import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import  StatusMessages from '../plugins/StatusMessages.js';

Vue.use(VueRouter);
// import ActivityRoutes from './activityRoutes.js';
// import ApplicationRoutes from './applicationRoutes.js';
// import ChatRoutes from './chatRoutes.js';
// import DocumentRoutes from './documentRoutes.js';
// import EmbeddedRoutes from './embeddedRoutes.js';
// import EventRoutes from './eventRoutes.js';

import AdminRoutes from './adminRoutes.js';
import BaseRoutes from './baseRoutes.js';
import BillingRoutes from './billingRoutes.js';
import DashboardRoutes from './dashboardRoutes.js';
import InvoiceRoutes from './invoiceRoutes.js';
import ContactRoutes from './contactRoutes.js';
import DesignSystemRoutes from './designSystemRoutes.js';
// import LeaseRoutes from './leaseRoutes.js';
import MaintenanceRoutes from './maintenanceRoutes.js';
import MessageRoutes from './leadRoutes.js';
import PaymentRoutes from './paymentRoutes.js';
import PropertyRoutes from './propertyRoutes.js';
import ReportsRoutes from './reportRoutes.js';
import ReservationRoutes from './reservationRoutes.js';
import SettingsRoutes from './settingsRoutes.js';
import SigningRoutes from './signingRoutes.js';
import TenantRoutes from './tenantRoutes.js';
import UnitRoutes from './unitRoutes.js';
import UserRoutes from './userRoutes.js';
// import RentManagement from './rateManagement.js'
import RateManagement from './revenueManagement/rateManagement.js'
import PromotionManagement from './revenueManagement/promotionManagement.js'
import RentManagement from './revenueManagement/rentManagement.js'
import Directrefund from './directrefunds.js';
import DownloadCenter from './downloadCenter.js'
import AdvancedPurchaseWindow from './advancedPurchaseWindow.js'
import NoticeManager from './noticeManager.js'
import CommunicationCenter from './communicationCenter.js';
import OnBoardingRoutes from './onBoardingRoutes.js';
import Accounting from './accountingRoutes.js';
import CloseOfDay from './closeOfDayRoutes.js';
import RouteLibrary from './reportLibraryRoutes.js';
import OneTimePaymentRoutes from './oneTimePaymentRoutes';
import powerBI from './powerBI.js'
import nationalAccountRoutes from './nationalAccountRoutes.js';
import phoneContactRoutes from './phoneContactRoutes.js';
import merchandiseManagement from './merchandiseManagement.js';
import audits from './audits.js';

const routes = [].concat(
    // ActivityRoutes,
    AdminRoutes,
    // ApplicationRoutes,
    BaseRoutes,
    BillingRoutes,
    ContactRoutes,
    DesignSystemRoutes,
    // ChatRoutes,
    CommunicationCenter,
    OneTimePaymentRoutes,
    DashboardRoutes,
    // DocumentRoutes,
    // EmbeddedRoutes,
    // EventRoutes,
    InvoiceRoutes,
    // LeaseRoutes,
    MessageRoutes,
    MaintenanceRoutes,
    PaymentRoutes,
    PropertyRoutes,
    ReportsRoutes,
    ReservationRoutes,
    UnitRoutes,
    SettingsRoutes,
    SigningRoutes,
    TenantRoutes,
    UserRoutes,
    RateManagement,
    Directrefund,
    RentManagement,
    DownloadCenter,
    AdvancedPurchaseWindow,
    NoticeManager,
    OnBoardingRoutes,
    Accounting,
    CloseOfDay,
    RouteLibrary,
    PromotionManagement,
    powerBI,
    nationalAccountRoutes,
    phoneContactRoutes,
    merchandiseManagement,
    audits
);


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});


router.beforeEach( async (to, from, next) => {
  // Set Active Route in Store
  store.dispatch('authenticationStore/activeRoute', to);
  // Update token from local storage and company id for further use.
  await store.dispatch('authenticationStore/updateAuth');

  // If route needs to be authenticated
  
  if (to.matched.some(record => record.meta.requiresAuth)) {
    
    // if we are not loged in need to redirect to login page
    
    if(!store.getters['authenticationStore/checkAuth']){

      await store.dispatch('statusStore/setStatus', {
        status: false,
        message: "You are not logged in. Please log in to continue"
      });

      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });

    } else { // we are login in need to check user type and redirect accordingly

      const contact_roles = JSON.parse(localStorage.getItem("contact_roles"))

      if(!(to.meta.hasAccess.some(type => contact_roles?.includes(type)))){
        await store.dispatch('statusStore/setStatus', {
          status: false,
          message: "You are not authorized to access that location"
        });

        if(contact_roles?.includes('admin')){
          return await router.replace('/dashboard');
        } else if(contact_roles?.includes('tenant')) {
          return await router.replace('/leases');
        }
      }
      next();
    }
  } else { // if route didnt needs to be authenticated

    // if we forcely route to login manually and we are already authenticated then redirect to dashboard
    if(to.name === 'Login' && store.getters['authenticationStore/checkAuth']){

      const contact_roles = JSON.parse(localStorage.getItem("contact_roles"))
      
      if(contact_roles?.includes('admin')){
        next({
          path: '/dashboard'
        });
      } else if(contact_roles?.includes('tenant')) {
        next({
          path: '/my-account'
        });
      } else {
        next();
      }
    } else { // else remains on login page
      next();
    }
  }
});


router.afterEach(async (to,from,failure) => {
  try{

    // i am executing whenever someone reloads the page in order to re-set the previous version of state
    // restrict the user to not get loged in user details if user reloads login page again and again

    if(to.name != 'Login' && to.name != 'Logout' && store.getters['authenticationStore/checkAuth']){
      await store.dispatch('authenticationStore/getLoggedInUser');
    }

  }catch(err){
    // do nothing
  }
});

export default router
