<template>
  <hb-expansion-panel
    key="freeCoverage"
    :left-cols="8"
    :right-cols="4"
  >
    <template v-slot:title>
      Free Coverage Period
      <span
        class="pl-3"
        @click.stop
      >
        <hb-checkbox
          v-if="isPropertyLevel"
          v-model="insuranceData.useCorporateDefaults"
          @change="handleUseCorporateDefaults"
          :readonly="!insuranceData.is_found"
          label="Use Corporate Default"
        ></hb-checkbox>
      </span>
    </template>
    <template v-slot:actions>
      <span @click.stop>
        <hb-switch
          class="mx-2"
          v-model="insuranceData.is_enabled"
          @change="handleFreeCoverageToggle($event)"
        />
      </span>
    </template>

    <template v-slot:content>
      <hb-form
        v-if="coverageType === INSURANCE.TYPES.LIBRARY.KEY"
        label="Maximum Premium Threshold"
        description="Enter maximum Free Coverage premium threshold in dollars"
        full
      >
        <template v-slot:tooltipBody> tooltip for premium threshold </template>
        <HbTextField
          box
          condensed
          medium
          hide-details
          id="premium_threshold"
          name="premium_threshold"
          v-model="insuranceData.premiumThreshold"
          v-validate="'required|max:32|numeric'"
          data-vv-name="premiumThreshold"
          data-vv-as="Premium Threshold"
          :error="errors.has('premium_threshold')"
          @input="errors.clear('premium_threshold')"
        >
          <template v-slot:prepend-inner> $ </template>
        </HbTextField>
      </hb-form>
      <hb-form
        v-else
        label="Maximum Premium Threshold"
        description="Enter maximum Free Coverage premium threshold in dollars or based on coverage values."
        full
      >
        <template v-slot:tooltipBody> Maximum Free Coverage premium threshold in dollars or based on coverage values </template>
        <hb-radio-group
          row
          v-model="insuranceData.premiumThresholdType"
          v-if="isTableRateInsuranceSaved"
        >
          <hb-radio value="premium">
            <template v-slot:label> Dollars </template>
            <template v-slot:tooltipBody> Maximum amount in dollars that will be discounted from insurance premium </template>
          </hb-radio>
          <hb-radio value="coverage">
            <template v-slot:label> Coverage </template>
            <template v-slot:tooltipBody> Maximum coverage of insurance, against which the premium will be discounted </template>
          </hb-radio>
        </hb-radio-group>
        <HbTextField
          v-if="insuranceData.premiumThresholdType === 'premium'"
          box
          condensed
          medium
          hide-details
          id="premium_threshold"
          name="premium_threshold"
          v-model="insuranceData.premiumThreshold"
          v-validate="'required|max:32|numeric'"
          data-vv-name="premiumThreshold"
          data-vv-as="Premium Threshold"
          :error="errors.has('premium_threshold')"
          @input="errors.clear('premium_threshold')"
        >
          <template v-slot:prepend-inner> $ </template>
        </HbTextField>
        <v-row
          class="pl-4"
          v-else
        >
          <HbTextField
            box
            condensed
            small
            hide-details
            id="premium_threshold"
            name="premium_threshold"
            v-model="insuranceData.premiumThreshold"
            v-validate="'required|max:32|numeric'"
            data-vv-name="premiumThreshold"
            data-vv-as="Premium Threshold"
            :error="errors.has('premium_threshold')"
            @input="errors.clear('premium_threshold')"
          >
            <template v-slot:prepend-inner> $ </template>
          </HbTextField>
          <div class="hb-inline-sentence-text">,000</div>
        </v-row>
      </hb-form>
      <hb-form
        label="Minimum Invoice Generation Threshold"
        description="Define the numbers of days before generating the next invoice after the Free Coverage Period has
                    been initiated"
        full
      >
        <template v-slot:tooltipBody> Numbers of days before generating the next invoice after the Free Coverage Period has been initiated </template>
        <HbTextField
          box
          condensed
          x-small
          hide-details
          id="invoice_days_threshold"
          name="invoice_days_threshold"
          v-model="insuranceData.invoiceDaysThreshold"
          v-validate="'required|max:32|numeric'"
          data-vv-name="invoice_days_threshold"
          data-vv-as="Invoice Days Threshold"
          @input="errors.clear('invoice_days_threshold')"
          :error="errors.has('invoice_days_threshold')"
        >
        </HbTextField>
      </hb-form>
      <space-selector
        :selectedSpaceTypes="insuranceData.selectedSpaceTypes"
        @spaceTypeUpdate="spaceTypesUpdated"
      >
      </space-selector>
      <hb-bottom-action-bar
        v-if="isPropertyLevel ? !insuranceData.useCorporateDefaults : true"
        @close="handleCancel"
      >
        <template v-slot:right-actions>
          <hb-btn @click="saveFreeInsurance">Save</hb-btn>
        </template>
      </hb-bottom-action-bar>
    </template>
  </hb-expansion-panel>
</template>

<script type="text/babel">
import { mapGetters, mapActions } from "vuex";
import { notificationMixin } from "../../../mixins/notificationMixin.js";
import SpaceSelector from "./SpaceSelector.vue";
import INSURANCE from "@/constants/insurance.js";
export default {
  name: "FreeInsurance",
  mixins: [notificationMixin],
  props: {
    activeTab: {
      type: String,
      default: "",
    },
    level: {
      type: String,
      default: "",
    },
    coverageType: {
      type: String,
      default: "",
    },
    isOPS: {
      type: Boolean,
      default: false,
    },
    insurance: {
      required: true
    },
    savedInsuranceTemplates: {
      required: true
    },
  },
  data() {
    return {
      unitType: {},
      confirmUncheckCorporate: false,
      insuranceData: {}
    };
  },
  async created() {
    this.INSURANCE = INSURANCE;
    this.insuranceData = JSON.parse(JSON.stringify(this.insurance))
  },
  components: {
    SpaceSelector,
  },
  computed: {
    ...mapGetters({
      defaultProperty: "propertiesStore/defaultProperty",
      properties: "propertiesStore/filtered",
      hasRolePermission: "authenticationStore/rolePermission",
      unitTypes: "filterStore/unit_type",
    }),
    showSaveButton() {
      if (this.level === "property") return !this.insurance.useCorporateDefaults && this.insuranceData.is_found;
      return true;
    },
    isPropertyLevel() {
      return this.level === "property";
    },
    isTableRateInsuranceSaved() {
      return this.savedInsuranceTemplates[this.activeTab] && this.savedInsuranceTemplates[this.activeTab].InsuranceTemplate.type === INSURANCE.TableRate;
    },
  },

  watch: {
    insurance: {
      handler(val){
       this.insuranceData = val;
     },
     deep: true
    },
  },

  methods: {
    ...mapActions({
      setDefaultProperty: "propertiesStore/setDefaultProperty",
    }),
    async validateOpenForm() {
      let status = await this.$validator.validateAll();
      if(!this.insuranceData.selectedSpaceTypes || (this.insuranceData.selectedSpaceTypes && this.insuranceData.selectedSpaceTypes.length < 1)){
        status = false;
        this.showMessageNotification({ description: 'Space Types can not be empty' });
      }
      return status;
    },
    spaceTypesUpdated(types){
      this.$emit('spaceTypesUpdated', types);
    },
    async saveFreeInsurance() {
      let status = await this.validateOpenForm();
      if (!status) return;
      this.$emit("saveFreeInsurance", this.insuranceData);
    },
    handleCancel() {
      this.$emit("handleCancelInsurance");
    },
    handleFreeCoverageToggle(value) {
      this.$emit("handleFreeCoverageToggle", value || false);
    },
    handleUseCorporateDefaults(value) {
      if (value == null || !value) {    
        this.$validator.validateAll();    
        this.$emit("handleUseCorporateDefaults", value);
      }
      if (value) {
        this.confirmUncheckCorporate = true;
      }
    },
  },
};
</script>

<style lang="scss"></style>
