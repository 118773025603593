export default Object.freeze({
    THOUSAND: 1000,
    TableRate: 'table_rate',
    Library: 'library',
    Settings: 'Settings',
    TYPES: {
        LIBRARY: {
            KEY: 'library',
            LABEL: 'Library'
        },
        TABLE_RATE: {
            KEY: 'table_rate',
            LABEL: 'Table Rate'
        },
    },
    FREE_INSURANCE_TYPES: {
        PREMIUM: {
            KEY: 'premium',
            LABEL: 'Dollars'
        },
        COVERAGE: {
            KEY: 'coverage',
            LABEL: 'Coverage'
        }
    },
    
    
});