<template>
  <div class="change-billing-day-main">
    <div style="padding: 15px 15px 0;" v-if="errorHas($options.name) || successHas($options.name)">
      <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)"
        status="error"></status>
      <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)"
        :message="successGet($options.name)" status="success"></status>
    </div>
    <div class="breadcrumb-generation">
      <hb-breadcrumb @click="backToMenu">
        <span class="back-text-communication">
          Billing Day Change
        </span>
      </hb-breadcrumb>
    </div>
    <div class="billing-day-panel-main">
      <div class="billing-day-panel">
        <div class="box-communication-first">
          <p class="mb-0">Choose a new billing day for the selected leases.</p>
        </div>
        <div>
          <v-select
            :items="daysOfMonth"
            v-model="new_billing_day"
            label="New Billing Day"
            class="pl-4"
            id="new_bill_day"
            name="new_bill_day"
            hide-details
          >
            <template v-slot:selection="{ item }">
              {{ item | dayEnding }} of the month
            </template>
            <template v-slot:item="{ item }">
              {{ item | dayEnding }}
            </template>
          </v-select>
          <v-divider v-show="true"></v-divider>
          <p v-if="true" class="pl-4 pr-4 pt-4 pb-2 mb-0">
            Review the details of selected leases.
          </p>
          <v-container class="pt-2 pb-0 pl-4 pr-4" v-show="true">
          <hb-data-table :loading="!items.length > 0" fixed-header :height="computedTableHeight" disable-sort
            :headers="headers" :items="items" style="overflow-y: auto;">
            <template v-slot:[`item.tenant_name`]="{ item }">
              <span v-if="item.tenant_name">{{ item.tenant_name }}</span>
              <v-alert v-else style="font-size: 14px;" dense flat text class="ma-0" type="error">
                Tenant Name missing
              </v-alert>
            </template>
            <template v-slot:[`item.unit_number`]="{ item }">
              <span v-if="item.unit_number">{{ item.unit_number }}</span>
              <v-alert v-else style="font-size: 14px;" dense flat text class="ma-0" type="error">
                Space Number Missing
              </v-alert>
            </template>
            <template v-slot:[`item.lease_bill_day`]="{ item }">
              <span v-if="item.lease_bill_day">{{ item.lease_bill_day }}</span>
              <v-alert v-else style="font-size: 14px;" dense flat text class="ma-0" type="error">
                Lease Billing Day Missing
              </v-alert>
            </template>
            <template v-slot:[`item.lease_bill_day_new`]="{ item }">
              <span v-if="new_billing_day">
                {{ new_billing_day }}
              </span>
              <v-alert v-else style="font-size: 14px;" dense flat text class="ma-0" type="error">
                New Billing Day Missing
              </v-alert>
            </template>
            <template v-slot:footer>
              <div class="pa-2 custom-footer">
                Total Leases: {{ items.length }}
              </div>
            </template>
          </hb-data-table>
        </v-container>
        </div>
      </div>

      <div class="footer-action-bar">
        <hb-bottom-action-bar v-if="true" @close="backToMenu()">
          <template v-slot:right-actions>
            <hb-btn color="primary" :loading="confirmLoading" @click="showConfirmDialog" :disabled="alreadySameBillDay">
              Confirm
            </hb-btn>
          </template>
        </hb-bottom-action-bar>
      </div>
    </div>


    <hb-modal size="medium" title="Confirmation" v-model="confirmPopup" confirmation>
      <template v-slot:content>
        <div class="py-4 px-6">
          You are about to update the billing day for the selected leases. Please note the following:
          <br /><br />
          <ul>
            <li>
              <strong>Stop-gap invoices</strong> will be created for the affected leases to ensure accurate billing during this transition.
            </li>
            <br />
            <li>
              The <strong>billing day</strong> will be updated for all selected leases, and the default billing day of the tenants will be adjusted to {{ new_billing_day | dayEnding }} of every month.
            </li>
            <br v-if="showSameBillingDayMessage"/>
            <li v-if="showSameBillingDayMessage">
              You have selected one or more leases that already have same billing day assigned. This action will have no effect on them.
            </li>
          </ul>
          <br />
          Are you sure you want to proceed ?
        </div>
      </template>
      <template v-slot:left-actions>
      </template>
      <template v-slot:right-actions>
        <hb-btn color="primary" :loading="confirmLoading" @click="changeBillDay()">Yes</hb-btn>
      </template>
    </hb-modal>
  </div>
</template>

<script>

import Status from '../Messages.vue';
import Loader from '../../assets/CircleSpinner.vue';
import { mapGetters, mapActions } from 'vuex';
import api from '../../../assets/api.js';
import { notificationMixin } from '../../../mixins/notificationMixin.js';
import { calendarMixin } from "../../../mixins/calendarMixin";
import { EventBus } from "../../../EventBus.js";

export default {
  name: "BulkBillingDayChange",
  mixins: [notificationMixin, calendarMixin],
  data() {
    return {
      headers: [
        { text: 'Tenant', value: 'tenant_name' },
        { text: 'Space#', value: 'unit_number' },
        { text: 'Current Billing Day', value: 'lease_bill_day' },
        { text: 'New Billing Day', value: 'lease_bill_day_new' }
      ],

      new_billing_day: 1,
      confirmLoading: false,

      confirmPopup: false,
    }
  },
  props: ['items'],
  async mounted() {
  },
  components: {
    Status,
    Loader,
  },
  computed: {
    ...mapGetters({
    }),
    isDisabled() {
      return false;
    },
    ...mapGetters({
      bulk_edit_items: 'bulkEditStore/items',
      property: 'propertiesStore/filtered',
    }),
    computedTableHeight() {
      const maxHeight = 300;
      const rowHeight = 48;
      const headerHeight = 48;

      const calculatedHeight = (this.items.length * rowHeight) + headerHeight;

      return `${Math.min(calculatedHeight, maxHeight)}px`;
    },
    showSameBillingDayMessage(){
      return this.items.some(i => i.lease_bill_day == this.new_billing_day);
    },
    alreadySameBillDay(){
      return this.items.every(i => i.lease_bill_day == this.new_billing_day);
    }
  },
  watch: {},
  methods: {
    ...mapActions({
      resetAction: 'bulkEditStore/resetAction',
    }),
    async changeBillDay() {
      try{
        this.confirmLoading = true;
        let payload = {
          new_bill_day: this.new_billing_day,
          dryrun: false,
          lease_ids: this.items.map(i => i.lease_id),
          property_id: this.property[0].id
        };
        await api.post(this, api.LEASES + "bulk-change-bill-day", payload).then(() => {
            const payload = {
              type: 'bulk_leases_update',
              title: 'Updating Billing Day',
              persistent: true,
              loading: true,
              description: 'Billing day change process has been initiated for the selected leases.',
              progress: true
            }
            this.showMessageNotification(payload);
          }).catch((error) => {
            console.log(error);
            this.showMessageNotification({
              type: "error",
              description:
                "Unable to change billing day of selected leases.",
            });
          });
      } catch(err) {
        console.log("Failed to change billing day of leases -> ",err)
      } finally {
        this.confirmLoading = false;
        this.confirmPopup = false;
        this.$emit('close-bulk-edit');
      }
    },
    async showConfirmDialog() {
      this.confirmPopup = true;
    },
    backToMenu() {
      EventBus.$emit('disablecheckbox:report', false);
      this.$emit('back')
    },
  }
}
</script>

<style scoped lang="scss">
.change-billing-day-main {
  height: 100%;

  .billing-day-panel-main {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: var(--Primary-Grayscale-White, #FFF);

    .billing-day-panel {
      flex: 1;
      // overflow-y: auto;
    }

    .footer-action-bar {
      height: 60px;
      position: sticky;
      bottom: 0;
      width: 100%;
    }
  }

  .breadcrumb-generation ::v-deep a.hb-link i.mdi-chevron-left {
    color: #101318 !important;
  }

  .breadcrumb-generation {
    padding: 16px;
    background: var(--Primary-Grayscale-White, #FFF);
  }

  .back-text-communication {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: #101318;
    display: block;
  }
}

.box-communication-first {
  border-top: 1px solid rgba(221, 223, 224, 0.575);
  border-bottom: 1px solid rgba(221, 223, 224, 0.575);
  border-left: none;
  border-right: none;
  padding: 16px;
}

.custom-footer {
  border: 1px solid rgb(223, 227, 232);
  font-weight: 500;
}
</style>
