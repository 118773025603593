<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<!-- WARNING!!!! ****** DO NOT EDIT THIS FILE FOR ANY REASON OR YOU WILL BE EJECTED INTO SPACE ****** WARNING!!!!-->
<template>
    <div
        class="hb-aviary-form-wrapper hb-aviary-form-row"
        :class="{'hb-aviary-form-auto-layout-on' : autoLayout}"
        v-resize="calculateWidth"
        ref="hbForm"
    >
        <v-row no-gutters :style="last || contentPosition ? 'min-height:' + dynamicHeight + 'px' : ''">

            <v-col class="hb-aviary-form-label hb-aviary-form-padding font-weight-medium" :class="labelWidth">
                <span v-if="tooltipHeader || tooltipBody || $slots.tooltipHeader || $slots.tooltipBody">
                    <v-row no-gutters>
                        <v-col cols="10">
                            {{ label }}{{required ? '*' : ''}}
                        </v-col>
                        <v-col cols="2" align="right" style="margin-top: -1.5px;">
                            <hb-tooltip v-if="(tooltipHeader || $slots.tooltipHeader) && (tooltipBody || $slots.tooltipBody)">
                                <template v-slot:header>
                                    <span v-if="$slots.tooltipHeader">
                                        <slot name="tooltipHeader"></slot>
                                    </span>
                                    <span v-else-if="tooltipHeader">
                                        {{ tooltipHeader }}
                                    </span>
                                </template>
                                <template v-slot:body>
                                    <span v-if="$slots.tooltipBody">
                                        <slot name="tooltipBody"></slot>
                                    </span>
                                    <span v-else-if="tooltipBody">
                                        {{ tooltipBody }}
                                    </span>
                                </template>
                            </hb-tooltip>
                            <hb-tooltip v-else-if="(!tooltipHeader && !$slots.tooltipHeader) && (tooltipBody || $slots.tooltipBody)">
                                <template v-slot:body>
                                    <span v-if="$slots.tooltipBody">
                                        <slot name="tooltipBody"></slot>
                                    </span>
                                    <span v-else-if="tooltipBody">
                                        {{ tooltipBody }}
                                    </span>
                                </template>
                            </hb-tooltip>
                            <hb-tooltip v-else-if="(tooltipHeader || $slots.tooltipHeader) && (!tooltipBody && !$slots.tooltipBody)">
                                <template v-slot:header>
                                    <span v-if="$slots.tooltipHeader">
                                        <slot name="tooltipHeader"></slot>
                                    </span>
                                    <span v-else-if="tooltipHeader">
                                        {{ tooltipHeader }}
                                    </span>
                                </template>
                            </hb-tooltip>
                        </v-col>
                    </v-row>
                </span>
                <span v-else>
                    {{ label }}{{required ? '*' : ''}}
                </span>
            </v-col>

            <v-col
                v-if="editable"
                class="hb-aviary-form-padding-content-editable-wrapper"
                :class="{ 'hb-aviary-form-editable' : hovering && !editing && !startOpen }"
                @mouseover="!editing && !startOpen ? hovering = true : ''"
                @mouseleave="!editing && !startOpen ? hovering = false : ''"
                :style="'max-width:' + (formWidth - computedLabelWidth) + 'px'"
            >

                <div
                    v-if="$slots.before"
                    @click="edit(beforeClickable)"
                    class="hb-form-edit-mode-main-padding-before"
                    :class="{'hb-form-editable-hover-cursor-default' : !beforeClickable}"
                >
                    <slot name="before"></slot>
                </div>

                <div
                    v-if="description"
                    @click="edit(descriptionClickable)"
                    class="hb-form-description-text-night-light hb-form-edit-mode-main-padding-description"
                    :class="{'hb-form-editable-hover-cursor-default' : !descriptionClickable}"
                >
                    {{description}}
                </div>

                <div v-if="(editing && active === label) || startOpen" class="hb-form-edit-mode-main-padding" no-gutters :class="{ 'hb-aviary-form-input-370-max-width' : !multiple && !full }">
                    <div class="hb-aviary-input-half-pixel-fix hb-aviary-form-slot-wrapper">
                        <slot name="edit"></slot>
                    </div>
                    <div v-if="!hideActions" cols="12" class="hb-avairy-editable-save-wrapper d-flex align-center">
                        <hb-btn color="primary" small @click="save" :disabled="disableSave">Save</hb-btn>
                        <hb-link class="ml-2" @click="cancel" use-default-click-behavior>Cancel</hb-link>
                    </div>
                </div>
                <v-row
                    v-else
                    @click="edit"
                    no-gutters
                    class="hb-form-edit-mode-main-padding"
                    :style="last || contentPosition ? 'min-height:' + dynamicHeight + 'px' : !$slots.after ? 'height:100%' : ''"
                >
                    <v-col
                        cols="12"
                    >
                        <span v-if="!empty">
                            <v-row no-gutters>
                                <v-col cols="11" class="hb-aviary-form-text-night hb-aviary-form-slot-wrapper-display-spacing">
                                    <slot name="display"></slot>
                                </v-col>
                                <v-col cols="1" align="right">
                                    <hb-tooltip bottom>
                                        <template v-slot:item>
                                            <v-hover v-slot:default="{ hover }">
                                                <span>
                                                    <hb-icon v-if="hovering || $vuetify.breakpoint.xs" small :color="hover ? '#101318' : ''" style="position:absolute;right:20px">mdi-pencil</hb-icon>
                                                </span>
                                            </v-hover>
                                        </template>
                                        <template v-slot:body>
                                            Edit
                                        </template>
                                    </hb-tooltip>
                                </v-col>
                            </v-row>
                        </span>
                        <span v-else>
                            <v-row no-gutters style="height:100%;">
                                <hb-link @click="edit">Click to Add</hb-link>
                            </v-row>
                        </span>
                    </v-col>
                    
                </v-row>

                <div
                    v-if="$slots.after"
                    @click="edit(afterClickable)"
                    class="hb-form-edit-mode-main-padding-after"
                    :class="{'hb-form-editable-hover-cursor-default' : !afterClickable}"
                >
                    <slot name="after"></slot>
                </div>
            </v-col>

            <v-col class="hb-aviary-form-padding-content" v-else :style="'max-width:' + (formWidth - computedLabelWidth) + 'px'">

                <div v-if="$slots.before">
                    <slot name="before"></slot>
                </div>

                <div v-if="description" class="hb-form-description-text-night-light mt-0 pt-0 mb-4">{{description}}</div>

                <div class="hb-aviary-form-slot-wrapper" :class="{ 'hb-aviary-form-input-370-max-width' : !multiple && !full }">
                    <slot></slot>
                    <span class="hb-aviary-form-slot-wrapper-display-spacing">
                        <slot name="display"></slot>
                    </span>
                </div>

                <div v-if="$slots.after">
                    <slot name="after"></slot>
                </div>
                
            </v-col>

        </v-row>
        <v-divider v-if="divider && !last"></v-divider>
    </div>
</template>
<script type="text/babel">

    import HbBtn from './HbBtn.vue';
    import HbIcon from './HbIcon.vue';
    import HbLink from './HbLink.vue';
    import HbTooltip from './HbTooltip.vue';
    
    export default {
        name: "HbForm",
        data: function() {
            return {
                editing: false,
                hovering: false,
                // dynamicHeight: 10000,
                dynamicHeight: 168,
                formWidth: 0,
                computedLabelWidth: 150,
            };
        },
        components: {
            HbBtn,
            HbIcon,
            HbLink,
            HbTooltip
        },
        created(){
            this.$nextTick(()=>{
                this.calculateWidth();
            });
            setTimeout(() => {
                this.calculateWidth();
                if(this.$refs.hbForm){
                    this.calculatePosition();
                }
            }, 500);
            setTimeout(() => {
                this.calculateWidth();
            }, 1000);
            /*
            setTimeout(() => {
                if(!this.contentPosition){
                    this.dynamicHeight = 168;
                }
            }, 1500);
            */
        },
        computed: {
            labelWidth(){
                if(this.small){
                    this.computedLabelWidth = '100';
                    return 'hb-aviary-form-label-100';
                } if(this.medium){
                    this.computedLabelWidth = '125';
                    return 'hb-aviary-form-label-125';
                } if(this.large){
                    this.computedLabelWidth = '175';
                    return 'hb-aviary-form-label-175';
                } if(this.extraLarge){
                    this.computedLabelWidth = '200';
                    return 'hb-aviary-form-label-200';
                } else {
                    this.computedLabelWidth = '150';
                    return 'hb-aviary-form-label-150';
                }
            }
        },
        methods: {
            edit(clickable){
                if(clickable !== undefined && ((clickable && ((this.editing && this.active === this.label) || this.startOpen)) || !clickable)) {
                    return
                }
                this.editing = true;
                this.$emit('update:active', this.label);
                this.$emit('editStart', this.label);
            },
            save(){
                this.$emit('save', this.label);
                this.calculatePosition();
            },
            cancel(){
                this.editing = false;
                this.hovering = false;
                this.$emit('cancel', this.label);
                this.$emit('update:active', '');
                this.calculatePosition();
            },
            calculatePosition(){
                if(this.contentPosition){
                    var position = this.$refs.hbForm.getBoundingClientRect();
                    this.dynamicHeight = this.contentPosition.top - position.top + this.contentPosition.height;
                }
            },
            calculateWidth(){
                var mainRef = this.$refs.hbForm;

                if(mainRef){
                    this.formWidth = this.$refs.hbForm.offsetWidth;
                }
            }
        },
        props: {
            label: {
                type: String,
                required: true
            },
            small: {
                type: Boolean,
                default: false
            },
            medium: {
                type: Boolean,
                default: false
            },
            large: {
                type: Boolean,
                default: false
            },
            extraLarge: {
                type: Boolean,
                default: false
            },
            active: {
                type: String,
                required: false
            },
            empty: {
                type: Boolean,
                default: false
            },
            required: {
                type: Boolean,
                default: false
            },
            tooltip: {
                type: Boolean,
                default: false
            },
            tooltipHeader: {
                type: String,
                required: false
            },
            tooltipBody: {
                type: String,
                required: false
            },
            editable: {
                type: Boolean,
                default: false
            },
            requireValidation: {
                type: Boolean,
                default: false
            },
            passedValidation: {
                type: Boolean,
                default: false,
            },
            description:{
                type: String,
                default: undefined
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            full: {
                type: Boolean,
                default: false,
            },
            startOpen: {
                type: Boolean,
                default: false,
            },
            hideActions: {
                type: Boolean,
                default: false,
            },
            divider: {
                type: Boolean,
                default: true
            },
            beforeClickable: {
                type: Boolean,
                default: false
            },
            descriptionClickable: {
                type: Boolean,
                default: true
            },
            afterClickable: {
                type: Boolean,
                default: false
            },
            last: {
                type: Boolean,
                default: false
            },
            autoLayout: {
                type: Boolean,
                default: true
            },
            contentPosition: {

            },
            disableSave: {
                type: Boolean,
                default: false
            }
        },
        watch:{
            'active'(){
                if((this.active === 'saved' || this.active === '') && this.editing){
                    this.editing = false;
                    this.hovering = false;
                } else if((this.active !== this.label) && this.editing){
                    this.$emit('cancel', this.label);
                    this.editing = false;
                    this.hovering = false;
                }
            },
            'contentPosition'(){
                if(this.contentPosition){
                    this.calculatePosition();
                }
            }
        }
    }
</script>

<style scoped>

.hb-aviary-form-row {
    font-size: 14px;
}
.hb-aviary-form-label {
    color: #101318;
    background: #F4F6F8;
    line-height: 1.3;
}
.hb-aviary-form-label-100 {
    min-width: 100px;
    max-width: 100px;
}
.hb-aviary-form-label-125 {
    min-width: 125px;
    max-width: 125px;
}
.hb-aviary-form-label-150 {
    min-width: 150px;
    max-width: 150px;
}
.hb-aviary-form-label-175 {
    min-width: 175px;
    max-width: 175px;
}
.hb-aviary-form-label-200 {
    min-width: 200px;
    max-width: 200px;
}
.hb-aviary-form-padding {
    padding: 10px 16px 12px 16px;
}
.hb-aviary-form-padding-content {
    padding: 10.2px 16px 12px 16px;
    font-size: 14px;
    line-height: 1.3;
}
.hb-aviary-form-padding-content-editable-wrapper {
    font-size: 14px;
    line-height: 1.3;
}
.hb-form-edit-mode-main-padding {
    padding: 10.2px 16px 12px 16px;
}
.hb-form-edit-mode-main-padding-before {
    padding: 10.2px 16px 0px 16px;
}
.hb-form-edit-mode-main-padding-description {
    padding: 10.2px 16px 0px 16px;
}
.hb-form-edit-mode-main-padding-after {
    padding: 0px 16px 12px 16px;
}
.hb-aviary-form-editable {
    background-color: #F9FAFB;
    cursor: pointer;
}
.hb-aviary-form-text-night {
    color: #101318;
}
.hb-aviary-form-text-light {
    color: #637381;
}

.hb-aviary-form-row .v-input {
    margin: -6.5px 0 0 0;
    padding: 0;
}

.hb-aviary-form-row .v-input.v-input--radio-group {
    margin: 0;
}

.hb-aviary-form-row .v-label {
    font-size: 14px !important;
}

.hb-aviary-form-row >>> label, .hb-aviary-form-row >>> input, .hb-aviary-form-row >>> .v-select__selections, .hb-aviary-form-row >>> textarea {
    font-size: 14px;
}

.hb-aviary-form-row >>> .v-input:not(.error--text) .v-input__slot::before {
    border-color: #DFE3E8 !important;
}
.hb-aviary-form-row >>> .v-input.error--text .v-input__slot::before {
    border-color: #FB4C4C !important;
}
.hb-aviary-form-row >>> .v-input.error--text input, .hb-aviary-form-row >>> .v-input.error--text label, .hb-aviary-form-row >>> .v-input.error--text .v-select__selection, .hb-aviary-form-row >>> .v-input.error--text textarea, .hb-aviary-form-row >>> .v-label.v-label--active.error--text {
  color: #FB4C4C !important;
}
.hb-aviary-form-row >>> .v-label {
  color: #637381 !important;
}

.hb-aviary-form-row >>> .v-radio .v-label, .hb-aviary-form-row >>> .v-input--checkbox .v-label, .hb-aviary-form-row >>> .v-input--switch .v-label {
  color: #101318 !important;
}

.hb-aviary-form-row .v-textarea {
    margin-bottom: -1px !important;
}

.hb-aviary-form-row .v-textarea >>> label {
    margin-top: 0 !important;
}

.hb-aviary-form-row .custom-v-textarea-wrapper.v-textarea >>> textarea {
    margin-top: 3.5px !important;
}

.hb-aviary-form-row .v-textarea >>> .v-input__slot::before {
    bottom: 0px;
}

.hb-aviary-form-row .v-textarea >>> .v-input__slot::before:focus {
    border-top-width: 0 !important;
}

.hb-aviary-form-row textarea {
    border-width: 1px !important;
}
/*
.hb-aviary-input-half-pixel-fix {
    margin-top: -.5px;
}
*/

.hb-aviary-form-row >>> .v-input__icon--clear .v-icon.v-icon {
    font-size: 16px !important;
    color: #C4CDD5;
}

.hb-form-description-text-night-light {
    color: #637381;
}

.hb-aviary-form-input-370-max-width {
    max-width: 370px;
}

.hb-avairy-editable-save-wrapper {
    margin: 18px 0 6px 0;
}

.hb-aviary-form-auto-layout-on .hb-aviary-form-slot-wrapper * {
    margin-bottom: 12px;
}

/* This is to prevent text overflowing */
.hb-aviary-form-slot-wrapper-display-spacing {
    word-break: break-word; 
}

.hb-aviary-form-slot-wrapper-display-spacing *:last-of-type {
    margin-bottom: 4px;
}

.hb-aviary-form-wrapper.hb-aviary-form-auto-layout-on *:last-of-type {
    margin-bottom: 0;
}

.hb-form-editable-hover-cursor-default {
    cursor: default;
}

.hb-form-modal-last-entry-min-height {
    min-height: 168px;
}

.hb-aviary-form-wrapper {
    overflow: hidden;
}
    
</style>