<template>

  <v-row justify="center">
    <v-dialog v-model="dialog" class="settings-dialogs" :retain-focus="false" persistent :no-click-animation="true" fullscreen hide-overlay transition="dialog-bottom-transition" content-class="hb-settings-fullscreen">
      <v-card class="bg-colo-light b-radius-0">
        <v-app-bar dark fixed :height="$vuetify.breakpoint.mdAndUp ? '64' : ''">
            <hb-btn
                class="ml-n2"
                style="margin-top: -3px;"
                icon
                color="#ffffff"
                active-state-off
                hover-background-color="#333333"
                :mdi-icon="hasDrawer ? 'mdi-custom-main-menu' : 'mdi-custom-main-menu-arrow'"
                :tooltip="hasDrawer ? 'Hide Navigation' : 'Show Navigation'"
                @click="hasDrawer =! hasDrawer"
            />

            <v-toolbar-title class="mt-n2 ml-2 d-flex align-center">
                <div class="mr-2"><hb-icon color="#FFFFFF">mdi-settings</hb-icon></div>
                <div class="mt-1">Settings</div>
            </v-toolbar-title>

            <v-spacer></v-spacer>
            
            <hb-btn icon large-close class="mr-n2" hover-background-color="#333333" color="#ffffff" @click="emitHideSettings()">mdi-close</hb-btn>
            </v-app-bar>
            <div style="padding-left: 60px; padding-top: 64px" :class="{'pl-260px': hasDrawer && $vuetify.breakpoint.smAndUp, 'pl-0' : !$vuetify.breakpoint.smAndUp}">
                
                <app-navigation class="no-animation-setting zi-11" :isSettings="true" />
                <v-navigation-drawer
                  width="200"
                  flat
                  v-model="hasDrawer"
                  class="cms-navigation mt-60"
                  :class="{'ml-60 pt-2': $vuetify.breakpoint.smAndUp, 'no-animation-setting' : hasDrawer }"
                  disable-resize-watcher
                  clipped
                  fixed
                  elevation-0
                  mobile-breakpoint="415">
                    <div id="filter-container" class="hb-cms-nav text-capitalize filter-section">
                         <v-select
                            v-if="hasCmsExtensions && isHBUser"
                            v-model="filterInput"
                            :class="filterClass"
                            :items="filter"
                            :menu-props="{ maxHeight: '300', minWidth: '199', contentClass: 'settings-filter-menu-v-select hb-elevation-large' }"
                            placeholder="Filter"
                            multiple
                            append-icon="mdi-filter-variant"
                            attach="#filter-container"
                            outlined
                            dense
                            dark
                            >

                        <template v-slot:selection="{ item, index }">
                            <span v-if="index === 0">
                            <div v-if="filterInput.length === 1">
                                {{ item }}
                            </div>
                            <div v-else>
                                {{ item.substring(0, 5) }}
                            </div>
                            </span>
                            <span v-if="index === 1">
                            ..(+{{ filterInput.length - 1 }}
                            others)
                            </span>
                        </template>
                        </v-select>

                        <v-text-field
                            v-model="search"
                            class="hb-cms-nav placeholder-search"
                            placeholder="Search"
                            append-icon="mdi-magnify"
                            outlined
                            dense
                            color="#00848E"
                        >
                        </v-text-field>
                            <!-- append-icon="mdi-magnify" -->
                    </div>

                <div class="hb-cms-nav pt-2">
                  <v-list nav class="pb-0 pt-0 pl-0 pr-0"  :class="hasCmsExtensions && isHBUser ? 'filter-height' : 'remove-filter-height'">
                      <v-list-item v-for="(route, index) in navigationItems" :key="index" class="mt-0 mb-0 setting-menu-list" :class="{'active-menu-class' : showMenuType == route.title, 'is-active-website-screens': hasWebsiteScreens }" @click="sectionClicked(route)">
                          <!-- <v-list-item-icon class="mr-3">
                              <hb-icon class="setting-menu-list-inactive-color" :color="showMenuType == 'Corporate' ? '#101318' : ''">mdi-web</hb-icon>
                          </v-list-item-icon> -->
                            <v-list-item-content>
                                <div v-if="hasTabs(route)" class="setting-menu-list-inactive-color font-weight-regular" :class="{'setting-menu-list-active-color font-weight-medium' : showMenuType == route.title}" style="font-size:14px;">{{route.group.group_name}}</div>
                                <div v-else class="setting-menu-list-inactive-color font-weight-regular" :class="{'setting-menu-list-active-color font-weight-medium' : showMenuType == route.title}" style="font-size:14px;">{{route.title}}</div>
                            </v-list-item-content>
                      </v-list-item>
                      <!-- <v-divider style="margin:0 20px;"></v-divider> -->
                  </v-list>
                </div>

              </v-navigation-drawer>

              <v-flex class="hb-flex">
                  <div class="section-content pt-0 pl-3 pr-0 pb-3">
                      <div class="primary-section-content">
                          <v-row class="mx-0">
                              <v-col cols="12 py-0 pr-0">
                                  <div class="main-section-heading pa-0 mr-0">
                                      <v-row class="mt-2 mr-0 w-100 mx-0" :class="{'mb-1' : !showTabs}">
                                          <!-- <div class="mt-10 mb-n1">
                                            <hb-breadcrumb v-if="activePage" @click="corporateIndexPage">Back to Settings Dashboard</hb-breadcrumb>
                                          </div> -->
                                          <top-headings v-if="activePage || isHBUser || hasTabs(activePage)" :cms-app-title="cmsAppData" @showCorporateIndexPage="corporateIndexPage" :active-page="activePage.key" @tabs="showTabs =! showTabs" :showAccessHoursUpgrade="showAccessHoursUpgrade" :selectedProperty="selectedProperty"></top-headings>
                                          <span v-else>
                                              <hb-page-header
                                                    class="mt-1"
                                                    title="Mariposa™ Content Management System"
                                                    description=""
                                                >
                                                </hb-page-header>
                                          </span>
                                          <v-col cols="11" class="pt-0 pl-0 pb-0" v-if="showTabs">
                                              <v-tabs background-color="transparent" class="hb-text-light pl-0" color="#101318">
                                                  <v-tabs-slider color="#00848E"></v-tabs-slider>
                                                  <v-tab class="text-capitalize" v-for="(item, index) in tabsList" :key="index" @click="setActiveTab(item.value)">{{item.title}}</v-tab>
                                              </v-tabs>
                                          </v-col>
                                          <!-- <v-col cols="1" class="d-flex pa-0" v-if="activePage.key">
                                              <v-row align="end" justify="end" class="mx-0">
                                                  <hb-btn class="mr-1" :style="showTabs ? 'position:relative;bottom:13px;' : 'position:relative;bottom:10px;'" icon tooltip="Sidebar" @click="showNavigation =! showNavigation" :active="showNavigation">mdi-table-actions-custom-1</hb-btn>
                                              </v-row>
                                          </v-col> -->
                                      </v-row>
                                  </div>
                              </v-col>
                          </v-row>

                          <v-row class="mx-0 pt-1 settings-pages-content-wrapper" :class="{'mr-6' : activePage && activePage.key && activePage.key.indexOf('cms') === 0 }">
                        <!-- please replace the above v-row with the v-row commented out below when doing final website settings fixes to match new hummingbird settings, the above v-row and conditional class logic (i.e. the :class="{'mr-6' : activePage && activePage.key && activePage.key.indexOf('cms') === 0 } part) is just a temp workaround -->
                        <!--
                          <v-row class="mx-0 pt-1 settings-pages-content-wrapper">
                        -->

                              <v-col :cols="12" class="pt-0 pr-0" style="transition: 0.3s ease">
                                  <!-- <v-row justify="center" class="ma-0 mt-2" v-if="!activePage.key">
                                      <a v-for="(route, index) in settingsList" @click="sectionClicked(route)" class="settings-menu-iem hb-larger-font-size font-weight-medium col-12 mb-4 pl-6 py-4" :class="{ 'mt-2' : index == 0 }" :key="index">{{route.title}}</a>
                                  </v-row> -->

                                <!-- below v-divider is a temp workaround to make website settings match new hummingbird settings, please delete this v-divider later when doing final website settings fix to match hummingbird settings -->
                                <v-divider v-if="activePage && activePage.key && activePage.key.indexOf('cms') === 0 && !hasTabs(activePage)"></v-divider>
                                <!-- above v-divider is a temp workaround to make website settings match new hummingbird settings, please delete this v-divider later when doing final website settings fix to match hummingbird settings -->

                                <tab-view-list
                                    v-if="hasTabs(activePage)"
                                    :key="activePage.key"
                                    :tab-groups="getGroupTabList(activePage)"
                                    :gds_id="activePage.gds_id"
                                    :owner="companyDetails.gds_owner_id"
                                    :base-url="baseUrl"
                                    :token="platformAppToken"
                                    :app-list="appList"
                                    :api-url="apiUrl"
                                />
                                <component
                                    v-else-if="activePage || isHBUser"
                                    :is="activePage.component"
                                    :tab-details="getTabDetails" 
                                    :component="activePage.key"
                                    :gds_id="activePage.gds_id"
                                    :owner="companyDetails.gds_owner_id"
                                    :base-url="baseUrl"
                                    :token="platformAppToken"
                                    :app-list="appList"
                                    :api-url="apiUrl"
                                    :selected-properties="selectedProperties"
                                    :showAccessHoursSettings="showAccessHoursSettings"
                                ></component>
                                <UpsellCardsContainer
                                    v-if="upsellData.cms && !activePage && !isHBUser"
                                    is-cms
                                    :data="upsellData.cms"
                                ></UpsellCardsContainer>

                                <div v-if="!upsellData.cms && !isHBUser && !activePage">
                                    <UpsellSkeletonLoader></UpsellSkeletonLoader>
                                    <div class="d-flex">
                                        <UpsellSkeletonLoader type="vertical"></UpsellSkeletonLoader>
                                        <UpsellSkeletonLoader type="vertical"></UpsellSkeletonLoader>
                                        <UpsellSkeletonLoader type="vertical"></UpsellSkeletonLoader>
                                    </div>
                                </div> 
                              </v-col>

                          </v-row>
                      </div>
                  </div>
              </v-flex>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import { EventBus } from '../../EventBus.js';
  import api from '../../assets/api.js';
  import { mapGetters, mapActions } from 'vuex';
  import { addTokenPrefix } from '../../utils/common.js'
  import UpsellCardsContainer from '../dashboard/widgets/components/UpsellCardsContainer.vue'
  import UpsellSkeletonLoader from '../dashboard/widgets/components/UpsellSkeletonLoader.vue'
  import { notificationMixin } from '../../mixins/notificationMixin.js';
    // import Apps from './Apps.vue'


export default {
    name: "AppSettings", 
    mixins: [notificationMixin],
    components : {
        'settings-tab-view' : () => import('./TabWrapper'),
        'tertiary-navigation' : () => import('../../components/assets/TertiaryNavigation'),
        'top-headings' : () => import('./TopHeadings.vue'),
        'app-navigation' : () => import('../includes/AppNavigation/PlatformNavigation.vue'),
        'settings-payment-configuration': () => import('./PaymentConfiguration/index.vue'),
        'settings-access-control': () => import('./AccessControl/Index.vue'),
        'settings-accounting': () => import('./AccountingTemplates/Index.vue'),
        'settings-triggers': () => import('./Triggers.vue'),
        'settings-automations': () => import('./Automations/Index.vue'),
        'settings-templates': () => import('./Templates.vue'),
        'settings-billing': () => import('./NewBillingSettings.vue'),
        // 'settings-promotions': () => import('./Promotions/Index.vue'),
        'settings-categories': () => import('./CategoriesSettings.vue'),
        'settings-api-keys': () => import('./ApiKeysSettings.vue'),
        'settings-properties': () => import('./Properties/Index.vue'),
        'settings-property-groups': () => import('./PropertyGroups/Index.vue'),
        'settings-merchandise': () => import('./MerchandiseManagement/index.vue'),
        'settings-services': () => import('./Services.vue'),
        // Apps,
        // 'settings-tax-rates': () => import('./TabWrapper.vue'),
        'settings-auctions': () => import('./Auctions/Index.vue'),
        'settings-space-groups': () => import('./SpaceGroups/Index.vue'),
        'settings-feature-and-amenity': () => import('./FeatureAndAmenity/Index.vue'),
        'settings-document-manager': () => import('./DocumentManager/Index.vue'),
        'tab-view-list': () => import('./TabViewList.vue'),
        'settings-revenue-management': () => import('./RevenueManagement/Index.vue'),
        'settings-charm': () => import('./CharmConfiguration/index.vue'),
        'settings-sso': () => import('./SSO/Index.vue'),
        // 'settings-lead-management': ()=> import('./LeadManagement/Index.vue'), //Temporarily commented, needed for Lead flow revamp
                // 'settings-transactional': () => import('./Transactional/Index.vue')
        'settings-communication-template' :()=> import('./CommunicationTemplates/index.vue'),
        UpsellCardsContainer,
        UpsellSkeletonLoader

    },
     data() {
         return {
                search: "",
                searchedApps: [],
                groupData: {},
                // Add "Website" to filter list after CMS App integration 
                filter: ["hummingbird"],
                filterInput: ["hummingbird"],
                filteringClass: "hb-cms-nav filter placeholder-filter",

                activePage: '',
                dialog: true,
                hasDrawer: true,
                navigationHeading: "Facility Dashboard",
                selectedProperty: null,
                showAccessHoursUpgrade: false,
                showAccessHoursSettings: false,
                settingsList: [
                    { path: '/settings/properties', key: "settings-properties", component: "settings-properties", title: "Properties", type: 'native', tag:['hummingbird'], terms: ["address", "zipcode", "street", "phone", "email", "legal name", "store ID"]},
                    { path: '/settings/property-groups', key: "settings-property-groups", component: "settings-property-groups", title: "Property Groups", type: 'native', tag:['hummingbird']},
                    { path: '/settings/accounting', key: "settings-accounting", component: "settings-accounting", title: "Accounting", type: 'native', tag:['hummingbird'], terms: ["chart of accounts", "journal", "template", "books", "GL code", "Yardi", "cash", "accrual"]},
                    { path: '/settings/automations/index', key: "settings-automations", component: "settings-automations", title: "Automations", tag:['hummingbird'], terms: ["triggers", "lien", "auction", "late fee", "access", "deny"]},
                    { path: '/settings/lease-configuration', key: "settings-lease-configuration", component: "settings-tab-view", title: "Lease Configuration", tag:['hummingbird'], terms: ["move-in", "movein", "transfer", "autopay", "fees", "products", "checklist", "security", "invoice", "billing", "lease", "insurance", "protection", "document", "move-out", "prorate"]},
                    // { path: '/settings/promotions', key: "settings-promotions", component: "settings-promotions", title: "Promotions", type: 'native', tag:['hummingbird'], terms: ["discounts", "rules", "code", "duration", "rounding", "promotions"]},
                    { path: '/settings/categories', key: "settings-categories", component: "settings-categories", title: "Space Categories", type: 'native', tag:['hummingbird'], terms: []},
                    { path: '/settings/products', key: "settings-products", component: "settings-tab-view", title: "Manage Products", type: 'native', tag:['hummingbird'], terms: ["locks", "merchandise"]},
                    { path: '/settings/national-accounts', key: "settings-national-account", component: "settings-tab-view", title: "National Accounts", type: 'native', tag:['hummingbird'], terms: ["locks", "merchandise"]},
                    { path: '/settings/api-keys', key: "settings-api-keys", component: "settings-api-keys", title: "API Keys", type: 'native', tag:['hummingbird'], terms: []},
                    // { path: '/settings/merchandise', key: "settings-merchandise", component: "settings-tab-view", title: "Merchandise", type: 'native', tag:['hummingbird'], terms: []},
                    { path: '/settings/insurance', key: "settings-insurance", component: "settings-tab-view", title: "Coverage", tag:['hummingbird'], terms: ["insurance", "protection", "premium", "deductible", "prorate", "recurring", "income", "coverage"]},
                    { path: '/settings/roles', key: "settings-roles", component: "settings-tab-view", title: "Users and Roles", type: 'native', tag:['hummingbird', 'website'], terms: ["permissions", "users", "managers"]},
                    { path: '/settings/fees', key: "settings-fees" , component: "settings-tab-view", title: "Fees", type: 'native', tag:['hummingbird'], terms: ["recurring", "prorate", "fixed", "fee schedule", "variable", "percentage", "delinquency", "service", "lien"]},
                    // { path: '/settings/rent', key: "settings-rent", component: "settings-tab-view", title: "Rent", type: 'native', tag:['hummingbird']},
                    { path: '/settings/tax-profiles', key: "settings-tax-profiles", component: "settings-tab-view", title: "Tax", tag:['hummingbird'], terms: ["liability", "account"]},
                    { path: '/settings/Auctions', key: "settings-auctions", component: "settings-auctions", title: "Auctions", tag:['hummingbird'], terms: ["cleaning", "clean out period"]},
                    { path: '/settings/billing', key: "settings-billing", component: "settings-tab-view", title: "Billing", type: 'native', tag:['hummingbird'], terms: ["reversal", "adjustment", "chargeback", "ach", "close of day"]},
                    { path: '/settings/payment-configuration', key: "settings-payment-configuration", component: "settings-tab-view", title: "Payment Processing", tag:['hummingbird'], terms: ["credit card", "visa", "mastercard", "authorize", "gateway", "swiper", "terminal", "pos", "point of sale", "merchant", "device", "ach", "tsys", "forte", "tenant payments", "integration"]},
                    { path: '/settings/access-control', key: "settings-access-control", component: "settings-access-control", title: "Access Control", tag:['hummingbird'], terms: ["integration", "brivo", "PTI", "Flat file", "opentech", "noke", "cia", "gate", "lock", "sentinel", "rcs"]},
                    { path: '/settings/space-groups', key: "settings-space-groups", component: "settings-space-groups", title: "Space Groups", tag:['hummingbird'], terms: ["OSR", "Rate Management", "Website", "Area tier", "grouping profile", "revenue management"]},
                    { path: '/settings/feature-and-amenity', key: "settings-feature-and-amenity", component: "settings-feature-and-amenity", title: "Features and Amenities", tag:['hummingbird'], terms: []},
                    { path: '/settings/services', key: "settings-services", component: "settings-services", title: "Services", tag:['hummingbird'], terms:[]},
                    { path: '/settings/document-manager', key: "settings-document-manager", component: "settings-document-manager", title: "Document Manager", tag:['hummingbird'], terms: ["signature", "notice", "lien", "intent to vacate", "agreement", "template", "tokens", "authorization"]},
                    { path: '/settings/revenue-management', key: "settings-revenue-management", component: "settings-tab-view", title: "Revenue Management", tag: ['hummingbird'], terms: []},
                    { path: '/settings/charm', key: "settings-charm", component: "settings-charm", title: "Charm", tag: ['hummingbird'], terms: []},
                    { path: '/settings/lead-management', key: "settings-lead-management", component: "settings-tab-view", title: "Lead Management",type: 'native',  tag: ['hummingbird'], terms: ['script'] },
                    { path: '/settings/sso', key: "settings-sso", component: "settings-tab-view", title: "SSO Manager", tag:['hummingbird'], terms: ["permissions", "users", "managers"]},
                    { path: '/settings/communication', key: "settings-communications", component: "settings-tab-view", title: "Communication",type: 'native',  tag: ['hummingbird'], terms: ['script'] },
                    // { path: '/settings/automation/groups', key: "settings-automation", title: "Automation", type: 'native', tag:['hummingbird']},
                    // { path: '/settings/templates', key: "settings-templates", title: "Lease Configuration Library", type: 'native'},
                    // { path: '/settings/templates', key: "settings-templates", title: "Set Up Lease Templates", type: 'native'},
                    // { path: '/settings/tax-rates', key: "settings-tax-rates", title: "Tax Rates", tag:['hummingbird']},
                    // { path: '/settings/transactional', key: "settings-transactional", title: "Transactional"},
                     { path: '/settings/Communicationtemplates', key: "settings-communication-templates", component: "settings-communication-template", title: "Communication Templates", type: 'native', tag: ['hummingbird'], terms: [] },
                    ],

                showNavigation : false,
                tabOptions : [
                    {
                        title : 'Details',
                        value : 'defaults'
                    },
                    {
                        title : 'Products',
                        value : 'products'
                    },
                    // {
                    //     title : 'Insurance',
                    //     value : 'insurance'
                    // },
                    {
                        title : 'Documents, etc.',
                        value : 'checklist'
                    }
                ],
                showTabs : false,
                showMenuType : 'Corporate',
                isMainSettingsPage : this.$route.name === 'settings',
                cmsAppData : [],
                tabDetails : {
                    "settings-tax-profiles": {
                        Property: "Tax Rates",
                        Corporate: "Tax Profiles",
                    },
                    "settings-merchandise": {
                      Corporate: "Corporate Settings",
                      Property: "Property Settings",
                    },
                    "settings-lease-configuration": {
                        Property: "Lease Configuration",
                        Corporate: "Lease Templates",
                    },
                   "settings-roles": {
                        Property: "Roles and Permissions",
                        Corporate: "Users",
                    },
                    "settings-national-account": {
                        Corporate: "Corporate Settings",
                    },
                    "settings-payment-configuration":{
                        Corporate: "Payment Configuration",
                        Property: "Payment Processing Reports",
                       
                    },
                    "settings-sso":{
                        Corporate: "SSO Configuration",
                        Property: "Property Group Configuration"
                    },
                },
                defaultTabs: [
                    {
                        key: "Corporate",
                        label: "Corporate Settings",
                        description: "Manage settings on company level",
                    },
                    {
                        key: "Property",
                        label: "Property Settings",
                        description: "Manage settings on property level",
                    }
                ],
         }
     },
     async created() {
         this.$store.dispatch('navigationStore/setSettingsNavigationOnly', true);
         EventBus.$on('HB-Show-Tabs-List-Settings', () => {
             this.showTabs =! this.showTabs;
         });

         EventBus.$on('Access-Hours-Enabled', (isAccessHours) => {
            this.$set(this, 'showAccessHoursSettings', isAccessHours);
            this.showAccessHoursUpgrade = !isAccessHours;
         })
         let extensions = this.$store.getters['authenticationStore/getExtensionPoints']?.settings;
         if (extensions)
            extensions.map(e => {
                this.settingsList.push({
                    path: "/settings/app/" + e.id,
                    key: 'apps',
                    gds_id: e.id,
                    title: e.name
                });
            })
         EventBus.$on('showCorporateIndexPage', this.corporateIndexPage);
         EventBus.$on('selectedAccessProperty', this.handleSelectedAccessProperty)
         this.generatePlatformAppToken();
         this.getMerchandiseSettingsAction();
         await this.fetchAllAutomations();



     },
     computed: {
         ...mapGetters({
          hasPermission: "authenticationStore/rolePermission",
          cmsAppId: 'authenticationStore/getCmsAppId',
          platformAppToken: 'authenticationStore/getPlatformAppToken',
          companyDetails: 'authenticationStore/getCompany',
          appIdList: 'authenticationStore/getApplications',
          isHBUser: 'authenticationStore/isHBUser',
          upsellData: 'dashboardsStore/get_upsell_data',
          properties: 'propertiesStore/filtered',
          merchandiseSettings: 'productStore/merchandiseSettings',
          notification: 'automationStore/getNotification',
          application: "tenantPaymentsApplicationStore/getApplication",
          getSettings: 'authenticationStore/getSettings',
        }),
        appList() {
            return JSON.stringify(this.appIdList)
        },
        selectedProperties() {
            return JSON.stringify(this.properties)
        },
        hasCmsExtensions(){
            let extensions = this.$store.getters['authenticationStore/getExtensionPoints']?.cms;
            return !!extensions?.length
        },
        baseUrl() {
          return process.env.VUE_APP_GDS_BASE_URL
        },
        apiUrl() {
            let url = process.env.VUE_APP_API_PROTOCOL + '://' + process.env.VUE_APP_API_SUBDOMAIN + '.' + process.env.VUE_APP_DOMAIN + ':' + process.env.VUE_APP_API_PORT;
            return url;
        },
        getTabDetails() {
            let tabs = []
            
            let customTabLabel = this.tabDetails?.[this.activePage.key];
            if(customTabLabel) {
                this.defaultTabs.forEach(tab => {
                    tabs.push({
                        "key": tab?.key,
                        "label": customTabLabel?.[tab?.key]
                    })
                })
            }
            else tabs = this.defaultTabs;
            if (this.activePage.key === 'settings-payment-configuration') {
                if (!this.hasPermission('view_sso_page_of_tenant_payments')) {
                    return tabs = []
                }
            }
            return tabs;
        },
        
        tabsList(){
            let tabs = [];
        //   if (this.activePage.key === 'settings-promotions') {
        //     tabs.push(
        //       {
        //         title : 'Promotions',
        //         value : 'promotion'
        //       },
        //       {
        //         title : 'Discounts',
        //         value : 'discount'
        //       }
        //     )
        //   } else 
            if(this.activePage.key === 'settings-templates') {
                tabs = this.tabOptions;
            }
            return tabs
        },

        navigationItems() {
            let navItems = []
            if (this.searchItems?.length) {
                navItems = this.searchItems
            } else if (!this.search?.length && !this.filterInput?.length) {
                navItems = this.settingsList
            }
            navItems.sort((a, b) => a?.title?.localeCompare(b?.title));
            let filteredItems = this.pinClearCacheScreen(navItems)
            let modifiedFilteredItems = JSON.parse(JSON.stringify(filteredItems))

            let uniqueItemGroup = []
            let navigationItems = []
            modifiedFilteredItems.forEach((item) => {
                if (item?.group) {
                    let groupId = item.group?.group_id
                    if(!uniqueItemGroup.includes(groupId)) {
                        navigationItems.push(item)
                        if (groupId) uniqueItemGroup.push(groupId)
                    }
                } else navigationItems.push(item)
            })
            
            if(!this.hasPermission('mini_charm')) {
                navigationItems = navigationItems.filter(ele=>{
                    
                  if(ele.key!="settings-charm"){
                    return ele
                  }
              })
            }
            if(this.getSettings?.nationalAccountFeature != '1') {
                navigationItems = navigationItems.filter(ele=>{
                    const nationalAccountFeature = [
                        'settings-national-account',
                        'settings-sso'
                    ]
                    if(!nationalAccountFeature.includes(ele.key)){
                        return ele
                    }
                })
            }
            if (this.merchandiseSettings) {
                navigationItems = navigationItems.filter(navItem => navItem.key !== 'settings-products');
            }
            return navigationItems
        },

        // filter navigation items
        filteredNavigationItems() {
            let filteredItems = JSON.parse(JSON.stringify(this.settingsList))
            if (!this.isHBUser) {
                let searchList = []
                filteredItems?.forEach((app) => {
                    if (app?.tag && app?.tag?.includes('website')) {
                            searchList.push(app)
                    }
                })
                filteredItems = searchList
            }
            else if (this.filterInput.length) {
                let searchList = []
                filteredItems.forEach((app) => {
                    if (app.tag) {
                        let result = this.filterInput.some((val) =>
                            app.tag.includes(val.toLowerCase())
                        )
                        if (result) {
                            searchList.push(app)
                        }
                    }
                })
                filteredItems = searchList
            }
            return filteredItems
        },
        // search navigation items
        searchItems() {
            let searchedItems = this.filteredNavigationItems?.filter(app =>
                app.title?.toLowerCase()?.includes(this.search?.toLowerCase()) ||
                app.terms?.some(term => term.toLowerCase().includes(this.search?.toLowerCase()))
            )
            return searchedItems
        },
        filterClass() {
            return this.filterInput.length
                ? 'hb-cms-nav filter-after placeholder-filter-after'
                : 'hb-cms-nav filter placeholder-filter'
        },
        hasWebsiteScreens() {
            let hasClearCacheScreen = this.settingsList.some(page => page?.key === "cms-clear-cache-screen");
            if(!this.isHBUser && hasClearCacheScreen) return true;
            return this.filterInput.includes('website') || !this.filterInput.length
        }
     },

     watch: {
        hasCmsExtensions: {
            immediate: true,
            handler(value) {
                // if(value) {
                //     if (!this.filter.includes("Website")) 
                //         this.filter.push("Website")
                //     }
                let isPlatformIntegration = this.$store.getters['authenticationStore/getPlatformIntegrationEnabled']
                if(isPlatformIntegration) {
                    let applications = this.$store.getters['authenticationStore/getExtensionPoints'].cms;
                    applications?.map(app => {
                        this.settingsList.push({
                            path: "/settings/app/" + app?.id,
                            key: app?.component,
                            component: app?.component,
                            title: app?.name,
                            type: 'native',
                            tag: app?.tags,
                            terms: app?.terms ?? [],
                            ...this.hasTabGroup(app) && { group: this.groupData }
                        });
                        this.cmsAppData.push({
                            name: app?.component,
                            title: app?.name,
                            description: '',
                            ...this.hasTabGroup(app) && { group: this.groupData }
                        })
                    })
                }
                this.settingsList.forEach(settings => {
                    this.filter = [...this.filter, ...settings.tag]
                })
            }
        },
        async notification(notification) {
            let {type = '', msg = ''} = notification;
            if (msg && type) {
                this.showMessageNotification({ type: type, description: msg });
                await this.$store.commit('automationStore/setNotification', {type:'', msg:''});
            }
        }
     },
     
     methods: {
        ...mapActions({
            getMerchandiseSettingsAction: 'productStore/getMerchandiseSettingsAction',
            fetchAllAutomations: 'automationStore/fetchAllAutomations'
        }),
        hasTabGroup(app) {
            this.groupData = {}
            this.groupData = app?.extensions.find((extention) => extention?.id === app.id)?.group
            return this.groupData ? this.groupData : false
        },
        hasTabs(activePage) {
                        return this.getGroupTabList(activePage).length > 1
        },
        getGroupTabList(activePage) {
            let tabGroups = []
            if (activePage?.group?.group_id) {
                tabGroups = this.filteredNavigationItems.filter((item) => {
                    return item?.group?.group_id === activePage?.group?.group_id
                })
                tabGroups.sort((a,b) => a?.group?.tab_name.localeCompare(b?.group?.tab_name))
            }
            return tabGroups
        },
        pinClearCacheScreen(navItems) {
            let cacheScreenPinned = false
            let isClearCacheScreen = (screen) => screen?.component == "cms-clear-cache-screen"
            let clearCacheIndex = navItems?.findIndex(isClearCacheScreen)
            if (clearCacheIndex > 0) {
                let clearCacheScreen = navItems?.splice(clearCacheIndex, 1)[0]
                navItems?.unshift(clearCacheScreen)
                cacheScreenPinned = true
            }
            if (!cacheScreenPinned && this.hasWebsiteScreens) {
                let clearCacheComp = this.settingsList?.find(isClearCacheScreen)
                if (clearCacheIndex < 0 && clearCacheComp) {
                    navItems?.unshift(clearCacheComp)
                }
            }
            return navItems
        },
         setMenuType(title){
             this.showMenuType = title;
             this.activePage = '';
             this.showNavigation = false;
              //  if (!this.isMainSettingsPage) {
              //    this.$router.replace({ name: 'settings' });
              //  }
         },
         async generatePlatformAppToken() {
            let response = await api.post(this, api.GENERATE_TOKEN, { appId: this.cmsAppId });
            this.$store.dispatch('authenticationStore/setPlatformAppToken', addTokenPrefix(response.token));
            
         },

         setActiveTab(value){
            //  if (this.activePage.key === 'settings-promotions') {
            //      EventBus.$emit('HB-settings-Promotions:Active-Tab', value);
            //  } else {
                EventBus.$emit('HB-Lease-Template:Active-Tab', value);
            //  }
         },
        //  setActivePage(option){
        //     this.showMenuType = 'Corporate'
        //      switch(this.showMenuType){
        //          case 'Corporate':
        //              this.activePage = this.settingsList.find(pi => pi.key === option)
        //              this.showMenuType = this.settingsList.find(pi => pi.key === option).title
        //              break;
        //          case 'Property':
        //              this.activePage = this.propertyItems.find(pi => pi.key === option)
        //              break;
        //      }
        //  },
         emitHideSettings(){
            EventBus.$emit("refresh-contact-profile");
            this.$emit('hideSettings');
         },
         corporateIndexPage(){
             this.showMenuType = '';
             this.activePage = '';
             this.showNavigation = false;
         },
         async handleSelectedAccessProperty(propertyId){
            this.$set(this, 'selectedProperty', propertyId);
            await api.get(this, api.PROPERTIES + propertyId + "/property-area-management")
              .then(results => {
                let areaConfiguration = results?.property_area_configuration
                if (areaConfiguration) {
                    this.showAccessHoursUpgrade = areaConfiguration.access_hours_upgrade === 0 && areaConfiguration.enable_access_hours_by_admin === 1;
                    this.showAccessHoursSettings = areaConfiguration.access_hours_upgrade === 1;
                }
              })
              .catch(error => {
                console.log('Error: Getting Access Hours Flag', error)
                this.showAccessHoursSettings = false;
              })
              this.$set(this, 'showAccessHoursUpgrade', this.showAccessHoursUpgrade);
         },
         propertyIndexPage(){
             this.activePage = '';
             this.showNavigation = false;
         },
         sectionClicked(section){
                          this.showMenuType = section.title
             this.activePage = section;
             this.showNavigation = true;
         },
         eventShowTabs(){
             this.showTabs =! this.showTabs;
         }

     },
     destroyed() {
        EventBus.$off('HB-Show-Tabs-List-Settings', this.eventShowTabs);
        EventBus.$off('showCorporateIndexPage', this.corporateIndexPage);
        EventBus.$off('selectedAccessProperty', this.handleSelectedAccessProperty);
        EventBus.$off('showAccessHoursSettings')
        this.$store.dispatch('navigationStore/setSettingsNavigationOnly', false);
        localStorage.removeItem('level');
     },
}
</script>

<style scoped>
    .rotate-icon{
        transform: rotateY(180deg);
    }
    .show-menu-div {
        animation: cssAnimation 0.2s 0.3s forwards;
        opacity: 0;
    }

    @keyframes cssAnimation {
        to   { opacity: 1; }
    }
    .settings-menu-iem{
        text-decoration: none;
        line-height: 24px;
        background-color: #FFFFFF;
        color: #101318;
        box-shadow: 0px 1px 0px rgba(11, 18, 29, 0.1), 0px 2px 2px rgba(11, 18, 29, 0.1), 0px 1px 2px rgba(11, 18, 29, 0.1);
        border-radius: 4px;
        border: 0;
    }
    .ml--10px{
        margin-left: -13px;
    }
    .active-menu-class{
        background: #E0F5F5;
        border-radius: 0;
    }
    .fixed-box{
        position: fixed;
        width: 15.5%;
    }

    .setting-menu-list-inactive-color{
        color:#637381;
    }

    .setting-menu-list-active-color{
        color:#101318;
    }

    .setting-menu-list-active-color svg path{
        fill:#101318;
    }
    .w-100{
        width: 100%;
    }
    .rel-top-5px{
        position: relative;
        top: 5px;
    }
    .pl-260px{
        padding-left: 260px !important;
    }
    .zi-11{
        z-index: 11 !important;
    }
    .bg-colo-light{
        background-color: #F9FAFB;
    }
    .drawer-btn:focus{
        border: 0px solid !important;
    }
    .drawer-btn:focus::before{
        opacity: 0 !important;
    }
    .main-section-heading {
        padding: 20px 20px 20px 0;
        justify-content: space-between;
        border-bottom: none !important;
        /* border-bottom: 1px solid #ccc; */
        display: flex;
    }
    .b-radius-0{
        border-radius: 0 !important;
    }
    .has-shadow{
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    }
</style>

<style>
    .transparent-tabs.theme--light.v-tabs > .v-tabs-bar{
        background-color: transparent;
    }
    .no-animation-setting{
        transform: translateX(0%) !important;
        visibility: visible !important;
    }
    .cms-navigation {
        padding-bottom : 61px;
    }
    
    /* ::-webkit-scrollbar {
    width: 5px;
    }

    ::-webkit-scrollbar-track {
    background: #f1f1f1; 
    }
    
    ::-webkit-scrollbar-thumb {
    background: rgb(189, 189, 189); 
    border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
    background: rgb(197, 197, 197); 
    } */

    /* below is a temp fix to make website settings match with new hummingbird settings, please delete this below if conflicting with any sort of new changes */
    .settings-pages-content-wrapper .cms-facility-list > .v-autocomplete {
        margin-top: 12px !important;
    }
</style>




<style lang="scss" scoped>
.hb-cms-nav {
  height: fit-content;
  &.filter-section {
    padding-top: 15px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #f9fafb;
    width: 100%;
    border-bottom: 1px solid #dfe3e8;
    position: absolute;
    z-index: 2;
    border-right: 1px solid rgb(221, 221, 221);
  }
  &.filter {
    background: linear-gradient(180deg, #ffffff 0%, #f9fafb 100%);
  }
  &.filter-after {
    background: linear-gradient(180deg, #47c0bf -70%, #00848e 126.25%);
  }
  .v-list-item {
      min-height: 26px !important;
      min-width: 200px;
  }
  .v-list-item__content {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .filter-height {
    margin-top: 96px;
    margin-bottom: 100px !important;
  }
  .remove-filter-height {
    margin-top: 52px;
    // margin-bottom: 100px !important;
  }
  .nav-list-filter {
    margin-top: 110px;
  }
  .setting-menu-list-inactive-color {
    color: #637381;
  }
  .setting-menu-list-active-color {
    color: #101318;
  }
  .setting-menu-list {
    border-left: 4px solid #ffffff;
    padding: 7px 14px !important;
  }
  .setting-menu-list:not(.active-menu-class):hover {
    background-color: #ffffff !important;
    border-left: 4px solid #ffffff !important;
  }

  .setting-menu-list:hover .setting-menu-list-inactive-color {
    color: #101318;
  }
  .setting-menu-list:hover::before {
    background-color: #ffffff !important;
    border-left: 4px solid #ffffff !important;
  }
  .active-menu-class {
    background: #e0f5f5;
    border-left: 4px solid #00848E;
  }
  .is-active-website-screens.setting-menu-list.theme--light.v-list-item:first-child.v-list-item--link {
    background-color: #FFFFFF;
    border-bottom: 1px solid #dfe3e8;
    border-right: 1px solid rgb(221, 221, 221);
    height: 43px;
    position: fixed;
    z-index: 1
  }
  .is-active-website-screens.setting-menu-list.v-list-item:not(:first-child):not(:only-child), .v-list--rounded .v-list-item:not(:first-child):not(:only-child) {
    top: 44px
  }
}
</style>

<style lang="scss">
.hb-cms-nav {
  .v-text-field__details {
    display: none;
  }
  .placeholder-search input::placeholder {
    color: #101318 !important ;
    font-size: 14px;
    line-height: 17px;
    padding-top: 7px;
    padding-bottom: 12px;
  }
  .placeholder-filter input::placeholder {
    color: #101318 !important ;
    font-size: 14px;
    line-height: 17px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .placeholder-search {
    &.v-text-field--outlined fieldset {
      border: none;
    }
  }
  .placeholder-filter {
    &.v-text-field--outlined fieldset {
      border-color: #c4cdd5;
    }
  }
  .placeholder-filter-after {
    &.v-text-field--outlined fieldset {
      border: none;
    }
  }
  .filter .theme--dark.v-icon {
    color: #637381;
  }
  &.filter-section {
    .v-list-item__action:first-child {
      margin-right: 7px !important;
    }
  }
  .settings-filter-menu-v-select {
    .v-list-item {
        height: 32px;
        &.v-list-item--highlighted::before {
            opacity: 0.12;
        }
        .v-list-item__title {
            font-weight: 400 !important;
            font-size: 14px;
            padding: 0px 0 1px 0;
        }
    }
  }
}
</style>
