<template>
    <v-card class="pa-0 pt-0 mb-6 delinquency-wrapper" :class="{'mt-4' : $vuetify.breakpoint.width < 600 && $vuetify.breakpoint.width > 1460, 'hb-border-default' : hideHeader !== true, 'hb-elevation-medium' : disableElevation !== true }" :elevation="disableElevation === true ? 0 : undefined">
        <v-card-text v-if="!loading && !delinquencies.length">
            <v-row class="mx-0">
                <v-col md="12" class="text-center">
                    <div class="no-task-message"><v-icon class="move-up-icon" size="30" color="#02AD0F">mdi-check</v-icon> Tenant is in good standing!</div>
                </v-col>
            </v-row>
        </v-card-text>
        
        <v-card-text v-else-if="loading">
            <v-row class="mx-0 py-3" >
                <v-col class="text-center">
                     <v-progress-circular width="2" size="32" indeterminate color="primary" ></v-progress-circular>
                </v-col>
            </v-row>
        </v-card-text>

        <div class="pa-0"  v-else-if="selected.id">  
            <v-container v-if="hideHeader !== true">
                <v-row dense>
                    <v-col>
                        <p class="mb-0 hb-font-header-3-medium font-weight-medium">Delinquency Timeline</p>
                    </v-col>
                    <v-col v-show="delinquencies.length > 1" cols="4">
                         <v-select
                            :items="delinquencies"
                            v-model="selected_delinquency"
                            item-value="id"
                            background-color="white"
                            outlined
                            dense
                            hide-details
                            :menu-props="{ bottom: true, offsetY: true }"
                        >
                            <template v-slot:selection="data">
                                <div class="hb-input-overflow d-flex align-center">
                                    <hb-unit-icon color="#101318" class="pr-1" :type="getUnitTypeLabel(data.item.Lease.Unit)" />
                                    {{data.item.Lease.Unit.number}}
                                </div>
                            </template>
                            <template v-slot:item="data">
                                <hb-unit-icon color="#101318" class="pr-1" :type="getUnitTypeLabel(data.item.Lease.Unit)" />
                                {{data.item.Lease.Unit.number}}
                            </template>
                        </v-select> 
                    </v-col>
                </v-row>
            </v-container>   
            <v-divider v-if="hideHeader !== true"></v-divider>
            
            <hb-timeline>
                <hb-timelineItem
                    :stage-type="start_stage.stageType"
                    :day-section-title="`Day ${start_stage.start}`"
                    :name-section-title="start_stage.name"
                    :name-section-description-one="start_stage.description"
                    :day-section-description-one="formateDate(selected.start_date, 'MMM DD, YYYY')"
                    :expand-action="false"
                    :action-border="false"
                />
                <hb-timelineItem v-for="(value, i) in selected.Timeline" 
                    :key="i"
                    :stage-type="stageTypeStatus(value)"
                    :day-section-title="`Day ${value.start}`"
                    :day-section-description-one="(active_pause === -1 || i <= active_pause) ? formateDate(value.date,'MMM DD, YYYY') : ''"
                    :name-section-title="value.name"
                    :name-section-sub-title="getNameSectionSubTitle(value)"
                    :name-section-sub-title-description="getNameSectionSubTitleDescription(value)"
                    :name-section-description-one="value.description"
                    :name-section-description-two="getNameSectionDescription(value)"
                    :expand-action="false"
                    :show-action-toggle="value.Actions ? true : false"
                    :action-count="value.Actions ? value.Actions?.length : 0"
                    :action-border="value.Actions ? true : false"
                    :action-label="'Action' | pluralize(value.Actions?.length)"
                    :day-section-title-font-color="getFontColor(value)"
                    :name-section-title-font-color="getFontColor(value)"	
                    :description-font-color="getFontColor(value)"
                    :is-linked-stage="value.isLinkedStage"
                    :audit="audit === true"
                    :audit-verified="value.audit_status === 'completed'"
                    :audit-verified-by="value.audit_by"
                    :audit-verified-on="value.audit_date"
                    @audit-mark-as-verified-clicked="$emit('audit-mark-as-verified-clicked', value)"
                    @audit-undo-clicked="$emit('audit-undo-clicked', value)"
                    :is-paid-to-date-stage="value.isPTODStage"
                    >
                    <template v-if="value.Actions && value.Actions?.length" #action>
                        <span v-for="(action, index) in value.Actions" :key="index">
                            <HbTimelineItemAction 
                                :type="getTypeOfHbTimelineItemAction(action, value)" 
                                :title="action.action_name"
                                :description="action.description"
                            />
                            <template v-if="action?.Message || action?.Document">
                                <HbTimelineItemSubAction v-if="action.Message?.include_alternate || action.Document?.include_alternate"
                                    :title="'Include Alternate Contact'"
                                    :icon="'mdi-check'"
                                    :icon-color="'#637381'"
                                />
                                <HbTimelineItemSubAction v-if="action.Message?.include_lien || action.Document?.include_lien"
                                    :title="'Include Lien Holder'"
                                    :icon="'mdi-check'"
                                    :icon-color="'#637381'"
                                />
                            </template>
                            <template v-if="action.DeliveryMethods?.length > 0">
                                <span v-for="(deliveryMethod, index) in action.DeliveryMethods" :key="index">
                                    <HbTimelineItemSubAction
                                        :key="'sub_action_'+index"
                                        :type="deliveryMethod?.error ? 'error' : 'success'"
                                        :title="'Delivery Method'"
                                        :description= "getHbTimelineItemSubActionDescription(deliveryMethod)"
                                        :icon="getHbTimelineItemSubActionIcon(deliveryMethod)"
                                    />
                                </span>
                            </template>
                            <HbTimelineItemSubAction v-if="action?.error"
                                :key="'sub_action_'+index"
                                :type="action?.error ? 'error' : 'success'"
                                :title="action?.error"
                            />
                        </span>
                    </template>
                </hb-timelineItem>
            </hb-timeline>
        </div>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import api from '../../assets/api.js';
import moment from 'moment';
import { EventBus } from '../../EventBus.js';
import AUTOMATION from '@/constants/automation.js';


export default {
    name: "ContactDelinquency",
    components: {  },
    props: ['contact', 'propertyView', 'active_delinquencies', 'hideHeader', 'disableElevation', 'audit'],
    data() {
        return {
            delinquencies: [],
            
            selected_delinquency:'',
            loading: false,
            start_stage:  {
                name: AUTOMATION.DAY_0_STAGE_TITLE,
                description: AUTOMATION.DAY_0_STAGE_DESCRIPTION,
                start: 0,
                stageType: 'past'
            }
        }
    },
    created() {
        this.AUTOMATION = AUTOMATION;
       if(!this.active_delinquencies){
           this.getDelinquencies();
       } else {
           this.delinquencies = this.active_delinquencies; 
           if(this.delinquencies.length){
               this.selected_delinquency = this.delinquencies[0].id
            }
            this.loading = false;
       }
    },
    filters: {
       delivery_method(method){
            return method && method.replaceAll('_', ' ').replaceAll('sms', "SMS"); 
       }, 
        timeline_item_icon(item){
            // return "mdi-circle-medium"
            return "mdi-record-circle";
        },
        
        timeline_item_icon_color(item){
            return "primary"
        }, 
         timeline_item_icon_background(item){
            return "white"
        }
    }, 
    computed: {
        pause_date(){
           for (let i = 0; i < this.selected.Timeline.length; i++) {
                let item = this.selected.Timeline[i];
                if(item.type === 'pause' && !item.end_date) return item.execution_date;
            }
            return null; 
        }, 
        selected(){ 
            let active = {};
            if(this.delinquencies.length){
                active = this.delinquencies.find(d => d.id === this.selected_delinquency);
            }
            return active; 
        },
        active_stage(){
            // return the first step in the future
            for (let i = 0; i < this.selected.Timeline.length; i++) {
                 let item = this.selected.Timeline[i];
                if(item.type === 'pause' && !item.end_date) return item;
                if(item.type === 'action' && item.execution_date >= moment().format('YYYY-MM-DD') && item.Actions.find(a => !a.completed) ){
                    return item;
                }
            }
        },
        delinquent_lease(){
            // return the first step in the future
            return this.selected?.Lease || {};
        }, 
        current_timeline_date(){
            return this.pause_date ? this.pause_date : moment().format('YYYY-MM-DD')
        },
        is_deactivated(){
            this.stageStatus(this.active_stage) === 'paused'
        },
        active_pause(){
            let latestIndex = -1;
            for (let i = 0; i < this.selected.Timeline.length; i++) {
                let item = this.selected.Timeline[i];
                if(item.type === 'pause' && !item.end_date) latestIndex = i;
            }
            return latestIndex;
        }
    },
    methods: {
        unitNumer(delinquency){
            return delinquency.Lease.Unit.number
        }, 
        isActiveAction(stage){
            let stageStatus = this.stageStatus(stage);
            if(this.selected.status === 'paused' &&  (stageStatus === 'future' || stageStatus === 'current')){
                return false;
            } 
            return true;


        }, 
        stageStatus(stage){

            if(stage.type === 'pause' && !stage.end_date ) return 'paused'; 
            if(stage.type === 'pause' && stage.end_date ) return 'complete';

            if(stage.type === 'resume' ) return 'complete'; 

            if(
                    stage.Actions && 
                    stage.Actions.find(a => a.error || (a.DeliveryMethods && a.DeliveryMethods.find(dm => dm.error)))  
            ) {
                return 'failed'; 
            }

            if(stage.Actions &&  !stage.Actions.find(a => !a.completed) ) return 'complete'; 
            
            if(stage.date < this.current_timeline_date){    
                if(stage.Actions.find(a => !a.completed)) return "missed"
                return "complete"; 
            } 
            // else if(stage.execution_date === this.active_stage.execution_date ) {
            //     return "current"
            // } 
            else {
                return "future"
            }
        }, 

        stageTypeStatus (stage){
            if (this.stageStatus(stage) === 'paused' ){
                return 'current';
            }
            else if (this.stageStatus(stage) === 'complete'){
                return 'past';
            }
            else if(this.stageStatus(stage) === 'future'){
                return 'future';
            }
            else{
                return 'error';
            }
        }, 
        async getDelinquencies(){  
            this.loading = true;
            let r = await api.get(this, api.CONTACTS + this.contact.id + '/delinquencies')
            this.delinquencies = r.delinquencies;
            this.selected_delinquency = this.delinquencies[0].id
            this.loading = false;
             
        },
        getNameSectionDescription(value){ 
            const {type, by} = value || {}; 
            return type == 'pause' ? `Paused by ${by}` : type == 'resume' ? `Resumed by ${by}` : '';
        },
        getFontColor(value){
            const {type, end_date} = value || {};
            return (type == 'pause' && !end_date) ? '#FB4C4C' : '';
        },
        getNameSectionSubTitle(value){
            const { isLinkedStage, isPTODStage, days_after_action } = value || {};
            let sub_title = '';
            if (isPTODStage || isLinkedStage) {
                const actionType = isPTODStage ? 'Paid to Date' : 'completed action';
                const daysText = days_after_action === 0 ? 'Immediately' : `${days_after_action} Days`;
                sub_title = `${daysText} after ${actionType}`;
            }
            return sub_title;
        },
        getNameSectionSubTitleDescription(value){
            const {isLinkedStage, isPTODStage, parent_action_name, parent_action_desc} = value || {};
            let description = '';
            if(isPTODStage){
                let paidToDate = value?.paid_to_date || '';
                description = paidToDate ? moment(paidToDate).format('MMM D, YYYY') : '';
            }else if(isLinkedStage){
                description = `${parent_action_name} ${parent_action_desc && `(${parent_action_desc})`}`;
            }
            return description;
        },
        getTypeOfHbTimelineItemAction(action = {}, value = {}){
            const {error = null, completed = null, DeliveryMethods = []} = action;
            return (error || (!completed && this.stageTypeStatus(value) !=='future') || DeliveryMethods?.find(dm => dm.error)) ? 'error' 
                    : this.stageTypeStatus(value)==='future' ? 'default'
                    : 'success';
        },
        getHbTimelineItemSubActionDescription(deliveryMethod = []){
            let sentDetails = `${deliveryMethod.name} To ${deliveryMethod.sent_to}. Sent ${deliveryMethod?.error ? `` : `-`} ${moment(deliveryMethod?.completed).format('MMM DD, YYYY')}${deliveryMethod?.error ? `.` : ``} `;
            return deliveryMethod.error ? sentDetails + deliveryMethod.error : sentDetails;
        },
        getHbTimelineItemSubActionIcon(deliveryMethod = []){
            return deliveryMethod.error ? 'mdi-custom-delivery-error' : 'mdi-email-check-outline';
        },
        formateDate(value, format){
            if (!value || moment(value).format('MM/DD/YYYY') == 'Invalid date') return '';
            return moment(value).format(format); 
        }
    },
    watch: {
        '$route.params.contact_id'(){
            this.getDelinquencies();
        },
        propertyView(){
            this.getDelinquencies();
        },
        active_delinquencies(delinquencies){
            this.delinquencies = delinquencies; 
            if(this.delinquencies.length){
                this.selected_delinquency = this.delinquencies[0].id
            }
        }
    }
}
</script>
<style lang="scss" scoped >

    .delinquency-wrapper::v-deep .v-timeline-item__dot {
        z-index: 1!important;
    }

    .actions-link{
        border-bottom: 1px dashed #ccc;
        padding-bottom: 3px;
        cursor: pointer;
    }
    .timeline-error-msg.actions-link{
        border-bottom: 1px dashed #FB4C4C;
        padding-bottom: 3px;
    }
    
    .v-timeline-item{
        margin-bottom: 0;
    }
    .v-timeline-item.timeline-item.theme--light.timeline-item-paused,
    .v-timeline-item.timeline-item.theme--light.timeline-item-paused .hb-font-caption, 
    .v-timeline-item.timeline-item.theme--light.timeline-item-paused .v-list-item__content{
        color: #FB4C4C;
    }
    .hb-font-caption{
        color: #637381;

    }
    .v-timeline-item.timeline-item.theme--light.timeline-item-future,
    .v-timeline-item.timeline-item.theme--light.timeline-item-future .hb-font-caption, 
    .v-timeline-item.timeline-item.theme--light.timeline-item-future .v-list-item__content{
        color: #c4cdd5;
    }
    .timeline-action-description{
        border: 1px solid rgba(0, 0, 0, 0.12); 
        border-radius: 4px;
    }
    .timeline-error{
        border: 1px solid #FB4C4C; 
    }
    .timeline-error-msg{
        color: #FB4C4C; 
    }

    .delivery-method{
        border: 1px solid rgba(0, 0, 0, 0.12);
        background-color: #f9fafb; 
    }

    .no-task-message{
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #101318;
    }
    .delivery-method-error{
        color: #FB4C4C; 
        
    }

</style>
