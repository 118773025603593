<template>
  <div>
    <div class="px-4 pt-3 pb-2">
      <div v-for="product in feesServices" class="pb-1">
        <div class="align-start d-flex justify-space-between my-2">
          <div class="pr-2">
            <div class="hb-button-text-regular font-weight-medium pb-2">
              {{ product.name }} - {{ product.value }}%
            </div>
            <div class="hb-text-light hb-button-text-small">
              {{ product.description }}
            </div>
          </div>
          <div class="d-flex align-center">
            <div class="mr-2 pt-1" style="width: 60px;text-align: right;">{{ (type == 'fees') ? '' : '-' }}
              ${{ product.price }}
            </div>
            <hb-icon color="#637381" class="hand"
              @click="removeAddQty('remove_all', (type == 'promotion') ? product.id : product?.product_id)"
              small>mdi-close-circle</hb-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">

import api from '../../../assets/api.js';
import HbDatePicker from '../../assets/HummingbirdDatepicker';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { EventBus } from '../../../EventBus.js';
import { notificationMixin } from '../../../mixins/notificationMixin.js';

export default {
  name: "SendQuoteFees",
  mixins: [notificationMixin],
  data() {

  },
  components: {
  },

  created() {
  },
  props: ["feesServices", "unit_Id", "property_Id", "allPropertyUnits", "type"],
  computed: {

  },
  methods: {
    async removeAddQty(type, prodId) {
      let propertyUnitsParse = JSON.parse(JSON.stringify(this.allPropertyUnits));
      propertyUnitsParse = await Promise.all(
        propertyUnitsParse.map(async (propData) => {
          if (propData?.property?.id == this.property_Id) {
            const unitValues = await Promise.all(
              propData?.units.map(async (unitValue) => {
                if (unitValue.unit_id == this.unit_Id) {
                  if (this.type == "discount") {
                    unitValue.selectedDiscount = '';
                    unitValue.isSelectDiscount = false;
                  } else if (this.type == "promotion") {
                    let allPromotion = JSON.parse(JSON.stringify(unitValue?.unitLevelPromotions))
                    unitValue.selectedPromotions = unitValue.selectedPromotions
                      .map((promoData) => {
                        if (promoData.id == prodId) {
                          // remove the item
                          return null;
                        }
                        return promoData;
                      })
                      .filter((item) => item !== null); // Remove null items
                    const filteredArray = allPromotion.filter(item => !unitValue.selectedPromotions.some(subItem => subItem.id == item.id));
                    unitValue.filteredPromotions = filteredArray;
                  } else {
                    unitValue.selectedFees = unitValue.selectedFees
                      .map((feeData) => {
                        if (feeData.product_id == prodId) {
                          // remove the item
                          return null;
                        }
                        return feeData;
                      })
                      .filter((item) => item !== null); // Remove null items
                  }
                }
                return unitValue;
              })
            );
            propData.units = unitValues;
          }
          return propData;
        })
      );

      await this.$store.dispatch(
        "sendQuoteStore/getPropertyUnitsInfo",
        propertyUnitsParse
      );
    }
  },
  destroyed() {
  }
}
</script>