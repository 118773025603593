<template>
	<v-row class="hb-font-body" no-gutters>
		<div class="hb-inline-sentence-text">Protection Plan $</div>
			<HbTextField
				box
				condensed
				small
				:disabled="!isSelected"
				v-model="coverage_value"
				v-validate="'required|max:100'"
				name="coverage_value"
				id="coverage_value"
				@input="onInputChange"
			/>
		<div class="hb-inline-sentence-text">,000 Coverage</div>
	</v-row>
</template>

<script type="text/babel">
  import { notificationMixin } from '../../mixins/notificationMixin.js';
  export default {
    name: "TableRateLabel",
    data() {
      return {
        coverage_value: "",
      }
    },
    mixins: [ notificationMixin ],
    methods: {
      onInputChange: _.debounce(function(){
        this.validate();
        this.$emit('updateCoverageValue', this.coverage_value);
      }, 1000),
      validate() {
        if(this.insurances.length) {
          const tiers = this.insurances[0]?.TableRateTiers;
          if(tiers.length) {
            const lastTier = tiers[tiers.length - 1];
            if(this.coverage_value == "" || this.coverage_value <= 0) {
              this.showMessageNotification({ type: "error", description: "Coverage value must be greater than 0" });
              this.coverage_value = 1;
            } else if(this.coverage_value > (lastTier.coverage_limit / lastTier.coverage_unit)) {
              this.showMessageNotification({ type: "error", description: `Coverage value must be less than ${lastTier.coverage_limit + lastTier.coverage_unit}` });
              this.coverage_value = (lastTier.coverage_limit / lastTier.coverage_unit);
            }
          }
        }
      },
    },
    props: {
      insurances: {
        type: Array,
        required: true
      },
      isSelected: {
        type: Boolean,
        required: true
      },
    }
  }
</script>
