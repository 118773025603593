<template>
  <hb-expansion-panel
    key="coverageOptions"
    :left-cols="8"
    :right-cols="4"
  >
    <template v-slot:title>
      <span>Coverage Options</span>
      <span
        class="pl-3"
        @click.stop
      >
        <hb-checkbox
          v-if="isPropertyLevel"
          v-model="coverageData.useCorporateDefaults"
          @change="handleUseCoverageCorporateDefaults"
          :readonly="!coverageData.is_found"
          label="Use Corporate Default"
        ></hb-checkbox>
      </span>
    </template>
    <template v-slot:actions>
      <span @click.stop>
        <hb-switch
          class="mx-2"
          v-model="coverageData.is_enabled"
          @change="handleCoverageToggle($event)"
        />
      </span>
    </template>
    <template v-slot:content>
      <hb-form
        label="Coverage Type"
        description="Select the Coverage Option to use for this storage type. "
        full
      >
        <hb-radio-group
          row
          v-model="coverageData.coverageType"
          mandatory
          class="pa-0 mb-n3"
        >
          <hb-radio :value="INSURANCE.Library">
            <template v-slot:label> Library </template>
            <template v-slot:tooltipBody> Select this option to use library coverages </template>
          </hb-radio>

          <hb-radio :value="INSURANCE.TableRate">
            <template v-slot:label> Table Rate </template>
            <template v-slot:tooltipBody> Select this option to use tiers-based coverage </template>
          </hb-radio>
        </hb-radio-group>
      </hb-form>

      <hb-form
        v-if="coverageData.coverageType === INSURANCE.TableRate"
        label="Name"
        full
      >
        <HbTextField
          v-model="coverageData.coverageName"
          v-validate="'max:256|required'"
          data-vv-name="name"
          data-vv-as="Name"
          :error="errors.has('name')"
          placeholder="Enter Name of Coverage"
        />
      </hb-form>
      <hb-form
        v-if="coverageData.coverageType === INSURANCE.TableRate"
        label="Description"
        full
      >
        <HbTextField
          v-model="coverageData.coverageDescription"
          v-validate="'max:256'"
          data-vv-name="description"
          data-vv-as="Description"
          :error="errors.has('description')"
          placeholder="Describe Coverage"
        />
      </hb-form>

      <hb-form
        v-if="coverageData.coverageType === INSURANCE.TableRate"
        label="Monthly Premiums"
        full
      >
        <v-row
          no-gutters
          class="pa-0 mb-n3"
          v-for="(tier, i) in coverageData.tableRateTiers"
          :key="i"
        >
          <hb-text-field
            condensed
            box
            medium
            v-model="tier.premium_rate"
            v-validate="'required|numeric|min_value:1|decimal:2'"
            data-vv-name="monthlyPremium"
            data-vv-as="Monthly Premiums"
            :error="errors.has('monthlyPremium')"
            placeholder="0"
          >
            <template v-slot:prepend-inner> $ </template>
          </hb-text-field>

          <div class="hb-inline-sentence-text">/$1,000 of coverage upto</div>

          <hb-text-field
            condensed
            box
            medium
            v-model="tier.coverage_limit"
            v-validate="'required|numeric|min_value:1'"
            data-vv-name="coverageAmount"
            data-vv-as="Monthly Premiums"
            :error="errors.has('coverageAmount')"
            placeholder="0"
          >
            <template v-slot:prepend-inner> $ </template>
          </hb-text-field>
          <hb-icon
            v-if="i >= 1"
            @click="removeTier(tier.coverage_limit)"
            class="hand pt-1"
            >mdi-close-circle</hb-icon
          >
        </v-row>
        <a
          class="ma-0 pa-0"
          @click="addTier(coverageData.tableRateTiers.length)"
          >+ Add New Tier</a
        >
      </hb-form>

      <hb-form
        v-if="coverageData.coverageType === INSURANCE.TableRate"
        label="Taxable"
        full
      >
        <hb-radio-group
          row
          class="pa-0 mb-n3"
          v-model="coverageData.isTaxable"
        >
          <HbRadio
            label="Yes"
            :value="1"
          />
          <HbRadio
            label="No"
            :value="0"
          />
        </hb-radio-group>
      </hb-form>

      <hb-form
        v-if="coverageData.coverageType === INSURANCE.TableRate"
        label="Prorate In"
        full
      >
        <hb-radio-group
          row
          v-model="coverageData.isProrateIn"
          class="pa-0 mb-n3"
        >
          <HbRadio
            label="Yes"
            :value="1"
          />
          <HbRadio
            label="No"
            :value="0"
          />
        </hb-radio-group>
      </hb-form>

      <hb-form
        v-if="coverageData.coverageType === INSURANCE.TableRate"
        label="Prorate Out"
        full
      >
        <hb-radio-group
          row
          v-model="coverageData.isProrateOut"
          class="pa-0 mb-n3"
        >
          <HbRadio
            label="Yes"
            :value="1"
          />
          <HbRadio
            label="No"
            :value="0"
          />
        </hb-radio-group>
      </hb-form>

      <hb-form
        v-if="coverageData.coverageType === INSURANCE.TableRate"
        label="Income Account"
      >
        <hb-autocomplete
          name="income_account"
          hide-details
          label="Select an Account"
          :items="accounts"
          :clearable="false"
          v-validate="'required'"
          data-vv-as="income account"
          item-value="id"
          item-text="name"
          v-model="coverageData.incomeAccount"
        >
          <template v-slot:selection="{ item }">
            {{ item.code }} : {{ item.name }}
          </template>
          <template v-slot:item="{ item }">
            {{ item.code }} : {{ item.name }}
          </template>
        </hb-autocomplete>
      </hb-form>
      <space-selector
        :selectedSpaceTypes="coverageData.selectedSpaceTypes"
        @spaceTypeUpdate="spaceTypesUpdated"
      >
      </space-selector>
      <hb-bottom-action-bar
        v-if="showSaveButton"
        @close="handleCancelCoverage"
      >
        <template v-slot:right-actions>
          <hb-btn @click="saveCoverageOptions">Save</hb-btn>
        </template>
      </hb-bottom-action-bar>
    </template>
  </hb-expansion-panel>
</template>

<script type="text/babel">
import { mapGetters, mapActions } from "vuex";
import api from "../../../assets/api.js";
import { notificationMixin } from "../../../mixins/notificationMixin.js";
import INSURANCE from "@/constants/insurance.js";
import SpaceSelector from "./SpaceSelector.vue";
export default {
  name: "CoverageOptions",
  mixins: [notificationMixin],
  props: {
    activeTab: {
      type: String,
      required: true,
      default: "property",
    },
    coverage: {
      required: true,
    },
    level: { type: String, required: true },
  },
  data() {
    return {
      accounts:[],
      coverageData: {},
    };
  },
  async created() {
    this.coverageData = JSON.parse(JSON.stringify(this.coverage));
    this.INSURANCE = INSURANCE;
    await this.fetchAccounting();
  },
  components: {
    SpaceSelector,
  },
  computed: {
    ...mapGetters({
      defaultProperty: "propertiesStore/defaultProperty",
      properties: "propertiesStore/filtered",
      hasRolePermission: "authenticationStore/rolePermission",
      unitTypes: "filterStore/unit_type",
    }),
    showSaveButton() {
      if (this.level !== "property") return true;
      return this.coverageData.is_found && !this.coverageData.useCorporateDefaults;
    },    
    isPropertyLevel() {
      return this.level === "property";
    },
  },

  watch: {
    coverage: {
      handler(val){
       this.coverageData = val;
     },
     deep: true
    },
  },

  methods: {
    ...mapActions({
      setDefaultProperty: "propertiesStore/setDefaultProperty",
    }),
    async fetchAccounting() {
      let p = await api.get(this, api.ACCOUNTING + "accounts");
      this.accounts = p.accounts
        ? p.accounts.filter(
            (a) =>
              a.category &&
              ["income", "expense", "liability"].includes(
                a.category.toLowerCase()
              )
          )
        : [];
    },
    spaceTypesUpdated(types){
      this.$emit('spaceTypesUpdated', types);
    },
    async validateOpenForm() {
      let status = await this.$validator.validateAll();
      if(this.coverageData.coverageType === INSURANCE.TableRate && this.coverageData.tableRateTiers.length < 1){
        status = false;
        this.showMessageNotification({description: 'Table Rate Insurance with at least 1 tier is required for Table Rate Insurance Template' });
      }
      return status;
    },
    handleCancelCoverage() {
      this.$emit("handleCancelCoverage");
    },
    handleCoverageToggle(value) {
      this.coverageData.is_enabled = value || false;
      this.$emit("handleCoverageToggle", value || false);
    },
    async saveCoverageOptions() {    
      let status = await this.validateOpenForm();
      if (!status) return;
      this.$emit("saveCoverageOptions", this.coverageData);
    },
    addTier() {
      this.coverageData.tableRateTiers.push({
        premium_rate: 0,
        coverage_limit: 0,
      });
    },
    removeTier(coverage_limit) {
      this.coverageData.tableRateTiers = this.coverageData.tableRateTiers
        ? this.coverageData.tableRateTiers.filter(
            (tier) => tier.coverage_limit !== coverage_limit
          )
        : [];
    },
    handleUseCoverageCorporateDefaults(value) {
      if (value) this.confirmUncheckCoverageCorporate = true;
      this.$emit("handleUseCoverageCorporateDefaults", value);
    },
  },
};
</script>

<style lang="scss"></style>
