<script>
export default {
  props: {
    content: {
      type: String,
      required: true,
    },
    styleProps: {
            type: Object,
            default: () => ({}),
        },
  },
  mounted() {
    this.initializeShadowRoot();
    this.updateShadowContent();
  },
  watch: {
    content: {
      immediate: true,
      handler() {
        this.updateShadowContent();
      },
    },
  },
  methods: {
    initializeShadowRoot() {
      if (!this.shadowRoot) {
        this.shadowRoot = this.$el.attachShadow({ mode: 'open' });
      }
    },
    updateShadowContent() {
      if (this.shadowRoot) {
        this.shadowRoot.innerHTML = this.content || ''; // Update Shadow DOM content
      }
    },
  },
  render(h) {
    return h('div'); // Placeholder element for Shadow DOM
  },
};
</script>
