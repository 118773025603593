<template>
  <div>
    <hb-modal 
      v-model="dialog"
      :title="titleText"
      @close="closeWindow"
    >
        <template v-slot:subheader>
          {{subHeaderText}}
        </template>

        <template v-slot:content >
          <div style="height:568px;">
              <hb-form label="Group Name" required>
                <HbTextField
                  v-model="formData.name"
                  v-validate="'required|max:45'"
                  data-vv-scope="default"
                  data-vv-name="group_name"
                  data-vv-as="Group Name"
                  :error="errors.has('default.group_name')"
                  placeholder="Enter Group Name"
                >
                </HbTextField> 
              </hb-form>
              <hb-form label="Description" required>
                 <HbTextField
                  v-model="formData.description"
                  v-validate="'required'"
                  data-vv-scope="default"
                  data-vv-name="description"
                  data-vv-as="Group Description"
                  :error="errors.has('default.description')"
                  placeholder="Enter Group Description"
                >
                </HbTextField> 
              </hb-form>
              <hb-form label="Group Criteria" required>
                <hb-select
                  v-model="formData.property_group_type"
                  :items="propertyGroupTypes"
                  :item-text="(item) => getPropertyGroupTypeDisplayText(item.id)"
                  item-value="id"
                  v-validate="'required'"
                  data-vv-scope="default"
                  data-vv-as="Property Group Criteria"
                  name="property_group_type"
                  placeholder="Select Group Criteria"
                  :error="errors.collect('default.property_group_type').length > 0"
                  return-object
                >
                </hb-select>
              </hb-form>
              <hb-form v-if="formData.property_group_type?.label === 'custom'" label="Properties" required>
                <div class="pb-2">
                  <span style="color: #637381">
                    Select desired Properties for your Group Criteria
                  </span>
                </div>
                <div>
                  <hb-combobox
                    v-model="formData.properties"
                    :items="properties"
                    v-validate="'required'"
                    id="properties"
                    name="properties"
                    data-vv-scope="default"
                    data-vv-as="Properties"
                    data-vv-name="properties"
                    label="Select Properties"
                    :error="errors.collect('default.properties').length > 0"
                    item-value="id"
                    item-text="name"
                    key="properties"
                    return-object
                  >
                    <template v-slot:item="{item, attrs}">
                      <v-list-item-action> 
                        <HbIcon
                          :mdi-code="attrs.inputValue ? 'check_box' : 'mdi-checkbox-blank-outline'"
                          :color="attrs.inputValue ? '#00848E' : '#9b9b9b'"
                        />
                      </v-list-item-action>
                      <v-list-item-content> 
                          <v-list-item-title> 
                              <span>{{ item.number }} - {{item.name}}</span>
                          </v-list-item-title> 
                      </v-list-item-content> 
                    </template>
                  </hb-combobox>
                </div>
              </hb-form>
              <hb-form v-if="formData.property_group_type?.label === 'country'" label="Countries" required>
                <div 
                  v-if="!countries?.length"
                  :style="{ width: $vuetify.breakpoint.width < 770? '100%' : '520px' }"
                  >
                  <hb-notification
                    type="error"
                    not-dismissable
                  >
                    No configurations found for 'Country' in Property Settings for all properties. Please configure this setting to save your New Property Group.    
                  </hb-notification>
                </div>
                <div class="pb-2">
                  <span style="color: #637381">
                    Select desired Countries for your Group Criteria
                  </span>
                </div>
                <div>
                  <hb-combobox
                    v-model="formData.country"
                    :items="countries"
                    item-text="name"
                    v-validate="'required'"
                    id="countries"
                    name="countries"
                    data-vv-scope="default"
                    data-vv-as="Countries"
                    data-vv-name="Countries"
                    label="Select Countries"
                    :error="errors.collect('default.countries').length > 0"
                    key="countries"
                    return-object
                  >
                  </hb-combobox>
                </div>
              </hb-form>
              <hb-form v-if="formData.property_group_type?.label === 'state'" label="States" required>
                <div 
                  v-if="!states?.length"
                  :style="{ width: $vuetify.breakpoint.width < 770? '100%' : '520px' }"
                  >
                  <hb-notification
                    type="error"
                    not-dismissable
                  >
                    No configurations found for 'State' in Property Settings for all properties. Please configure this setting to save your New Property Group.    
                  </hb-notification>
                </div>
                <div class="pb-2">
                  <span style="color: #637381">
                    Select desired States for your Group Criteria
                  </span>
                </div>
                <div>
                  <hb-combobox
                    v-model="formData.state"
                    :items="states"
                    item-text="name"
                    v-validate="'required'"
                    id="states"
                    name="states"
                    data-vv-scope="default"
                    data-vv-as="States"
                    data-vv-name="States"
                    label="Select States"
                    :error="errors.collect('default.states').length > 0"
                    key="states"
                    return-object
                  >
                  </hb-combobox>
                </div>
              </hb-form>
              <hb-form v-if="formData.property_group_type?.label === 'market_region'" label="Market Regions" required>
                <div 
                  v-if="!market_regions?.length"
                  :style="{ width: $vuetify.breakpoint.width < 770? '100%' : '520px' }"
                  >
                  <hb-notification
                    type="error"
                    not-dismissable
                  >
                    No configurations found for 'Market Region' in Property Settings for all properties. Please configure this setting to save your New Property Group.    
                  </hb-notification>
                </div>
                <div class="pb-2">
                  <span style="color: #637381">
                    Select desired Market Regions for your Group Criteria
                  </span>
                </div>
                <div>
                  <hb-combobox
                    v-model="formData.market_region"
                    :items="market_regions"
                    item-text="name"
                    v-validate="'required'"
                    id="market_regions"
                    name="market_regions"
                    data-vv-scope="default"
                    data-vv-as="Market Regions"
                    data-vv-name="Market Regions"
                    label="Select Market Regions"
                    :error="errors.collect('default.market_regions').length > 0"
                    key="market_regions"
                    return-object
                  >
                  </hb-combobox>
                </div>
              </hb-form>
              <hb-form v-if="formData.property_group_type?.label === 'operations_manager'"  label="Management Contact" required>
                <div 
                  v-if="!operations_managers?.length"
                  :style="{ width: $vuetify.breakpoint.width < 770? '100%' : '520px' }"
                  >
                  <hb-notification
                    type="error"
                    not-dismissable
                  >
                    No configurations found for 'Management Contact' in Property Settings for all properties. Please configure this setting to save your New Property Group.    
                  </hb-notification>
                </div>
                <div class="pb-2" :style="{ width: $vuetify.breakpoint.width < 740? '100%' : '400px' }">
                  <span style="color: #637381">
                    Select desired Management Contacts for your Group Criteria
                  </span>
                </div>
                <div>
                  <hb-combobox
                    v-model="formData.operations_manager"
                    :items="operations_managers"
                    item-text="name"
                    v-validate="'required'"
                    id="operations_managers"
                    name="operations_managers"
                    data-vv-scope="default"
                    data-vv-as="Management Contacts"
                    data-vv-name="Management Contacts"
                    label="Select Management Contacts"
                    :error="errors.collect('default.operations_managers').length > 0"
                    key="operations_managers"
                    return-object
                  >
                  </hb-combobox>
                </div>
              </hb-form>
              <hb-form v-if="formData.property_group_type?.label === 'year_opened'"  label="Year Opened" required>
                <div 
                  v-if="!hasYearOpenedConfig"
                  :style="{ width: $vuetify.breakpoint.width < 770? '100%' : '520px' }"
                  >
                  <hb-notification
                    type="error"
                    not-dismissable
                  >
                    No configurations found for 'Year Opened' in Property Settings for all properties. Please configure this setting to save your New Property Group.    
                  </hb-notification>
                </div>
                <v-row class="pt-0 pb-3 ma-0 pr-0 pl-0" :style="{ width: $vuetify.breakpoint.width < 750? '100%' : '520px' }">
                  <span style="color: #637381">
                    Set the range in which you would like to group properties based on the year that they were opened
                  </span>
                </v-row>
                <v-row v-for="(e, i) in formData.year_opened" class="pt-0 pb-0 ma-0 pr-0 pl-0">
                  <v-col cols="4" class="pt-0 pb-0 ma-0 pr-0 pl-0" >
                    <hb-select
                      box
                      condensed
                      v-model="formData.year_opened[i].start"
                      :items="getYearsList(i, 'start')"
                      item-text="name"
                      item-value="label"
                      placeholder="YYYY"
                    ></hb-select>
                  </v-col>
                  <v-col
                    cols="1"
                    class="pt-0 pb-0 ma-0 pr-0"
                    :class="{
                      'pl-0': $vuetify.breakpoint.xs,
                      'pl-1': !$vuetify.breakpoint.xs
                  }">
                    <div class="hb-inline-sentence-text">to</div>  
                  </v-col>             
                  <v-col cols="4" class="pt-0 pb-0 ma-0 pr-0 pl-0">
                    <hb-select
                      box
                      condensed
                      v-model="formData.year_opened[i].end"
                      :items="getYearsList(i, 'end')"
                      item-text="name"
                      item-value="label"
                      placeholder="YYYY"
                    ></hb-select>
                  </v-col>
                  <v-col cols="1" class="pt-0 pb-0 ma-0 pr-0 pl-0 mt-n1 ml-n2">
                    <hb-btn
                      icon
                      small
                      :activeStateOff="true"
                      color="#CCCCCC"
                      hoverBackgroundColor="#FFFFFF"
                      hoverColor="#000000"
                      tooltip="Remove"
                      mdi-code="mdi-close-circle"
                      @click="removeRange(i)"
                    />
                  </v-col>
                </v-row>
                <v-row class="pt-0 pb-0 ma-0 pr-0 pl-0">
                  <hb-link @click="addRange">+ Add Additional Range</hb-link>
                </v-row>
              </hb-form>
              <hb-form v-if="formData.property_group_type?.label === 'total_sqft'"  label="Total SQFT" required>
                <v-row class="pt-0 pb-3 ma-0 pr-0 pl-0" :style="{ width: $vuetify.breakpoint.width < 750? '100%' : '520px' }">
                  <span style="color: #637381">
                    Set the square footage range in which you would like to group properties by their total area
                  </span>
                </v-row>
                <v-row v-for="(e, i) in formData.total_sqft" class="pt-0 pb-0 ma-0 pr-0 pl-0">
                  <v-col cols="4" class="pt-0 pb-0 ma-0 pr-0 pl-0">
                    <hb-text-field
                      box
                      condensed
                      v-model.number="formData.total_sqft[i].start"
                      placeholder="0 SQ FT"
                      type="number"
                    >
                    </hb-text-field>
                  </v-col>
                  <v-col
                    cols="1"
                    class="pt-0 pb-0 ma-0 pr-0"
                    :class="{
                      'pl-0': $vuetify.breakpoint.xs,
                      'pl-1': !$vuetify.breakpoint.xs
                  }">
                    <div class="hb-inline-sentence-text">to</div>
                  </v-col>
                  <v-col cols="4" class="pt-0 pb-0 ma-0 pr-0 pl-0">              
                    <hb-text-field
                      box
                      condensed
                      v-model.number="formData.total_sqft[i].end"
                      placeholder="0 SQ FT"
                      type="number"
                    ></hb-text-field>
                  </v-col>
                  <v-col cols="1" class="pt-0 pb-0 ma-0 pr-0 pl-0 mt-n1 ml-n2">
                    <hb-btn
                      icon
                      small
                      :activeStateOff="true"
                      color="#CCCCCC"
                      hoverBackgroundColor="#FFFFFF"
                      hoverColor="#000000"
                      tooltip="Remove"
                      mdi-code="mdi-close-circle"
                      @click="removeRange(i)"
                    />
                  </v-col>
                </v-row>
                <v-row class="pt-0 pb-0 ma-0 pr-0 pl-0">
                  <hb-link @click="addRange">+ Add Additional Range</hb-link>
                </v-row>
              </hb-form>
              <hb-form v-if="formData.property_group_type?.label === 'performance_category'" label="Performance Categories" required>
                <div 
                  v-if="!hasPerformanceCategoryConfig"
                  :style="{ width: $vuetify.breakpoint.width < 770? '100%' : '520px' }"
                  >
                  <hb-notification
                    type="error"
                    not-dismissable
                  >
                    No configurations found for 'Performance Category' in Property Settings for all properties. Please configure this setting to save your New Property Group.    
                  </hb-notification>
                </div>
                <div class="pb-2" :style="{ width: $vuetify.breakpoint.width < 700? '100%' : '520px' }">
                  <span style="color: #637381">
                    Select desired Performance Categories for your Group Criteria
                  </span>
                </div>
                <div>
                  <hb-combobox
                    v-model="formData.performance_category"
                    :items="performance_categories"
                    item-text="name"
                    v-validate="'required'"
                    id="performance_categories"
                    name="performance_categories"
                    data-vv-scope="default"
                    data-vv-as="Performance Categories"
                    data-vv-name="Performance Categories"
                    label="Select Performance Categories"
                    :error="errors.collect('default.performance_categories').length > 0"
                    key="performance_categories"
                    return-object
                  >
                  </hb-combobox>
                </div>
              </hb-form>
              <hb-form v-if="formData.property_group_type?.label === 'same_store_class'"  label="Same Store Class" required>
                <div 
                  v-if="!hasSameStoreClassConfig"
                  :style="{ width: $vuetify.breakpoint.width < 770? '100%' : '520px' }"
                  >
                  <hb-notification
                    type="error"
                    not-dismissable
                  >
                    No configurations found for 'Same Store Class' in Property Settings for all properties. Please configure this setting to save your New Property Group.    
                  </hb-notification>
                </div>
                <hb-radio-group
                  row
                  v-model="formData.same_store_class_category"
                >
                  <HbRadio
                    label="Type"
                    value="type"
                  />
                  <HbRadio
                    label="Range"
                    value="range"
                  />
                </hb-radio-group>
                <div v-if="formData.same_store_class_category == 'type'">
                  <v-row class="pt-0 pb-3 ma-0 pr-0 pl-0" :style="{ width: $vuetify.breakpoint.width < 750? '100%' : '520px' }">
                    <span style="color: #637381">
                      Select desired same store class types for your group criteria
                    </span>
                  </v-row>
                  <hb-combobox
                    v-model="formData.same_store_class_type"
                    :items="same_store_class_types"
                    item-text="name"
                    v-validate="'required'"
                    id="same_store_class_types"
                    name="same_store_class_types"
                    data-vv-scope="default"
                    data-vv-as="Same Store Class Types"
                    data-vv-name="Same Store Class Types"
                    label="Select Same Store Class Types"
                    :error="errors.collect('default.same_store_class_types').length > 0"
                    key="same_store_class_types"
                    return-object
                  >
                  </hb-combobox>
                </div>
                <div v-if="formData.same_store_class_category == 'range'">
                  <v-row class="pt-0 pb-3 ma-0 pr-0 pl-0" :style="{ width: $vuetify.breakpoint.width < 750? '100%' : '520px' }">
                    <span style="color: #637381">
                    Set the range in which you would like to group properties by the same store class
                    </span>
                  </v-row>
                  <v-row v-for="(e, i) in formData.same_store_class" class="pt-0 pb-0 ma-0 pr-0 pl-0">
                    <v-col cols="2" class="pt-0 pb-0 ma-0 pr-0 pl-0">
                      <hb-select
                        box
                        condensed
                        v-model.number="formData.same_store_class[i].start"
                        :items="getSameStoreClassList(i, 'start')"
                        item-text="name"
                        item-value="label"
                        placeholder="0"
                      ></hb-select>
                    </v-col>
                    <v-col
                      cols="1"
                      class="pt-0 pb-0 ma-0 pr-0"
                      :class="{
                        'pl-0': $vuetify.breakpoint.xs,
                        'pl-1': !$vuetify.breakpoint.xs
                    }">
                      <div class="hb-inline-sentence-text">to</div>
                    </v-col>       
                    <v-col cols="2" class="pt-0 pb-0 ma-0 pr-0 pl-0">
                      <hb-select
                        box
                        condensed
                        v-model.number="formData.same_store_class[i].end"
                        :items="getSameStoreClassList(i, 'end')"
                        item-text="name"
                        item-value="label"
                        placeholder="0"
                      ></hb-select>
                    </v-col>
                    <v-col cols="1" class="pt-0 pb-0 ma-0 pr-0 pl-0 mt-n1 ml-n2">
                      <hb-btn
                        icon
                        small
                        :activeStateOff="true"
                        color="#CCCCCC"
                        hoverBackgroundColor="#FFFFFF"
                        hoverColor="#000000"
                        tooltip="Remove"
                        mdi-code="mdi-close-circle"
                        @click="removeRange(i)"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="pt-0 pb-0 ma-0 pr-0 pl-0">
                    <hb-link @click="addRange">+ Add Additional Range</hb-link>
                  </v-row>
                </div>
              </hb-form>
          </div>
        </template>
        <template v-slot:right-actions>
          <hb-btn color="primary" @click="submitForm">
            Save
          </hb-btn>
        </template>
    </hb-modal>
  </div>
</template>

<script type="text/babel">
import { mapActions, mapGetters } from "vuex";
import { notificationMixin } from  '@/mixins/notificationMixin.js';
import { propertyGroupTypeMixin } from '@/mixins/propertyGroupTypeMixin.js';
import HbDatePicker from '../../assets/HummingbirdDatepicker.vue';
import moment from 'moment';
export default {
  name:  "AddEditPropertyGroups",
  mixins: [notificationMixin,propertyGroupTypeMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    properties: {
      type: Array,
      default: []
    },
    propertyGroup: {
      type: Object,
      default: {}
    },
    selectedPropertyGroupTypeId: {
      type: String,
      default: null
    },
    admins: {
      type: Array,
      default: []
    },
    changeGroupTypeTableView: {
      type: Function,
      required: true 
    }
  },
  created() {
    this.setCountriesList();
    this.setStatesList();
    this.setMarketRegionsList();
    this.setOperationsManagersList();
    this.setYearsList();
    this.setPerformanceCategoriesList();
    this.setSameStoreClassList();
    this.hasYearOpenedConfig = this.properties?.some(property => property.year_opened);
    this.hasPerformanceCategoryConfig = this.properties?.some(property => property.performance_category);
    this.hasSameStoreClassConfig = this.properties?.some(property => property.same_store_class);
    
    this.editMode = !!this.propertyGroup.id;
    
    if(this.editMode) {
      let property_group = JSON.parse(JSON.stringify(this.propertyGroup));
      this.formData = {
        name: property_group.name,
        description: property_group.description,
        property_group_type: this.getPropertyGroupType(property_group.property_group_type_id),
        properties: property_group.properties,
        country: this.countries.filter(item => property_group.filters?.country?.includes(item.label)) || [],
        state: this.states.filter(item => property_group.filters?.state?.includes(item.label)) || [],
        market_region: this.market_regions.filter(item => property_group.filters?.market_region?.includes(item.label)) || [],
        operations_manager: this.operations_managers.filter(item => property_group.filters?.operations_manager?.includes(item.label)) || [],
        year_opened: property_group.filters?.year_opened || [{ start:'', end:'' }],
        total_sqft: property_group.filters?.total_sqft || [{ start:'', end:'' }],
        performance_category: this.performance_categories.filter(item => property_group.filters?.performance_category?.includes(item.label)) || [],
        same_store_class: property_group.filters?.same_store_class && property_group.filters?.category == 'range'? 
          property_group.filters?.same_store_class : [{ start:'', end:'' }],
        same_store_class_category: property_group.filters?.same_store_class? property_group.filters?.category : 'type',
        same_store_class_type: property_group.filters?.same_store_class && property_group.filters?.category == 'type'?
          this.same_store_class_types.filter(item => property_group.filters?.same_store_class?.includes(item.label)) : [],
      };
    }
    else {
      this.formData.property_group_type = this.selectedPropertyGroupTypeId? this.getPropertyGroupType(this.selectedPropertyGroupTypeId) : null;
    }
  },

  data( ) {
    return {
      formData: {
        name: '',
        description: '',
        property_group_type: null,
        properties: [],
        country: [],
        state: [],
        market_region: [],
        operations_manager: [],
        year_opened: [{ start:'', end:'' }],
        total_sqft:  [{ start:'', end:'' }],
        performance_category: [],
        same_store_class: [{ start:'', end:'' }],
        same_store_class_category: 'type',
        same_store_class_type: [],
      },
      editMode: false,
      countries: [],
      states: [],
      market_regions: [],
      operations_managers: [],
      performance_categories: [],
      years_list: [],
      same_store_classes: [],
      same_store_class_types: [],
    }
  },
  components:{
    HbDatePicker
  },
  computed: {
    ...mapGetters({
      selectedPropertyGroup: 'propertiesStore/selectedPropertyGroup'
    }),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    titleText() {
      return this.editMode ? `Edit Property Group` : `Add New Property Group`;
    },
    subHeaderText() {
      return this.editMode ? `Edit and manage property groups.` : `Add and manage property groups.`;
    },

  },
  methods: {
    ...mapActions({
            exchangeToken: 'propertiesStore/exchangeToken',
            fetchPropertyGroups: "propertiesStore/fetchPropertyGroups",
            addPropertyGroup: "propertiesStore/addPropertyGroup",
            updatePropertyGroup: "propertiesStore/updatePropertyGroup"
        }),
    async submitForm() {
      const valid = await this.$validator.validateAll('default');
      if(!valid) return;

      let errors = [];

      if(this.formData.property_group_type?.options?.type === 'range' || this.formData.property_group_type?.label === 'same_store_class' &&  this.formData.same_store_class_category === 'range') {
        errors = this.validateRanges();
      }

      if(errors && errors.length) {
        this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: errors });
        return
      }

      try {
        let data = {
          name: this.formData.name,
          description: this.formData.description,
          property_group_type_id: this.formData.property_group_type.id,
        }

        let category = this.formData.property_group_type?.label;
        if(category == 'same_store_class' && this.formData.same_store_class_category == 'type') {
          category = 'same_store_class_type';
        }
        switch (category) {
          case 'custom':
            data.property_ids = this.formData.properties?.map(p => p.id);
            break;
          case 'country':
          case 'state':
          case 'market_region':
          case 'operations_manager':
          case 'performance_category':
          case 'same_store_class_type':
            data.filters = { [this.formData.property_group_type?.label] : this.formData[category].map(item => item.label) };
            break;
          default:
            data.filters = { [category] : this.formData[category] }
            break;
        }

        if(this.formData.property_group_type?.label == 'same_store_class') {
          data.filters.category = this.formData.same_store_class_category;
        }

        if(this.editMode) {
          let payload = {propertyGroupId: this.propertyGroup.id, data}
          
          await this.updatePropertyGroup(payload);
          
          if(this.selectedPropertyGroup.id === this.propertyGroup.id) 
            await this.exchangeToken({ property_ids: this.selectedPropertyGroup.properties?.map(p => p.id) });
          
          this.showMessageNotification({type: `success`, description: `Edits have been made to group - ${data.name}`});
        }
        else {
          await this.addPropertyGroup(data);
          this.showMessageNotification({type: `success`, description: `A new group has been added - ${data.name}`});
          this.changeGroupTypeTableView(data.property_group_type_id);
        }
        this.resetFormData();
        this.$emit("close");
      }
      catch(err) {
        this.showMessageNotification({ description: err })
      }

    },
    closeWindow() {
      this.$emit("close");
      this.resetFormData();
    },
    resetFormData() {
      this.formData = {
        name: '',
        description: '',
        property_group_type: null,
        properties: [],
        country: [],
        state: [],
        market_region: [],
        operations_manager: [],
        year_opened: [{ start:'', end:'' }],
        total_sqft:  [{ start:'', end:'' }],
        performance_category: [],
        same_store_class: [{ start:'', end:'' }]
      }
    },
    addRange() {
      this.formData[this.formData.property_group_type?.label].push({ start:'', end:'' });
    },
    removeRange(i) {
      this.formData[this.formData.property_group_type?.label].splice(i, 1);
    },
    validateRanges() {
      let errors = [];

      if(!this.formData[this.formData.property_group_type?.label]?.length) {
          errors.push('Please include at least 1 range');
          return errors;
      }

      let ranges = Array.from(this.formData[this.formData.property_group_type?.label]);
      ranges.sort((r1, r2) => r1.start - r2.start);

      for(let idx=0; idx<ranges.length; idx++) {

        if(!ranges[idx].start.toString() ||
           !ranges[idx].end.toString()
          ) {
          errors.push('Please select all the ranges you want to include');
          break;
        }

        if(!Number.isInteger(+ranges[idx].start) ||
           +ranges[idx].start < 0 ||
           !Number.isInteger(+ranges[idx].end) ||
           +ranges[idx].end < 0
          ) {
          errors.push('Please use positive numbers');
          break;
        }

        if(idx > 0 && 
           ranges[idx].start <= ranges[idx-1].end
          ) {
          errors.push('Please ensure that your ranges are not overlapping');
          break;
        }

        if(ranges[idx].start > ranges[idx].end) {
          errors.push('Please include valid ranges. Range start should be less than range end');
          break;
        }

      }

      return errors;
    },
    setCountriesList() {
      this.countries = this.transformList(
          Array.from(
            new Set(this.properties?.map(property => property.Address?.country)?.filter(Boolean))
          ) || []
      );
    },
    setStatesList() {
      this.states = this.transformList(
          Array.from(
            new Set(this.properties?.map(property => property.Address?.state)?.filter(Boolean))
          ) || []
      );
    },
    setMarketRegionsList() {
      this.market_regions = this.transformList(
          Array.from(
            new Set(this.properties?.flatMap(property => property.MarketRegions?.map(region => region.name) || []))
          ) || []
      );
    },
    setOperationsManagersList() {
      let operationsManagers = this.properties?.map(property => property.operations_manager)?.filter(Boolean) || [];
      operationsManagers = this.admins?.filter(admin => operationsManagers.includes(admin.contact_id)) || [];
      this.operations_managers = operationsManagers.map(manager => ({ name: manager.name, label: manager.contact_id}));
    },
    setYearsList() {
      let current_year = +moment().format('YYYY');
      let years = [];
      for (let year = 1950; year <= current_year; year++) {
          years.push({
              name: year,
              label: year
          })
      }
      this.years_list = years;
    },
    setPerformanceCategoriesList() {
      let performanceCategories = this.propertyGroupTypes?.find(type => type.label === 'performance_category')?.options?.options || [];
      this.performance_categories = performanceCategories.map(
        category => ({ name: category.split('_').map(s => s[0].toUpperCase() + s.substring(1)).join(' '), label: category })
      );
    },
    setSameStoreClassList() {
      let sameStoreClasses = this.propertyGroupTypes?.find(type => type.label === 'same_store_class')?.options?.options?.range || [];
      this.same_store_classes = sameStoreClasses.map(storeClass => ({ name: storeClass, label: storeClass }));
      let sameStoreClassTypes = this.propertyGroupTypes?.find(type => type.label === 'same_store_class')?.options?.options?.type || [];
      this.same_store_class_types = this.transformList(sameStoreClassTypes);
    },
    transformList(list) {
      let transformedList = [];

      for(let i=0; i<list.length; i++) {
        let data = list[i];
        
        if(typeof data != 'object') {
          transformedList.push({ name: data, label: data })
        }
        else {
          transformedList.push(data)
        }
      }
      return transformedList;
    },
    getYearsList(i, key) {
      if (key === 'start' && i > 0) {
        const prevEnd = this.formData.year_opened[i - 1]?.end;
        return prevEnd? this.years_list.slice(this.years_list.findIndex(year => year.label === prevEnd) + 1) : [];
      }
      if (key === 'end') {
        const currentStart = this.formData.year_opened[i]?.start;
        return currentStart? this.years_list.slice(this.years_list.findIndex(year => year.label === currentStart)) : [];
      }
      return this.years_list;
    },
    getSameStoreClassList(i, key) {
      if (key === 'start' && i > 0) {
        const prevEnd = this.formData.same_store_class[i - 1]?.end;
        return prevEnd !== ''? this.same_store_classes.slice(prevEnd + 1) : [];
      }
      if (key === 'end') {
        const currentStart = this.formData.same_store_class[i]?.start;
        return currentStart !== ''? this.same_store_classes.slice(currentStart) : [];
      }
      return this.same_store_classes;
    }
  }
}
</script>

<style lang="scss" scoped>

.modal-height {
    height:568px
}

</style>
