<template>
  <div>
    <div class="send-quote-body-scroll mx-2 mb-2" :style="{ height: reportHeight + 'px' }" v-resize="setHeight">
      <v-expansion-panels elevation="0" class="hb-expansion-panel send-quote-hb-panel-div">
        <div v-for="(property, index) in selectedPropertyUnits" :key="'div_' + property.property.id"
          class="send-quote-hb-panel-div">
          <hb-expansion-panel :key="property.property.id" :left-cols="10" :right-cols="2">
            <template v-slot:title>
              <HbIcon mdi-code="mdi-facility-custom-2" color="#101318" />
              <span class="mt-1 ml-1 send-quote-property-title">
                {{ property?.property?.number }} -
                {{ property?.property?.name }} -
                {{ property?.property?.Address?.state }}
              </span>
              <span class="unites-counter" v-if="property?.units?.length">{{ property?.units?.length }}</span>
            </template>
            <template v-slot:content>
              <div class="mx-2 mb-2">
                <v-expansion-panels elevation="0" class="hb-expansion-panel">
                  <hb-expansion-panel v-for="unit in property.units" :key="unit.unit_id" :left-cols="10"
                    :right-cols="2">
                    <template v-slot:title>
                      <div>
                        <span class="send-quote-unit-title  ">
                          <HbSpaceIcon :type="unit.unit_type" color="#101318" />
                          <span class="send-quote-unit-header">
                            {{ unit.unit_type }} - {{ unit.unit_size }}
                          </span>
                        </span>
                        <div class="mt-1">
                          <span class="unit-amenities" style="text-align: left !important;">
                            {{ getFirstTwoAmenities(unit?.unit_amenities) }}
                          </span>
                        </div>
                      </div>
                    </template>
                    <template v-slot:actions>
                      <div class="mr-2">
                        <div>
                          <span class="unit-currency-display">
                            <span class="ma-0">{{ formattedUnitPrice("USD", unit.unit_price) }}</span>
                          </span>
                        </div>
                        <div class="pt-2">
                          <hb-link @click="removeUnit(property.property.id, unit.unit_id)">
                            Remove
                          </hb-link>
                        </div>
                      </div>

                    </template>
                    <template v-slot:content>
                      <div class="pa-4">
                        <div class="rent-details">
                          <span class="hb-font-header-3-medium">
                            Rent
                          </span>
                          <span class="hb-font-body">{{ calculateRentPeriod() }}</span>
                          <span class="hb-font-body amount hb-font-header-3-medium" style="text-align: right;">{{
                            formattedUnitPrice("USD", unit.unit_price) }}</span>
                        </div>
                        <div class="mt-3">
                          <hb-select :v-model="true" :items="unit?.unitLevelInsurances" :clearable="true" full
                            return-object item-text="name" item-value="id" placeholder="Select Coverage" single-line
                            @change="(e) => { onCoverageDiscountSelection(e, property.property.id, unit.unit_id, 'coverage') }"
                            background-color="white">
                            <template v-slot:selection="{ item }">
                              {{ item.name }} - &nbsp;<span style="color:#101318;">{{ `${item.premium_type
                                }${item.premium_value}` }}</span>
                            </template>
                            <template v-slot:item="{ item }">
                              {{ item.name }}
                            </template>
                          </hb-select>

                        </div>
                        <div class="mt-3">
                          <hb-btn
                            @click="showDialogEvent(property?.property?.id, unit?.unit_id, 'fees', property?.servicesByProperty?.feesProducts, unit?.selectedFees)"
                            color="secondary" small>+ Add
                            Fees</hb-btn>
                        </div>
                        <div v-if="unit?.selectedFees?.length">
                          <fees :feesServices="unit?.selectedFees" :unit_Id="unit?.unit_id"
                            :property_Id="property?.property?.id" :allPropertyUnits="selectedPropertyUnits" type="fees">
                          </fees>
                        </div>
                        <v-divider v-if="unit?.selectedFees?.length"></v-divider>
                        <div class="mt-3">
                          <hb-btn
                            @click="showDialogEvent(property?.property?.id, unit?.unit_id, 'merchandise', property?.servicesByProperty?.merchandiseProducts, unit?.selectedMerchandise)"
                            color="secondary" small>+ Add Merchandise</hb-btn>
                        </div>
                        <div v-if="unit?.selectedMerchandise?.length">
                          <merchandise :productServices="unit?.selectedMerchandise" :unit_Id="unit?.unit_id"
                            :property_Id="property?.property?.id" :allPropertyUnits="selectedPropertyUnits">
                          </merchandise>
                        </div>
                        <v-divider v-if="unit?.selectedMerchandise?.length"></v-divider>

                        <div class="mt-3 title-price-align-div">
                          <span class="hb-font-header-3-medium">Subtotal:</span>
                          <span class="hb-font-header-3-medium general-price">{{ calculateUnitSubtotal(unit) }}</span>
                        </div>
                        <v-divider></v-divider>
                        <div v-if="unit?.selectedDiscount && !unit.isSelectDiscount">
                          <fees :feesServices="[unit?.selectedDiscount]" :unit_Id="unit?.unit_id"
                            :property_Id="property?.property?.id" :allPropertyUnits="selectedPropertyUnits"
                            type="discount"></fees>
                        </div>
                        <div :class="(unit?.selectedDiscount) ? 'mt-1' : 'mt-3'" v-if="!unit.isSelectDiscount">
                          <hb-btn
                            @click="(e) => { (unit?.selectedDiscount) ? onShowWarning() : onCoverageDiscountSelection(e, property.property.id, unit.unit_id, 'show_discount') }"
                            color="secondary" small>+ Add Discount</hb-btn>
                        </div>
                        <div class="mt-4" v-if="unit.isSelectDiscount">
                          <hb-select :v-model="true" :items="property?.discounts" :clearable="true" full return-object
                            item-text="name" item-value="id" placeholder="Select Discount" single-line
                            @change="(e) => { onChangeDiscount(e) }" background-color="white">
                            <template v-slot:selection="{ item }">
                              {{ item.name }} - &nbsp;<span style="color:#101318;">{{ `${item.value}%` }}</span>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-list-item-content>
                                <v-list-item-title>
                                  <span class="hb-font-body-medium hb-text-night">{{ item?.name }}</span>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <span class="hb-font-caption hb-text-night-light">{{ item?.description }}</span>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </hb-select>
                        </div>
                        <div class="mt-3" v-if="unit.isSelectDiscount">
                          <hb-btn
                            @click="onCoverageDiscountSelection(tempSelectedDiscount, property.property.id, unit.unit_id, 'discount')"
                            color="primary" small>Apply Discount</hb-btn>
                        </div>
                        <v-divider v-if="unit?.selectedPromotions?.length" class="mt-3"></v-divider>
                        <div v-if="unit?.selectedPromotions?.length">
                          <fees :feesServices="unit?.selectedPromotions" :unit_Id="unit?.unit_id"
                            :property_Id="property?.property?.id" :allPropertyUnits="selectedPropertyUnits"
                            type="promotion">
                          </fees>
                        </div>
                        <div class="mt-4" v-if="unit.isSelectPromo">
                          <hb-select :v-model="true" :items="unit?.filteredPromotions" :clearable="true" full
                            return-object item-text="name" item-value="id" placeholder="Select Promotion" single-line
                            @change="(e) => { onCoverageDiscountSelection(e, property.property.id, unit.unit_id, 'promotion') }"
                            background-color="white">
                            <template v-slot:selection="{ item }">
                              {{ item.name }} - &nbsp;<span style="color:#101318;">{{ `${item.value}%` }}</span>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-list-item-content>
                                <v-list-item-title>
                                  <span class="hb-font-body-medium hb-text-night">{{ item?.name }}</span>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <span class="hb-font-caption hb-text-night-light">{{ item?.description }}</span>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </hb-select>
                        </div>
                        <div class="mt-3">
                          <hb-btn
                            @click="onCoverageDiscountSelection(null, property.property.id, unit.unit_id, 'show_promotion')"
                            color="secondary" small>+ Add Promotion</hb-btn>
                        </div>
                        <div class="mt-3 title-price-align-div">
                          <span class="hb-font-header-3">Tax:</span>
                          <span class="hb-font-header-3 general-price">{{ taxUnitCalculation(unit, property.propertyTax)
                            }}</span>
                        </div>
                        <div class="mt-3 title-price-align-div">
                          <span class="hb-font-header-3-medium">Total Charges:</span>
                          <span class="hb-font-header-3-medium general-price">{{ totalUnitCalculation(unit,
                            property.propertyTax) }}</span>
                        </div>
                      </div>
                    </template>

                  </hb-expansion-panel>
                </v-expansion-panels>

              </div>
            </template>
          </hb-expansion-panel>
          <HbBtn class="mt-4 mr-0" icon tooltip="Close" mdi-code="mdi-close-circle"
            @click="removeProperty(property.property.id)" />
        </div>
      </v-expansion-panels>
    </div>
    <div class="send-quote-total-cal pa-3">
      <div class="mt-3 title-price-align-div">
        <span class="hb-font-header-3-medium">Est. Total Balance Due</span>
        <span class="hb-font-header-3-medium general-price">{{ calculateGrandTotal(selectedPropertyUnits) }}</span>
      </div>
      <div class="mt-3 title-price-align-div">
        <span class="hb-font-body-medium">Est. Monthly Rent</span>
        <span class="hb-font-body-medium general-price">{{ calculateTotalRent(selectedPropertyUnits) }}</span>
      </div>
    </div>
    <add-service :products-list="productsList" :services="existingProductsList"
      @addSelectedServiceProd="addServiceProductListToUnit" :dialog-type="dialogType" v-model="productsDialog" />
  </div>
</template>

<script type="text/babel">
import api from '../../../assets/api.js';
import { EventBus } from '../../../EventBus.js'
import AddService from './SendQuoteAddServiceModal.vue'
import { mapGetters, mapActions } from 'vuex';
import { notificationMixin } from '@/mixins/notificationMixin.js';
import Merchandise from './SendQuoteMerchandise.vue';
import Fees from './SendQuoteFeesPromoAndDiscount.vue';

export default {
  name: 'SendQuoteSummary',
  components: {
    AddService,
    Merchandise,
    Fees,
  },
  data: () => ({
    key_setup: 0,
    setupPropertyModal: [],
    productsDialog: false,
    reportHeight: 0,
    dialogType: '',
    productsList: [],
    existingProductsList: [],
    tempUnitId: '',
    tempPropId: '',
    tempSelectedDiscount: ''
  }),
  mixins: [notificationMixin],
  async created() {
    this.setHeight();
  },
  computed: {
    ...mapGetters({

    }),
  },
  filters: {

  },
  props: ['search', 'condition', 'allSpaces', 'selectedPropertyUnits'],
  methods: {
    getFirstTwoAmenities(amenities) {
      if (!amenities) return '';
      return amenities.split(',').slice(0, 2).join(' | ');
    },
    formattedUnitPrice(currency, unit_price) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
      }).format(unit_price).replace(currency, `${currency}`);
    },
    calculateRentPeriod() {
      const options = { month: 'short', day: 'numeric' };

      // Get current date
      const currentDate = new Date();

      // Add 30 days to the current date
      const endDate = new Date();
      endDate.setDate(currentDate.getDate() + 30);

      // Format dates without the year
      const formattedStart = currentDate.toLocaleDateString('en-US', options);
      const formattedEnd = endDate.toLocaleDateString('en-US', options);

      // Return formatted string
      return `(${formattedStart} - ${formattedEnd})`;
    },
    setHeight() {
      let header = 72;
      let stepper = 100;
      let tabs = 48;
      let footer = 60;
      let heading = 122;
      let padding = 20;
      this.reportHeight = window.innerHeight - header - stepper - tabs - heading - footer - padding;
    },
    removeUnit(propId, unitId) {
      EventBus.$emit('remove_selected_unit', propId, unitId);
    },
    removeProperty(propId) {
      EventBus.$emit('remove_selected_property', propId);
    },
    roundToTwoDecimals(value) {
      return Math.round(value * 100) / 100;
    },
    onChangeDiscount(e) {
      this.tempSelectedDiscount = e;
    },
    async onShowWarning() {
      this.showMessageNotification({ type: 'error', description: 'Only one discount can be applied at a time. To apply a new discount, please remove the existing one.' });
    },
    async onCoverageDiscountSelection(e, propId, unitId, type, promId) {
      // Create a deep copy of the selectedPropertyUnits to avoid direct mutation
      let parsedData = JSON.parse(JSON.stringify(this.selectedPropertyUnits));

      // Map over the parsedData to find the matching property and unit, and update the selected insurance/discount
      const updatedData = parsedData.map(pData => {
        if (pData?.property?.id === propId) {
          // Iterate over units in the matching property
          pData.units = pData.units.map(uData => {
            if (uData?.unit_id === unitId) {
              // Update the selected insurance/discount for the specific unit
              if (type == "show_promotion") {
                uData.isSelectPromo = true;
              } else if (type == "promotion") {
                let allPromotion = JSON.parse(JSON.stringify(uData?.unitLevelPromotions))
                if (e) {
                  let unitPrice = parseFloat(uData?.unit_price || 0);
                  let promoPer = parseFloat(e?.value || 0);
                  e.price = this.roundToTwoDecimals(unitPrice * (promoPer / 100));
                  uData.selectedPromotions.push(e);
                } else {
                  uData.selectedPromotions = uData.selectedPromotions
                    .map((promoData) => {
                      if (promoData.id == promId) {
                        // remove the item
                        return null;
                      }
                      return promoData;
                    })
                    .filter((item) => item !== null); // Remove null items
                }
                const filteredArray = allPromotion.filter(item => !uData.selectedPromotions.some(subItem => subItem.id == item.id));
                uData.filteredPromotions =  filteredArray;
                uData.isSelectPromo = false;
              } else if (type == "show_discount") {
                uData.isSelectDiscount = true;
              } else if (type == "discount") {
                if (e) {
                  let unitPrice = parseFloat(uData?.unit_price || 0);
                  let discountPer = parseFloat(e?.value || 0);
                  e.price = this.roundToTwoDecimals(unitPrice * (discountPer / 100));
                }
                uData.selectedDiscount = e;
                uData.isSelectDiscount = false;
              } else {
                uData.selectedInsurance = e;

              }
            }
            return uData;
          });
        }
        return pData;
      });

      // Dispatch the updated data to the store
      await this.$store.dispatch('sendQuoteStore/getPropertyUnitsInfo', updatedData);
    },
    calculateUnitSubtotal(unit, type) {
      const unitPrice = parseFloat(unit?.unit_price || 0);
      const coveragePrice = parseFloat(unit?.selectedInsurance?.premium_value || 0);
      let merchandisePrice = 0;
      let feesPrice = 0;
      if (unit?.selectedMerchandise?.length) {
        unit?.selectedMerchandise.forEach((merchandiseData) => {
          let calMerAmount = parseFloat(merchandiseData.price) * parseFloat(merchandiseData.qty);
          merchandisePrice += calMerAmount
        })
      }
      if (unit?.selectedFees?.length) {
        unit?.selectedFees.forEach((feeData) => {
          let calFessAmount = parseFloat(feeData.price) * parseFloat(feeData.qty);
          feesPrice += calFessAmount
        })
      }
      const subTotal = unitPrice + coveragePrice + merchandisePrice + feesPrice;
      // Return the subtotal with a dollar sign prefix
      if (type == "total") {
        return this.roundToTwoDecimals(subTotal);
      }
      return `$${this.roundToTwoDecimals(subTotal)}`;
    },
    taxUnitCalculation(unit, taxTempalte, type) {
      const unitPrice = parseFloat(unit?.unit_price || 0);
      const coveragePrice = parseFloat(unit?.selectedInsurance?.premium_value || 0);
      let merchandisePrice = 0;
      let feesPrice = 0;
      let unitTaxPer = 0;
      let coverageTaxPer = 0;
      let merchandiseTaxPer = 0;
      let feesTaxPer = 0;
      if (unit?.selectedMerchandise?.length) {
        unit?.selectedMerchandise.forEach((merchandiseData) => {
          let calMerAmount = parseFloat(merchandiseData.price) * parseFloat(merchandiseData.qty);
          merchandisePrice += calMerAmount
        })
      }
      if (unit?.selectedFees?.length) {
        unit?.selectedFees.forEach((feeData) => {
          let calFessAmount = parseFloat(feeData.price) * parseFloat(feeData.qty);
          feesPrice += calFessAmount
        })
      }
      if (taxTempalte?.length > 0) {
        let findUnitTax = taxTempalte.find((tax) => {
          if (tax?.unit_type_id == unit.unit_type_id) {
            return tax
          }
          return false;
        })
        if (findUnitTax) {
          unitTaxPer = parseFloat(findUnitTax?.tax_rate);
        }
        let finsCoverageTax = taxTempalte.find((tax) => {
          if (tax?.type == "insurance") {
            return tax
          }
          return false;
        })
        if (finsCoverageTax) {
          coverageTaxPer = parseFloat(finsCoverageTax?.tax_rate);
        }
        let finsMerchandiseTax = taxTempalte.find((tax) => {
          if (tax?.type == "merchandise") {
            return tax
          }
          return false;
        })
        if (finsMerchandiseTax) {
          merchandiseTaxPer = parseFloat(finsMerchandiseTax?.tax_rate);
        }
        let finsFeeTax = taxTempalte.find((tax) => {
          if (tax?.type == "fee") {
            return tax
          }
          return false;
        })
        if (finsFeeTax) {
          feesTaxPer = parseFloat(finsFeeTax?.tax_rate);
        }
      }
      unitTaxPer = unitTaxPer / 100;
      coverageTaxPer = coverageTaxPer / 100;
      merchandiseTaxPer = merchandiseTaxPer / 100;
      feesTaxPer = feesTaxPer / 100;
      let unitTax = this.roundToTwoDecimals(unitPrice * unitTaxPer);
      let coverageTax = this.roundToTwoDecimals(coveragePrice * coverageTaxPer);
      let merchandiseTax = this.roundToTwoDecimals(merchandisePrice * merchandiseTaxPer);
      let feeTax = this.roundToTwoDecimals(feesPrice * feesTaxPer);
      const totalSubtotalTax = unitTax + coverageTax + merchandiseTax + feeTax;
      if (type == "total") {
        return this.roundToTwoDecimals(totalSubtotalTax);
      }
      return `$${this.roundToTwoDecimals(totalSubtotalTax)}`;
    },
    totalUnitCalculation(unit, taxTempalte, returnType) {
      let type = "total"
      const unitSubTotalTax = this.calculateUnitSubtotal(unit, type);
      const totalUnitTax = this.taxUnitCalculation(unit, taxTempalte, type);
      let unitPrice = parseFloat(unit?.unit_price || 0);
      let discountPer = parseFloat(unit?.selectedDiscount?.value || 0);
      const discountPrice = this.roundToTwoDecimals(unitPrice * (discountPer / 100));
      let promoDiscount = 0;
      unit.selectedPromotions.forEach((promo) => {
        promoDiscount += promo?.price || 0;
      });
      if (returnType == "number") {
        return this.roundToTwoDecimals(unitSubTotalTax + totalUnitTax - discountPrice - promoDiscount);
      }
      return `$${this.roundToTwoDecimals(unitSubTotalTax + totalUnitTax - discountPrice - promoDiscount)}`;
    },
    calculateUnitsTotal(units, propertyTax) {
      let unirGrandTotal = 0;
      units.forEach((unit) => {
        unirGrandTotal = unirGrandTotal + this.totalUnitCalculation(unit, propertyTax, "number")
      })
      return unirGrandTotal;
    },
    calculateUnitsRent(units) {
      let totalUnitRent = 0;
      units.forEach((unit) => {
        totalUnitRent = totalUnitRent + parseFloat(unit?.unit_price || 0)
      })
      return totalUnitRent;
    },
    calculateGrandTotal(allPropertyUnits) {
      var grandTotal = 0;
      if (allPropertyUnits?.length) {
        allPropertyUnits.forEach((propData) => {
          if (propData?.units?.length) {
            grandTotal = grandTotal + this.calculateUnitsTotal(propData.units, propData.propertyTax);
          }
        })
      }
      return `$${this.roundToTwoDecimals(grandTotal)}`;
    },
    calculateTotalRent(allPropertyUnits) {
      var totalRent = 0;
      if (allPropertyUnits?.length) {
        allPropertyUnits.forEach((propData) => {
          if (propData?.units?.length) {
            totalRent = totalRent + this.calculateUnitsRent(propData.units);
          }
        })
      }
      return `$${this.roundToTwoDecimals(totalRent)}`;
    },
    addFees() {

    },
    addMerchandise() {

    },

    addDiscount() {

    },
    addPromotion() {

    },
    calculateTax() {

    },
    calculateTotal() {

    },
    showDialogEvent(prop_id, unit_id, params, serviceProducts, existingProductsList) {
      this.tempPropId = prop_id;
      this.tempUnitId = unit_id;
      this.productsList = (serviceProducts?.length) ? serviceProducts : [];
      this.existingProductsList = (existingProductsList?.length) ? existingProductsList : [];
      this.dialogType = params;
      this.productsDialog = true;
    },
    async addServiceProductListToUnit({ productType, selectedProductList }) {
      await this.setSelectedServiceProduct(productType, selectedProductList)
      this.productsList = [];
      this.existingProductsList = [];
      this.dialogType = "";
      this.productsDialog = false;
    },
    async setSelectedServiceProduct(productType, selectedProd) {
      // Create a deep copy of the selectedPropertyUnits to avoid direct mutation
      let parsedData = JSON.parse(JSON.stringify(this.selectedPropertyUnits));

      // Map over the parsedData to find the matching property and unit, and update the selected service product
      const updatedData = parsedData.map(pData => {
        if (pData?.property?.id === this.tempPropId) {
          // Iterate over units in the matching property
          pData.units = pData.units.map(uData => {
            if (uData?.unit_id === this.tempUnitId) {
              // Update the selected service product
              if (productType == "merchandise") {
                let tempProd = (uData?.selectedMerchandise?.length) ? uData.selectedMerchandise : [];
                uData.selectedMerchandise = tempProd.concat(selectedProd).reduce((acc, current) => {
                  // If the id does not exist in the accumulator, add it
                  if (!acc.some(item => item.product_id === current.product_id)) {
                    acc.push(current);
                  }
                  return acc;
                }, []);
              } else {
                let tempProd = (uData?.selectedFees?.length) ? uData.selectedFees : [];
                uData.selectedFees = tempProd.concat(selectedProd).reduce((acc, current) => {
                  // If the id does not exist in the accumulator, add it
                  if (!acc.some(item => item.product_id === current.product_id)) {
                    acc.push(current);
                  }
                  return acc;
                }, []);
              }
            }
            return uData;
          });
        }
        return pData;
      });

      // Dispatch the updated data to the store
      await this.$store.dispatch('sendQuoteStore/getPropertyUnitsInfo', updatedData);
    }
  },
  watch: {
  }
}

</script>

<style scoped>
.send-quote-body-scroll {
  /* Adjust this value as needed */
  overflow-y: auto;
  /* Enables vertical scrolling when content exceeds max height */
}

.unites-counter {
  height: 21px;
  width: 21px;
  background-color: #F26500;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  text-align: center;
  padding: 4px 4px;
  border-radius: 50px;
  display: inline-block;
  margin-left: 8px;
  margin-top: 2px;
}

.unit-amenities-container {
  display: block !important;
}

.send-quote-unit-header {
  text-transform: capitalize;
}

.unit-amenities {
  /* display: block; */
  text-align: left !important;
  /* font-size: 14px; */
  color: #637381;
}

.unit-currency-display {
  display: inline-flex;
  /* Use flexbox for alignment */
  align-items: center;
  /* Align icon and text vertically */
  color: #101318;
  font-size: 14px;
  font-weight: 500;
  gap: 4px;
  /* Add gap between icon and text */
}

.send-quote-unit-title {
  align-items: center;
  display: flex;
  align-content: center;
  text-align: center;
  gap: 5px;
  border-bottom: 0px !important;
}

.send-quote-property-title {
  gap: 5px;
}

.send-quote-total-cal {
  position: fixed;
  bottom: 60px;
  left: 0;
  width: 56%;
  box-shadow: 0px -2px 4px 2px rgba(71, 84, 116, 0.1);
  background: rgba(224, 245, 245, 1);
  z-index: 1000;
}

.send-quote-hb-panel-div {
  display: flex;
  flex-wrap: nowrap;
  width: 96%;
}

.rent-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.rent-details .amount {
  margin-left: auto;
  text-align: right;
}

.title-price-align-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.general-price {
  text-align: right;
}
</style>
